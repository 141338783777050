













import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import DashboardStatsCard from '@/components/DashboardStatsCard.vue';

@Component({
  components: {
    DashboardStatsCard
  }
})
export default class OrderAmount extends Mixins(TaxonomyMixin) {
  @Prop({ default: '' })
  public dateRange!: string;
  private result: number = 0;
  private loading: boolean = true;
  @Prop({ default: null })
  public territoryId!: number | null;
  @Prop({ default: null })
  public userId!: number | null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;

    const params = {
      only: 'orderAmount',
      range: this.dateRange,
      territory_id: this.territoryId,
      user_id: this.userId
    };

    try {
      let { data } = await this.$http.get(this.$api(`/daily-reports${this.$toQueryString(params)}`));
      this.result = data.dailyReports.orderAmount.toLocaleString('en-US');
    } finally {
      // catch (e) {
      //
      // }
      this.loading = false;
    }
  }

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency'];
  }

  @Watch('dateRange')
  onChangeDateRange() {
    this.fetch();
  }

  @Watch('territoryId')
  onTerritoryId() {
    this.fetch();
  }

  @Watch('userId')
  onUserId() {
    this.fetch();
  }
}
