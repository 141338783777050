












































































































import {
    Vue,
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";

import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";

import {
    Drawer
} from "element-ui";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";

@Component({
    components: {
        DateRange,
        ProductLazydropdown,
        TerritoryCascaderDropdown,
        ChannelLazydropdown,
        BadgeLazydropdown,
        DepartmentLocationTypeLazyDropdown,
        DepartmentCategoryLazyDropdown,
        [Drawer.name]: Drawer,
        ProductBrandLazydropdown,
        ProductCategoryLazydropdown,
    }
})
export default class PromotionFilterDrawer extends Vue {
    @Prop({
        required: true
    })
    isFilterModelActive!: boolean;

    private drawer: boolean = false;
    private query: any = [];
    private afterQuery: boolean = false;

    @Prop({
        required: true
    })
    filter!: any;

    public promotionTypes = [{
            'label': 'Quantity',
            'name': 'qty'
        },
        {
            'label': 'Amount',
            'name': 'amount'
        },
        // {'label': 'Unit', 'name': 'unit'},
    ]
    public activatingTypes = [{
            'label': 'Flat',
            'name': 'flat'
        },
        {
            'label': 'Percent',
            'name': 'percent'
        },
    ]

    public promotionOn = [{
            'label': 'Product Brand',
            'name': 'brand'
        },
        {
            'label': 'Product Category',
            'name': 'category'
        },
        {
            'label': 'SKU',
            'name': 'product'
        }
    ]

    public status = [{
            'label': 'Live',
            'name': 'active'
        },
        {
            'label': 'Inactive',
            'name': 'inactive'
        },
        {
            'label': 'Draft',
            'name': 'draft'
        },
        {
            'label': 'Completed',
            'name': 'completed'
        },
    ]

    @Watch('isFilterModelActive')
    changeDrawerValue(newVal: any) {
        if (newVal) {
            this.drawer = true
        }
    }

    closeBtn(): void {
        this.$emit('submitFiltered', this.filter);
        this.drawer = false
        this.$emit('update:isFilterModelActive', false);
    }

    handleClose(done: any) {
        this.$emit('update:isFilterModelActive', false);
        done();
    }

    resetBtn() {
        this.$emit('update:filter', {
            title: '',
            start_date: '',
            end_date: '',
            expires_at_date_range: '',
            promotion_on: '',
            status: '',
            creator_ids: '',
            territory_ids: '',
            badge_ids: '',
            department_type_ids: '',
            department_category_ids: '',
            department_location_type_ids: '',
            promotion_able_ids: '',
            offer_product_ids: '',
        });
    }
}
