



































import {
    Vue,
    Component,
    Prop,
    Mixins,
    Watch
} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {
    AxiosResponse
} from 'axios';
import {
    Button
} from "element-ui";
import SMSPackage from "@/intefaces/SMSPackage";

@Component({
    components: {
        ElButton: Button,
    }
})
export default class SMSPackageForm extends Mixins(BaseCUModalComponent) {
    @Prop({
        type: Object,
        default: {}
    })
    currentEntity!: SMSPackage;
    @Prop({
        type: Object,
        default: {}
    })
    form!: SMSPackage;

    formReset(): any {
        this.form.name = '';
        this.form.amount = null;
        this.form.qty = null;
        this.form.price = null;
        this.form.status = false;
        return this.form;
    }

    async afterCreate(response: AxiosResponse < any > ): Promise < void > {
        this.$emit('created', response.data.sms_package);
    }

    afterUpdate(response: AxiosResponse < any > ): void {
        this.$emit('updated', response.data.sms_package)
    }

    landlordCheck(): boolean {
        return true;
    }

    get createApi(): string {
        return '/sms_package';
    }

    get updateApi(): string {
        return `/sms_package/${this.currentEntity.id}`;
    }

    created() {
        if (!this.isUpdate) {
            this.formReset();
        }
    }

    @Watch('form.amount')
    handleAmountChange() {
        this.calculateSMSQty();
    }

    @Watch('form.price')
    calculateSMSQty() {
        if (this.form.amount && this.form.price) {
            const qty = parseInt(String(this.form.amount), 10) / parseFloat(String(this.form.price));
            if (qty === Infinity || Number.isNaN(qty)) {
                this.form.qty = null;
            } else {
                this.form.qty = parseInt(Number(qty).toFixed());
            }
        } else {
            this.form.qty = null;
        }
    }

}
