

























































































import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import { Button, MessageBox } from "element-ui";
import Tenant from "@/intefaces/Tenant";
import VueClipboard from 'vue-clipboard2';
import { Tag } from 'element-ui';

Vue.use(VueClipboard);


@Component({
  components: {
    ElButton: Button,
    'el-tag': Tag
  }
})
export default class EditTenantForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: Tenant;
  @Prop({
    default: () => ({
      id: 0,
      name: '',
      domain: '',
      database: '',
      email: '',
      active: 0,
      address: '',
      phone_number: '',
      footer: '',
      created_at: '',
      updated_at: '',
    })
  })
  form!: {
    id?: number;
    name: string;
    domain: string;
    database: string;
    email: string;
    active?: number;
    address?: string;
    phone_number?: string;
    footer?: string;
    created_at?: string;
    updated_at?: string;
  };
  public token: any = null;
  private isNew: boolean = false;

  formReset(): any {
    this.form.id = 0;
    this.form.name = '';
    this.form.domain = '';
    this.form.database = '';
    this.form.email = '';
    this.form.active = 1;
    this.form.address = '';
    this.form.phone_number = '';
    this.form.footer = '';
    this.form.created_at = '';
    this.form.updated_at = '';
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.org);
  }

  @Watch('form.active')
  statusChange() {
    if (this.form.active == 0) {
      this.$notify({
        title: 'Are you sure you want to inactive this tenant ?',
        message: 'if change status active to inactive this tenant all token will be deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'warning',
      });
    }
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.org)
  }

  landlordCheck(): boolean {
    return true;
  }

  get createApi(): string {
    return '/orgs';
  }

  get updateApi(): string {
    return `/orgs/${this.currentEntity.id}`;
  }

  created() {
    if (!this.isUpdate) {
      this.formReset();
    }
  }

  mounted() {
    this.fetchToken();
  }

  onCopy() {
    this.$notify({
      message: 'Copied',
      title: 'Success',
      type: 'success'
    });
  }

  async generateNew() {
    this.isNew = true;
    MessageBox.alert('<span style="font-size: 14px"> Are you sure you want to generate a new API token? </span>', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      dangerouslyUseHTMLString: true,
      type: 'warning',
      center: true
    })
      .then(() => {
        this.$http.post(this.$landLordApi(`/tenant-api-token`), {
          "org_id": this.currentEntity.id,
          "update_token": 1
        })
          .then(response => {
            this.token = response.data.bearerToken;
            if (this.isNew) {
              this.$notify({
                message: 'API token successfully regenerated!',
                title: 'Success',
                type: 'success'
              });
            }
          });
      })
  }

  fetchToken() {
    this.$http.post(this.$landLordApi(`/tenant-api-token`), {
      "org_id": this.currentEntity.id,
      "update_token": 0
    })
      .then(response => {
        this.token = response.data.bearerToken;
      });
  }
}
