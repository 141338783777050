// @ts-ignore
import TransactionReport from '@/views/admin/transaction/transactionReport/TransactionReport.vue';
// @ts-ignore
import { RouteConfig } from 'vue-router';
// @ts-ignore
import Transaction from "@/views/admin/transaction/Transaction.vue";

// @ts-ignore
const TransactionRoutes: RouteConfig = {
    path: 'transaction',
    name: 'transaction',
    redirect: '/transaction/transaction-report',
    components: {dashboard: Transaction},
    children: [
        {
            path: 'transaction-report',
            name: 'transaction-report',
            // @ts-ignore
            label: 'Transaction',
            icon: 'ni ni-credit-card',
            component: TransactionReport
        }
    ]
};
export default TransactionRoutes;
