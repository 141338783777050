








































































































































































































import {
    Component,
    Vue,
    Watch,
    Mixins
} from "vue-property-decorator";
import {
    Popover,
    Switch,
    Select,
    Option,
    Alert,
    MessageBox
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
    components: {
        [Switch.name]: Switch,
        [Popover.name]: Popover,
        [Select.name]: Select,
        [Option.name]: Option,
        [Alert.name]: Alert,
    }
})

export default class Order extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
    public createdComponent: boolean = true;
    public needSaveButtonPress: boolean = false;
    // For Order Reference Settings
    public orderReferenceStatus: boolean = false;
    public loading: boolean = false;
    public updateReference: boolean = false;
    public invoiceBackOrder: boolean = false;
    public initPage: boolean = true;

    public orderReferences: any = [];
    public newOrderReferences: any = {
        code: '',
        value: ''
    };

    // For Bank Info Settings
    public loadingBankInfo: boolean = false;
    public bankInfoStatus: boolean = false;
    public updateBankInfo: boolean = false;
    public bankInfos: any = [];
    public newBankInfo: any = {
        bank_name: '',
        ac_name: '',
        ac_no: '',
        branch_name: ''
    };
    public tempBankInfo: any = {};

    created() {
        this.pagination.entityPerPage = 10;
        this.fetchSettings();
        this.fetchReference();
        this.fetchBankInfo();
        setTimeout(() => {
            this.initPage = false;
        }, 2000);
    }

    @Watch('orderReferenceStatus')
    @Watch('bankInfoStatus')
    detectChanges() {
        if (this.createdComponent) {
            setTimeout(() => {
                this.createdComponent = false;
            }, 1000);
        } else {
            this.needSaveButtonPress = true;
        }
    }

    updateSettings(message: string | null = null) {
        this.needSaveButtonPress = false;
        this.$http.patch(this.$api('/settings/1'), {
            order: {
                references_status: this.orderReferenceStatus,
                bank_infos_status: this.bankInfoStatus,
                invoice_back_order: this.invoiceBackOrder
            }
        }).then(response => {
            if (response.status == 200) {
                this.updateNotification('Success', message ?? 'Settings successfully updated', 'success');
                localStorage.setItem('settings', JSON.stringify(response.data.setting));
            } else {
                this.updateNotification('Something went wrong!', 'Settings could not be updated', 'warning');
            }
        }).catch(error => {
            (this.$refs.formValidator as Vue & {
                setErrors: (errors: []) => any;
            }).setErrors(error.response.data.errors);
            this.updateNotification('Something went wrong!', 'Please contact your service provider.', 'warning');
        }).finally(
            () => (this.loading = false)
        );
    }

    fetchSettings() {
        this.$http.get(this.$api(`/settings`)).then(response => {
            if (response.data.settings.order != null) {
                const order = JSON.parse(response.data.settings.order);
                if (order != null) {
                    this.orderReferenceStatus = order.references_status;
                    this.bankInfoStatus = order.bank_infos_status;
                    this.invoiceBackOrder = order.invoice_back_order;
                }
            }
        });
    }

    updateNotification(title: string, message: string, type: string = 'success') {
        this.$notify({
            title: title,
            message: message,
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            // @ts-ignore
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            // @ts-ignore
            type: type
        });
    }

    @Watch('pagination.currentPage')
    fetchReference() {
        this.$http.get(this.$api(`/order-reference?page=${this.pagination.currentPage}`)).then(response => {
            this.orderReferences = response.data.order_references.data;
            this.setPagination(response.data.order_references);
        });
    }

    addUpdateReference() {
        this.loading = true;

        const {
            value,
            _id
        } = this.newOrderReferences;
        const isNewReference = !_id;

        if (!value) {
            this.updateNotification('Error', 'Please fill the Value field', 'warning');
            this.loading = false;
            return;
        }

        const url = isNewReference ? '/order-reference' : `/order-reference/${_id}`;
        const method = isNewReference ? 'post' : 'patch';

        this.$http[method](this.$api(url), this.newOrderReferences)
            .then(response => {
                const action = isNewReference ? 'added' : 'updated';
                this.updateNotification('Success', `Order Reference ${action} successfully`, 'success');
                this.fetchReference();
                this.resetNewOrderReferences();
                this.updateReference = false;
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    const errorMessage = error.response.data.errors.code ? error.response.data.errors.code[0] : 'Invalid data';
                    this.updateNotification('Duplicate Code', errorMessage, 'warning');
                } else {
                    this.updateNotification('Error', 'Something went wrong', 'warning');
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    resetNewOrderReferences() {
        this.newOrderReferences = {
            code: null,
            value: null
        };
    }

    deleteReference(reference: any) {
        this.loading = true;
        this.$http.delete(this.$api(`/order-reference/${reference._id}`)).then(response => {
            this.updateNotification('Success', 'Order Reference deleted successfully', 'success');
            this.fetchReference();
        }).catch(error => {
            this.updateNotification('Error', 'Something went wrong', 'warning');
        }).finally(() => {
            this.loading = false;
        });
    }

    updateOrderReference(reference: any) {
        this.newOrderReferences = {
            code: reference.code,
            value: reference.value,
            _id: reference._id
        };
        this.updateReference = true;
    }

    // For Bank Info
    fetchBankInfo() {
        this.$http.get(this.$api('/payment-bank-infos')).then(response => {
            this.bankInfos = response.data.bank_infos;
        });
    }

    addUpdateBankInfo() {
        this.loadingBankInfo = true;

        const {
            bank_name,
            _id
        } = this.newBankInfo;
        const isNewBankInfo = !_id;

        if (!bank_name) {
            this.updateNotification('Error', 'Please fill the Value field', 'warning');
            this.loadingBankInfo = false;
            return;
        }

        const url = isNewBankInfo ? '/payment-bank-infos' : `/payment-bank-infos/${_id}`;
        const method = isNewBankInfo ? 'post' : 'patch';

        this.$http[method](this.$api(url), this.newBankInfo)
            .then(response => {
                const action = isNewBankInfo ? 'added' : 'updated';
                this.updateNotification('Success', `Bank info ${action} successfully`, 'success');
                this.fetchBankInfo();
                this.resetNewBankInfo();
                this.updateBankInfo = false;
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    const errorMessage = error.response.data.errors.bank_name ? error.response.data.errors.bank_name[0] : 'Invalid data';
                    this.updateNotification('Duplicate bank info', errorMessage, 'warning');
                } else {
                    this.updateNotification('Error', 'Something went wrong', 'warning');
                }
            })
            .finally(() => {
                this.loadingBankInfo = false;
            });
    }

    resetNewBankInfo() {
        this.newBankInfo = {
            bank_name: null,
            ac_name: null,
            ac_no: null,
            branch_name: null
        };
    }

    deleteBankInfo(bank_info: any) {
        this.loadingBankInfo = true;
        this.$http.delete(this.$api(`/payment-bank-infos/${bank_info._id}`)).then(response => {
            this.updateNotification('Success', 'Bank info deleted successfully', 'success');
            this.fetchBankInfo();
        }).catch(error => {
            this.updateNotification('Error', 'Something went wrong', 'warning');
        }).finally(() => {
            this.loadingBankInfo = false;
        });
    }

    updateBankInfos(bank_info: any) {
        this.newBankInfo = {
            bank_name: bank_info.bank_name,
            ac_name: bank_info.ac_name,
            ac_no: bank_info.ac_no,
            branch_name: bank_info.branch_name,
            _id: bank_info._id
        };
        this.updateBankInfo = true;
    }

    @Watch('invoiceBackOrder')
    changeIncoiceBackOrder(value: boolean) {
      if(value && !this.initPage){
        MessageBox.alert('<span style="font-size: 14px">' +
        'Please review the information carefully. Once the status changes to active, any detected changes to the product information on an ' + this.getTaxonomy('invoice') + ' will require the ' + this.getTaxonomy('invoice') + ' to be returned to the ' + this.getTaxonomy('order') + ' for approval.', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true
        })
      .catch(() => {
        this.invoiceBackOrder = !this.invoiceBackOrder;
      });
    }
  }
}
