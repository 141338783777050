





















































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import TaxonomyMixin from '../../../mixins/TaxonomyComponent';
import { Invoice } from '@/intefaces/Invoice';
import OrderDepartmentInfo from '@/views/order/Components/OrderDepartmentInfo.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import BaseOrderProducts from '@/views/order/Components/BaseOrderProducts.vue';
import BaseOrderComment from '@/views/order/BaseOrderComment.vue';
import { OrderStatus as OrderStatusEnum } from '@/enums/OrderStatus';
import BaseOrderCal from '@/views/order/Components/BaseOrderCal.vue';
import OrderStatus from '@/views/order/Components/OrderStatusButtons.vue';
import OrderEdit from '@/views/order/Components/OrderEdit.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';

@Component({
  components: {
    OrderDepartmentInfo,
    BaseOrderProducts,
    BaseOrderComment,
    OrderStatus,
    BaseOrderCal,
    OrderEdit
  }
})
export default class ReadyForDeliveryInvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() title: string | undefined;
  @Prop() isActive: boolean | undefined;
  @Prop() currentEntity!: any;
  @Prop() orderIncludeLot!: boolean;
  @Prop() orderTransportEnable!: boolean;
  public editable: boolean = false;
  private revisionList: OrderRevision[] = [];
  private statuses: any = [];
  private subTotal: number = 0;
  private salesReturn: number = 0;
  private discount: number = 0;
  private returnProducts: any = [];
  private saveEditedOrder: boolean = false;
  public deliveryObject: any = {};
  public errorMessages: any = [];
  // public productDiscount: number = 0;

  created() {
    this.errorMessages = [];
    this.deliveryObject = {};
    // this.productDiscount = this.currentEntity.latest_revision.invoiced_products.reduce((acc: number, product: any) => {
    //   return acc + parseFloat(product.promotion_discount) + parseFloat(product.custom_discount)
    // }, 0);
  }

  @Watch('isActive')
  fetchRevisionAndStatusList(newVal: boolean) {
    if (newVal) {
      this.$http.get(this.$api(`/invoices/${this.currentEntity.id}/revisions`)).then(resposne => {
        this.revisionList = resposne.data.revisions;
      });
      this.$http
        .get(
          this.$api(`/orders/${this.currentEntity.order_id}/statuses?statuses=
      ${OrderStatusEnum.STATUS_ACCEPTED},${OrderStatusEnum.STATUS_CANCELLED}`)
        )
        .then(response => {
          this.statuses = response.data.statuses;
        });
      // this.$http.get(this.$api(`/sales-returns?order_id=${this.currentEntity.id}`)).then(resposne => {
      //   if(resposne.data.salesReturns.length > 0){
      //     this.returnProducts = resposne.data.salesReturns[0].returned_products;
      //     this.salesReturn = resposne.data.salesReturns[0].total_amount;
      //   }
      // });
      // this.subTotal = JSON.parse(this.currentEntity.latest_revision).amount;
      if(this.currentEntity.latest_revision.returned_products){
       this.salesReturn = this.currentEntity.latest_revision.returned_products.reduce((acc: any, item: any) => {
        return acc + parseFloat(item.amount);
       }, 0);
      }
      this.subTotal = this.currentEntity.latest_revision.amount;
      this.discount = this.currentEntity.latest_revision.discount;
    }
  }

  updateStatus(event: any) {
    this.$emit('updatedStatus', event.invoice);
  }

  get permissionEnum() {
    return PermissionEnum;
  }

  close(): void {
    this.$emit('update:isActive', false);
    this.editable = false;
    this.saveEditedOrder = false;
    this.errorMessages = [];
  }

  @Watch('deliveryObject')
  initDelivery(deliveryObject: any) {
    if (deliveryObject.delivery) {
      const products: any = [];
      let productDiscount: number = 0;
      this.currentEntity.latest_revision.invoiced_products.forEach((product: any) => {
        products.push({
          product_id: product.product_id,
          price: product.price,
          qty: product.qty,
          free_with: product.free_with,
          promotion_discount: product.promotion_discount,
          custom_discount: product.custom_discount,
          promotion_id: product.promotion ? product.promotion.id : null,
          lot_number: product.lot_number,
        });
        productDiscount += parseFloat(product.promotion_discount) + parseFloat(product.custom_discount);
      });
      this.$http.post(this.$api(`/orders/${this.currentEntity.order_id}/deliveries`), {
          buyer_id: this.currentEntity.buyer_id,
          seller_id: this.currentEntity.seller_id,
          amount: parseFloat(this.currentEntity.latest_revision.amount) + productDiscount,
          discount: this.discount,
          promotion_discount: this.currentEntity.promotion_discount,
          products: products,
          comment: deliveryObject.remarks
        })
        .then(response => {
          // this.$emit('updatedStatus', this.currentEntity);
          this.$notify({
            title: 'Success',
            message: 'Delivered Successfully',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          this.close();
        })
        .catch(error => {
          this.errorMessages = [];
          if (error.response.data.errors) {
            this.currentEntity.latest_revision.invoiced_products.forEach((product: any, index: number) => {
              if (error.response.data.errors['products.' + index + '.product_id'][0]) {
                this.errorMessages.push({name: product.product.full_name , error: error.response.data.errors['products.' + index + '.product_id'][0] });
                return;
              }
              if (error.response.data.errors['products.' + index + '.qty'][0]) {
                this.errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.qty'][0] });
                return;
              }
              if (error.response.data.errors['products.' + index + '.free_with'][0]) {
                this.errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.free_with'][0] });
                return;
              }
              if (error.response.data.errors['products.' + index + '.promotion_id'][0]) {
                this.errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.promotion_id'][0] });
                return;
              }
              if (error.response.data.errors['products.' + index + '.promotion_discount'][0]) {
                this.errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.promotion_discount'][0] });
                return;
              }
              if (error.response.data.errors['products.' + index + '.custom_discount'][0]) {
                this.errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.custom_discount'][0] });
                return;
              }
            });
          }
        });
    }
  }
}
