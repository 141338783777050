import { RouteConfig } from 'vue-router';
// @ts-ignore
// import Sales from '@/views/Report/Sales/Sales.vue';
// @ts-ignore
// import Product from '@/views/Report/Product/Product.vue';
// @ts-ignore
import Report from '@/views/Report/Report.vue';
// @ts-ignore
// import VisitReports from '@/views/Report/Visit/VisitReports.vue';
import { PermissionHelper } from "@/classes/PermissionHelper";
import { Permission } from '@/enums/Permission';
// @ts-ignore
import KPIReports from '@/views/Report/KPI/KPIReports.vue';
// @ts-ignore
import ProductBasedReport from '@/views/Report/ProductBasedReport/ProductBasedReport.vue';
// @ts-ignore
import HRReport from '@/views/Report/HRReport/index.vue';
// @ts-ignore
import PerformanceReport from '@/views/Report/Performance/index.vue';
// @ts-ignore
import TAReport from '@/views/Report/HRReport/TAReport.vue';
// @ts-ignore
import TrackingReport from '@/views/Report/HRReport/TrackingReport.vue';
// @ts-ignore
import CheckInReport from '@/views/Report/HRReport/CheckInReport.vue';
// @ts-ignore
import VisitReport from '@/views/Report/HRReport/VisitReport.vue';
// @ts-ignore
import DailyKPIReport from '@/views/Report/Performance/DailyKpiReport.vue';
// @ts-ignore
import UserWisePerformanceReport from '@/views/Report/Performance/UserWisePerformanceReport.vue';
// @ts-ignore
import DeptWisePerformanceReport from '@/views/Report/Performance/DeptWisePerformanceReport.vue';
// @ts-ignore
import NoOrderPerformanceReport from '@/views/Report/Performance/NoOrderPerformanceReport.vue';
// @ts-ignore
import DailyCallPerformanceReport from '@/views/Report/Performance/DailyCallPerformanceReport.vue';
// @ts-ignore
import PerformanceBoardReport from '@/views/Report/Performance/PerformanceBoardReport.vue';
// @ts-ignore
import ProductTargetAchievementReport from '@/views/Report/Performance/ProductTargetAchievementReport.vue';
// @ts-ignore
import StockReport from '@/views/Report/Stock/index.vue';
// @ts-ignore
import TheStockReport from '@/views/Report/Stock/StockReport.vue';
// @ts-ignore
import StockSummaryReport from '@/views/Report/Stock/StockSummaryReport.vue';
// @ts-ignore
import StockHistoryReport from '@/views/Report/Stock/StockHistoryReport.vue';
// @ts-ignore
import StockReportDetailsReport from '@/views/Report/Stock/StockSummaryDetailsReport.vue';
// @ts-ignore
import AttendanceReport from '@/views/Report/HRReport/AttendanceReport.vue';
// @ts-ignore
import LoginHistoryReport from '@/views/Report/HRReport/LoginHistoryReport.vue';
// @ts-ignore
import DayInDayOutReport from '@/views/Report/HRReport/DayInDayOutReport.vue';
// @ts-ignore
import SystemReport from '@/views/Report/System/index.vue';
// @ts-ignore
// @ts-ignore
import NotOrderedOutletListReport from '@/views/Report/Performance/NotOrderedOutletListReport.vue';
// @ts-ignore
import ECO from "@/views/Report/ECO/index.vue";
// @ts-ignore
import ECOReport from "@/views/Report/ECO/eco-report.vue";
// @ts-ignore
import ECOReportSummary from "@/views/Report/ECO/eco-report-summary.vue";
// @ts-ignore
import OrderSummary from "@/views/Report/OrderSummary/index.vue";
// @ts-ignore
import DeliverySummary from "@/views/Report/DeliverySummary/index.vue";
// @ts-ignore
import CheckinSummary from "@/views/Report/CheckinSummary/index.vue";
// @ts-ignore
import CheckinSummaryDetails from "@/views/Report/CheckinSummary/CheckinSummaryDetails.vue";
// @ts-ignore
import KPIReportByOrder from '@/views/Report/KPI/KPIReportByOrder.vue';
// @ts-ignore
import TargetAchievementReport from "@/views/Report/Performance/TargetAchievementReport.vue";
// @ts-ignore
import Outlet from "@/views/Report/Outlet/index.vue";
// @ts-ignore
import orderRejection from "@/views/Report/OrderRejection/index.vue";
// @ts-ignore
import PaymentReport from "@/views/Report/Payment/PaymentReport.vue";
// @ts-ignore
import bounceRate from "@/views/Report/BounceRate/index.vue";
// @ts-ignore
import VisitCoverageReport from "@/views/Report/VisitCoverage/index.vue";
// @ts-ignore
import PriorityProductReport from "@/views/Report/PriorityProductReport/index.vue";
// @ts-ignore
import ProductPriceHistoryReport from "@/views/Report/ProductPriceHistoryReport/index.vue";
// @ts-ignore
// @ts-ignore
import LateReport from '@/views/Report/HRReport/LateReport.vue';
import SalesReturnReport from "@/views/Report/SalesReturn/index.vue";
import SystemHistoriesReport from "@/views/Report/System/SystemHistoriesReport.vue";
import DepartmentTargetAchievementReport from "@/views/Report/Performance/DepartmentTargetAchievementReport.vue";

// Redirect to user's authorized (has permission) tab
function redirectToUserAuthorizedRoute() {
    return "/report/" + PermissionHelper([
        Permission.KPI_REPORT,
        Permission.TARGET_VS_ACHIEVEMENT_REPORT,
        Permission.DEPARTMENT_TARGET_VS_ACHIEVEMENT_REPORT,
        Permission.ORDER_SUMMARY_REPORT,
        Permission.DELIVERY_SUMMARY_REPORT,
        Permission.PAYMENT_SUMMARY_REPORT,
        Permission.ECO_REPORT,
        Permission.OUTLET_REPORT,
        Permission.ORDER_REJECTION_REPORT,
        Permission.BOUNCE_RATE_REPORT,
        Permission.STOCK_SUMMARY_REPORT,
        Permission.CHECKIN_SUMMARY_DETAILS_REPORT,
        Permission.STOCK_SUMMARY_DETAILS_REPORT,
        Permission.PRIORITY_PRODUCT_REPORT,
        Permission.PRODUCT_PRICE_HISTORY_REPORT,
        Permission.STOCK_HISTORY_REPORT,
    ], [{permission: Permission.KPI_REPORT, name: 'kpi'},
        {permission: Permission.TARGET_VS_ACHIEVEMENT_REPORT, name: 'target-achievement-report'},
        {permission: Permission.DEPARTMENT_TARGET_VS_ACHIEVEMENT_REPORT, name: 'department-target-achievement'},
        {permission: Permission.ORDER_SUMMARY_REPORT, name: 'order-summary'},
        {permission: Permission.DELIVERY_SUMMARY_REPORT, name: 'delivery-summary'},
        {permission: Permission.PAYMENT_SUMMARY_REPORT, name: 'payment-summary'},
        {permission: Permission.ECO_REPORT, name: 'eco-report'},
        {permission: Permission.OUTLET_REPORT, name: 'outlet-report'},
        {permission: Permission.ORDER_REJECTION_REPORT, name: 'order-rejection'},
        {permission: Permission.BOUNCE_RATE_REPORT, name: 'bounce-rate'},
        {permission: Permission.STOCK_SUMMARY_REPORT, name: 'stock-summary-report'},
        {permission: Permission.CHECKIN_SUMMARY_DETAILS_REPORT, name: 'checkin-summary-details'},
        {permission: Permission.STOCK_SUMMARY_DETAILS_REPORT, name: 'stock-summary-details-report'},
        {permission: Permission.PRIORITY_PRODUCT_REPORT, name: 'priority-product-report'},
        {permission: Permission.PRODUCT_PRICE_HISTORY_REPORT, name: 'product-price-history-report'},
        {permission: Permission.STOCK_HISTORY_REPORT, name: 'stock-history-report'}
    ]);
}

const ReportRoutes: RouteConfig = {
    path: 'report',
    name: 'report',
    // @ts-ignore
    redirect: to => {
        return redirectToUserAuthorizedRoute();
    },
    components: {dashboard: Report},
    children: [
        // {
        //     path: 'sales',
        //     name: 'sales-report',
        //     meta: {
        //         label: 'Sales',
        //         icon: 'fas fa-hand-holding-usd',
        //         breadCrumb: 'sales'
        //     },
        //     components: { report: Sales }
        // },
        // {
        //     path: 'product',
        //     name: 'product-report',
        //     meta: {
        //         label: 'Product',
        //         icon: 'fas fa-th',
        //         breadCrumb: 'product'
        //     },
        //     components: { report: Product }
        // },
        // {
        //     path: 'visit-target',
        //     name: 'visit-target',
        //     meta: {
        //         label: 'Visit',
        //         icon: 'fas fa-car-side',
        //         breadCrumb: 'visit-target'
        //     },
        //     components: { report: VisitReports }
        // },
        {
            path: 'kpi',
            name: 'kpi',
            meta: {
                label: 'KPI',
                icon: 'fa fa-flag-checkered',
                breadCrumb: 'kpi'
            },
            components: {report: KPIReports},
            children: [
                {
                    path: 'based-on-order',
                    name: 'kpi-report-based-on-order',
                    meta: {
                        label: 'KPI Report Based On Order',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'product-based-report'
                    },
                    components: {KPIReports: KPIReportByOrder}
                }]
        },
        {
            path: 'product-based-report',
            name: 'product-based-report',
            meta: {
                label: 'Product Based',
                icon: 'fas fa-list',
                breadCrumb: 'product-based-report'
            },
            components: {report: ProductBasedReport}
        },
        {
            path: 'hr-report',
            name: 'hr-report',
            components: {report: HRReport},
            meta: {
                label: 'HR',
                icon: 'fas fa-bus',
                breadCrumb: 'hr-report'
            },
            children: [
                {
                    path: 'ta-report',
                    name: 'ta-report',
                    meta: {
                        label: 'TA Report',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'product-based-report'
                    },
                    components: {hrReport: TAReport}
                },
                {
                    path: 'tracking-report',
                    name: 'tracking-report',
                    meta: {
                        label: 'Tracking Report',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'product-based-report'
                    },
                    components: {hrReport: TrackingReport}
                },
                {
                    path: 'checkin-report',
                    name: 'checkin-report',
                    meta: {
                        label: 'Check-In Report',
                        icon: 'ni ni-bullet-list-69',
                        breadCrumb: 'product-based-report'
                    },
                    components: {hrReport: CheckInReport}
                },
                {
                    path: 'attendance-report',
                    name: 'attendance-report',
                    meta: {
                        label: 'Attendance Report',
                        icon: 'ni ni-bullet-list-69',
                        breadCrumb: 'attendance-report'
                    },
                    components: {hrReport: AttendanceReport}
                },
                {
                    path: 'login-history-report',
                    name: 'login-history-report',
                    meta: {
                        label: 'Login History Report',
                        icon: 'ni ni-bullet-list-69',
                        permission: Permission.VIEW_LOGIN_HISTORY_REPORT,
                        breadCrumb: 'login-history-report'
                    },
                    components: {hrReport: LoginHistoryReport}
                },
                {
                    path: 'visit-report',
                    name: 'visit-report',
                    meta: {
                        label: 'Visit Report',
                        icon: 'ni ni-bullet-list-69',
                        permission: Permission.HR_VISIT_REPORT,
                        breadCrumb: 'visit-report'
                    },
                    components: {hrReport: VisitReport}
                },
                {
                    path: 'day-in-day-out-report',
                    name: 'day-in-day-out-report',
                    meta: {
                        label: 'Day In Day Out Report',
                        icon: 'ni ni-bullet-list-69',
                        permission: Permission.HR_DAY_IN_DAY_OUT_REPORT,
                        breadCrumb: 'day-in-day-out-report'
                    },
                    components: {hrReport: DayInDayOutReport}
                },
                {
                    path: 'late-report',
                    name: 'late-report',
                    meta: {
                        label: 'Late Report',
                        icon: 'ni ni-bullet-list-69',
                        permission: Permission.HR_LATE_REPORT,
                        breadCrumb: 'late-report'
                    },
                    components: {hrReport: LateReport}
                },
            ]
        },
        {
            path: 'performance-report',
            name: 'performance-report',
            components: {report: PerformanceReport},
            meta: {
                label: 'Performance',
                icon: 'ni ni-paper-diploma',
                breadCrumb: 'performance-report'
            },
            children: [
                {
                    path: 'daily-kpi-report',
                    name: 'daily-kpi-report',
                    meta: {
                        label: 'Daily KPI Report',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'daily-kpi-report'
                    },
                    components: {performanceReport: DailyKPIReport}
                },
                {
                    path: 'user-wise-product-performance-report',
                    name: 'user-wise-product-performance-report',
                    meta: {
                        label: 'User-Wise Product Performance Report',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'user-wise-product-performance-report'
                    },
                    components: {performanceReport: UserWisePerformanceReport}
                },
                {
                    path: 'dept-wise-product-performance-report',
                    name: 'dept-wise-product-performance-report',
                    meta: {
                        label: 'Department-Wise Product Performance Report',
                        icon: 'ni ni-bullet-list-67',
                        breadCrumb: 'dept-wise-product-performance-report'
                    },
                    components: {performanceReport: DeptWisePerformanceReport}
                },
                {
                    path: 'no-order-report',
                    name: 'no-order-report',
                    meta: {
                        label: 'No Order Report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'no-order-report'
                    },
                    components: {performanceReport: NoOrderPerformanceReport}
                },
                {
                    path: 'daily-call-report',
                    name: 'daily-call-report',
                    meta: {
                        label: 'Daily call report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'daily-call-report'
                    },
                    components: {performanceReport: DailyCallPerformanceReport}
                },
                {
                    path: 'performance-board-report',
                    name: 'performance-board-report',
                    meta: {
                        label: 'Performance Board report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'performance-board-report'
                    },
                    components: {performanceReport: PerformanceBoardReport}
                },
                {
                    path: 'product-target-achievement-report',
                    name: 'product-target-achievement-report',
                    meta: {
                        label: 'Product Target Achievement Report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'product-target-achievement-report'
                    },
                    components: {performanceReport: ProductTargetAchievementReport}
                },
                {
                    path: 'not-ordered-outlet-list-report',
                    name: 'not-ordered-outlet-list-report',
                    meta: {
                        label: 'Not Ordered Outlet List Report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'not-ordered-outlet-list-report'
                    },
                    components: {performanceReport: NotOrderedOutletListReport}
                }
            ]
        },
        {
            path: 'stock-report',
            name: 'stock-report',
            components: {report: StockReport},
            meta: {
                label: 'Stock',
                icon: 'ni ni-basket',
                breadCrumb: 'stock-report'
            },
            children: [
                {
                    path: 'the-stock-report',
                    name: 'the-stock-report',
                    meta: {
                        label: 'Stock report',
                        icon: 'ni ni-button-power',
                        permission: Permission.VIEW_REPORT,
                        breadCrumb: 'the-stock-report'
                    },
                    components: {StockReport: TheStockReport}
                },
                {
                    path: 'stock-summary-report',
                    name: 'stock-summary-report',
                    meta: {
                        label: 'Stock Summary Report',
                        icon: 'ni ni-button-power',
                        permission: Permission.STOCK_SUMMARY_REPORT,
                        breadCrumb: 'stock-summary-report'
                    },
                    components: {StockReport: StockSummaryReport}
                },
                {
                    path: 'stock-summary-details-report',
                    name: 'stock-summary-details-report',
                    meta: {
                        label: 'Stock Summary Details Report',
                        icon: 'ni ni-button-power',
                        permission: Permission.STOCK_SUMMARY_DETAILS_REPORT,
                        breadCrumb: 'stock-summary-details-report'
                    },
                    components: {StockReport: StockReportDetailsReport}
                },
                {
                    path: 'stock-history-report',
                    name: 'stock-history-report',
                    meta: {
                        label: 'Stock History Report',
                        icon: 'ni ni-button-power',
                        permission: Permission.STOCK_HISTORY_REPORT,
                        breadCrumb: 'stock-history-report'
                    },
                    components: {StockReport: StockHistoryReport}
                }
            ]
        },
        {
            path: 'system-report',
            name: 'system-report',
            components: {report: SystemReport},
            meta: {
                label: 'System',
                icon: 'ni ni-chart-pie-35',
                breadCrumb: 'system-report'
            },
            children: [
                // {
                //     path: 'active-user-report',
                //     name: 'active-user-report',
                //     meta: {
                //         label: 'Active User report',
                //         icon: 'ni ni-button-power',
                //         permission: Permission.ACTIVE_USER_REPORT,
                //         breadCrumb: 'active-user-report'
                //     },
                //     components: {SystemReport: ActiveUserReport}
                // },
                {
                    path: 'system-histories-report',
                    name: 'system-histories-report',
                    meta: {
                        label: 'System Histories Report',
                        icon: 'fas fa-history',
                        permission: Permission.VIEW_SYSTEM_HISTORY_REPORT,
                        breadCrumb: 'system-histories-report',
                    },
                    components: {SystemReport: SystemHistoriesReport}
                },
            ]
        },
        // {
        //     path: 'eco',
        //     name: 'eco-report',
        //     meta: {
        //         label: 'Eco',
        //         icon: 'ni ni-atom',
        //         permission: Permission.ECO_REPORT,
        //         breadCrumb: 'eco'
        //     },
        //     components: {report: ECO}
        // },
        {
            path: 'eco',
            name: 'eco',
            components: {report: ECO},
            meta: {
                label: 'Eco',
                icon: 'ni ni-atom',
                permission: Permission.ECO_REPORT,
                breadCrumb: 'eco'
            },
            children: [
                {
                    path: 'eco-report',
                    name: 'eco-report',
                    meta: {
                        label: 'Eco Report',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'eco-report'
                    },
                    components: {EcoReport: ECOReport}
                },
                {
                    path: 'eco-report-summary',
                    name: 'eco-report-summary',
                    meta: {
                        label: 'Eco Report Summary',
                        icon: 'ni ni-button-power',
                        permission: Permission.ECO_REPORT,
                        breadCrumb: 'eco-report-summary'
                    },
                    components: {EcoReport: ECOReportSummary}
                }
            ]
        },
        {
            path: 'order-summary',
            name: 'order-summary',
            meta: {
                label: 'Order Summary',
                icon: 'ni ni-cart',
                permission: Permission.ORDER_SUMMARY_REPORT,
                breadCrumb: 'order-summary'
            },
            components: {report: OrderSummary}
        },
        {
            path: 'delivery-summary',
            name: 'delivery-summary',
            meta: {
                label: 'Delivery Summary',
                icon: 'ni ni-delivery-fast',
                permission: Permission.DELIVERY_SUMMARY_REPORT,
                breadCrumb: 'delivery-summary'
            },
            components: {report: DeliverySummary}
        },
        {
            path: 'checkin-summary',
            name: 'checkin-summary',
            meta: {
                label: 'Checkin Summary',
                icon: 'ni ni-square-pin',
                breadCrumb: 'checkin-summary'
            },
            components: {report: CheckinSummary},
            children: [
                {
                    path: 'details',
                    name: 'checkin-summary-details',
                    components: {CheckinSummary: CheckinSummaryDetails},
                    meta: {
                        label: 'Checkin Summary Details',
                        icon: 'ni ni-square-pin',
                        permission: Permission.CHECKIN_SUMMARY_DETAILS_REPORT,
                        breadCrumb: 'checkin-summary-details'
                    },
                }
            ]
        },
        {
            path: 'target-achievement',
            name: 'target-achievement-report',
            meta: {
                label: 'Target Achievement',
                icon: 'ni ni-button-power',
                permission: Permission.TARGET_VS_ACHIEVEMENT_REPORT,
                breadCrumb: 'target-achievement'
            },
            components: {report: TargetAchievementReport}
        },
        {
            path: 'department-target-achievement',
            name: 'department-target-achievement-report',
            meta: {
                label: 'Dept. Target Achievement',
                icon: 'ni ni-button-power',
                permission: Permission.DEPARTMENT_TARGET_VS_ACHIEVEMENT_REPORT,
                breadCrumb: 'department-target-achievement'
            },
            components: {report: DepartmentTargetAchievementReport}
        },
        {
            path: 'outlet-report',
            name: 'outlet-report',
            meta: {
                label: 'Outlet Report',
                icon: 'ni ni-shop',
                permission: Permission.OUTLET_REPORT,
                breadCrumb: 'outlet-report'
            },
            components: {report: Outlet},
        },
        {
            path: 'payment-summary',
            name: 'payment-summary',
            meta: {
                label: 'Payment Summary',
                icon: 'fas fa-wallet',
                permission: Permission.PAYMENT_SUMMARY_REPORT,
                breadCrumb: 'payment-summary'
            },
            components: {report: PaymentReport},
        },
        {
            path: 'order-rejection',
            name: 'order-rejection',
            meta: {
                label: 'Order Rejection',
                icon: 'ni ni-box-2',
                permission: Permission.ORDER_REJECTION_REPORT,
                breadCrumb: 'order-rejection',
            },
            components: {report: orderRejection}
        },
        {
            path: 'bounce-rate',
            name: 'bounce-rate',
            meta: {
                label: 'Bounce Rate',
                icon: 'ni ni-istanbul',
                permission: Permission.BOUNCE_RATE_REPORT,
                breadCrumb: 'bounce-rate',
            },
            components: {report: bounceRate}
        },
        {
            path: 'priority-product-report',
            name: 'priority-product-report',
            meta: {
                label: 'Priority Product',
                icon: 'fas fa-level-up-alt',
                permission: Permission.PRIORITY_PRODUCT_REPORT,
                breadCrumb: 'priority-product-report',
            },
            components: {report: PriorityProductReport}
        },
        {
            path: 'product-price-history-report',
            name: 'product-price-history-report',
            meta: {
                label: 'Product Price History',
                icon: 'fas fa-history',
                permission: Permission.PRIORITY_PRODUCT_REPORT,
                breadCrumb: 'product-price-history-report',
            },
            components: {report: ProductPriceHistoryReport}
        },
        {
            path: 'visit-coverage-report',
            name: 'visit-coverage-report',
            meta: {
                label: 'Visit Coverage',
                icon: 'fas fa-map-marked-alt',
                permission: Permission.VIEW_VISIT_COVERAGE_REPORT,
                breadCrumb: 'visit-coverage-report',
            },
            components: {report: VisitCoverageReport}
        },
        {
            path: 'sales-return-report',
            name: 'sales-return-report',
            meta: {
                label: 'Sales Return',
                icon: 'ni ni-bag-17',
                permission: Permission.SALES_RETURN_REPORT,
                breadCrumb: 'sales-return-report',
            },
            components: {report: SalesReturnReport}
        }

    ]
};
export default ReportRoutes;
