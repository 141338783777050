import { Component, Vue } from 'vue-property-decorator';

// @ts-ignore
@Component
abstract class HasTenantModules extends Vue {
    public tenentModules: { [key: string]: boolean } = {}; // Initialize as an empty object

    // All modules are available in the landlord's modules table
    public async checkTenantModule(moduleName: any = null) {
        if (moduleName === null) {
            return false;
        }
        try {
            const response = await this.$http.get(this.$api(`/check-module/${moduleName}`));
            if (moduleName.includes(',')) {
                this.tenentModules = response.data;
            } else {
                this.$set(this.tenentModules, moduleName, !!response.data[moduleName]); // Convert to boolean using !! and set the value
            }
        } catch (error) {
            window.console.error('Error fetching tenant module:', error);
            this.$set(this.tenentModules, moduleName, false); // Set to false if there is an error
        }
    }
}

// @ts-ignore
@Component
//@ts-ignore
export default class TenantModulesMixin extends HasTenantModules { }
