

































































































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Table,
    TableColumn,
    Tag,
    Tooltip
} from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import InvoiceDetails from '@/views/order/invoice/InvoiceDetails.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import {
    Invoice
} from '@/intefaces/Invoice';
import InvoiceAmountDetailsForm from '@/views/order/invoice/InvoiceAmountDetailsForm.vue';
import InvoicePaymentDetailsForm from '@/views/order/invoice/InvoicePaymentDetailsForm.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import RevisionList from '@/views/order/RevisionList.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import {
    Permission as PermissionEnum
} from '@/enums/Permission';
import DispatchInvoiceDetails from '@/views/order/readyToDispatch/DispatchInvoiceDetails.vue';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import moment from 'moment';
import TenantModulesMixin from '@/mixins/HasTenantModules';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';

@Component({
    components: {
        FilterDrawer,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Tag.name]: Tag,
        GIFModal,
        InvoiceDetails,
        InvoiceAmountDetailsForm,
        InvoicePaymentDetailsForm,
        RevisionList,
        DispatchInvoiceDetails,
        FilterDrawerComponent,
        DateRangeForReport,
        UserLazydropdown,
        TerritoryLazydropdown,
        DepartmentLazydropdown
    }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin, TenantModulesMixin) {
    private invoices: Invoice[] = [];
    private currentInvoice: any = [];
    private allRevision: OrderRevision[] = [];
    private invoiceDetails: Invoice[] = [];
    private showGIF: boolean = false;
    private demoTourEntity: string = 'invoice';
    private isShowAmountDetails: boolean = false;
    private isShowPaymentDetails: boolean = false;
    private isActiveInvoiceDetails: boolean = false;
    private isFilterModelActive = false;
    private renderKey: number = 0;
    private queryBuilder = new QueryBuilder();
    public order_type: string = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';

    private form = {
        status: '',
        remarks: ''
    };
    private GIFs: Array < any > = [{
            label: 'Create a invoice',
            url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
        },
        {
            label: 'Update a invoice',
            url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
        },
        {
            label: 'Delete a invoice',
            url: 'https://www.youtube.com/embed/LeAltgu_pbM'
        }
    ];
    private demoTourTitle: string = 'A quick demo of invoice';

    public filters: any = {
        range: moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'),
        code: '',
        created_by: '',
        buyer_id: '',
        terrtory_id: ''
    };

    get permissionEnum() {
        return PermissionEnum;
    }

    entityUpdated(entity: Invoice): void {
        this.invoices.splice(
            this.invoices.findIndex(type => type.id == entity.id),
            1
        );
        this.isActiveInvoiceDetails = false;
    }
    onFilterSubmit() {
        this.isFilterModelActive = false;
    }
    resetBtn() {
        this.filters.range = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
        this.filters.code = '';
        this.filters.created_by = '';
        this.filters.buyer_id = '';
        this.filters.territory_id = '';
    }

    @Watch('invoiceDetails')
    async updateRenderKey() {
        await this.fetchInvoices();
        this.renderKey += 1;
    }

    @Watch('filters.range', {
        deep: true
    })
    onChangeSubmissionDateRange(value: string) {
        this.queryBuilder.addListener('created_between', value);
    }

    @Watch('filters.code', {
        deep: true
    })
    onChangeOrderCode(value: string) {
        this.queryBuilder.addListener('code', value);
    }

    @Watch('filters.created_by', {
        deep: true
    })
    onChangeCreatedBy(value: string) {
        this.queryBuilder.addListener('created_by', value);
    }

    @Watch('filters.buyer_id', {
        deep: true
    })
    onChangeBuyerId(value: string) {
        this.queryBuilder.addListener('buyer_id', value);
    }

    @Watch('filters.territory_id', {
        deep: true
    })
    onChangeTerritoryId(value: string) {
        this.queryBuilder.addListener('territory_id', value);
    }

    @Watch('pagination.currentPage')
    @Watch('queryBuilder.watch')
    async fetchInvoices() {
        const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
        this.loading = true;
        let queryFilters = this.queryBuilder.getFilters(true);
        queryFilters += queryFilters.includes('?') ? '&' : '?';
        if (!queryFilters.includes('created_between')) {
            queryFilters += `created_between=${this.filters.range}`;
        }
        queryFilters += `&latest_status=readyToDispatch&with=payments&page=${this.pagination.currentPage}&order_type=${order_type}`;
        try {
            let {
                data
            } = await this.$http.get(
                this.$api3(`/invoices${queryFilters}`)
            );
            this.setPagination(data);
            this.invoices = data.invoices;
        } catch (e) {
            this.$notify({
                title: 'Something Went Wrong!',
                message: 'Please Try again.',
                type: 'error'
            });
        } finally {
            this.loading = false;
        }
    }

    async created() {
        await this.fetchInvoices();
        this.checkTenantModule('order_include_lot,order_transportation');
    }
}
