




















































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    Button
} from 'element-ui';

@Component({
    components: {
        [Button.name]: Button
    }
})
export default class Delivery extends Vue {
    public reports: any[] = [];
    public loading: boolean = false;

    fetchResults() {
        this.loading = true;
        this.$http.get(this.$api3('/custom-report?type=delivery')).then((response) => {
            this.reports = response.data;
            this.reports.forEach((value: any) => {
                value['tooltip'] = 'Report will be generated only for custom delivery report.';
                value['path'] = 'download-report-delivery';
            }); 
        }).finally(() => {
            this.loading = false;
        });
    }

    created() {
        this.fetchResults();
    }
}
