var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isActive)?_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal',{attrs:{"show":_vm.isActive},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(_vm._s(_vm.isUpdate ? 'Update' : 'Add New')+" Territory")])]),_c('div',[_c('TerritoryTypeAutocomplete',{attrs:{"name":"Territory Type","label":"Territory Type","placeholder":"Select","create-button":true,"rules":{ required: true }},on:{"level":function($event){return _vm.onChangeTerritoryLevel($event)}},model:{value:(_vm.form.territory_type_id),callback:function ($$v) {_vm.$set(_vm.form, "territory_type_id", $$v)},expression:"form.territory_type_id"}}),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"name":"Territory Name","rules":{ required: true },"label":"Territory Name","placeholder":"e.g. Laraville"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"name":"code","rules":{ required: true },"label":"Territory Code","placeholder":"e.g. DHK-101"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('el-row',{attrs:{"gutter":10}},[(!_vm.isUpdate)?_c('el-col',{attrs:{"span":12}},[_c('MemberAutocomplete',{attrs:{"label":"Territory Manager","name":"Territory Manager","placeholder":"Assign a manager","end-point":'users?non_manager',"multiple":true,"value":_vm.isUpdate && _vm.form.manager
                ? _vm.form.manager.id == _vm.form.managed_by
                  ? ((_vm.form.manager.name) + " | " + (_vm.form.manager.email))
                  : _vm.form.managed_by
                : _vm.form.managed_by},on:{"input":function($event){_vm.form.managed_by = $event}}})],1):_vm._e(),(_vm.requireParent)?_c('el-col',{attrs:{"span":12}},[(_vm.isUpdate)?_c('div',[_c('TerritoryAutocomplete',{attrs:{"label":"Parent Territory","name":"Parent Territory","rules":{ required_if: _vm.requireParent },"placeholder":"Select","value":_vm.isUpdate && _vm.form.parent
                  ? _vm.form.parent.id == _vm.form.parent_id
                    ? ((_vm.form.parent.name) + " | " + (_vm.form.parent.code))
                    : _vm.form.parent_id
                  : _vm.form.parent_id},on:{"input":function($event){_vm.form.parent_id = $event}}})],1):_c('div',[_c('label',{attrs:{"for":"parent_territory"}},[_vm._v("Parent Territory")]),_c('el-select',{attrs:{"filterable":"","allow-create":"","default-first-option":"","placeholder":"Parent Territory"},model:{value:(_vm.form.parent_id),callback:function ($$v) {_vm.$set(_vm.form, "parent_id", $$v)},expression:"form.parent_id"}},[_c('el-option',{attrs:{"label":"Parent Territory"}}),_vm._l((_vm.territoryList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1)]):_vm._e()],1)],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v("Close")]),_c('base-button',{attrs:{"type":"success","loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v(_vm._s(_vm.isUpdate ? 'Update' : 'Save'))])],1)],2)]}}],null,false,2289070814)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }