// @ts-ignore
import Subscription from '@/views/admin/subscription/Subscription.vue';
import SubscriberList from '@/views/admin/subscription/subscriber/SubscriberList.vue';
import { RouteConfig } from 'vue-router';

// @ts-ignore
const SubscriptionRoutes: RouteConfig = {
    path: 'subscription',
    name: 'subscription',
    redirect: '/subscription/subscribers',
    components: { dashboard: Subscription },
    children: [
        {
            path: 'subscribers',
            name: 'subscribers',
            // @ts-ignore
            label: 'Subscribers',
            icon: 'fa fa-thumbs-up',
            component: SubscriberList
        },
    ]
};

export default SubscriptionRoutes;
