

















































import {
  Component,
  Mixins,
  Prop,
  Watch
} from "vue-property-decorator";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
// @ts-ignore
import PieChart from '@/components/argon-core/Charts/PieChart';

import moment from 'moment';

@Component({
  components: {
    PieChart,
  },
})
export default class YearToYearGrowthComparisonReport extends Mixins(TaxonomyMixin) {
  @Prop({default: ''})
  public range!: string;

  @Prop({default: null})
  public territoryId!: number | null;

  @Prop({default: null})
  public userIds!: string | null;

  @Prop({default: null})
  public departmentIds!: string | null;

  @Prop({default: null})
  public brandIds!: string | null;

  @Prop({default: null})
  public viewBaseOn!: string | null;

  @Prop({default: null})
  public valueType!: string | null;

  @Prop({default: null})
  public limitBrand!: number | null;

  @Prop({default: false})
  public isGenerateReport!: boolean;

  private loading: boolean = true;

  public growthList: any[] = [];
  public headerData: any = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    this.growthList = [];
    this.headerData = null;
    const params = {
      type: 'yearToYearGrowth',
      range: this.range,
      territory_id: this.territoryId,
      user_ids: this.userIds,
      department_ids: this.departmentIds,
      brand_ids: this.brandIds,
      view_base_on: this.viewBaseOn,
      value_type: this.valueType,
      limit_brand: this.limitBrand
    };

    try {
      let {data} = await this.$http.get(this.$api3(`/brand-report${this.$toQueryString(params)}`))
      this.growthList = data.yearToYearGrowthComparison.results;
      this.headerData = data.yearToYearGrowthComparison.headerData;
    }
      // catch (e) {
      //
      // }
    finally {
      this.loading = false
    }
  }

  @Watch('key')
  onKeyChange() {
    this.fetch();
  }

}
