






















































































































































import { Component, Vue } from 'vue-property-decorator';
import PromotionForm from '@/components/products/PromotionForm.vue';
import ProductImage from '@/components/products/ProductImage.vue';
import PromotionViewForm from '@/components/products/PromotionViewForm.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import Productable from '@/intefaces/Productable';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    ProductImage,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    PromotionForm,
    PromotionViewForm,
    GIFModal
  }
})
export default class Promotion extends Vue {
  private isActive = false;
  private isUpdate = false;
  private isView = false;
  private showDeleteModal = false;
  private users: any = [];
  private productBrands: Productable[] = [];
  private productCategories: Productable[] = [];
  private products: any = [];
  private promotions: any = [];
  private currentPromotion: any = [];
  private disableButton = false;
  private loading = false;
  private showGIF: boolean = false;
  private form = {
    title: '',
    type: 'all',
    date: '',
    users: [],
    offerType: '',
    promotionValue: '',
    image: '',
    promotionableId: '',
    offerProduct: '',
    offerValue: '',
    promotionType: '',
    startDate: '',
    active: false,
  };
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private userPagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private productBrand = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private productCategoryPagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private productPagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private demoTourEntity: string = 'productPromotion';
  private GIFs: Array<any> = [
    {
      label: 'Create a promotion',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a promotion',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a promotion',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of promotion';

  get permissionEnum () {
    return PermissionEnum;
  }

  pomotionCreated(promotion: any) {
    this.isActive = false;
    this.promotions.unshift(promotion);
  }

  promotionUpdated(promotion: any) {
    this.isUpdate = false;
    this.isActive = false;
    this.promotions.splice(
      this.promotions.findIndex((promotion: any) => promotion.id == this.currentPromotion.id),
      1,
      promotion
    );
  }

  deletePromotion() {
    this.disableButton = true;
    this.$http
      .delete(this.$api(`/promotions/${this.currentPromotion.id}`))
      .then(response => {
        this.disableButton = false;
        this.showDeleteModal = false;
        this.promotions.splice(
          this.promotions.findIndex((promotion: any) => promotion.id == this.currentPromotion.id),
          1
        );
        this.$notify({
          title: 'Success',
          message: 'Successfully Deleted',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch(error => {
        this.disableButton = false;
      });
  }

  onEdit(row: any) {
    this.isUpdate = true;
    this.currentPromotion = row;
    var users: any = [];
    this.currentPromotion.assignees.forEach((assigne: any) => {
      users.push(assigne.id);
    });
    this.form = {
      title: this.currentPromotion.title,
      type: this.currentPromotion.promotion_on,
      date: this.currentPromotion.expires_at,
      users: users,
      offerType: this.currentPromotion.offer_type,
      promotionValue: this.currentPromotion.promotion_value,
      image: this.isUpdate ? this.currentPromotion.banner_url : '',
      promotionableId: this.currentPromotion.promotionable_id != null ? this.currentPromotion.promotionable_id : '',
      offerProduct: this.currentPromotion.offer_product_id != null ? this.currentPromotion.offer_product_id : '',
      offerValue: this.currentPromotion.offer_value,
      promotionType: this.currentPromotion.promotion_type,
      startDate: this.currentPromotion.start_from,
      active: this.currentPromotion.active === 1 ? true : false
    };
    this.isActive = true;
  }

  fetchUser() {
    let isFirstPage = this.userPagination.currentPage < 2;
    let url = isFirstPage ? '/users' : `/users?page=${this.userPagination.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.users = response.data.users;
      this.userPagination.total = response.data.total;
      this.userPagination.perPage = response.data.per_page;
    });
  }

  fetchProductBrand() {
    let isFirstPage = this.productBrand.currentPage < 2;
    let url = isFirstPage ? '/product-brands' : `/product-brands?page=${this.productBrand.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.productBrands = response.data.productBrands;
      this.productBrand.total = response.data.total;
      this.productBrand.perPage = response.data.per_page;
    });
  }

  fetchProductCategory() {
    let isFirstPage = this.productCategoryPagination.currentPage < 2;
    let url = isFirstPage ? '/product-categories' : `/product-categories?page=${this.productCategoryPagination.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.productCategories = response.data.productCategories;
      this.productCategoryPagination.total = response.data.total;
      this.productCategoryPagination.perPage = response.data.per_page;
    });
  }

  fetchProduct() {
    let isFirstPage = this.productPagination.currentPage < 2;
    let url = isFirstPage ? '/products' : `/products?page=${this.productPagination.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.products = response.data.products;
      this.productPagination.total = response.data.total;
      this.productPagination.perPage = response.data.per_page;
    });
  }

  fetchPromotion() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage ? '/promotions' : `/promotions?page=${this.pagination.currentPage}`;
    this.$http.get(this.$api(url)).then(response => {
      this.promotions = response.data.promotions;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  created() {
    this.fetchUser();
    this.fetchProductBrand();
    this.fetchProductCategory();
    this.fetchProduct();
    this.fetchPromotion();
    // this.todaysDate();
  }
}
