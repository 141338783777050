




































  import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import DepartmentType from '@/intefaces/DepartmentType.js';

@Component({
  components: {}
})
export default class CreateChannelForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: DepartmentType;

  @Prop({ default: () => ({ name: '', label: '', has_outlet: false, self_managed:false }) })
  form!: {
    name: string;
    label: string;
    has_outlet: boolean;
    self_managed:boolean
  };

  @Prop()
  private departmentTypes!: DepartmentType[];
  public departmentHasOutletUpdate: boolean = false;

  formReset(): any {
    this.form.name = '';
    this.form.label = '';
    this.form.has_outlet = false;
    this.form.self_managed = false;
    return this.form;
  }

  created() {
    if (this.form.has_outlet) {
      this.departmentHasOutletUpdate = true;
    }
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.departmentType);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.departmentType);
  }

  get createApi(): string {
    return '/department-types';
  }

  get updateApi(): string {
    return `/department-types/${this.currentEntity.id}`;
  }

  @Watch('form.has_outlet')
  onChangeOutlet(value:boolean){
    if (value){
      this.form.self_managed=false;
    }
  }

  @Watch('form.self_managed')
  onChangeManaged(value:boolean){
    if (value){
      this.form.has_outlet=false;
    }
  }
}
