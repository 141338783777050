






























import {
    Vue,
    Watch,
    Prop,
    Component
} from 'vue-property-decorator';
import {
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup
} from "element-ui";

@Component({
    components: {
        [Menu.name]: Menu,
        [Submenu.name]: Submenu,
        [MenuItem.name]: MenuItem,
        [MenuItemGroup.name]: MenuItemGroup
    }
})

export default class SubmenuNavbar extends Vue {
    public activeTab: number = 0;
    public collapseMenu: boolean = false;
    @Prop({
        default: []
    }) routes!: any;

    mounted() {
        this.setActiveTab();
    }

    setActiveTab() {
        if (this.routes.children === undefined) return;
        this.routes.children.forEach((route: any, index: number) => {
            if (this.$route.name === route.name) {
                this.activeTab = index + 1;
            }
        });
    }

    get menuName() {
        const menuName = this.routes.name;
        return menuName.charAt(0).toUpperCase() + menuName.slice(1);
    }

    handleMenuSelect(index: number) {
        this.activeTab = index;
    }

    handleMenuClick(route: any) {
        this.$router.push(this.$router.resolve({name:route.name}).href);
    }

}
