






















































































































import {
  Component,
  Mixins,
  Watch
} from "vue-property-decorator";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Tag,
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import EditTenantForm from "@/views/admin/subscription/tenant/EditTenantForm.vue";
import ViewFeaturePermission from "@/views/admin/package/feature/ViewFeaturePermission.vue";
import GIFModal from "@/components/tour/GIFModal.vue";
import Tenant from "@/intefaces/Tenant";
import LazyDropdown from "@/components/LazyDropdown.vue";
import {
  QueryBuilder
} from '@/classes/QueryBuilder';
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import PriorityProductView from "@/components/products/PriorityProductView.vue";
import TenantApiTokenModal from "@/views/admin/tenant/tenant/TenantApiTokenModal.vue";

@Component({
  components: {
    TenantApiTokenModal,
    GIFModal,
    ViewFeaturePermission,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Tag.name]: Tag,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    EditTenantForm,
    FilterDrawerComponent,
    LazyDropdown
  }
})
export default class TenantList extends Mixins(PaginationComponentMixin) {
  private tenants: Tenant[] = [];
  private showGIF: boolean = false;
  private currentEntity: any = [];
  private showTokenModal: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private demoTourEntity: string = 'tenant';
  private demoTourTitle: string = 'A quick demo of tenant';
  public isFilterModelActive = false;
  private queryBuilder = new QueryBuilder();
  private GIFs: Array<any> = [{
    label: 'Create a role',
    url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
  },
    {
      label: 'Update a role',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a role',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];

  private form: Tenant = {
    id: 0,
    name: '',
    domain: '',
    database: '',
    email: '',
    active: false,
    address: '',
    phone_number: '',
    footer: '',
    created_at: '',
    updated_at: '',
  }

  public filters: any = {
    id: null,
    active: null,
    domain: null,
  };

  public options: any = [{
    value: '1',
    label: 'Active'
  }, {
    value: '0',
    label: 'Inactive'
  }];

  entityCreated(entity: Tenant) {
    this.tenants.unshift(entity);
  }

  entityUpdated(entity: Tenant) {
    this.tenants.splice(this.tenants.findIndex(tenant => tenant.id == entity.id), 1, entity);
  }

  onEdit(row: Tenant) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.isActive = true;
    this.form = {
      id: this.currentEntity.id,
      name: this.currentEntity.name,
      domain: this.currentEntity.domain,
      database: this.currentEntity.database,
      email: this.currentEntity.email,
      active: this.currentEntity.active,
      address: this.currentEntity.address,
      phone_number: this.currentEntity.phone_number,
      footer: this.currentEntity.footer,
      created_at: this.currentEntity.created_at,
      updated_at: this.currentEntity.updated_at,
    }
  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  fetchTenants() {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/orgs${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?'}&page=${this.pagination.currentPage}`))
      .then(response => {
        this.tenants = response.data.orgs;
        this.setPagination(response.data);
        this.loading = false;
      })
  }

  created() {
    this.fetchTenants();
  }

  resetBtn() {
    this.filters.id = null;
    this.filters.active = null;
    this.filters.domain = null;
  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }

  @Watch('filters.id', {
    deep: true
  })
  onChangeNameFilter(newVal: string) {
    this.queryBuilder.addListener('id', newVal);
  }

  @Watch('filters.active', {
    deep: true
  })
  onChangeActiveFilter(newVal: string) {
    this.queryBuilder.addListener('active', newVal);
  }

  @Watch('filters.domain', {
    deep: true
  })
  onChangeDomainFilter(newVal: string) {
    this.queryBuilder.addListener('domain', newVal);
  }
}
