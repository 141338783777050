
































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import RevisionList from "@/views/order/Components/RevisionList.vue";

@Component({
  name: 'PromotionViewForm',
  components: {
    RevisionList,
    BadgeLazydropdown,
    ChannelLazydropdown,
    ProductLazydropdown,
    ProductCategoryLazydropdown,
    DepartmentCategoryLazyDropdown,
    ProductBrandLazydropdown,
    DepartmentLocationTypeLazyDropdown,
    TerritoryCascaderDropdown
  }
})
export default class PromotionDetails extends Vue {
  @Prop() isView!: boolean;
  @Prop() currentPromotion!: any;

  capitalize(string: string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  close() {
    this.$emit('update:isView', false);
  }
}
