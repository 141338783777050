


































































































































































import {
  Component,
  Mixins,
  Watch,
  Prop
} from 'vue-property-decorator';
import DepartmentTargetInfo from '@/views/user/target/DepartmentTargetInfo.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {
  Badge,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tag,
  Tooltip
} from 'element-ui';
import Target from '@/intefaces/Target';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import {
  QueryBuilder
} from '@/classes/QueryBuilder';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import AsyncImage from '@/components/AsyncImage.vue';
import {
  Permission as PermissionEnum
} from '@/enums/Permission';

const flatPicker = require('vue-flatpickr-component');

@Component({
  components: {
    AsyncImage,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Badge.name]: Badge,
    [Drawer.name]: Drawer,
    [Tag.name]: Tag,
    GIFModal,
    FilterDrawerComponent,
    flatPicker,
    DepartmentTargetInfo,
  }
})
export default class DepartmentTargetInfoList extends Mixins(TaxonomyMixin) {
  public isAdded = false;
  public targets: Target[] = [];
  public types: any = [{
    value: 'amount',
    name: 'Amount'
  },
    {
      value: 'qty',
      name: 'Quantity'
    }
  ];
  public targetTypes: any = [{
    value: 'primary',
    name: 'Primary'
  }, {
    value: 'secondary',
    name: 'Secondary'
  }
  ];
  public filters: any = {
    month: '',
    target_type: '',//add
    department_type_id: '',//add
    department_code: '',
    department_name: '',
    value_type: '',
    category: '',
    brand: '',
    product_sku: '',
    status: ''
  };
  public showDeleteModal = false;
  public currentTarget: any = [];
  public isUpdate = false;
  public loading: boolean = false;
  public isFilterModelActive: boolean = false;
  public showGIF: boolean = false;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public form: any = {
    month: '',
    value_type: '',
  };
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  public getUnits: boolean = true;
  private demoTourEntity: string = 'target';
  private GIFs: Array<any> = [{
    label: 'Create a target',
    url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
  },
    {
      label: 'Update a target',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a target',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of target';

  get permissionEnum() {
    return PermissionEnum;
  }

  @Watch('filters.month', {
    deep: true
  })
  filterMonth(value: string) {
    this.queryBuilder.addListener('month', value);
  }

  @Watch('filters.department_code', {
    deep: true
  })
  filterEmployeeCode(value: string) {
    this.queryBuilder.addListener('department_code', value);
  }

  @Watch('filters.department_name', {
    deep: true
  })
  filterEmployeeName(value: string) {
    this.queryBuilder.addListener('department_name', value);
  }

  @Watch('filters.value_type', {
    deep: true
  })
  filterValueType(value: string) {
    this.queryBuilder.addListener('value_type', value);
  }

  @Watch('filters.category', {
    deep: true
  })
  filterCategory(value: string) {
    this.queryBuilder.addListener('category', value);
  }

  @Watch('filters.brand', {
    deep: true
  })
  filterBrand(value: string) {
    this.queryBuilder.addListener('brand', value);
  }

  @Watch('filters.product_sku', {
    deep: true
  })
  filterProductSku(value: string) {
    this.queryBuilder.addListener('product_sku', value);
  }

  @Watch('filters.status', {
    deep: true
  })
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  onEdit(row: any) {
    this.currentTarget = row;
    this.form = {
      value_type: this.currentTarget.value_based_on,
      month: this.currentTarget.name,
    };
    this.isUpdate = true;
  }

  deleteTarget() {
    this.$http.delete(this.$api3(`/department-targets/${this.currentTarget.id}`)).then(response => {
      this.showDeleteModal = false;
      this.targets.splice(
        this.targets.findIndex((target: any) => target.id == this.currentTarget.id),
        1
      );
    });
  }

  @Watch('pagination.currentPage')
  async fetchTarget() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage ?
      `/department-targets?${this.queryBuilder.getFilters()}` :
      `/department-targets?${this.queryBuilder.getFilters()}&page=${this.pagination.currentPage}`;

    await this.$http.get(this.$api3(url)).then(response => {
      this.targets = response.data.data;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  created() {
    this.fetchTarget();
    if (this.getUnits) {
      this.$http.get(this.$api(`/units`)).then(response => {
        const units = this.sortItems(response.data.units);
        units.forEach((element: any, key: number) => {
          this.types.push({
            name: element.name,
            value: 'unit' + (key + 1)
          });
        })
      });
      this.getUnits = false;
    }
  }

  closeBtn() {
    this.fetchTarget();
    this.isFilterModelActive = false;
  }

  resetBtn() {
    this.filters = {
      month: '',
      department_code: '',
      department_name: '',
      value_type: '',
      category: '',
      brand: '',
      product_sku: '',
      status: ''
    }
    this.fetchTarget();
  }

  handlefilter() {
    this.isFilterModelActive == true ? (this.isFilterModelActive = false) : (this.isFilterModelActive = true);
  }

  sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  getValueBased(value_based_on: String) {
    let value = this.types.find((type: any) => type.value === value_based_on);
    if (value) {
      return value.name;
    }
  }
}
