




























































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import 'flatpickr/dist/flatpickr.css';
import DateRange from '@/components/DateRange.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, Button, Table, TableColumn, Tooltip, Row, Col, Tag } from 'element-ui';
import moment from 'moment';
import swal from 'sweetalert2';
import { AxiosResponse } from "axios";
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import MultiSelectRow from '@/mixins/MultiSelectRow';
import BaseOrderSectionDetails from '@/views/order/BaseOrderSectionDetails.vue';
// import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
// import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
// @ts-ignore
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const flatPicker = require('vue-flatpickr-component');


@Component({
  components: {
    DateRange,
    flatPicker,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Row.name]: Row,
    [Col.name]: Col,
    [TableColumn.name]: TableColumn,
    DatePicker,
    QueryBuilder,
    BaseOrderSectionDetails,
    // TerritoryAutocomplete,
    // TerritoryTypeAutocomplete,
    TerritoryCascaderDropdown
  }
})

export default class OutletOrder extends Mixins(MultiSelectRow, PaginationComponentMixin, TaxonomyMixin) {
  private terrritoryList: [] = [];
  private checkboxList: [] = [];
  //private selectedRows:[] = [];
  //private allItemSelected:boolean = false;
  // private selectedTerritoryType: number | null = null;
  private selectedTerritory: any | null = null;
  private showDownloadModal: boolean = false;
  private showDownloadNotOrderModal: boolean = false;
  private selectedButton: string = 'Today';
  private selectedDateRange: string = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private territory_type: number | null = null;
  private previous_territory_type: number | null = null;
  private range: string = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
  private recipient: string = '';
  loading = false;
  isSelected = false;
  showBackButton = false;
  fetchOutlet() {
    this.loading = true
    this.$http.get(this.$api3(`/outlet-order-report?page=${this.pagination.currentPage}&territory_type=${this.territory_type}&range=${this.range}`))
      .then((response: AxiosResponse) => {
        this.terrritoryList = response.data.terrritoryList;
        if (response.data.terrritoryList[0]) {
          this.previous_territory_type = response.data.terrritoryList[0]['parent_parent_id'];
        }
        this.setPagination(response.data);
      })
      .finally(() => this.loading = false);
  }

  @Watch('selectedDateRange')
  onChangeDateRange(value: string) {
    this.pagination.currentPage = this.pagination.currentPage ?? 1;
    this.range = value;
    this.queryBuilder.setParam('range', value);
  }
  @Watch('selectedTerritoryType')
  onChangeTerritoryType(value: number) { }

  @Watch('selectedTerritory')
  onChangeTerritory(value: number) {
    this.pagination.currentPage = 1;
    this.territory_type = value;
    this.queryBuilder.setParam('territory_type', value);
  }

  @Watch('pagination.currentPage')
  getOutletResults() {
    this.queryBuilder.setParam('territory_type', this.territory_type);
    this.queryBuilder.setParam('range', this.range);
    this.fetchOutlet();
  }

  territoryType(value: number) {
    this.pagination.currentPage = 1;
    this.territory_type = value;
    this.showBackButton = true;
    this.getOutletResults();
  }

  backPreviousPage() {
    if (this.previous_territory_type == null || this.previous_territory_type == 1) {
      this.showBackButton = false;
    }
    this.territory_type = this.previous_territory_type;
    this.queryBuilder.setParam('territory_type', this.previous_territory_type);
    this.fetchOutlet();
  }
  // checkedValue(id,event){
  //   if (event.target.checked) {
  //     this.checkboxList.push(id);
  //   }else{
  //     var index = this.checkboxList.indexOf(id);
  //     this.checkboxList.splice(index,1);
  //   }
  // }
  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }

  updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      this.selectedDateRange = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'last30') {
      this.selectedDateRange =
        moment()
          .day(-26)
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'WTD') {
      this.selectedDateRange =
        moment()
          .day(-1)
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'MTD') {
      this.selectedDateRange =
        moment()
          .startOf('month')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'QTD') {
      this.selectedDateRange =
        moment()
          .startOf('quarter')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'YTD') {
      this.selectedDateRange =
        moment()
          .startOf('year')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    }
  }
  downloadOrderSingle(value: number) {
    this.showDownloadModal = true;
    this.queryBuilder.setParam(
      'id',
      value
    );
  }
  downloadNotOrderSingle(value: number) {
    this.showDownloadNotOrderModal = true;
    this.queryBuilder.setParam(
      'id',
      value
    );
  }
  async downloadOrder(status: string) {
    this.loading = true;
    await this.queryBuilder.setParam('recipient', this.recipient);
    await this.queryBuilder.setParam('type', 'csv');
    if (this.selectedRows.length > 0) {
      await this.queryBuilder.setParam(
        'id',
        this.selectedRows.map(order => order['id'])
      );
    }
    try {
      let { data, headers } = await this.$http.get(this.$api(`/download/order-outlet${this.queryBuilder.getFilters(true)}`));
      await swal.fire('Report Sent', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      //console.log(e);
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      await this.queryBuilder.setParam('recipient', null);
      await this.queryBuilder.setParam('type', null);
      await this.queryBuilder.setParam('id', null);
    }
  }
  async downloadNotOrder(status: string) {
    this.loading = true;
    await this.queryBuilder.setParam('recipient', this.recipient);
    await this.queryBuilder.setParam('type', 'csv');
    if (this.selectedRows.length > 0) {
      await this.queryBuilder.setParam(
        'id',
        this.selectedRows.map(order => order['id'])
      );
    }
    try {
      let { data, headers } = await this.$http.get(this.$api(`/download/not-order-outlet${this.queryBuilder.getFilters(true)}`));
      await swal.fire('Report Sent', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      await this.queryBuilder.setParam('recipient', null);
      await this.queryBuilder.setParam('type', null);
      await this.queryBuilder.setParam('id', null);
    }
  }
  created() {
    this.queryBuilder.setParam('range', this.range);
    this.queryBuilder.setParam('territory_id', null);
    this.queryBuilder.setParam('territory_type', null);
    this.fetchOutlet();
  }
}

