


































































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator';
import Order from '@/intefaces/Order';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag, Drawer, ButtonGroup, Button } from 'element-ui';
import OrderDetailsForm from '@/views/order/OrderDetailsForm.vue';
import OrderStatusAutocomplete from '@/components/resources-autocomplete/OrderStatusAutocomplete.vue';
import statuses from '@/data/OrderStatus';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { QueryBuilder } from '@/classes/QueryBuilder';
import DateRange from '@/components/DateRange.vue';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import BaseOrderSectionDetails from '@/views/order/BaseOrderSectionDetails.vue';
import HasCursorPaginationMixin from '@/mixins/HasCursorPagination';
import CursorPagination from '@/components/CursorPagination.vue';
import moment from 'moment';
import swal from 'sweetalert2';
import TenantModulesMixin from '@/mixins/HasTenantModules';
import printJS from 'print-js';


@Component({
  components: {
    CursorPagination,
    DepartmentLazydropdown,
    UserLazydropdown,
    TerritoryLazydropdown,
    GIFModal,
    OrderStatusAutocomplete,
    OrderDetailsForm,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    [Tag.name]: Tag,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
    FilterDrawerComponent,
    DateRange,
    BaseOrderSectionDetails
  },
  filters: {
    currentStatus: function (value: Order) {
      if (!value) return '';
      return value.statuses[value.statuses.length - 1].status.toString().toUpperCase();
    }
  }
})
export default class OrderList extends Mixins(HasCursorPaginationMixin, MultiSelectRow, TaxonomyMixin, TenantModulesMixin) {
  private orders: Order[] = [];
  private isActive: boolean = false;
  private isFilterModelActive: boolean = false;
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private showGIF: boolean = false;
  private demoTourEntity: string = 'orders';
  private dialogVisible: boolean = false;
  private filterApplied: boolean = false;
  private orderEditPermission: string = '';
  private orderApprovePermission: string = '';
  private selectedButton: string = 'Today';
  private showDownloadModal: boolean = false;
  private recipient: string = '';
  private generating: boolean = false;

  public order_type: string = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
  private bulkForm = {
    remarks: ''
  };

  async updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    }
    if (range === 'WTD') {
      await this.queryBuilder.setParam(
        'created_between',
        moment()
          .day(-1)
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD')
      );
    }
    if (range === 'MTD') {
      await this.queryBuilder.setParam(
        'created_between',
        moment()
          .startOf('month')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD')
      );
    }
    if (range === 'QTD') {
      await this.queryBuilder.setParam(
        'created_between',
        moment()
          .startOf('quarter')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD')
      );
    }
    if (range === 'YTD') {
      await this.queryBuilder.setParam(
        'created_between',
        moment()
          .startOf('year')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD')
      );
    }
    this.$nextTick(function () {
      this.applyFilter();
    });
  }

  private currentEntity = {
    ordered_products: []
  };

  get statuses() {
    let data = [...statuses];
    data.unshift({ title: 'All ' + this.getTaxonomy('order') + 's', value: '' });
    return data;
  }

  private filters: any = {
    orderDateRange: '',
    orderId: '',
    orderBy: '',
    departmentId: '',
    territoryId: '',
    status: 'pending',
    minimumAmount: '',
    maximumAmount: ''
  };

  private GIFs: Array<any> = [
    {
      label: 'Create an order',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an order',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an order',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of order';

  get permissionEnum() {
    return PermissionEnum;
  }

  get amountRange() {
    if (this.filters.minimumAmount && this.filters.maximumAmount) {
      return `${this.filters.minimumAmount},${this.filters.maximumAmount}`;
    }
    return '';
  }

  @Watch('isActive')
  getPermission(newVal: boolean) {
    this.orderApprovePermission = this.permissionEnum.APPROVE_ORDER;
    this.orderEditPermission = this.permissionEnum.CREATE_ORDER_MOBILE;
  }

  async mounted() {
    this.checkTenantModule('order_include_lot,order_transportation');
    await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    await this.queryBuilder.setParam('status', 'pending');
    this.$nextTick(function () {
      this.applyFilter();
    });
  }

  updateOrder(event: any) {
    this.$http.get(this.$api(`/orders/${event.order_id}`)).then(response => {
      this.orders.splice(
        this.orders.findIndex((order: any) => order.id == response.data.order.id),
        1,
        response.data.order
      );
    });
  }

  entityCreated(entity: Order) {
    this.orders.unshift(entity);
  }

  entityUpdated(entity: Order): void {
    this.orders.splice(
      this.orders.findIndex(type => type.id == entity.id),
      1,
      entity
    );
    this.isActive = false;
  }

  @Watch('currentCursor')
  async fetchOrder(cursor: string | null) {
    this.loading = true;
    const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
    let { data } = await this.$http.get(
      this.$api2(`/orders${cursor ? '?cursor=' + cursor + '&' : ''}${this.queryBuilder.getFilters(cursor == null)}&order_type=${this.order_type}`)
    );
    this.orders = data.data;
    this.setPagination(data);
    this.loading = false;
  }

  @Watch('filters.orderDateRange', { deep: true })
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('created_between', value);
  }

  @Watch('filters.orderId', { deep: true })
  onChangeCodeFilter(value: string) {
    this.queryBuilder.addListener('code', value);
  }

  @Watch('filters.orderBy', { deep: true })
  onChangeNameFilter(value: string) {
    this.queryBuilder.addListener('created_by', value);
  }

  @Watch('filters.departmentId', { deep: true })
  onChangeDepartmentFilter(value: number) {
    this.queryBuilder.addListener('buyer_id', value);
  }

  @Watch('filters.territoryId', { deep: true })
  onChangeTerritoryFilter(value: number) {
    this.queryBuilder.addListener('buyer_territory_id', value);
  }

  @Watch('filters.status', { deep: true })
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('filters.minimumAmount', { deep: true })
  onChangeMinimumAmountFilter() {
    if (this.filters.maximumAmount && this.filters.minimumAmount <= this.filters.maximumAmount) {
      this.queryBuilder.addListener('amount_between', this.amountRange);
    }
  }

  @Watch('filters.maximumAmount', { deep: true })
  onChangeMaximumAmountFilter() {
    if (this.filters.minimumAmount && this.filters.maximumAmount >= this.filters.minimumAmount) {
      this.queryBuilder.addListener('amount_between', this.amountRange);
    }
  }

  bulkActionDispatchNotification() {
    this.$notify({
      title: 'Success',
      message: 'Bulk Actions Applied',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success'
    });
  }

  submitBulkAction(status: string) {
    this.loading = true;
    this.dialogVisible = false;
    this.$http
      .post(
        this.$api(`/bulk-order-status?${this.queryBuilder.getFilters()}`),
        this.allItemSelected
          ? {
            id: '',
            status_action: status,
            remarks: this.bulkForm.remarks
          }
          : {
            id: this.selectedRows.map(order => order.id),
            status_action: status,
            remarks: this.bulkForm.remarks
          }
      )
      .then(response => {
        this.bulkActionDispatchNotification();
        this.selectedRows = [];
        this.bulkForm.remarks = '';
        this.fetchOrder(null);
      })
      .catch(error => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      })
      .finally(() => (this.loading = false));
  }

  async downloadOrder() {
    this.loading = true;
    if (!this.allItemSelected) {
      await this.queryBuilder.setParam(
        'id',
        this.selectedRows.map(order => order.id)
      );
    }
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api(`/download/bulk-order${this.queryBuilder.getFilters(true)}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      let err_msg = [];
      const decoder = new TextDecoder();
      const errorMessage = JSON.parse(decoder.decode(new Uint8Array(e.response.data)));
      if (errorMessage['errors'].created_between) {
        err_msg.push(errorMessage['errors'].created_between[0]);
      }
      if (errorMessage['errors'].recipient) {
        err_msg.push(errorMessage['errors'].recipient[0]);
      }
      if (err_msg.length > 0) {
        err_msg.forEach(error => {
          this.$notify({
            type: 'warning',
            message: error,
            title: 'Unprocessable Content'
          });
        })
      } else {
        this.$notify({ title: 'Something went wrong!', type: 'warning', message: 'Unable to download reports.' });
      }
    } finally {
      this.loading = false;
      await this.queryBuilder.setParam('id', null);
    }
  }

  // async downloadOrder(status: string) {
  //   this.loading = true;
  //   await this.queryBuilder.setParam('recipient', this.recipient);
  //   await this.queryBuilder.setParam('type', 'csv');
  //   if (!this.allItemSelected) {
  //     await this.queryBuilder.setParam(
  //       'id',
  //       this.selectedRows.map(order => order.id)
  //     );
  //   }
  //   try {
  //     let { data, headers } = await this.$http.get(this.$api(`/download/bulk-order${this.queryBuilder.getFilters(true)}`));
  //     await swal.fire('Report Sent', 'You will receive the report within a few minutes.', 'success');
  //   } catch (e) {
  //     let err_msg = [];
  //     if (e.response.data.errors.created_between) {
  //       err_msg.push(e.response.data.errors.created_between[0]);
  //     }
  //     if (e.response.data.errors.recipient) {
  //       err_msg.push(e.response.data.errors.recipient[0]);
  //     }
  //     if (err_msg.length > 0) {
  //       err_msg.forEach(error => {
  //         this.$notify({
  //           type: 'warning',
  //           message: error,
  //           title: 'Unprocessable Content'
  //         });
  //       })
  //     } else {
  //       this.$notify({ title: 'Something went wrong!', type: 'warning', message: 'Unable to download reports.' });
  //     }
  //   } finally {
  //     this.loading = false;
  //     this.showDownloadModal = false;
  //     await this.queryBuilder.setParam('recipient', null);
  //     await this.queryBuilder.setParam('type', null);
  //     await this.queryBuilder.setParam('id', null);
  //   }
  // }

  closeBtn() {
    this.isFilterModelActive = false;
    this.applyFilter();
  }

  handleClose(done: any) {
    done();
  }

  applyFilter() {
    this.filterApplied = true;
    this.fetchOrder(null);
    this.selectedRows = [];
    this.deselectAllItems();
  }

  resetBtn() {
    this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
    this.queryBuilder.setParam('status', 'pending');
    this.$nextTick(function () {
      this.applyFilter();
    });
    this.filters.orderDateRange = '';
    this.filters.orderBy = '';
    this.filters.orderId = '';
    this.filters.status = 'pending';
    this.filters.territoryId = '';
    this.filters.minimumAmount = '';
    this.filters.maximumAmount = '';
    this.filters.selectedOrders = '';
    // this.fetchOrder(null);
    // this.selectedRows = [];
    // this.deselectAllItems();
  }

  async ordersObjectUrl(pos = false) {
    let {data} = await this.$http.post(
      this.$api(`/pdf/orders${this.queryBuilder.getFilters() ? '?' + this.queryBuilder.getFilters() : ''}`),
      {order_id: this.allItemSelected ? null : this.selectedRows.map(value => value.id).join(',')},
      {
        responseType: 'blob'
      }
    );
    const blob = await new Blob([data], {type: 'application/pdf'});
    let fileURL = await URL.createObjectURL(blob);
    return fileURL;
  }

  async printOrders() {
    this.generating = true;
    try {
      await printJS({printable: await this.ordersObjectUrl()});
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
    }
  }
}
