











































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'; 
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
// @ts-ignore
const flatPicker = require("vue-flatpickr-component");
import "flatpickr/dist/flatpickr.css";
@Component({
    components: {
        TerritoryTypeAutocomplete, 
        TerritoryAutocomplete,
        MemberAutocomplete,
        flatPicker
    }
})

export default class SalesGrowthFilter extends Vue { 
    private selectedTerritoryType: number | null = null;
    private territory_id: number | null = null; 
    @Prop() isActive!: boolean;
    private month!: any;
    @Prop({
        default: () => ({ 
            month: '',
            territory_id: '',
            user_id: ''
        })
    })
    filters!: { 
        month: number,
        territory_id: number,
        user_id: number
    } 
    private months: any = [
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ]
    @Watch('filters', { immediate: true, deep: true })
    onChangeFilter(newVal: any) {
        this.$emit('updateFilter', this.filters)
    }

    closeFilter() {
        this.$emit('update:isActive', false);
    }


    close() {
        this.$emit('update:isActive', false);
        this.$emit('update:filters', { 
            month: this.month
        })
    }
}
