





















































































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import {
  Tree, DatePicker, Select
} from 'element-ui';
import DateRange from "@/components/DateRange.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import moment from "moment/moment";

@Component({
  components: {
    DepartmentCategoryLazyDropdown,
    BadgeLazydropdown,
    DepartmentLocationTypeLazyDropdown,
    DateRange,
    TerritoryCascaderDropdown,
    UserLazydropdown,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    [Tree.name]: Tree,
  }
})
export default class SalesReturnReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public user: any = this.$store.getters['Auth/user'];
  public props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };
  public formData = {
    parent_id: 0,
    range: '',
    territory_id: '',
    user_ids: '',
    outlet_category_ids: '',
    location_type_ids: '',
    badge_ids: '',
  };
  public allItemDownloadFormData: any = {};
  public loading: boolean = false;
  public reports = [];
  public filterData = null;
  public maxDate: string = moment().format('YYYY-MM-DD');

  public onClickGenerate() {
    this.allItemDownloadFormData = this.formData;
    this.fetchReport();
  }

  async fetchReport() {
    this.loading = true
    this.reports = [];
    const {['parent_id']: _, ...filteredObject} = this.formData;
    await this.$http.get(this.$api3(`/sales-return-report${this.$toQueryString(filteredObject)}`)).then((response: any) => {
      this.reports = response.data.reports;
      this.filterData = response.data.filterData;
    }).catch((e) => {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
    }).finally(() => {
      this.loading = false
    })
  }

  async handleDownload(territoryId: number, type: string) {
    this.loading = true;
    try {
      this.formData.parent_id = territoryId;
      const {['territory_id']: _, ...filteredObject} = this.formData;
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/sales-return-report${this.$toQueryString(filteredObject)}&download&status=${type}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.loading = false;
    }
  }

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  loadNode(node: any, resolve: any) {
    let territories: any[] = [];
    this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
    const {['territory_id']: _, ...filteredObject} = this.formData;
    if (this.formData.range) {
      this.$http.get(this.$api3(`/sales-return-report${this.$toQueryString(filteredObject)}`)).then((response) => {
        territories = response.data.reports;
        let treeData = territories.map((territory) => {
          const container: any = {};
          container['name'] = territory.name;
          container['code'] = territory.code;
          container['total_outlets'] = territory.total_outlets;
          container['returned_outlets'] = territory.returned_outlets;
          container['percentage_returned_outlets'] = territory.percentage_returned_outlets;
          container['total_memo'] = territory.total_memo;
          container['total_products'] = territory.total_products;
          container['unique_products'] = territory.unique_products;
          container['total_amount'] = territory.total_amount;
          container['leaf'] = !territory.children_count;
          container['id'] = territory.id;
          return container;
        });
        resolve(treeData);
        this.loading = false;
      });
    }
  }
}
