






























































































































































































import {Component, Watch} from 'vue-property-decorator';
import { QueryBuilder } from "@/classes/QueryBuilder";
import { mixins } from 'vue-class-component';
import DateRange from "@/components/DateRange.vue";
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import LazyDropdown from "@/components/LazyDropdown.vue";
import { ElSelect } from "element-ui/types/select";

const statusOptions = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Rejected', value: 'REJECTED' }
];

const typeOptions = [
  { label: 'SMS', value: 'sms' },
  { label: 'Bill', value: 'bill' }
];
@Component({
  components: {
    LazyDropdown,
    DateRange
  }
})

export default class TransactionReport extends  mixins(PaginationComponentMixin) {

  public downloading: boolean = false;
  public loading: boolean = false;
  public type: string | null = null;
  public status: string = '';
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public TransactionReport = [];
  public range: string = '';
  public isShowPaymentDetails: boolean = false;
  public selectedTransactionDetails: any;
  public org_id: number | null = null;
  data() {
    return {
      statusOptions: statusOptions,
      typeOptions: typeOptions,
      selectedTransactionDetails: null,
    };
  }
  
  @Watch('pagination.currentPage')
  onChangePage(newVal: any) {
    this.queryBuilder.addListener('page', newVal);
  }

  @Watch('queryBuilder.watch')
  async getTransactionReport() {
    if (!this.range) {
      this.TransactionReport = [];
      return
    }
    this.loading = true;
    try {
      let {data} = await this.$http.get(this.$landLordApi(`/tenant-payment${this.queryBuilder.getFilters(true)}&page=${this.pagination.currentPage}`))
      this.TransactionReport = data.data;
      this.setPagination(data);
    } catch (e) {
      this.$notify({message: "Please contact your service provider.", title: "Something went wrong!", type: 'warning'})
    } finally {
      this.loading = false;
    }
  }
  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$landLordApi(`/tenant-payment${this.queryBuilder.getFilters(true)}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }

  @Watch('type')
  onChangeType(value: string) {
    this.queryBuilder.addListener('type', value);
  }

  @Watch('status')
  onChangeStatus(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  @Watch('org_id')
  onChangeOrganization(value: number) {
    this.queryBuilder.addListener('org_id', value);
  }

  close() {
    this.isShowPaymentDetails = false;
    this.selectedTransactionDetails = '';
  }
  showPaymentDetailsModal(row: any) {
    this.selectedTransactionDetails = row;
    this.isShowPaymentDetails = true;
  }
}
