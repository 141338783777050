





























































































import {
    Component,
    Mixins,
    Prop,
    Watch
} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {
    AxiosInstance,
    AxiosResponse,
    AxiosStatic
} from 'axios';
import LazyDropdown from '@/components/LazyDropdown.vue';
import Payment from '@/intefaces/Payment';
import {
    PaymentTypes
} from '@/enums/PaymentTypes';

const flatPicker = require('vue-flatpickr-component');
import 'flatpickr/dist/flatpickr.css';
import SingleImageUploader from '@/components/ui/SingleImageUploader.vue';
import {
    MfsVendors
} from '@/enums/MfsVendors';
import InvoiceAutocomplete from '@/components/resources-autocomplete/InvoiceAutocomplete.vue';

@Component({
    components: {
        InvoiceAutocomplete,
        SingleImageUploader,
        LazyDropdown,
        flatPicker
    }
})
export default class CreatePaymentForm extends Mixins(BaseCUModalComponent) {
    @Prop()
    currentEntity!: any;
    @Prop({
        default: () => ({
            advance: false,
            amount: null,
            department_id: null,
            method: null,
            paid_at: new Date().toISOString(),
            mfs_vendor: null,
            invoices: [],

            ac_no: '',
            ac_holder: '',
            txn_no: '',

            bank: '',
            branch: '',
            cheque_no: '',
            deposit_no: '',
            deposit_slip: '',
            seller_id: null,
        })
    })
    form!: Payment;

    private totalInvoiceAmount = null;
    public image = '';
    private invoiceAmount: number = 0;
    private multipleInvoice: boolean = false;
    // Computed properties +++
    get createApi(): string {
        return '/payments';
    }

    get updateApi(): string {
        return `/payments/${this.currentEntity.id}`;
    }

    get paymentTypes() {
        return PaymentTypes;
    }

    get mfsVendors() {
        return MfsVendors;
    }

    // Computed properties ---
    formReset(): any {
        this.form.method = this.paymentTypes.METHOD_CASH;
        this.form.advance = false;
        this.form.amount = null;
        this.form.department_id = null;
        return this.form;
    }

    async afterCreate(response: AxiosResponse < any > ): Promise < void > {
        this.$emit('created', response.data.payment);
    }

    afterUpdate(response: AxiosResponse < any > ): void {
        this.$emit('updated', response.data.badge);
    }

    formData() {
        let extForm = new FormData();
        extForm.append('advance', String(this.form.advance));
        extForm.append('amount', String(this.form.amount));
        extForm.append('department_id', String(this.form.department_id));
        extForm.append('method', String(this.form.method));
        extForm.append('paid_at', String(this.form.paid_at));
        extForm.append('mfs_vendor', String(this.form.mfs_vendor));
        extForm.append('invoices', String(this.form.invoices));
        extForm.append('ac_no', String(this.form.ac_no));
        extForm.append('ac_holder', String(this.form.ac_holder));
        extForm.append('txn_no', String(this.form.txn_no));
        extForm.append('bank', String(this.form.bank));
        extForm.append('branch', String(this.form.branch));
        extForm.append('cheque_no', String(this.form.cheque_no));
        extForm.append('deposit_no', String(this.form.deposit_no));
        extForm.append('deposit_slip', String(this.image));
        extForm.append('seller_id', String(this.form.seller_id));
        return extForm;
    }
}
