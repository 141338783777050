














































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import { Drawer } from "element-ui";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import RoleLazydropdown from "@/components/lazyDropdown/RoleLazydropdown.vue";

@Component({
  components: {
    RoleLazydropdown,
    UserLazydropdown,
    DateRange,
    TerritoryCascaderDropdown,
    [Drawer.name]: Drawer,
  }
})
export default class PromotionFilterDrawer extends Vue {
  @Prop({required: true})
  isFilterModelActive!: boolean;
  @Prop({required: true})
  entityPerPage!: number;
  @Prop({required: true})
  totalEntity!: number;
  @Prop({required: true, default: 1})
  entityCurrentPage!: number;
  @Prop({required: true})
  loading!: boolean;

  private drawer: boolean = false;
  private query: any = [];
  private afterQuery: boolean = false;

  private filter: any = {
    title: '',
    type: '',
    expires_at_date_range: '',
    assigned_parent_territory_id: '',
    assigned_territory_ids: '',
    assigned_user_ids: '',
    assigned_role_ids: '',
    created_by: '',
  };

  @Watch('isFilterModelActive')
  changeDrawerValue(newVal: any) {
    if (newVal) {
      this.drawer = true
    }
  }

  @Watch('filter', {immediate: true, deep: true})
  onChangeFilter(newVal: any) {
    this.query = ['page=1'];
    // this.query.push('with=')
    this.afterQuery = true;
    newVal.title != '' ? this.query.push(`title=${newVal.title}`) : '';
    newVal.type != '' ? this.query.push(`type=${newVal.type}`) : '';
    newVal.expires_at_date_range != '' ? this.query.push(`expires_at_date_range=${newVal.expires_at_date_range}`) : '';
    newVal.assigned_parent_territory_id ? this.query.push(`assigned_parent_territory_id=${newVal.assigned_parent_territory_id}`) : '';
    newVal.assigned_territory_ids.length > 0 ? this.query.push(`assigned_territory_ids=${newVal.assigned_territory_ids}`) : '';
    newVal.assigned_user_ids.length > 0 ? this.query.push(`assigned_user_ids=${newVal.assigned_user_ids}`) : '';
    newVal.assigned_role_ids.length > 0 ? this.query.push(`assigned_role_ids=${newVal.assigned_role_ids}`) : '';
    newVal.created_by ? this.query.push(`created_by=${newVal.created_by}`) : '';
    if (this.entityCurrentPage == 1) {
      this.fetchAnnouncement();
      return;
    }
    this.$emit('update:entityCurrentPage', 1);
  }

  @Watch('entityCurrentPage')
  onChangePage(val: any, oldVal: any) {
    this.query[0] = `page=${this.entityCurrentPage}`;
    this.fetchAnnouncement();
  }


  async fetchAnnouncement() {
    this.$emit('update:loading', true);
    const {data} = await this.$http.get(this.$api(`/announcements${this.query.length > 0 ? '?' + this.query.join('&') : ''}`));
    this.$emit('update', data.announcements);
    this.$emit('update:entityPerPage', data.per_page);
    this.$emit('update:totalEntity', data.total);
    this.$emit('update:entityCurrentPage', data.current_page);
    this.$emit('update:loading', false);
  }

  closeBtn(): void {
    this.drawer = false
    this.$emit('update:isFilterModelActive', false);
  }

  handleClose(done: any) {
    this.$emit('update:isFilterModelActive', false);
    done();
  }

  resetBtn() {
    this.filter.title = '';
    this.filter.type = '';
    this.filter.expires_at_date_range = '';
    this.filter.assigned_parent_territory_id = '';
    this.filter.assigned_territory_ids = '';
    this.filter.assigned_user_ids = '';
    this.filter.assigned_role_ids = '';
    this.filter.created_by = '';
  }
}
