























































import moment from "moment/moment";

declare global {
  interface Window {
    excelFIleSize: any;
    excelFIleProgressStart: any;
  }
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import FileInput from '@/components/argon-core/Inputs/FileInput.vue';
import { AxiosError, AxiosResponse } from 'axios';
import { RoutePlanTimespan } from "@/enums/RoutePlanTimespan";
//@ts-ignore
import downloadExcel from "vue-json-excel";

@Component({
  name: 'CreateBulkRoutePlan',
  components: {
    FileInput,
    downloadExcel,
  },
})
export default class CreateBulkRoutePlan extends Vue {
  public downloading: boolean = false;

  private btnDisabled: boolean = false;
  private csvFile: Blob | string = '';
  private timespan: string = '';
  private errors: Array<string> = [];

  public json_meta = [
    [{
      key: "charset",
      value: "utf-8",
    },],
  ];
  public excelType = 'xls';
  public fileName = 'order-summary-report-' + moment().format('YYYY-MM-DD') + '.' + this.excelType;
  public json_fields: any = {};
  @Prop() showBulkRouteModal!: boolean;

  close() {
    this.errors = [];
    this.$emit('update:showBulkRouteModal', false);
  }

  get timespans() {
    return RoutePlanTimespan;
  }
  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api(`/bulk-route-plans-sample?timespan=${this.timespan}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }

  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('route_plans', this.csvFile);
    form.append('timespan', this.timespan);
    this.$http
      .post(this.$api('/import-route-plans'), form)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: response.data.message,
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.btnDisabled = false;
        this.errors = [];
        this.$emit('update:showBulkRouteModal', false);
        //@ts-ignore
        this.$parent.fetchDepartments();
      })
      .catch((err: AxiosError) => {
        this.errors = err.response?.data.errors;
        this.btnDisabled = false;
      });
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }
}
