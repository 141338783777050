

















































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip,} from "element-ui";
import Delivery from "@/intefaces/Delivery";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import OrderDepartmentInfo from "@/views/order/Components/OrderDepartmentInfo.vue";
import BaseOrderProducts from "@/views/order/Components/BaseOrderProducts.vue";
import BaseOrderComment from "@/views/order/BaseOrderComment.vue";
import BaseOrderCal from "@/views/order/Components/BaseOrderCal.vue";
import OrderRevision from "@/intefaces/OrderRevision";
import { OrderStatus as OrderStatusEnum } from "@/enums/OrderStatus";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    OrderDepartmentInfo,
    BaseOrderProducts,
    BaseOrderComment,
    BaseOrderCal
  }
})
export default class InvoiceDetails extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() invoiceDetails!: Delivery;
  @Prop() isDeliver!: boolean;
  @Prop() orderIncludeLot!: boolean;
  @Prop() orderTransportEnable!: boolean;

  public revisionList: OrderRevision[] = [];
  public statuses: any = [];
  public salesReturn: number = 0;
  public returnProducts: any = [];


  close() {
    this.$emit('update:isActive', false);
  }
  created() {
    this.fetchRevisionAndStatusList(true);
    // window.console.log(this.invoiceDetails)
    // this.getTotalValues(true);
  }

  @Watch('isActive')
  getTotalValues(newVal: boolean) {
    if (newVal) {
      if(this.invoiceDetails.order.latest_revision.returned_products) {
        this.salesReturn = this.invoiceDetails.order.latest_revision.returned_products.reduce( (acc: number, item: any) => {
          return acc + parseFloat(item.amount);
        }, 0)
      }
    }
  }

  @Watch('isActive')
  fetchRevisionAndStatusList(newVal: boolean) {
    if (newVal) {
      this.$http.get(this.$api(`/invoices/${this.invoiceDetails.order.invoice.id}/revisions`)).then(resposne => {
        this.revisionList = resposne.data.revisions;
      })
      this.$http.get(this.$api(`/orders/${this.invoiceDetails.order_id}/statuses?statuses=${OrderStatusEnum.STATUS_PENDING},${OrderStatusEnum.STATUS_ACCEPTED},${OrderStatusEnum.STATUS_DISPATCH},${OrderStatusEnum.STATUS_ON_THE_WAY},${OrderStatusEnum.STATUS_DELIVERED}`))
        .then(response => {
        this.statuses = response.data.statuses;
        })
      this.getTotalValues(true);
      // this.$http.get(this.$api(`/sales-returns?order_id=${this.invoiceDetails.order_id}`)).then(resposne => {
      //   if (resposne.data.salesReturns.length > 0) {
      //     this.returnProducts = resposne.data.salesReturns[0].returned_products;
      //     this.salesReturn = resposne.data.salesReturns[0].total_amount;
      //   }
      // })
    }
  }

  getSubTotal() {
    return Number(parseFloat(this.invoiceDetails.amount.toString())).toFixed(2);
  }
}
