
























































































































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Announcement, { Territory, User, Role } from '@/intefaces/Announcement';
import CreateAnnouncement from '@/views/user/announcement/CreateAnnouncement.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag } from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    CreateAnnouncement,
    GIFModal,
  },
})
export default class AnnouncementList extends Mixins(PaginationComponentMixin) {
  private announcements: Announcement[] = [];
  private isActive = false;
  private newAnnouncement = false;
  private isUpdate = false;
  private currentEntity!: any;
  private currentAnnouncement: any = [];
  public loading = false;
  private showGIF: boolean = false;
  private form = {
    title: '',
    body: '',
    type: '',
    image: '',
    range: '',
    selectedTerritories: [],
    selectedUsers: [],
    selectedRoles: [],
  };
  private demoTourEntity: string = 'announcement';
  private GIFs: Array<any> = [
    {
      label: 'Create a announcement',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw',
    },
    {
      label: 'Update a announcement',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ',
    },
    {
      label: 'Delete a announcement',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM',
    },
  ];
  private demoTourTitle: string = 'A quick demo of announcement';

  get permissionEnum() {
    return PermissionEnum;
  }

  fetchAnnouncements() {
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage ? '/announcements' : `/announcements?page=${this.pagination.currentPage}`;

    this.$http.get(this.$api(url)).then((response) => {
      this.announcements = response.data.announcements;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  announcementCreated(announcement: Announcement) {
    this.newAnnouncement = false;
    this.isUpdate = false;
    this.announcements.unshift(announcement);
  }

  created() {
    this.newAnnouncement = false;
    this.isUpdate = false;
    this.fetchAnnouncements();
  }



  entityUpdated(entity: Announcement): void {
    this.newAnnouncement = false;
    this.announcements.splice(
      this.announcements.findIndex(type => type.id == entity.id),
      1,
      entity
    );
  }

  onEdit(row: Announcement) {
    this.isUpdate = true;
    this.newAnnouncement = true;
    this.currentEntity = row;
    this.form = {
      title:this.currentEntity.title,
      body:this.currentEntity.body,
      type:this.currentEntity.type,
      image:this.currentEntity.image,
      range:this.currentEntity.starts_at+','+this.currentEntity.ends_at,
      selectedTerritories: this.currentEntity.territories.map((territory: Territory) => territory.id),
      selectedUsers: this.currentEntity.users.map((user: User) => user.id),
      selectedRoles: this.currentEntity.roles.map((role: Role) => role.id),
    };
    this.isActive = true;

  }
}
