


































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Dialog} from "element-ui";

@Component({
  components: {
    [Dialog.name]: Dialog,
  }
})

export default class OrderStatusButton extends Vue {
  @Prop({default: false}) rejected!: boolean;
  @Prop() makeInvoice!: boolean;
  @Prop() edit!: boolean;
  @Prop() visible!: boolean;
  @Prop() cancel!: boolean;
  @Prop() readyToDispatch!: boolean;
  @Prop() readyToDelivery!: boolean;
  @Prop() rollBackToInvoice!: boolean;
  @Prop() deliveryReject!: boolean;
  @Prop() deliveryBtn!: boolean;
  @Prop() entityId!: number;
  @Prop() disabledSaveBtn!: boolean;
  @Prop() entity!: string;
  @Prop() editPermission!: string;
  @Prop() statusPermission!: string;
  private rollBackFlag: boolean = false;
  private confirmationForStatusChange: boolean = false;
  public deliveryEditBtnSave: boolean = false;
  private status: string = '';
  private remark: string = '';
  public autoApprove: boolean = JSON.parse(localStorage.settings).order_approval;

  formReset() {
    this.status = '';
    this.remark = '';
    this.rollBackFlag = false;
  }

  changeStatus() {
    if (this.status == 'edit') {
      if (this.deliveryEditBtnSave) {
        this.editOrder();
        this.$emit('editOrder', false)
        this.$emit('deliveryOrder', { 'remarks': this.remark });
        this.formReset();
      } else {
        this.$emit('editOrder', true);
      }
      this.deliveryEditBtnSave = false;
    } else if (this.status == 'delivered') {
      this.$emit('deliveryOrder', { 'delivery': true, 'remarks': this.remark });
    } else {
      this.$http.post(this.$api(`/${this.entity}/${this.entityId}/statuses`), {'status': this.status, 'remarks': this.remark})
        .then(response => {
          this.$notify({
            title: 'Success',
            message: 'Successfully Changed',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          this.$emit('updateStatus', response.data);
          location.reload();
        });
      this.formReset();
    }
  }

  editOrder() {
    this.$emit('triggerSaveBtn');
    this.status = '';
  }
}
