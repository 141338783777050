













































import { Component, Vue } from 'vue-property-decorator';
import { Button } from 'element-ui';
import ReportRoutes from '@/router/client-routes/ReportRoutes';
import {RouteConfig} from "vue-router";

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class StockReport extends Vue {
  get routes(): RouteConfig {
    return ReportRoutes;
  }

  get StockRoutes() {
    const stockReportRoute = ReportRoutes.children?.find(route => route.name === 'stock-report');
    return stockReportRoute?.children || [];
  }

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}
