

















import {
    Component,
    Mixins,
    Prop,
    Watch
} from "vue-property-decorator";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

// @ts-ignore
import PieChart from '@/components/argon-core/Charts/PieChart';

import moment from 'moment';
import Chart from 'chart.js';

@Component({
    components: {
        PieChart,
    },
})
export default class BasicBrandReport extends Mixins(TaxonomyMixin) {
    @Prop({
        default: ''
    })
    public range!: string;

    @Prop({
        default: null
    })
    public territoryId!: number | null;

    @Prop({
        default: null
    })
    public userIds!: string | null;

    @Prop({
        default: null
    })
    public departmentIds!: string | null;

    @Prop({
        default: null
    })
    public brandIds!: string | null;

    @Prop({
        default: null
    })
    public viewBaseOn!: string | null;

    @Prop({
        default: null
    })
    public valueType!: string | null;

    @Prop({
        default: null
    })
    public limitBrand!: number | null;

    @Prop({
        default: false
    })
    public isGenerateReport!: boolean;

    public loading: boolean = true;

    public basicBrandChartInfo: any = {
        chartData: {
            datasets: [],
            labels: []
        },
        extraOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'Product Brand'
            },
            legend: {
                display: true,
                position: 'right',
            },
        }
    };

    mounted() {
        this.fetch();
    }

    async fetch() {
        this.loading = true;
        this.basicBrandChartInfo.chartData.datasets = [];
        this.basicBrandChartInfo.chartData.labels = [];
        const params = {
            type: 'basicBrands',
            range: this.range,
            territory_id: this.territoryId,
            user_ids: this.userIds,
            department_ids: this.departmentIds,
            brand_ids: this.brandIds,
            view_base_on: this.viewBaseOn,
            value_type: this.valueType,
            limit_brand: this.limitBrand
        };

        try {
            let {
                data
            } = await this.$http.get(this.$api3(`/brand-report${this.$toQueryString(params)}`))
            this.basicBrandChartInfo.chartData.labels = data.productBasicBrandReport.labels;
            this.basicBrandChartInfo.extraOptions.title.text = data.productBasicBrandReport.datasets[0].label;

            let dataList = data.productBasicBrandReport.datasets[0].data;
            const total = dataList.reduce((previousValue: any, currentValue: any) => previousValue + currentValue, 0);

            data.productBasicBrandReport.datasets[0].data = dataList.map((currentValue: number) => {
                return currentValue === 0 ? 0 : Math.round((currentValue / total) * 100);
            });
            this.basicBrandChartInfo.chartData.datasets = data.productBasicBrandReport.datasets;

            this.basicBrandChartInfo.extraOptions.legend = {
                display: true,
                position: 'right',
                labels: {
                    generateLabels: function (chart: any) {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map(function (label: string, i: number) {
                                const meta = chart.getDatasetMeta(0);
                                const percentage = data.datasets[0].data[i];
                                const arc = meta.data[i];
                                const custom = arc && arc.custom || {};
                                const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                const arcOpts = chart.options.elements.arc;
                                const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(data.datasets[0].backgroundColor, i, arcOpts.backgroundColor);
                                const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(data.datasets[0].borderColor, i, arcOpts.borderColor);
                                const strokeWidth = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(data.datasets[0].borderWidth, i, arcOpts.borderWidth);

                                return {
                                    text: label + ' (' + percentage + '%)',
                                    fillStyle: fill,
                                    strokeStyle: stroke,
                                    lineWidth: strokeWidth,
                                    hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
                                    index: i
                                };
                            });
                        }
                        return [];
                    }
                },
            };
        }
        // catch (e) {
        //
        // }
        finally {
            this.loading = false
        }
    }

    @Watch('key')
    onKeyChange() {
        this.fetch();
    }

}
