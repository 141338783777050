





















































































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryLazydropdown from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import swal from 'sweetalert2';
import {
    DatePicker,
    Select
} from 'element-ui';

@Component({
    components: {
        TerritoryCascaderDropdown,
        ProductBrandLazydropdown,
        ProductCategoryLazydropdown,
        UserLazydropdown,
        ElDatePicker: DatePicker,
        ElSelect: Select,
    },
})
export default class TargetAchievement extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public formData: any = {};
    public selectedTerritoriesName = '';
    public loading: boolean = false;
    public targets = [];
    public table_fields = [];
    public filter_data = {};
    public viewBasedOnOptions: any = [{
            value: 'category',
            label: 'Category',
        },
        {
            value: 'brand',
            label: 'Brand',
        },
        {
            value: 'product',
            label: 'Product',
        },
    ];
    public achievementBasedOnOptions: any = [{
            value: 'order',
            label: 'Order',
        },
        {
            value: 'delivery',
            label: 'Delivery',
        },
    ];
    public types: any = [{
        value: 'amount',
        name: 'Amount'
    },
    {
        value: 'qty',
        name: 'Quantity'
    }
    ];
    public onClickGenerate() {
        this.fetchEcoReport();
    }

    async fetchEcoReport() {
        this.loading = true;
        this.targets = [];
        this.$http.get(this.$api3(`/target-achievement-report${this.$toQueryString(this.formData)}`)).then(response => {
                this.targets = response.data.targets;
                this.table_fields = response.data.table_fields;
                this.filter_data = response.data.filter_data;
                if (this.targets.length == 0) {
                    this.$notify({
                        type: 'warning',
                        message: 'There is no targets set in selected filters',
                        title: 'No data found!',
                    });
                }
            })
            .catch((error) => {
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            })
            .finally(() => {
                this.loading = false;
            })
    }
    onSelectBrand(value: []) {
        // window.console.log(value)
    }

    async onClickDownload() {
        this.downloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api3(`/target-achievement-report${this.$toQueryString(this.formData)}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.downloading = false;
        }
    }
    created() {
        this.$http.get(this.$api(`/units`)).then(response => {
            const units = this.sortItems(response.data.units);
            units.forEach((element: any, key: number) => {
                this.types.push({
                    name: element.name,
                    value: 'unit' + (key + 1)
                });
            })
        });
    }

    sortItems(arr: []) {
        return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    }

    getValueBased(value_based_on: String) {
        let value = this.types.find((type: any) => type.value === value_based_on);
        if (value) {
            return value.name;
        }
    }
}
