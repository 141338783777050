










































































































































































































































import _ from 'lodash';
import {Vue, Component, Watch, Mixins} from 'vue-property-decorator';
import RoutePlanForm from '@/components/tracking/RoutePlanForm.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag, Popover} from 'element-ui';
import RoutePlan from '@/intefaces/RoutePlan';
import OutletsModal from '@/views/tracking/OutletsModal.vue';
import GIFModal from "@/components/tour/GIFModal.vue";
import ActiveStatus from '@/data/ActiveStatus.ts';
import CustomTooltip from "@/components/CustomTooltip.vue";
import {Permission as PermissionEnum} from '@/enums/Permission'
import {QueryBuilder} from "@/classes/QueryBuilder";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import Days from "@/data/Days.ts";
import {RoutePlanTimespan} from '@/enums/RoutePlanTimespan.ts';
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import DateRange from "@/components/DateRange.vue";
import ViewOutletForRoutePlan from "@/views/tracking/ViewOutletForRoutePlan.vue";
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import CreateBulkRoute from "@/views/tracking/CreateBulkRoutePlan.vue";

@Component({
  components: {
    CreateBulkRoute,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Popover.name]: Popover,
    RoutePlanForm,
    OutletsModal,
    // ActiveStatus,
    CustomTooltip,
    UserLazydropdown,
    TerritoryLazydropdown,
    DepartmentLazydropdown,
    DateRange,
    ViewOutletForRoutePlan,
    FilterDrawerComponent
  }
})
export default class RouteList extends Mixins(PaginationComponentMixin) {
  private isActive = false;
  private isUpdate = false;
  private showBulkRouteModal: boolean = false;
  private showDeleteModal = false;
  private showOutletModal = false;
  private currentRoutePlan: any = [];
  private users: any[] = [];
  private routes: RoutePlan[] = [];
  private showGIF: boolean = false;
  private outlets: any = [];
  private isFilterModelActive: boolean = false;
  private status: any = '';
  private filters: any = {
    status: '',
    name: '',
    userName: '',
    territoryId: '',
    day: '',
    timespan: '',
    departmentId: '',
    createdDateRange: '',
    expiresDateRange: '',
  }
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private form: any = {
    routeName: '',
    timespans: '',
    user: '',
    days: [],
    outletList: [],
    expiresAt: '',
    territory: '',
    active: true
  };
  private demoTourEntity: string = 'routes';
  private outletLists: any = [];
  private GIFs: Array<any> = [
    {
      label: 'Create a route',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a route',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a route',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of routes';

  get permissionEnum() {
    return PermissionEnum;
  }

  get days() {
    return Days;
  }

  get timespans() {
    return RoutePlanTimespan;
  }

  fetchUser() {
    this.$http(this.$api('/users')).then(response => {
      this.users = response.data.users;
    });
  }

  get activeStatuses() {
    return ActiveStatus;
  }

  addZero(v: any) {
    if (v < 10) return '0' + v;
    return v;
  }

  stripDate(date: any) {
    var d = new Date(date),
      y = d.getFullYear(),
      m = this.addZero(d.getMonth() + 1),
      da = this.addZero(d.getDate());
    return y + '-' + m + '-' + da;
  }

  entityCreated(route: any) {
    this.routes.unshift(route);
  }

  onClick(row: any) {
    this.outletLists = [];
    this.$http(this.$api(`/route-plans/${row}/outlets`)).then(response => {
      response.data.outlets.forEach((outlet: any) => {
        this.outletLists.push(outlet.name);
      })
    });
  }

  onEdit(row: any) {
    this.isUpdate = true;
    this.currentRoutePlan = row;
    var currentDays: any = [];
    var outletLists: any = [];
    // this.currentRoutePlan.outlets.forEach((outletList: any) => {
    //   outletLists.push(outletList.id);
    // });
    this.form = {
      routeName: this.currentRoutePlan.name,
      timespans: this.currentRoutePlan.timespan,
      user: this.currentRoutePlan.assigned_to,
      days: this.currentRoutePlan.days,
      tour_dates: _.map(this.currentRoutePlan.route_plan_dates, 'tour_date'),
      reporting_time: this.currentRoutePlan.reporting_time,
      outletList: outletLists,
      expiresAt: this.stripDate(this.currentRoutePlan.expires_at),
      territory: this.currentRoutePlan.territory_id,
      active: Boolean(this.currentRoutePlan.active),
    };
    this.isActive = true;
  }

  deleteRoutePlan() {
    this.$http.delete(this.$api(`/route-plans/${this.currentRoutePlan.id}`)).then(response => {
      this.showDeleteModal = false;
      this.routes.splice(
        this.routes.findIndex(route => route.id == this.currentRoutePlan.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  createRoute(route: any) {
    this.isActive = false;
    this.routes.unshift(route);
  }

  updateRoute(updatedRoute: any) {
    this.isUpdate = false;
    this.routes.splice(
      this.routes.findIndex(route => route.id == updatedRoute.id),
      1,
      updatedRoute
    );
    this.isActive = false;
  }

  showOutletsModal(outlets: any) {
    this.outlets = outlets;
  }

  created() {
    // this.fetchUser();
    this.fetchRoute();
  }

  resetBtn() {
    this.filters.status = '';
    this.filters.name = '';
    this.filters.userName = '';
    this.filters.territoryId = '';
    this.filters.day = '';
    this.filters.timespan = '';
    this.filters.departmentId = '';
    this.filters.createdDateRange = '';
    this.filters.expiresDateRange = '';

    if (this.$refs.formValidator) {
      (this.$refs.formValidator as Vue & {
        reset: Function
      }).reset();
    }
  }

  @Watch('filters.name', {deep: true})
  onChangeNameFilter(value: string) {
    this.queryBuilder.addListener('name', value);
  }

  @Watch('filters.userName', {deep: true})
  onChangeUserNameFilter(value: string) {
    this.queryBuilder.addListener('assigned_to', value);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusFilter(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('filters.territoryId', {deep: true})
  onChangeTerritoryFilter(value: string) {
    this.queryBuilder.addListener('t', value);
  }

  @Watch('filters.day', {deep: true})
  onChangeDayFilter(value: string) {
    this.queryBuilder.addListener('day', value);
  }

  @Watch('filters.timespan', {deep: true})
  onChangeTimeSpanFilter(value: string) {
    this.queryBuilder.addListener('timespan', value);
  }

  @Watch('filters.departmentId', {deep: true})
  onChangeDepartmentFilter(value: string) {
    this.queryBuilder.addListener('dept', value);
  }

  @Watch('filters.expiresDateRange', {deep: true})
  onChangeExpiresDateRangeFilter(value: string) {
    this.queryBuilder.addListener('expires_at_date_range', value);
  }

  @Watch('filters.createdDateRange', {deep: true})
  onChangeCreatedAtDateRangeFilter(value: string) {
    this.queryBuilder.addListener('created_at_date_range', value);
  }

  @Watch('queryBuilder.watch')
  @Watch('pagination.currentPage')
  fetchRoute(): void {
    this.loading = true;
    this.$http.get(this.$api(`/route-plans?with=assignee&page=${this.pagination.currentPage}&${this.queryBuilder.getFilters()}`)).then(response => {
      this.routes = response.data.routePlans;
      this.setPagination(response.data);
      this.loading = false;
    });
  }
}
