









































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Button} from "element-ui";
import moment from "moment";

const flatPicker = require('vue-flatpickr-component');

@Component({
  components: {
    ElButton: Button,
    flatPicker
  }
})
export default class DateRange extends Vue {
  @Prop()
  value!: string;
  @Prop()
  labelProp!: string;
  @Prop()
  disabled!: boolean;
  @Prop()
  maxDate!: string;

  public startDate: string = '';
  public endDate: string = '';
  public enable = true;
  public disableAutoFocus = false;

  updateDateRange() {
    if (this.startDate && this.endDate) {
      this.$emit('input', this.startDate + ',' + this.endDate);
    }
  }

  mounted() {
    if (this.value) {
      this.disableAutoFocus = true;
      let dateRange = this.value.split(',');
      this.startDate = dateRange[0];
      this.endDate = dateRange[1];
      if(!this.startDate && !this.endDate){
      this.$nextTick(function () {
        this.disableAutoFocus = false;
      })
    }
    }
  }

  @Watch('startDate')
  async onChangeStartDate(value: string) {
    if (!this.disableAutoFocus) {
      this.endDate = '';
      this.updateDateRange();
      await this.$nextTick(() => this.enable = false);
      await this.$nextTick(() => this.enable = true);
      await this.$nextTick(() => (this.$refs.endDate as Vue & { $el: { focus: Function } }).$el.focus());
    }
  }

  @Watch('endDate')
  onChangeEndDate(value: string) {
    this.updateDateRange();
  }

  @Watch('value')
  onChangeValue(value: string) {
    if (!value) {
      this.startDate = this.endDate = '';
    }
  }

  onClickClear() {
    this.startDate = '';
    this.endDate = '';
    this.$emit('input', '');
  }

  get getConfig(): any {
    let self = this;
    return {
      allowInput: true,
      disable: [
        function (date: Date) {
          return moment(self.startDate).diff(date) > 0
        }
      ],
      maxDate: this.maxDate
    }
  }
}
