



















































































































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import DateRange from '@/components/DateRange.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
// import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
// import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import swal from 'sweetalert2';
import moment from 'moment';

@Component({
    components: {
        // TerritoryAutocomplete,
        // TerritoryTypeAutocomplete,
        ProductBrandLazydropdown,
        UserLazydropdown,
        DateRange,
        TerritoryCascaderDropdown
    },
})
export default class ECO extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public range = '';
    public selectedTerritoriesName = '';
    public selectedTerritoriesUsers = '';
    public avgWorking: any = 0;
    public totalPlanned: any = 0;
    public totalVisited: any = 0;
    public visitedPercentage: any = 0;
    public avgOutletTime: any = 0;
    public selectedUsers: any = [];
    // public selectedTerritoryType: any = null;
    public selectedTerritories: any = null;
    public loading: boolean = false;
    public checkInReport = [];
    public maxDate: string = moment().subtract(1, 'days').format('YYYY-MM-DD');
    public query: any = '';

    public onClickGenerate() {
        if (this.range === '' || this.selectedTerritories === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the required field',
                title: 'Required!',
            });
        } else {
            this.fetchReport();
        }
    }

    @Watch('pagination.currentPage')
    onChangePage(newVal: any) {
        this.fetchReport();
    }

    @Watch('range', { immediate: true })
    @Watch('selectedTerritories', { immediate: true })
    @Watch('selectedUsers', { immediate: true })
    updateCurrentpage() {
        this.pagination.currentPage = 1;
  }

    async fetchReport() {
        try {
            this.loading = true;
            this.checkInReport = [];
            this.query = {
                range: this.range,
                territory_id: this.selectedTerritories,
                user_id: this.selectedUsers.join(','),
                page: `${ this.pagination.currentPage }`,
            };
            let {
                data
            } = await this.$http.get(this.$api3(`/checkin-summary${this.$toQueryString(this.query)}`));
            this.checkInReport = data.reports.data;
            this.setPagination(data.reports);
            if (this.checkInReport.length > 0) {
                this.selectedTerritoriesName = data.territory.name;
                this.selectedTerritoriesUsers = data.territory.total_users;
                this.avgWorking = data.territory.avg_working;
                this.totalPlanned = data.territory.total_planned;
                this.totalVisited = data.territory.total_visited;
                this.visitedPercentage = data.territory.visited_percentage;
                this.avgOutletTime = data.territory.avg_outlet_time;
            } else {
                this.$notify({
                    type: 'info',
                    message: 'The filter you applied has no data',
                    title: 'Checkin summary report is empty!',
                    duration: 5000,
                });
            }
        } catch (e) {
            let err_msg = [];
            if (e.response.data.errors.range) {
                err_msg.push(e.response.data.errors.range[0]);
            }
            if (e.response.data.errors.territory_id) {
                err_msg.push(e.response.data.errors.territory_id[0]);
            }
            err_msg.forEach((error) => {
                this.$notify({
                    type: 'warning',
                    message: error,
                    title: 'Validation error',
                });
            });
        } finally {
            this.loading = false;
        }
    }
    async onClickDownload() {
        try {
            this.downloading = true;
            const link = document.createElement('a');
            link.href = this.$api3(`/checkin-summary${this.$toQueryString(this.query)}&download`);
            link.click();
        } catch (e) {
            this.$notify({
                type: 'warning',
                message: 'Unable to download.',
                title: 'Something went wrong!',
            });
        } finally {
            this.downloading = false;
        }
    }
}
