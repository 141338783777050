var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-3 col-lg-2"},[_c('base-input',{attrs:{"rules":{ required: true },"label":"Select Month","name":"month"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"month","placeholder":"Pick a month","clearable":"","format":"MMMM, yyyy","value-format":"yyyy-MM-dd"},model:{value:(_vm.formData.month),callback:function ($$v) {_vm.$set(_vm.formData, "month", $$v)},expression:"formData.month"}})],1)],1),_c('div',{staticClass:"col-xs-12 col-sm-4 col-lg-2"},[_c('base-button',{staticClass:"btn-block",staticStyle:{"margin-top":"20px"},attrs:{"loading":_vm.loading,"type":"success"},on:{"click":function($event){return handleSubmit(_vm.onClickGenerate)}}},[_c('i',{staticClass:"fa fa-search"},[_vm._v(" Generate")])])],1),_c('div',{staticClass:"col-xs-12 col-sm-4 col-lg-2"},[(_vm.users.length > 0)?_c('base-button',{staticStyle:{"margin-top":"20px"},attrs:{"loading":_vm.downloading,"type":"primary"},on:{"click":_vm.onClickDownload}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download ")]):_vm._e()],1),_c('div',{staticClass:"col-xs-12 col-sm-4 col-lg-2"},[(_vm.users.length > 0)?_c('el-tag',{staticStyle:{"margin-top":"20px"},attrs:{"type":"info","effect":"plain"}},[_vm._v("Total Billable Users: "+_vm._s(_vm.pagination.totalEntity))]):_vm._e()],1)])]}}])}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive  table-flush table-striped",staticStyle:{"margin-top":"1rem"},attrs:{"header-row-class-name":"thead-light","data":_vm.users}},[_c('el-table-column',{attrs:{"label":"SL","min-width":"100px","sortable":"","type":"index"}}),_c('el-table-column',{attrs:{"label":"Billing Month","min-width":"120px","prop":"date","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(row.month,'MMMM, YYYY'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"User Code","min-width":"120px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"User Name","min-width":"140px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Email","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.active ? 'Active' : 'Inactive')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Last Time Login","min-width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dateFormat(row.last_time_login))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Last Activity","min-width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dateFormat(row.last_activity))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Bill Generate at","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dateFormat(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Type","min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type)+" ")]}}])})],1),(_vm.users.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" Billing List ")])])])])}]

export { render, staticRenderFns }