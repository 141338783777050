









import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CategoryForm from "@/components/products/CategoryForm.vue";

@Component({
  components: {
    LazyDropdown,
    CategoryForm
  }
})
export default class ProductCategoryLazydropdown extends Mixins(DropdownField) {
  @Prop() addButton!: boolean;
  @Prop() disabled!: boolean;
}
