

























































































































import {
    Component,
    Vue,
    Watch,
    Mixins
} from 'vue-property-decorator';
import {
    Popover,
    Switch,
    Select,
    Option
} from 'element-ui';
import Currencies from '@/assets/json/Currencies.json';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
    components: {
        [Switch.name]: Switch,
        [Popover.name]: Popover,
        [Select.name]: Select,
        [Option.name]: Option
    }
})
export default class Miscellaneous extends Mixins(TaxonomyMixin) {
    public approval: boolean = false;
    public primaryApproval: boolean = false;
    public primaryApprovalDelayTime: number = 0;
    public approvalDelayTime: number = 0;
    public rejected: boolean = false;
    public rejectedDelayDays: number = 1;
    public currency: string = '';
    public retrievedSettings: any = '';
    public currencies: any = Currencies;
    public loading: boolean = false;
    public quantity_zero_approval: boolean = false;

    updateSettings() {
        this.loading = true;
        this.$http
            .patch(this.$api('/settings/1'), {
                order_approval: this.approval,
                order_approval_delay_time: this.approvalDelayTime,
                primary_order_approval: this.primaryApproval,
                primary_approval_delay_time: this.primaryApprovalDelayTime,
                order_rejected: this.rejected,
                order_rejected_delay_days: this.rejectedDelayDays,
                currency: this.currency,
                product_qty_checker: this.quantity_zero_approval,
            })
            .then(response => {
                this.updateSettingsNotification();
                this.currencySyncLocalStorage();
            })
            .catch(error => {
                (this.$refs.formValidator as Vue & {
                    setErrors: (errors: []) => any;
                }).setErrors(error.response.data.errors);
                this.$notify({
                    message: 'Please contact your service provider.',
                    title: 'Something went wrong!',
                    type: 'warning'
                });
            })
            .finally(() => (this.loading = false));
    }

    updateSettingsNotification() {
        this.$notify({
            title: 'Success',
            message: 'Settings Updated',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            // @ts-ignore
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            type: 'success'
        });
    }

    async currencySyncLocalStorage() {
        if (localStorage.settings) {
            this.retrievedSettings = JSON.parse(localStorage.settings);
            if (this.retrievedSettings.currency) {
                this.retrievedSettings.currency = this.currency;
            } else {
                this.retrievedSettings.push({
                    currency: this.currency
                });
            }
            localStorage.removeItem('settings');
            this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
            this.$store.dispatch('Settings/updateCurrency', this.currency);
        } else {
            let settings = [];
            settings.push({
                currency: this.currency
            });
            this.$store.dispatch('Settings/updateSettings', this.retrievedSettings);
            this.$store.dispatch('Settings/updateCurrency', this.currency);
        }
    }

    fetchSettings() {
        this.$http.get(this.$api(`/settings`)).then(response => {
            this.approval = Boolean(response.data.settings.order_approval);
            this.approvalDelayTime = Number(response.data.settings.order_approval_delay_time);
            this.primaryApproval = Boolean(response.data.settings.primary_order_approval)
            this.primaryApprovalDelayTime = Number(response.data.settings.primary_approval_delay_time);
            this.rejected = Boolean(response.data.settings.order_rejected);
            this.rejectedDelayDays = Number(response.data.settings.order_rejected_delay_days);
            this.currency = response.data.settings.currency;
            this.quantity_zero_approval = Boolean(response.data.settings.product_qty_checker);
        });
    }

    created() {
        this.fetchSettings();
    }
}
