
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DashboardStatsCard from "@/components/DashboardStatsCard.vue";
import moment from "moment/moment";
import { QueryBuilder } from "@/classes/QueryBuilder";
import DateRange from "@/components/DateRange.vue";
import { Button, ButtonGroup } from "element-ui";

@Component({
  components: {
    DashboardStatsCard,
    DateRange,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup
  }
})
export default class AdminHome extends Vue {
  private selectedButton: string = 'Today';
  private selectedDateRange: string = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
  private isFetchData: boolean = false;
  private loading: boolean = false;
  private error: string = '';
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private totalNumberOfUser: number = 0;
  private totalNumberOfActiveUser: number = 0;
  private totalNumberOfInActiveUser: number = 0;
  @Watch('selectedDateRange')
  onChangeDateRange(value: string) {
    this.queryBuilder.addListener('range', value);
  }
  @Watch('queryBuilder.watch')
  fetchTodayData() {
    this.loading = true;
    this.isFetchData = false;
    this.$http.get(this.$landLordApi(`/dashboard-report`)).then(response => {
      let dailyReports = response.data.dailyReports;
      this.totalNumberOfUser = dailyReports.totalUsers;
      this.totalNumberOfActiveUser = dailyReports.activeUsers;
      this.totalNumberOfInActiveUser = dailyReports.inActiveUsers;
      this.loading = false;
      this.isFetchData = true;
    })
      .catch((error) => {
        this.isFetchData = true;
        this.error = error.response.data.errors.manager[0];
      });
  }
  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
  updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      this.selectedDateRange = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'WTD') {
      this.selectedDateRange =
        moment()
          .day(-1)
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'MTD') {
      this.selectedDateRange =
        moment()
          .startOf('month')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'QTD') {
      this.selectedDateRange =
        moment()
          .startOf('quarter')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'YTD') {
      this.selectedDateRange =
        moment()
          .startOf('year')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    }
  }

  created() {
    this.fetchTodayData();
  }

}
