































































































import {
    Component,
    Mixins,
    Prop,
    Vue,
    Watch
} from 'vue-property-decorator';
import TerritoryTypeAutocomplete from "@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue";
import TerritoryAutocomplete from "@/components/resources-autocomplete/TerritoryAutocomplete.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import {
    Table,
    TableColumn,
    Radio,
    RadioGroup
} from "element-ui";

@Component({
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        TerritoryAutocomplete,
        TerritoryTypeAutocomplete,
    }
})
export default class CustomReport extends Mixins(PaginationComponentMixin, Vue) {
    public loading: boolean = false;
    public fetchloading: boolean = false;
    public customReports: any = [];
    public parameters: any = [{
        'name': 'Date',
        'value': 'date'
    }, {
        'name': 'User',
        'value': 'user'
    }, {
        'name': 'Category',
        'value': 'category'
    }, {
        'name': 'Brand',
        'value': 'brand'
    }];

    public parametersSums: any = [{
        'name': 'Amount',
        'value': 'amount'
    }, {
        'name': 'Quantity',
        'value': 'qty'
    }];

    @Prop({
        default: () => ({
            name: '',
            territory_type_id: null,
            territory_id: null,
            parameter: [],
            sums: [],
            type: '',

        }),
    })

    form!: {
        name: '';
        territory_type_id: number | null;
        territory_id: number | null;
        type: '';
        parameter: [];
        sums: [];
    };

    created() {
        this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
            const units = this.sortItems(response.data.units);
            units.forEach((element: any, key: number) => {
                this.parametersSums.push({
                    name: element.name,
                    value: 'unit' + (key + 1)
                });
            })
        });
        this.fetchSummaries();
    }

    sortItems(arr: []) {
        return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    }

    fetchSummaries() {
        this.fetchloading = true;
        this.$http.get(this.$api3('/custom-report')).then((response) => {
                this.customReports = response.data;
                this.fetchloading = false;
            })
            .catch(error => window.console.log(error))
    }

    formReset(): any {
        this.form.name = '';
        this.form.territory_id = null;
        this.form.territory_type_id = null;
        this.form.parameter = [];
        this.form.type = '';
        this.form.sums = [];
        //return this.form;
    }

    createEntity() {
        this.loading = true;
        this.$http
            .post(this.$api3('/custom-report'), this.form)
            .then((response) => {
                if (response.data.error) {
                    this.$notify({
                        title: 'error',
                        message: 'The maximum limit for creating custom report is 5',
                        duration: 5000,
                        iconClass: 'ni ni-bell-55',
                        type: 'warning',
                    });
                } else {
                    this.$notify({
                        title: 'Success',
                        message: 'Successfully Created',
                        duration: 5000,
                        iconClass: 'ni ni-bell-55',
                        type: 'success',
                    });
                    this.fetchSummaries();
                    this.formReset();
                }
            })
            .catch((error) => {
                this.loading = false;
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            }).finally(() => {
                this.loading = false;
            });
    }
    getSums(sums: any) {
        let sumsName: any = [];
        // sums.join(', ').forEach((element: any) => {
        //   sumsName.push =  this.parametersSums.find((parameter: any) => parameter.value === element).name;
        // });
        window.console.log(sums.join(', '));
    }
}
