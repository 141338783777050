















































//@ts-ignore
import {Vue, Component, Watch} from 'vue-property-decorator';
//@ts-ignore
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import CustomReportRoutes from "@/router/client-routes/CustomReportRoutes";
import {RouteConfig} from "vue-router";

@Component({
  components: {RouteBreadCrumb}
})

class Report extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  routes: RouteConfig = CustomReportRoutes;
  formErrors: any = {};
  formInputs: any = {};

  get CustomReportRoutes() {
    return CustomReportRoutes;
  }

  created(): void {
    this.setActiveTab();
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }

  setActiveTab() {
    if (this.routes.children === undefined) return;
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  @Watch('$route', {deep: true})
  onChangeRoute(to: any, from: any) {
    this.setActiveTab();
  }
}

export default Report;

