















































































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import { TargetActiveOn } from "@/enums/TargetActiveOn";

@Component({
  components: {ProductLazydropdown}
})
export default class SingleProduct extends Mixins(TaxonomyMixin) {
  @Prop()
  public product: any
  @Prop()
  public index: any
  @Prop()
  public deleteProductFromArray: any
  @Prop()
  public deleteProductFromSever: any
  @Prop()
  public unitType: any

  private actionOn = [
    {label: this.getTaxonomy('invoice'), value: this.targetActiveOn.ON_INVOICE},
    {label: this.getTaxonomy('order'), value: this.targetActiveOn.ON_ORDER},
    {label: 'Delivery', value: this.targetActiveOn.ON_DELIVERY}
  ];

  get targetActiveOn() {
    return TargetActiveOn;
  }

}
