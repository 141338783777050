


















import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
  name: 'CreateRole'
})
export default class ViewUserAcl extends Vue{
  @Prop() showRoleAcl!: boolean;
  @Prop() currentRole!: any;

  close(){
    this.$emit('update:showRoleAcl', false);
  }
}
