



















































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import InvoiceDetails from '@/views/order/invoice/InvoiceDetails.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Invoice } from '@/intefaces/Invoice';
import InvoiceAmountDetailsForm from '@/views/order/invoice/InvoiceAmountDetailsForm.vue';
import InvoicePaymentDetailsForm from '@/views/order/invoice/InvoicePaymentDetailsForm.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import RevisionList from '@/views/order/RevisionList.vue';
// import RequisitionForm from '@/views/order/requisition/RequisitionForm.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import { Permission as PermissionEnum } from '@/enums/Permission';
import ReadyForDeliveryInvoiceDetails from '@/views/order/readyForDelivery/ReadyForDeliveryInvoiceDetails.vue';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import moment from 'moment';
import TenantModulesMixin from '@/mixins/HasTenantModules';

@Component({
  components: {
    FilterDrawer,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    GIFModal,
    InvoiceDetails,
    InvoiceAmountDetailsForm,
    InvoicePaymentDetailsForm,
    RevisionList,
    // RequisitionForm,
    ReadyForDeliveryInvoiceDetails,
    FilterDrawerComponent,
    DateRangeForReport
  }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin, TenantModulesMixin) {
  private invoices: Invoice[] = [];
  private currentInvoice: any = [];
  private allRevision: OrderRevision[] = [];
  private invoiceDetails: Invoice[] = [];
  private showGIF: boolean = false;
  private demoTourEntity: string = 'invoice';
  private isShowAmountDetails: boolean = false;
  private isShowPaymentDetails: boolean = false;
  private isActiveInvoiceDetails: boolean = false;
  private isViewRevision: boolean = false;
  private isInvoiceForRevision: boolean = false;
  private isUpdate: boolean = false;
  private isActive: boolean = false;
  private isDelivery: boolean = false;
  private updateAmountCount: boolean = false;
  private isFilterModelActive = false;
  private queryBuilder = new QueryBuilder();

  private form = {
    status: '',
    remarks: ''
  };
  private formDelivery = {
    departmentId: '',
    sellerId: '',
    discount: 0,
    promotionalDiscount: 0,
    expectedDeliveryDate: '',
    paid: '',
    type: 'product',
    productableId: '',
    badgeDiscount: 0
  };
  private product: any = [
    {
      product_id: '',
      price: 0,
      qty: 0,
      free_with: '',
      promotion_discount: 0,
      promotion_id: '',
      fullName: '',
      lot_number: ''
    }
  ];
  private GIFs: Array<any> = [
    {
      label: 'Create a invoice',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a invoice',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a invoice',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of invoice';

  public filters: any = {
    range: moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD')
  };
  get permissionEnum() {
    return PermissionEnum;
  }

  UpdateLatestStatusForInvoice(row: Invoice) {
    this.$http.post(this.$api(`/invoices/${row.id}/statuses`), this.form).then(response => {
      this.$notify({
        title: 'Success',
        message: 'Successfully Changed',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.invoices.splice(
        this.invoices.findIndex(invoice => invoice.id == response.data.invoice.id),
        1
      );
    });
  }

  entityUpdated(entity: Invoice): void {
    this.invoices.splice(
      this.invoices.findIndex(type => type.id == entity.id),
      1
    );
    this.isActiveInvoiceDetails = false;
  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }
  resetBtn() {
    this.filters.range = '';
  }

  async onEdit(row: any) {
    let { data } = await this.$http.get(this.$api3(`/invoice-products/${row.id}`));

    this.currentInvoice = row;
    this.formDelivery = {
      departmentId: row.buyer_id,
      sellerId: row.seller_id,
      discount: row.discount,
      promotionalDiscount: row.promotional_discount,
      expectedDeliveryDate: row.expected_delivery_date,
      paid: '',
      type: this.checkPromotionOn(
        data.invoiceProducts[0].promotion ? data.invoiceProducts[0].promotion : '',
        data.invoiceProducts[0].promotion && data.invoiceProducts[0].promotion.promotion_on ? data.invoiceProducts[0].promotion.promotion_on : ''
      ),
      productableId: data.invoiceProducts[0].promotion ? data.invoiceProducts[0].promotion.promotionable_id : '',
      badgeDiscount: row.badge_percentage
    };
    this.product = [];
    [data.invoiceProducts].forEach((product: any) => {
      product.map((singleProduct: any) => {
        this.product.push({
          product_id: singleProduct.product_id,
          price: singleProduct.price,
          qty: singleProduct.qty,
          free_with: singleProduct.free_with,
          promotion_discount: singleProduct.promotion_discount,
          promotion_id: singleProduct.promotion != null ? singleProduct.promotion.id : '',
          fullName: singleProduct.product.full_name,
          lot_number: singleProduct.lot_number
        });
      });
    });
    this.isActive = true;
    this.isUpdate = true;
    this.updateAmountCount = true;
  }

  afterCreateDelivery(event: any) {
    this.isActive = false;
    this.isDelivery = false;
    this.form = {
      status: 'delivered',
      remarks: 'Order is Delivered'
    };
    this.UpdateLatestStatusForInvoice(this.currentInvoice);
  }

  @Watch('filters.range', { deep: true })
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('created_between', value);
  }
  @Watch('pagination.currentPage')
  @Watch('queryBuilder.watch')
  async fetchInvoices() {
    const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
    this.loading = true;
    try {
      let { data } = await this.$http.get(
        this.$api3(
          `/invoices${
            this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?created_between=' + this.filters.range
          }&latest_status=ontheway&with=payments,products.product&page=${this.pagination.currentPage}&order_type=${order_type}`
        )
      );
      this.setPagination(data);
      this.invoices = data.invoices;
    } catch (e) {
      this.$notify({ title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error' });
    } finally {
      this.loading = false;
    }
  }

  async created() {
    await this.fetchInvoices();
    this.checkTenantModule('order_include_lot,order_transportation');
  }

  checkPromotionOn(promotion: string, promotion_on: string) {
    if (promotion) {
      if (promotion_on != 'all') {
        return promotion_on;
      } else {
        return 'product';
      }
    } else {
      return 'product';
    }
  }
}
