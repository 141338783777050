



















import {
  Component,
  Mixins,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
// @ts-ignore
import BarChart from "@/components/argon-core/Charts/BarChart";

import moment from 'moment';
import Chart from "chart.js";

@Component({
  components: {
    BarChart,
  },
})
export default class effectiveCoverageReport extends Mixins(TaxonomyMixin) {
  @Prop({default: ''})
  public range!: string;

  @Prop({default: null})
  public territoryId!: number | null;

  @Prop({default: null})
  public userIds!: string | null;

  @Prop({default: null})
  public departmentIds!: string | null;

  @Prop({default: null})
  public brandIds!: string | null;

  @Prop({default: null})
  public viewBaseOn!: string | null;

  @Prop({default: null})
  public valueType!: string | null;

  @Prop({default: null})
  public limitBrand!: number | null;

  @Prop({default: false})
  public isGenerateReport!: boolean;

  private loading: boolean = true;

  @Ref('chartRef') chartRef!: BarChart;

  private effectiveCoverageChartInfo: any = {
    chartData: {
      datasets: [],
      labels: []
    },
    extraOptions: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Effective Coverage Report'
      },
      legend: {
        display: true,
        position: 'right'
      },
    }
  };

  mounted() {
    this.fetch();

  }

  async fetch() {
    this.loading = true;
    this.effectiveCoverageChartInfo.chartData.datasets = [];
    this.effectiveCoverageChartInfo.chartData.labels = [];
    const params = {
      type: 'effectiveCoverage',
      range: this.range,
      territory_id: this.territoryId,
      user_ids: this.userIds,
      brand_ids: this.brandIds,
      department_ids: this.departmentIds,
      view_base_on: this.viewBaseOn,
      value_type: this.valueType,
      limit_brand: this.limitBrand
    };

    try {
      let {data} = await this.$http.get(this.$api3(`/brand-report${this.$toQueryString(params)}`))
      this.effectiveCoverageChartInfo.chartData.datasets = data.effectiveCoverageReport.datasets;
      this.effectiveCoverageChartInfo.chartData.labels = data.effectiveCoverageReport.labels;
      this.effectiveCoverageChartInfo.extraOptions.title.text = data.effectiveCoverageReport.title;
      // this.updateChart();
      this.effectiveCoverageChartInfo.extraOptions.legend = {
        display: true,
        position: 'top',
        labels: {
          generateLabels: function (chart: any) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function (label: string, i: number) {
                const meta = chart.getDatasetMeta(0);
                // const meta = data.datasets[0]._meta;
                const currentValue = data.datasets[0].data[i];
                const total = data.datasets[0].data.reduce(function (previousValue: any, currentValue: any) {
                  return previousValue + currentValue;
                });
                const percentage = Math.round((currentValue / total) * 100);
                const arc = meta.data[i];
                const custom = arc && arc.custom || {};
                const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                const arcOpts = chart.options.elements.arc;
                const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(data.datasets[0].backgroundColor, i, arcOpts.backgroundColor);
                const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(data.datasets[0].borderColor, i, arcOpts.borderColor);
                const strokeWidth = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(data.datasets[0].borderWidth, i, arcOpts.borderWidth);

                return {
                  text: label + ' (' + data.datasets[0].data[i] + '%)',
                  fillStyle: fill,
                  strokeStyle: stroke,
                  lineWidth: strokeWidth,
                  hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
                  index: i
                };
              });
            }
            return [];
          }
        },
      };

    }
      // catch (e) {
      //
      // }
    finally {
      this.loading = false
    }
  }

  @Watch('key')
  onKeyChange() {
    this.fetch();
  }
}
