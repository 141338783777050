



















import { Component, Prop, Vue } from 'vue-property-decorator';
import Message from "@/intefaces/Messages";

@Component({
  name: 'NumberListOfSMS'
})
export default class NumberListOfSMS extends Vue {
  @Prop() isView!: boolean;
  @Prop() currentData!: any;
  private mobileNumbers: any = [];

  mounted() {
    this.convertMobileNumbersToArray();
  }

  private convertMobileNumbersToArray() {
    let numbersArray;
    if (typeof this.currentData.to === 'string' && this.currentData.to.includes(',')) {
      numbersArray = this.currentData.to.replace(/\s/g, '').split(',');
    } else {
      numbersArray = [this.currentData.to];
    }
    this.mobileNumbers = numbersArray.map((number: string, index: number) => ({
      id: index + 1,
      number
    }));
  }

  close() {
    this.$emit('update:isView', false);
  }
}
