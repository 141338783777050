
























































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import ViewPackageFeature from '@/views/admin/payment/invoice/ViewPackageFeature.vue';
import ManagePayment from '@/views/admin/payment/invoice/ManagePayment.vue';
import InvoiceDetails from '@/views/admin/payment/invoice/InvoiceDetails.vue';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import OrgInvoice from '@/intefaces/OrgInvoice';
import PaginationComponentMixin from '@/mixins/PaginationComponent';

@Component({
  components: {
    ManagePayment,
    ViewPackageFeature,
    InvoiceDetails,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
  },
})
export default class InvoiceList extends Mixins(PaginationComponentMixin) {
  private invoices: OrgInvoice[] = [];
  private invoiceDetails: OrgInvoice[] = [];
  private showDeleteModal = false;
  private isActive = false;
  private isActivePackageView: boolean = false;
  private isActiveInvoiceDetails: boolean = false;
  private isActivePaymentView: boolean = false;
  private isUpdate = false;
  private currentEntity: any = [];
  private currentPackage: any = [];
  private currentPayment: any = [];
  private currentPaymentIndex: any = null;
  private approved_at: string = '';
  // private form: Coupon = {
  //   code: '',
  //   discount: 0,
  //   expires_at: '',
  // };

  created() {
    this.fetchEntity();
  }

  // entityCreated(entity: Coupon) {
  //   this.invoices.unshift(entity);
  // }

  // entityUpdated(entity: Coupon): void {
  //   this.invoices.splice(
  //     this.invoices.findIndex((type) => type.id == entity.id),
  //     1,
  //     entity
  //   );
  // }

  // onEdit(row: Coupon) {
  //   this.isUpdate = true;
  //   this.currentEntity = row;
  //   this.form = {
  //     code: this.currentEntity.code,
  //     discount: this.currentEntity.discount,
  //     expires_at: this.currentEntity.expires_at,
  //   };
  //   this.isActive = true;
  // }

  // showPackage(row: any) {
  //   this.isActivePackageView = true;
  //   this.currentEntity = row.subscription.scheme_meta;
  // }

  @Watch('pagination.currentPage')
  fetchEntity(): void {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/org-invoices?page=${this.pagination.currentPage}`)).then((response) => {
      this.invoices = response.data.orgInvoices;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  onApproved(approved_at: string) {
    this.invoices[this.currentPaymentIndex].payments[0].approved_at = approved_at;
  }

  onRejected(rejected_at: string) {
    this.invoices[this.currentPaymentIndex].payments[0].rejected_at = rejected_at;
  }
  //   deleteEntity(): void {
  //     this.$http.delete(this.$landLordApi(`/coupons/${this.currentEntity.id}`)).then((response) => {
  //       this.showDeleteModal = false;
  //       this.invoices.splice(
  //         this.invoices.findIndex((value) => value.id == this.currentEntity.id),
  //         1
  //       );
  //       this.$notify({
  //         title: 'Success',
  //         message: 'Successfully Deleted',
  //         duration: 5000,
  //         iconClass: 'ni ni-bell-55',
  //         type: 'success',
  //       });
  //     });
  //   }
}
