

















import { Component, Mixins, Prop } from 'vue-property-decorator';
import LazyDropdown from '../LazyDropdown.vue';
import DropdownField from '../../mixins/DropdownField';
@Component({
  components: {
    LazyDropdown
  }
})
export default class BaseProductLazydropdown extends Mixins(DropdownField) {
  @Prop({ default: 'base-products' })
  endPoint!: string;
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: '' })
  inputError!: string;
} 
