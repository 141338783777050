








































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import Territory from '@/intefaces/Territory';
import CreateTerritoryForm from './CreateTerritoryForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import { Dropdown, DropdownItem, Switch, DropdownMenu, Table, TableColumn, Tooltip, Drawer, Tree, Button, Popover } from
    'element-ui';
// import {Tree, Draggable} from 'he-tree-vue';
// import 'he-tree-vue/dist/he-tree-vue.css';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { AxiosResponse } from 'axios';
import CreateBulkTerritories from '@/views/territory/CreateBulkTerritories.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
// import ManagerList from "@/views/department/ManagerList.vue";
import TerritoryDetailsModal from "@/views/territory/TerritoryDetailsModal.vue";

@Component({
  components: {
    TerritoryDetailsModal,
    // ManagerList,
    UserLazydropdown,
    TerritoryTypeAutocomplete,
    CreateTerritoryForm,
    CreateBulkTerritories,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Switch.name]: Switch,
    [Drawer.name]: Drawer,
    [Tree.name]: Tree,
    [Button.name]: Button,
    [Popover.name]: Popover,
    //@ts-ignore
    // Tree: Tree.mixPlugins([Draggable])
  },
})
export default class TerritoryList extends Mixins(PaginationComponentMixin) {
  private territories: Territory[] = [];
  private showBulkTerritoriesModal: boolean = false;
  private showDeleteModal: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private currentEntity!: Territory;
  // private draggable: boolean = false;
  private isFilterModelActive: boolean = false;
  private query: any = [];
  private viewType: any = 'table';
  private demoTourEntity: string = 'territory';
  private showGIF: boolean = false;
  private user: any = {};
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private showTerritory: boolean = false;
  private bulkDownloading: boolean = false;
  private currentTerritoryId!: number;
  private props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };
  private GIFs: Array<any> = [
    {
      label: 'Create a territory',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw',
    },
    {
      label: 'Update a territory',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ',
    },
    {
      label: 'Delete a territory',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM',
    },
  ];
  private demoTourTitle: string = 'A quick demo of territory';
  private filter: any = {
    search: '',
    code: '',
    territory_type: null,
    managed_by: null,
  };
  private form: Territory = {
    name: '',
    code: '',
    territory_type_id: null,
    managed_by: null,
    parent_id: null,
    managers: [],
    parent: null,
  };

  private paginate = {
    currentPage: 1,
    total: 0,
    perPage: 0,
  };

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  loadNode(node: any, resolve: any) {
    var territories: any[] = [];
    this.$http.get(this.$api(`/territories?parent_id=${node.level === 0 ? this.checkParentId : node.data.id}`)).then((response) => {
      territories = response.data.territories;
      let treeData = territories.map((territory) => {
        const container: any = {};
        container['name'] = territory.name;
        container['leaf'] = !territory.children_count;
        container['id'] = territory.id;
        return container;
      });
      resolve(treeData);
    });
  }

  get permissionEnum() {
    return PermissionEnum;
  }

  get formattedTerritoryTree(): any {
    return this.$options.filters ? this.$options.filters.parentTreeBuilder(this.territories) : [];
  }

  set formattedTerritoryTree(value: any) {
    // Deep copy of territories array
    let oldTerritory = JSON.parse(JSON.stringify(this.territories));

    function arrayFlattener(elements: Array<any>, parentId: null | number = null): void {
      elements.forEach((value1, index) => {
        if (value1.children.length) {
          arrayFlattener(value1.children, value1.id);
        }
        value1.parent_id = parentId;
      });
    }

    arrayFlattener(value);
    this.updateParentId(oldTerritory);
  }

  updateParentId(oldTerritoryValue: Array<any>) {
    this.territories
      .filter((element: any, index: number) => {
        return element.parent_id !== oldTerritoryValue[index].parent_id;
      })
      .forEach((value) => {
        this.$http.patch(this.$api(`/territories/${value.id}`), value);
      });
  }

  entityCreated(territory: Territory) {
    this.territories.unshift(territory);
  }

  onEdit(row: Territory) {
    this.isUpdate = true;
    this.currentEntity = row;
    this.form = {
      name: this.currentEntity.name,
      code: this.currentEntity.code,
      territory_type_id: this.currentEntity.territory_type_id,
      managed_by: this.currentEntity.managed_by,
      managers: this.currentEntity.managers,
      parent_id: this.currentEntity.parent_id,
      parent: this.currentEntity.parent,
    };
    this.isActive = true;
  }

  @Watch('queryBuilder.watch')
  async fetchEntity() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/territories${this.queryBuilder.getFilters(true)}`));
    this.territories = data.territories;
    this.setPagination(data);
    this.loading = false;
  }

  async fetchUser() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api('/me'));
    this.user = data.me;
  }

  @Watch('filter.search')
  onChangeSearch(value: string) {
    this.queryBuilder.addListener('q', value);
  }

  @Watch('filter.code')
  onChangeCode(value: string) {
    this.queryBuilder.addListener('code', value);
  }

  @Watch('filter.territory_type')
  onChangeTerritoryType(value: string) {
    this.queryBuilder.addListener('territory_type', value);
  }

  @Watch('filter.managed_by')
  onChangeManagedBy(value: string) {
    this.queryBuilder.addListener('managed_by', value);
  }

  @Watch('pagination.currentPage')
  onChangePagination(value: string) {
    this.queryBuilder.addListener('page', value);
  }

  entityUpdated(territory: Territory) {
    this.territories.splice(
      this.territories.findIndex((value) => value.id == territory.id),
      1,
      territory
    );
    this.territories.forEach(function (value, index) {
      if (value.parent && value.parent_id == territory.id) {
        value.parent.name = territory.name;
      }
    });
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/territories/${this.currentEntity.id}`)).then((response) => {
      this.showDeleteModal = false;
      this.territories.splice(
        this.territories.findIndex((value) => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success',
      });
    });
  }

  async created() {
    await this.fetchUser();
    await this.fetchEntity();
  }

  closeBtn() {
    this.isFilterModelActive = false;
    this.viewType = 'table';
  }

  handleClose() {
    this.isFilterModelActive = false;
    this.viewType = 'table';
  }

  resetBtn() {
    this.filter.search = '';
    this.filter.code = '';
    this.filter.territory_type = null;
    this.filter.managed_by = null;
  }

  async bulkDownload() {
    this.bulkDownloading = true;
    try {
      let {data, headers} = await this.$http.get(
        this.$api(`/territories-bulk-download?download&${this.queryBuilder.getFilters(false)}`),
        {responseType: 'arraybuffer'}
      )
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({type: 'warning', message: 'Unable to download.', title: 'Something went wrong!'});
    } finally {
      this.bulkDownloading = false;
    }

  }
}
