






















































import {
    Vue,
    Component,
    Prop
} from 'vue-property-decorator';
import LazyDropdown from '@/components/LazyDropdown.vue';
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import {
    MessageBox,
    Divider
} from 'element-ui';

@Component({
    components: {
        LazyDropdown,
        BaseProductLazydropdown,
        [Divider.name]: Divider,
    }
})
export default class StockSingleForm extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    public isActive!: boolean;
    public customError: boolean = true;
    public loading: boolean = false;
    public form: any = {
        department_id: null,
        challan_no: null,
        products: [{
            product_id: null,
            sku: null,
            qty: null
        }, ]
    };

    formReset(): any {
        this.form = {
            department_id: null,
            challan_no: null,
            products: [{
                product_id: null,
                sku: null,
                qty: null
            }, ]
        };
        return this.form;
    }

    close() {
        this.$emit('update:isActive', false);
        this.reset();
    }

    reset() {
        if (this.$refs.formValidator) {
            (this.$refs.formValidator as Vue & {
                reset: Function
            }).reset();
        }
        this.formReset();
    }

    async createStock() {
        this.customError = true;
        MessageBox.alert('<span style="font-size: 14px">' +
          'Please recheck the information again. Save data will not be able to get back once it is added to system. <br>' +
          ' <br> You may need System admin help to adjust this data if made any mistakes.</span>', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            dangerouslyUseHTMLString: true,
            type: 'warning',
            center: true
        }).then(() => {
            this.$http.post(this.$api3('/add-single-stock'), this.form).then(response => {
                this.$notify({
                    title: 'Confirmation!',
                    type: 'success',
                    message: 'Stock added successfully'
                });
                this.close();
            }).catch(error => {
                this.customError = false;
                (this.$refs.formValidator as Vue & {
                    setErrors: (errors: []) => any;
                }).setErrors(error.response.data.errors);
            }).finally(() => {
                this.loading = false;
            })
        });
    }
    addNewProduct() {
        this.form.products.push({
            product_id: null,
            sku: null,
            qty: null
        });
    }
    deleteProduct(index: number) {
        this.form.products.splice(index, 1);
    }
}
