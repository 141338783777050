// @ts-ignore
import Tenant from "@/views/admin/tenant/Tenant.vue";
// @ts-ignore
import TenantList from '@/views/admin/tenant/tenant/TenantList.vue';
import { RouteConfig } from 'vue-router';
// @ts-ignore
const TenantRoutes: RouteConfig = {
    path: 'tenant',
    name: 'tenant',
    redirect: '/tenant/tenants',
    components: { dashboard: Tenant },
    children: [
        {
            path: 'tenants',
            name: 'tenants',
            // @ts-ignore
            label: 'Tenants',
            icon: 'fa fa-thumbs-up',
            component: TenantList
        }
    ]
};

export default TenantRoutes;
