









































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import { AxiosResponse } from 'axios';
import Feature from "@/intefaces/Feature";
import { Button } from "element-ui";
import PermissionModal from "@/views/user/permission-grouping/PermissionModal.vue";

@Component({
  components: {
    PermissionModal,
    ElButton: Button,
  }
})
export default class CreateFeatureForm extends Mixins(BaseCUModalComponent) {
  @Prop()
  currentEntity!: Feature;

  @Prop({default: () => ({title: '', status: true, permissions: []})})
  form!: {
    title: string;
    permissions: any;
    status: boolean|number;
  }
  public isActivePermission: boolean = false;

  formReset(): any {
    this.form.title = ''
    this.form.permissions = []
    this.form.status = true
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    this.$emit('created', response.data.feature);
  }

  afterUpdate(response: AxiosResponse<any>): void {
    this.$emit('updated', response.data.feature)
  }

  landlordCheck(): boolean {
    return true;
  }

  get createApi(): string {
    return '/features';
  }

  get updateApi(): string {
    return `/features/${this.currentEntity.id}`;
  }

  created() {
    if (!this.isUpdate) {
      this.formReset();
    }
  }

  checkedPermissions(event: any) {
    this.form.permissions = event;
  }

  updatePermission(event: any) {
    if (this.isUpdate && this.isActivePermission) {
      // window.console.log(event[1]);
    }
  }
}
