<template>
<div class="mb-2 ml-1">
    <base-input clearable :label="label" :name="name" :rules="rules">
        <el-date-picker v-model="selectedDate" :type="type" :placeholder="placeholder" :picker-options="pickerOptions" :value-format="valueFormat" style="width: 100%;" :disabled="disabled"></el-date-picker>
    </base-input>
</div>
</template>

<script>
import {
    DatePicker
} from "element-ui";
import moment from "moment";

export default{
    name: 'base-date',
    components: {
        ElDatePicker: DatePicker
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        rules: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: 'date'
        },
        valueFormat: {
            type: String,
            default: 'yyyy-MM-dd'
        },
        placeholder: {
            type: String,
            default: 'Pick a date'
        },
        minDate: {
            type: String,
            default: ''
        },
        maxDate: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            selectedDate: '',
            pickerOptions: {
                disabledDate: (time) => {
                    const minDate = moment(this.minDate);
                    const maxDate = moment(this.maxDate);
                    if (this.minDate && this.maxDate) {
                        // Disable dates outside the range [minDate, maxDate]
                        return time.getTime() < minDate || time.getTime() > maxDate;
                    } else if (this.minDate) {
                        // Disable dates before minDate and current date
                        return time.getTime() < minDate || time.getTime() > Date.now();
                    } else if (this.maxDate) {
                        // Disable dates after maxDate
                        return time.getTime() > maxDate;
                    }
                    // Disable dates before today
                    return time.getTime() > Date.now();
                }
            }
        };
    },
    watch: {
        selectedDate: {
            immediate: true,
            handler(value) {
                this.$emit('input', value);
            }
        },
        value: {
            immediate: true,
            handler(value) {
                this.selectedDate = value;
            }
        }
    },
}
</script>

<style>
.el-date-editor .el-icon-circle-close {
    line-height: 30px;
}
</style>
