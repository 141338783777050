



























































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import DateRange from '@/components/DateRange.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import swal from 'sweetalert2';
import moment from 'moment';

@Component({
  components: {
    DateRange,
  },
})
export default class ProductPriceHistoryReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public range = '';
  public avgWorking: any = 0;
  public loading: boolean = false;
  public reports = [];
  public maxDate: string = moment().format('YYYY-MM-DD');
  public query: any = '';

  public onClickGenerate() {
    if (this.range === '') {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!',
      });
    } else {
      this.fetchReport();
    }
  }

  @Watch('pagination.currentPage')
  onChangePage(newVal: any) {
    this.fetchReport();
  }

  @Watch('range', {immediate: true})

  updateCurrentpage() {
    this.pagination.currentPage = 1;
  }

  async fetchReport() {
    try {
      this.loading = true;
      this.reports = [];
      this.query = {
        range: this.range,
        page: `${this.pagination.currentPage}`,
      };
      let {
        data
      } = await this.$http.get(this.$api3(`/product-price-histories${this.$toQueryString(this.query)}`));
      this.reports = data.reports.data;
      this.setPagination(data.reports);
      if (!this.reports.length) {
        this.$notify({
          type: 'info',
          message: 'The filter you applied has no data',
          title: 'Product Price report is empty!',
          duration: 5000,
        });
      }
    } catch (e) {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach((error) => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error',
        });
      });
    } finally {
      this.loading = false;
    }
  }

  async onClickDownload(type: string) {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/product-price-histories${this.$toQueryString(this.query)}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }
}

