




































//@ts-ignore
import {
    Vue,
    Component
} from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import {
    RouteConfig
} from 'vue-router';
import BillingRoutes from "@/router/admin-routes/BillingRoutes";
@Component({
    components: {
        RouteBreadCrumb
    },
})
class Tenant extends Vue {
    activeTab: number | null = 0;
    showModal: boolean = false;
    routes: RouteConfig = BillingRoutes;
    formErrors: any = {};
    formInputs: any = {};

    get BillingRoutes() {
        return BillingRoutes;
    }

    created(): void {
        if (this.routes.children === undefined) return;
        this.routes.children.forEach((route, index) => {
            if (this.$route.name === route.name) {
                this.activeTab = index;
            }
        });
    }

    setCurrentTab(tab = null) {
        if (tab === null) {
            this.activeTab = 0;
        } else {
            this.activeTab = tab;
        }
    }

    closeAndResetForm() {
        this.showModal = false;
        this.formInputs = {};
        this.formErrors = {};
    }
}

export default Tenant;
