

















































































































































































import { Component, Watch } from 'vue-property-decorator';
import RequisitionForm from '@/views/order/requisition/RequisitionForm.vue';
import statuses from '@/data/OrderStatus';
import RequisitionDetails from '@/views/order/requisition/RequisitionDetails.vue';
import { Drawer, Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip } from 'element-ui';
import Order from '@/intefaces/Order';
import OrderDetailsForm from '@/views/order/OrderDetailsForm.vue';
import { mixins } from 'vue-class-component';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import {Permission as PermissionEnum} from '@/enums/Permission'
import BaseOrderDetails from "@/views/order/BaseOrderDetails.vue";

@Component({
  components: {
    OrderDetailsForm,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    RequisitionForm,
    RequisitionDetails,
    BaseOrderDetails
  },
  filters: {
    currentStatus: function (value: Order) {
      if (!value) return '';
      return value.statuses[value.statuses.length - 1].status;
    }
  }
})
export default class RequisitionList extends mixins(PaginationComponentMixin, TaxonomyMixin) {
  private isActive = false;
  private isUpdate = false;
  private updateAmountCount: boolean = false;
  private isActiveRequisitionDetails = false;
  private departments: any[] = [];
  private requisitions: any[] = [];
  private isFilterModelActive: boolean = false;
  // private loading = false;
  private currentRequisition: any[] = [];
  private requisitionEditPermission: string = '';
  private requisitionApprovePermission: string = '';

  private form = {
    departmentId: '',
    sellerId: '',
    discount: 0,
    promotionalDiscount: 0,
    expectedDeliveryDate: '',
    paid: '',
    type: 'product',
    productableId: '',
    badgeDiscount: 0
  };
  public products: any = [
    {
      product_id: '',
      price: 0,
      qty: 0,
      free_with: '',
      promotion_discount: 0,
      promotion_id: '',
      fullName: ''
    }
  ];
  private salesReturns: any = [
    {
      product_id: '',
      qty: '',
      amount: '',
      return_rate: 0,
      paid: 0,
      reason: ''
    }
  ];
  private filter = [{
    title: 'Outgoing ' + this.getTaxonomy('requisition'),
    value: 'Outgoing requisitions'
  }, {title: 'Incoming ' + this.getTaxonomy('requisition'), value: 'Incoming requisitions'}];
  private selectedFilter = 'Outgoing requisitions';
  private selectedRequisition = 'all requisitions';
  private showGIF: boolean = false;
  private demoTourEntity: string = 'requisition';
  private GIFs: Array<any> = [
    {
      label: 'Create a requisition',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a requisition',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a requisition',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of requisition';

  get permissionEnum() {
    return PermissionEnum;
  }

  get statuses() {
    let data = [...statuses];
    data.unshift({title: 'All', value: 'all requisitions'});
    return data;
  }

  @Watch('selectedFilter')
  getFilter(newVal: any) {
    this.fetchRequisition();
  }

  @Watch('isActive')
  getPermission(newVal: boolean) {
    this.requisitionApprovePermission = this.permissionEnum.APPROVE_REQUISITION;
    this.requisitionEditPermission = this.permissionEnum.UPDATE_REQUISITION;
  }

  createRequisition(requisition: any) {
    this.isActive = false;
    this.requisitions.unshift(requisition);
  }

  updateRequisition(requisitionUpdate: any) {
    this.isUpdate = false;
    // this.requisitions.splice(this.requisitions.findIndex((requisiton: any) => requisiton.id == requisitionUpdate.id), 1, requisitionUpdate);
    this.isActive = false;
  }

  updateEditedOrder(event: Order) {
    this.$http.get(this.$api(`/orders/${event.id}`)).then(response => {
      this.requisitions.splice(this.requisitions.findIndex((order: any) => order.id == response.data.order.id), 1, response.data.order);
    })
  }

  entityUpdated(entity: Order): void {
    this.requisitions.splice(
      this.requisitions.findIndex(type => type.id == entity.id),
      1,
      entity
    );
    this.isActiveRequisitionDetails = false;
  }

  requistionStatus() {
    this.fetchRequisition();
  }

  @Watch('selectedRequisition')
  requisitionFilter() {
    this.fetchRequisition();
  }

  @Watch('pagination.currentPage')
  fetchRequisition() {
    this.loading = true;
    if (this.selectedFilter == 'Outgoing requisitions') {
      let url = `/requisitions?created_by=me${this.selectedRequisition !== 'all requisitions' ? '&status=' + this.selectedRequisition : ''}&page=${
        this.pagination.currentPage
      }`;
      this.$http.get(this.$api(url)).then(response => {
        this.requisitions = response.data.requisitions;
        this.setPagination(response.data);
        this.loading = false;
      });
    } else if (this.selectedFilter == 'Incoming requisitions') {
      let url = `/requisitions?seller_id=me${this.selectedRequisition !== 'all requisitions' ? '&status=' + this.selectedRequisition : ''}&page=${
        this.pagination.currentPage
      }`;
      this.$http.get(this.$api(url)).then(response => {
        this.requisitions = response.data.requisitions;
        this.setPagination(response.data);
        this.loading = false;
      });
    }
  }

  created() {
    this.fetchRequisition();
  }

  closeBtn() {
    this.isFilterModelActive = false;
  }

  handleClose(done: any) {
    done();
  }

  resetBtn() {
    this.selectedFilter = 'Outgoing requisitions';
    this.selectedRequisition = 'all requisitions';
  }
}
