
































































































































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    MessageBox,
    Table,
    TableColumn,
    Tag,
    Tooltip
} from 'element-ui';
import GIFModal from '@/components/tour/GIFModal.vue';
import {
    Permission as PermissionEnum
} from '@/enums/Permission'
import PromotionNewForm from "@/components/products/PromotionNewForm.vue";
import PromotionDetails from "@/components/products/PromotionDetails.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import PromotionFilterDrawer from "@/views/product/PromotionFilterDrawer.vue";

@Component({
    components: {
        PromotionFilterDrawer,
        PromotionDetails,
        PromotionNewForm,
        DepartmentLocationTypeLazyDropdown,
        DepartmentCategoryLazyDropdown,
        BadgeLazydropdown,
        ChannelLazydropdown,
        TerritoryCascaderDropdown,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Tag.name]: Tag,
        GIFModal
    }
})
export default class PromotionNew extends Vue {
    private isActive = false;
    private isUpdate = false;
    private isView = false;
    private showDeleteModal = false;
    private promotions: any = [];
    private currentPromotion: any = [];
    private disableButton = false;
    private loading = false;
    private showGIF: boolean = false;
    private isFilterModelActive = false;
    public filter: any = {
        title: '',
        expires_at_date_range: '',
        promotion_on: '',
        activating_type: '',
        promotion_type: '',
        status: '',
        offer_product_ids: '',
        promotion_able_ids: '',
        parent_territory_id: '',
        territory_ids: '',
        badge_ids: '',
        department_type_ids: '',
        department_category_ids: '',
        department_location_type_ids: '',
    };

    public form = {
        territory_ids: [],
        badge_ids: [],
        department_type_ids: [],
        department_category_ids: [],
        department_location_type_ids: [],
        title: '',
        start_date: '',
        expires_at: '',
        activating_type: '',
        promotion_type: '',
        promotion_on: '',
        status: '',
        items: [{
            promotion_able_id: '',
            minimum_value: '',
        }, ],
        offers: [{
            promotion_value: '',
            offer_type: '',
            offer_value: '',
            additional_discount: '',
            offer_products: [{
                offer_product_id: '',
                offer_product_qty: ''
            }],
        }, ]
    };

    private pagination = {
        currentPage: 1,
        total: 0,
        perPage: 0
    };
    private demoTourEntity: string = 'productPromotion';
    private GIFs: Array < any > = [{
            label: 'Create a promotion',
            url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
        },
        {
            label: 'Update a promotion',
            url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
        },
        {
            label: 'Delete a promotion',
            url: 'https://www.youtube.com/embed/LeAltgu_pbM'
        }
    ];
    private demoTourTitle: string = 'A quick demo of promotion';

    onFilterSubmit() {
        this.isFilterModelActive = false;
    }

    get permissionEnum() {
        return PermissionEnum;
    }

    capitalize(string: string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isExpired(expires_at: string): boolean {
        const expiresAtDate = new Date(expires_at);
        const today = new Date();
        return expiresAtDate < today;
    }

    switchColor(status: string): string {
        switch (status) {
            case 'active':
                return 'green';
            case 'inactive':
                return 'red';
            case 'draft':
                return 'black';
            case 'completed':
                return 'blue';
            default:
                return 'yellow';
        }
    }

    onSwitchChange(row: any) {
        let text = row.status == "active" ? "active to inactive" : "inactive to active";
        MessageBox.alert('<span style="font-size: 14px">' +
                'Please review the information carefully. Once this status changed ' + text +
                ', it cannot be retrieved or deleted from the system.' +
                '<br> <br>System admin may help to adjust this data if made any mistakes.</span>', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                    center: true
                })
            .then(() => {
                this.$http.get(this.$api2(`/promotions/change-status/${row.id}`))
                    .then((response: any) => {
                        this.fetchPromotion();
                        this.$notify({
                            title: 'Success',
                            message: `Status changed ${text} successfully`,
                            duration: 5000,
                            iconClass: 'ni ni-bell-55',
                            type: 'success'
                        });
                    })
            })
            .catch(() => {
                row.is_switch_on = !row.is_switch_on;
            });
    }

    promotionCreated(promotion: any) {
        this.isActive = false;
        this.fetchPromotion();
    }

    promotionUpdated(promotion: any) {
        this.isUpdate = false;
        this.isActive = false;
        this.fetchPromotion();
    }

    deletePromotion() {
        this.disableButton = true;
        this.$http
            .delete(this.$api2(`/promotions/${this.currentPromotion.id}`))
            .then((response: any) => {
                this.disableButton = false;
                this.showDeleteModal = false;
                this.promotions.splice(
                    this.promotions.findIndex((promotion: any) => promotion.id == this.currentPromotion.id),
                    1
                );
                this.$notify({
                    title: 'Success',
                    message: 'Successfully Deleted',
                    duration: 5000,
                    iconClass: 'ni ni-bell-55',
                    type: 'success'
                });
            })
            .catch((error: any) => {
                this.disableButton = false;
            });
    }

    onEdit(row: any) {
        this.isUpdate = true;
        this.currentPromotion = row;
        this.form = {
            territory_ids: [],
            badge_ids: row.badges.length > 0 ? row.badges.map((badge: any) => badge.id) : [],
            department_type_ids: row.department_types.length > 0 ? row.department_types.map((departmentType: any) => departmentType.id) : [],
            department_category_ids: row.department_categories.length > 0 ? row.department_categories.map((departmentCategory: any) => departmentCategory.id) : [],
            department_location_type_ids: row.department_location_types.length > 0 ? row.department_location_types.map((departmentLocationType: any) => departmentLocationType.id) : [],
            title: this.currentPromotion.title,
            start_date: this.currentPromotion.start_date,
            expires_at: this.currentPromotion.expires_at,
            promotion_on: this.currentPromotion.promotion_on,
            activating_type: this.currentPromotion.activating_type,
            promotion_type: this.currentPromotion.promotion_type,
            status: this.currentPromotion.status,
            items: this.currentPromotion.items,
            offers: this.currentPromotion.offers,
        };
        this.isActive = true;
    }

    fetchPromotion() {
        this.loading = true;
        this.filter = {
            ...this.filter,
            page: this.pagination.currentPage
        };
        let url = `/promotions${this.$toQueryString(this.filter)}`;
        this.$http.get(this.$api2(url)).then((response: {
            data: {
                promotions: any;total: number;per_page: number;
            };
        }) => {
            this.promotions = response.data.promotions;
            this.pagination.total = response.data.total;
            this.pagination.perPage = response.data.per_page;
            this.loading = false;
        });
    }

    created() {
        this.fetchPromotion();
    }

    onFilterChange(filter: any) {
        this.filter = filter;
        this.pagination.currentPage = 1;
        this.fetchPromotion();
    }
}
