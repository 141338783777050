var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row pl-3"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-4 col-lg-3"},[_c('DateRange',{attrs:{"label-prop":"Date Range (Required)","max-date":_vm.maxDate},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('div',{staticClass:"col-xs-12 col-sm-4 col-lg-2"},[_c('base-button',{staticClass:"btn-block",staticStyle:{"margin-top":"20px"},attrs:{"loading":_vm.loading,"type":"success"},on:{"click":_vm.onClickGenerate}},[_c('i',{staticClass:"fa fa-search"},[_vm._v(" Generate Report")])])],1)]),_c('br'),(_vm.reports.length > 0)?_c('div',{staticClass:"w-100 overflow-auto"},[_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Product Price History Report")]),_c('p',[_vm._v("Date Selected: "+_vm._s(_vm.range.substring(0, 10))+" to "+_vm._s(_vm.range.substring(11)))])]),_c('div',{staticClass:"col-12 text-right"},[_c('base-button',{attrs:{"loading":_vm.downloading,"type":"primary"},on:{"click":_vm.onClickDownload}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive  table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.reports}},[_c('el-table-column',{attrs:{"label":"SL","sortable":"","type":"index"}}),_c('el-table-column',{attrs:{"label":"Creator Name","prop":"creator_name","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.creator_name)+" ")]}}],null,false,2232576315)}),_c('el-table-column',{attrs:{"label":"Creator Code","prop":"creator_code","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.creator_code)+" ")]}}],null,false,1960499505)}),_c('el-table-column',{attrs:{"label":"Product SKU","prop":"product_sku","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.product_sku)+" ")]}}],null,false,3428466918)}),_c('el-table-column',{attrs:{"label":"Product Name","prop":"product_name","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.product_name)+" ")]}}],null,false,2189395212)}),_c('el-table-column',{attrs:{"label":"Priceable Name","prop":"priceable_name","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.priceable_name)+" ")]}}],null,false,2521107168)}),_c('el-table-column',{attrs:{"label":"Batch Name","prop":"batch_name","sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.batch_name)+" ")]}}],null,false,124030843)}),_c('el-table-column',{attrs:{"label":"Old Price","prop":"old_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.old_price)+" ")]}}],null,false,2498373834)}),_c('el-table-column',{attrs:{"label":"Price","prop":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.price)+" ")]}}],null,false,844907474)})],1),(_vm.reports.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"form-inline"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Product Price History Report")])])])])])}]

export { render, staticRenderFns }