

























import { Vue, Component } from 'vue-property-decorator';
// @ts-ignore
import DateRange from "@/components/DateRange";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import ChannelLazyDropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import UserLazyDropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import BadgeLazyDropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import DepartmentLazyDropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
// @ts-ignore
import MapboxMap from '@/components/heat-map/index';


@Component({
    components: {
        DateRange,
        TerritoryCascaderDropdown,
        ChannelLazyDropdown,
        DepartmentCategoryLazyDropdown,
        DepartmentLocationTypeLazyDropdown,
        UserLazyDropdown,
        DepartmentLazyDropdown,
        BadgeLazyDropdown,
        MapboxMap
    }
})

export default class OutletHeatMap extends Vue {
  public loading: boolean = false;
  public query: any = {
    latlong: 'yes'
  };
  public data: any = [];

  created() {
    this.fetchDepartments();
  }

  onClickFilter() {
    this.fetchDepartments();
  }

  async fetchDepartments() {
    this.loading = true;
    await this.$http.get(this.$api3(`/fetch-departments${this.$toQueryString(this.query)}`)).then((response) => {
      this.data = response.data;
    }).catch((error) => {
      window.console.log(error);
    }).finally(() => {
      this.loading = false;
    });
  }
}
