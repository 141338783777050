






























































































































































































































































































import { Component, Prop, Mixins, Vue, Watch } from 'vue-property-decorator';
import BaseCUModalComponentMixin from '@/mixins/BaseCUModalComponent';
import { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import { Select, Option, Row, Col, Button } from 'element-ui';
import DepartmentTypeAutocomplete from '@/components/resources-autocomplete/DepartmentTypeAutocomplete.vue';
import BadgeAutocomplete from '@/components/resources-autocomplete/BadgeAutocomplete.vue';
import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import SingleImageUploader from '@/components/ui/SingleImageUploader.vue';
import Territory from '@/intefaces/Territory';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
import RoutePlanAutocomplete from '@/components/resources-autocomplete/RoutePlanAutocomplete.vue';
import LazyDropdown from '@/components/LazyDropdown.vue';
import CreateBadgeForm from '@/views/department/CreateBadgeForm.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    DepartmentLocationTypeLazyDropdown,
    DepartmentCategoryLazyDropdown,
    DepartmentLazydropdown: () => import('@/components/lazyDropdown/DepartmentLazydropdown.vue'),
    CreateBadgeForm,
    LazyDropdown,
    MemberAutocomplete,
    RoutePlanAutocomplete,
    TerritoryAutocomplete,
    BadgeAutocomplete,
    DepartmentTypeAutocomplete,
    SingleImageUploader,
    [Select.name]: Select,
    [Option.name]: Option,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    flatPicker
  },
})
export default class CreateDepartmentForm extends Mixins(BaseCUModalComponentMixin, TaxonomyMixin) {
  @Prop()
  currentEntity!: any;
  @Prop({
    default: () => ({
      address: '',
      created_by: null,
      lat: null,
      long: null,
      owner_name: '',
      phone_no: '',
      photo: '',
      photo_url: '',
      name: '',
      code: '',
      department_type_id: null,
      territory_id: null,
      territory: null,
      credit_limit: 0,
      outstanding_balance: 0,
      badge_id: null,
      supplier_id: null,
      staffs: null,
      route_plan_id: null,
      is_visitable: 1,
      department_category_id: null,
      department_location_type_id: null,
      owner_dob: null,
      custom_code: null
    }),
  })
  form!: {
    address: string;
    created_by: string;
    lat: number | null;
    long: number | null;
    owner_name: string;
    phone_no: string;
    photo: string | Blob;
    photo_url: string;
    name: string;
    code: string;
    department_type_id: number | null;
    territory_id: number | null;
    territory: Territory | null;
    credit_limit: number | null;
    outstanding_balance: number | null;
    badge_id: number | null;
    supplier_id: null;
    staffs: any;
    is_visitable: any;
    department_category_id: null,
    department_location_type_id: null,
    owner_dob: null,
    custom_code: string
  };
  private isOutlet = 0;
  private isSelfManaged = 0;
  private isRemoveStaff: boolean = false;
  private newAddedStaff: string = '';
  private supplierName: string = '';
  private classificationName: string = '';
  private enableSupplierUpdate: boolean = false;
  private enableClassificationUpdate: boolean = false;

  get createApi(): string {
    return '/departments';
  }

  get updateApi(): string {
    return `/departments/${this.currentEntity.id}`;
  }

  @Watch('isUpdate')
  onUpdateCheckOutletTypeDepartment(newVal: boolean) {
    this.isOutlet = 0;
    this.isSelfManaged = 0;
    if (newVal) {
      this.isOutlet = this.currentEntity.department_type.has_outlet;
      this.isSelfManaged = this.currentEntity.department_type.self_managed;
    }
  }

  formData() {
    let extForm = new FormData();
    let visitable: any = this.form.is_visitable ? 1 : 0;
    extForm.set('address', this.form.address);
    extForm.set('created_by', this.form.created_by);
    this.form.lat ? extForm.set('lat', this.form.lat as unknown as string) : '';
    this.form.long ? extForm.set('long', this.form.long as unknown as string) : '';
    extForm.set('owner_name', this.form.owner_name);
    extForm.set('phone_no', this.form.phone_no);
    extForm.set('photo', this.form.photo);
    extForm.set('name', this.form.name);
    extForm.set('code', this.form.code);
    extForm.set('department_type_id', this.form.department_type_id as unknown as string);
    extForm.set('territory_id', this.form.territory_id as unknown as string);
    this.form.credit_limit ? extForm.set('credit_limit', this.form.credit_limit as unknown as string) : '';
    this.form.outstanding_balance ? extForm.set('outstanding_balance', this.form.outstanding_balance as unknown as string) : '';
    this.form.badge_id ? extForm.set('badge_id', this.form.badge_id as unknown as string) : '';
    extForm.set('supplier_id', this.form.supplier_id as unknown as string);
    extForm.set('is_visitable', visitable);
    this.form.department_category_id ? extForm.set('department_category_id', this.form.department_category_id as unknown as string) : '';
    this.form.department_location_type_id ? extForm.set('department_location_type_id',
      this.form.department_location_type_id as unknown as string) : '';
    this.form.owner_dob ? extForm.set('owner_dob', this.form.owner_dob + ' 00:00:00') : '';
    this.form.custom_code ? extForm.set('custom_code', this.form.custom_code) : '';
    return extForm;
  }

  formReset(): any {
    this.form.address = '';
    this.form.created_by = '';
    this.form.lat = null;
    this.form.long = null;
    this.form.owner_name = '';
    this.form.phone_no = '';
    this.form.photo = '';
    this.form.photo_url = '';
    this.form.name = '';
    this.form.code = '';
    this.form.department_type_id = null;
    this.form.territory_id = null;
    this.form.territory = null;
    this.form.credit_limit = null;
    this.form.outstanding_balance = null;
    this.form.badge_id = null;
    this.form.supplier_id = null;
    this.isOutlet = 0;
    this.form.staffs = [];
    this.form.department_category_id = null;
    this.form.department_location_type_id = null;
    this.form.owner_dob = null;
    this.form.custom_code = '';
    (
      this.$refs.formValidator as Vue & {
        reset: () => any;
      }
    ).reset();
    return this.form;
  }

  async afterCreate(response: AxiosResponse<any>): Promise<void> {
    if (this.form.staffs) {
      await this.assignFieldForce(response.data.department.id);
    }
    // this.form.routePlanId ? await this.assignRoutePlan(response.data.department.id) : '';
    let responseDept = await this.$http.get(this.$api(`/departments/${response.data.department.id}`));
    this.$emit('created', responseDept.data.department);
  }

  async afterUpdate(response: AxiosResponse<any>): Promise<void> {
    let responseDept = await this.$http.get(this.$api(`/departments/${response.data.department.id}`));
    this.$emit('updated', responseDept.data.department);
  }

  beforeClose() {
    this.$emit('onCloseSetDepartmentId', null);
  }

  onRemoveStaff(remove: any) {
    if (this.isUpdate) {
      this.isRemoveStaff = true;
      this.$http
        .delete(this.$api(`/users/${this.currentEntity.users ? this.currentEntity.users[0].id : ''}/outlets/${this.currentEntity.id}`))
        .then((response) => {
          this.isRemoveStaff = false;
        });
    }
  }

  @Watch('form.staffs')
  getSelectedStaff(old: any, current: any) {
    if (this.isUpdate && !this.isRemoveStaff && this.currentEntity.users.findIndex((user: any) => user.id == old) == -1) {
      let newStaff = '';
      if (this.form.staffs != '' && current) {
        newStaff = old.filter((item: any) => {
          return current.indexOf(item) === -1;
        });
      }
      if (old.length == 1 && current.length == 0) {
        this.$http.post(this.$api(`/users/${old[0]}/outlets`), {department_id: this.currentEntity.id});
      }
      if ((newStaff != '' && old.includes('0') == false) || old.includes(this.newAddedStaff) == true) {
        this.$http.post(this.$api(`/users/${newStaff != '' ? newStaff[0] : this.newAddedStaff}/outlets`), {department_id: this.currentEntity.id});
        this.newAddedStaff = '';
      }
    }
    if (old.includes(0)) {
      this.form.staffs.splice((staff: any) => staff == 0, 1);
    }
  }

  async assignFieldForce(department_id: number): Promise<void> {
    try {
      this.form.staffs.forEach((staff: number) => {
        this.$http.post(this.$api(`/users/${staff}/outlets`), {department_id});
      });
    } catch (e) {
      // TODO miraj: have to handel the error properly
      (e as AxiosError).request.retry;
    }
  }

  onChangeDepartmentType(event: number) {
    if (this.isSelfManaged) {
      this.form.territory_id = null;
      this.form.supplier_id = null;
    } else if (this.isOutlet) {
      this.form.territory_id = null;
      this.form.supplier_id = null;
      this.form.is_visitable = 1;
    }
    if (this.currentEntity.department_type.has_outlet) {
      if (this.isOutlet != 1) {
        this.$notify({
          type: 'warning',
          message: 'This Channel Must be Outlet',
          title: 'Required!',
        });
      }
    } else if (this.currentEntity.department_type.isSelfManaged) {
       if (this.isSelfManaged != 1) {
        this.$notify({
          type: 'warning',
          message: 'This Channel Must be Self Managed',
          title: 'Required!',
        });
      }
    }
  }

  fetchDepartment() {
    this.loading = true
    this.$http.get(this.$api(`/departments/${this.currentEntity.id}`))
      .then((response: AxiosResponse) => {
        this.supplierName = response.data.department.supplier ? response.data.department.supplier.name : "No Supplier";
        this.classificationName = response.data.department.department_category ?
          response.data.department.department_category.name : null;
      })
      .finally(() => this.loading = false);
  }

  mounted() {
    if (this.isUpdate) {
      this.fetchDepartment();
    }
  }
}
