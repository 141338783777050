










































































































import { Vue, Component } from 'vue-property-decorator';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import LazyDropdown from "@/components/LazyDropdown.vue";
import DepartmentTypeAutocomplete from "@/components/resources-autocomplete/DepartmentTypeAutocomplete.vue";
import BadgeAutocomplete from "@/components/resources-autocomplete/BadgeAutocomplete.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";

@Component({
  components: {
    DepartmentCategoryLazyDropdown,
    DepartmentLocationTypeLazyDropdown,
    BadgeAutocomplete,
    DepartmentTypeAutocomplete,
    LazyDropdown,
    TerritoryCascaderDropdown,
  }
})
export default class MarketingCampaign extends Vue {
  public filterForm = {
    territory_id: '',
    department_type_id: '',
    badge_id: '',
    department_location_type_id: '',
    department_category_id: '',
    message: '',
  };

  public loading: boolean = false;
  public isDisableSendBtn: boolean = false;
  public isLanEnglish: boolean = true;
  public outletCount: number = 0;
  public outletData: any = '';
  public smsBalance: number = 0;
  public charNum: number = 0;
  public totalSMS: number = 0;
  public leftChar: number = 0;
  public grandTotalSMS: number = 0;
  public smsCountInfo: string = '160 Characters are counted as 1 SMS in case of English language.';

  public onClickGenerate() {
    if (this.filterForm.territory_id) {
      this.fetchReport();
    } else {
      this.$notify({
        type: 'warning',
        message: 'Select the territory field',
        title: 'Required!'
      });
    }
  }

  async fetchReport() {
    this.loading = true
    await this.$http.get(this.$api(`/sms/marketing-campaign/outlet-filter${this.$toQueryString(this.filterForm)}`))
      .then((response: any) => {
        this.outletCount = response.data.validateOutletCount;
        this.smsBalance = response.data.smsBalance ?? 0;
        if (!this.outletCount) {
          this.$notify({
            type: 'warning',
            message: "No Outlet Found",
            title: 'Warning'
          });
        }
      }).catch((e) => {
        let err_msg = [];
        if (e.response.data.errors.range) {
          err_msg.push(e.response.data.errors.range[0]);
        }
        if (e.response.data.errors.territory_id) {
          err_msg.push(e.response.data.errors.territory_id[0]);
        }
        err_msg.forEach(error => {
          this.$notify({
            type: 'warning',
            message: error,
            title: 'Validation error'
          });
        })
      }).finally(() => {
        this.loading = false
      })
  }

  resetTextEditor() {
    this.charNum = 0;
    this.totalSMS = 0;
    this.leftChar = 0;
    this.grandTotalSMS = 0;
  }

  submitBulkSMSMarketingCampaign() {
    if (!this.filterForm.message || (this.smsBalance < this.grandTotalSMS)) {
      if (!this.filterForm.message) {
        this.$notify({
          type: 'warning',
          message: 'write your sms text first',
          title: 'Required!'
        });
      }
      if (this.smsBalance < this.grandTotalSMS) {
        this.$notify({
          type: 'warning',
          message: "Need to add sms balance",
          title: 'warning!'
        });
      }
    } else {
      this.loading = true;
      this.$http
        .post(this.$api('/sms/marketing-campaign/send-to-outlet'), this.filterForm)
        .then(response => {
          this.$notify({
            type: 'success',
            message: response.data.message,
            title: 'Success'
          });
          this.filterForm.message = '';
          this.resetTextEditor();
          this.fetchReport();
        }).catch(e => {
        let err_msg = [];
        if (e.response.data.errors.range) {
          err_msg.push(e.response.data.errors.range[0]);
        }
        if (e.response.data.errors.territory_id) {
          err_msg.push(e.response.data.errors.territory_id[0]);
        }
        err_msg.forEach(error => {
          this.$notify({
            type: 'warning',
            message: error,
            title: 'Error'
          });
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }

  messageFilter(newValue: any) {
    const language = this.detectLanguage(this.filterForm.message);

    if (this.isLanEnglish) {
      if (language === 'bn') {
        this.$notify({
          type: 'warning',
          message: 'Unicode characters are not supported when English mode is enabled.',
          title: 'Warning!'
        });
        this.filterForm.message = '';
      }
    } else {
      this.filterForm.message = newValue;
    }

    this.charNum = this.filterForm.message.length;
    const charactersPerSMS = (language === 'bn') ? 70 : 160;
    this.leftChar = charactersPerSMS - this.charNum % charactersPerSMS;
    this.totalSMS = Math.ceil(this.charNum / charactersPerSMS);
    this.grandTotalSMS = this.totalSMS * this.outletCount;
  }

  detectLanguage(text: string): string {
    const isBangla = /^[\u0980-\u09FF\s]*$/.test(text);
    return isBangla ? 'bn' : 'other';
  }
}
