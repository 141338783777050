
































































































































declare global {
  interface Window {
    excelFIleSize: any;
    excelFIleProgressStart: any;
  }
}
import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
// import TerritoryTypeAutocomplete from "@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue";
// import TerritoryAutocomplete from "@/components/resources-autocomplete/TerritoryAutocomplete.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import swal from 'sweetalert2';
import moment from "moment";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
//@ts-ignore
import downloadExcel from "vue-json-excel";

@Component({
  components: {
    // TerritoryAutocomplete,
    // TerritoryTypeAutocomplete,
    ProductBrandLazydropdown,
    UserLazydropdown,
    DateRange,
    ProductCategoryLazydropdown,
    BaseProductLazydropdown,
    DepartmentCategoryLazyDropdown,
    DepartmentLocationTypeLazyDropdown,
    BadgeLazydropdown,
    downloadExcel,
    TerritoryCascaderDropdown
  }
})
export default class OrderSummary extends Mixins(PaginationComponentMixin, Vue) {
  public downloadingXlsx: boolean = false;
  public downloadingXlx: boolean = false;
  public downloadingCsv: boolean = false;
  public range = '';
  public selectedTerritoriesName = '';
  public selectedTerritoriesUsers = '';
  public totalOutletsCount: any = 0;
  public totalBilledOutlet: any = 0;
  public totalNotBilledOutlet: any = 0;
  public selectedUsers: any = [];
  // public selectedTerritoryType: any = null;
  public selectedTerritories: any = null;
  public brand_id: any = '';
  public loading: boolean = false;
  public outletReport = [];
  public selectedBrands: any = '';
  public showOutletModal: boolean = false;
  public usersNotBuilledOutlets: any = [];
  public maxDate: string = moment().format('YYYY-MM-DD');
  public totalRecords: any = 0;
  public downloadbtn: any = false;
  public query: any = '';
  public selectedCategories: any = '';
  public selectedOutletCategories: any = '';
  public selectedOutletLocationTypes: any = '';
  public selectedOutletBadges: any = '';
  public selectedProducts: any = [];
  public excelType = 'xls';
  public fileName = 'order-summary-report-' + moment().format('YYYY-MM-DD') + '.' + this.excelType;
  public json_fields: any = {
    // 'SI': 'si',
    'Order Date': 'order_date',
    'Employee Code': 'created_code',
    'Employee Name': 'created_name',
    'Seller Department Code': 'seller_department_code',
    'Seller Department Name': 'seller_department_name',
    'Buyer Department Code': 'buyer_department_code',
    'Buyer Department Name': 'buyer_department_name',
    'Buyer Badge Name': 'buyer_badge_name',
    'Product Category': 'product_category_name',
    'Product Brand': 'product_brand_name',
    'SKU': 'product_sku',
    'Product Name': 'product_name',
    'Order Quantity': 'order_qty',
    'Unit Price': 'unit_price',
    'Amount': 'amount',
    'Subtotal': 'subtotal',
  };
  public json_meta = [
    [{
      key: "charset",
      value: "utf-8",
    },],
  ];
  public fileSize: any = 'Waiting for Server...';
  public clearTimer: any = 0;
  public downloadingCount = false;
  public searchMsg = false;

  created(): void {
    this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
      const units = this.sortItems(response.data.units);
      units.forEach((element: any, key) => {
        this.json_fields[element.name] = 'unit' + (key + 1);
      })
    }).finally(() => {
      this.json_fields['Order Reference'] = 'order_transportation.order_reference.full_name';
    });
    window.excelFIleProgressStart = false;
  }

  public sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  @Watch('range')
  onChangeRange(type: any) {
    this.downloadbtn = false;
    this.searchMsg = false;
  }

  @Watch('selectedTerritories')
  onChangeTerritories() {
    this.downloadbtn = false;
    this.searchMsg = false;
  }

  public onClickGenerate() {
    if (this.range === '' || this.selectedTerritories === '') {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!'
      });
    } else {
      this.search();
    }
  }

  async search() {
    try {
      this.loading = true;
      this.downloadbtn = false;
      this.downloadingCount = false;
      this.outletReport = [];
      this.query = {
        range: this.range,
        territory_id: this.selectedTerritories,
        created_id: this.selectedUsers,
        buyer_dept_cat_id: this.selectedOutletCategories,
        buyer_dept_loc_type_id: this.selectedOutletLocationTypes,
        buyer_badge_id: this.selectedOutletBadges,
        category: this.selectedCategories,
        brand: this.brand_id,
        product_id: this.selectedProducts
      };
      let {
        data
      } = await this.$http.get(this.$api3(`/order-summary-search${this.$toQueryString(this.query)}`));
      this.totalRecords = data.total_records;
      if (this.totalRecords >= 500000) {
        this.downloadbtn = false;
        this.searchMsg = true;
      } else {
        this.downloadbtn = true;
      }
    } catch (e) {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async exportSummaryXlsx() {
    this.downloadingXlsx = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/order-summary-export${this.$toQueryString(this.query)}&download&type=xlsx`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloadingXlsx = false;
    }
  }

  async exportSummary() {
    if (this.excelType === 'csv') {
      this.downloadingCsv = true;
    } else {
      this.downloadingXlx = true;
    }
    this.fileSize = 'Waiting for Server...';
    this.downloadingCount = true;
    window.excelFIleSize = 0;
    this.clearTimer = setInterval(() => {
      if (window.excelFIleProgressStart) {
        if (window.excelFIleSize != 0) {
          this.fileSize = 'Downloading : ' + window.excelFIleSize;
        } else {
          clearInterval(this.clearTimer);
        }
      }
    }, 100)
    try {
      let {
        data
      } = await this.$http.get(this.$api3(`/order-summary-export${this.$toQueryString(this.query)}&download`), {
        onDownloadProgress: function (progressEvent) {
          // Do whatever you want with the native progress event
          window.excelFIleProgressStart = true;
          const bytes = parseInt(progressEvent.loaded);
          const decimals = 2;
          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
          const i = Math.floor(Math.log(bytes) / Math.log(k));
          const current_size = parseFloat(((bytes / Math.pow(k, i)) / 2).toFixed(dm)) + ' ' + sizes[i];
          window.excelFIleSize = current_size;
          //window.console.log('Response size:', progressEvent);
        }
      });
      clearInterval(this.clearTimer);
      this.fileSize = 'Downloaded (appr) : ' + window.excelFIleSize;
      return JSON.parse(data.data);
    } catch (e) {
      window.console.log(e);
      this.$notify({
        title: 'Something went wrong!',
        type: 'warning',
        message: 'Unable to download reports.'
      });
    } finally {
      if (this.excelType === 'csv') {
        this.downloadingCsv = false;
      } else {
        this.downloadingXlx = false;
      }
    }
  }

  onSelectBrand(value: []) {
    // this.selectedBrands = value.sort((a: any, b: any) => a.id - b.id);
    this.selectedBrands = value;
  }

}
