













































import { Component, Vue } from 'vue-property-decorator';
import { Button } from 'element-ui';
import ReportRoutes from '@/router/client-routes/ReportRoutes';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class SystemReport extends Vue {

  get SystemRoutes() {
    const reportRoutes = ReportRoutes.children?.find(route => route.name === 'system-report');
    return reportRoutes?.children || [];
  }

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}
