







































import {
    Component,
    Mixins,
    Vue
} from "vue-property-decorator";
import swal from "sweetalert2";
import {
    Checkbox,
    Link,
    Button
} from "element-ui";

@Component({
    components: {
        [Checkbox.name]: Checkbox,
        [Link.name]: Link,
        [Button.name]: Button,
    }
})
export default class OnlineSMSPayment extends Mixins(Vue) {
    public product_name: string = "SMS Package-";
    public type: string = "sms";
    public product_description: string = "";
    public smsPackages: any = [];
    public paymentLoading: boolean = false;
    public isTermsAccepted: boolean = false;
    public selectedPackage: any = {};
    public refundPolicy: string = "https://www.sokrio.com/refund-policy/";

    created() {
        const paymentStatus = this.$route.query.paymentStatus;
        const currentPath = this.$route.path;
        const queryParameters = {
            ...this.$route.query,
        };
        delete queryParameters.paymentStatus;
        if (
            currentPath !== this.$route.path ||
            JSON.stringify(queryParameters) !== JSON.stringify(this.$route.query)
        ) {
            this.$router.replace({
                path: currentPath,
                query: queryParameters,
            });
        }
        if (paymentStatus) {
            if (paymentStatus === "ACCEPTED") {
                swal.fire({
                    title: paymentStatus,
                    text: "Payment completed successfully.",
                    icon: "success",
                });
            } else {
                swal.fire({
                    title: paymentStatus,
                    text: "Payment failed, please try again.",
                    icon: "error",
                });
            }
        }
    }

    mounted() {
        this.$http
            .get(this.$api("/tenant-sms-package?status=1"))
            .then((response) => {
                this.smsPackages = response.data.sms_packages;
            })
            .catch((error) => {
                window.console.error("Error fetching SMS packages:", error);
            });
    }
    get isPaymentEnabled() {
        return this.isTermsAccepted;
    }

    handlePaymentClick() {
        if (!this.selectedPackage.id) {
            this.$message({
                showClose: true,
                message: 'Please Select a SMS Package.',
                type: 'error'
            });
            return false;
        }

        if (!this.isTermsAccepted) {
            this.$message({
                showClose: true,
                message: 'Please Accept Terms & Conditions, Privacy Policy & Refund Policy.',
                type: 'error'
            });
            return false;
        }
        this.paymentLoading = true;

        this.product_name += this.selectedPackage.name;
        this.product_description =
            "SMS Recharge: Get " +
            this.selectedPackage.qty +
            " SMS for " +
            this.selectedPackage.amount +
            " BDT (at " +
            this.selectedPackage.price +
            " BDT per SMS).";
        const formData = new FormData();
        formData.append("amount", this.selectedPackage.amount);
        formData.append("package_id", this.selectedPackage.id);
        formData.append("product_name", this.product_name);
        formData.append("product_description", this.product_description);
        formData.append("type", this.type);
        this.$http
            .post(this.$api("/make-sms-payment"), formData)
            .then((response) => {
                window.location.href = response.data;
            })
            .catch((error) => {
                if (error.response.data.error) {
                    this.$message({
                        showClose: true,
                        message: error.response.data.error,
                        type: 'error'
                    });
                }
                window.console.error("Error Making online payment:", error);
                this.paymentLoading = false;
            });
    }
    onSelectPackage(pkg: any) {
        if (this.paymentLoading) {
            return false;
        }
        this.selectedPackage = pkg;
    }
    get themeColor() {
        return this.$store.getters['Theme/getThemeColor']
    }
}
