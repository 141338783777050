













































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Divider } from "element-ui";
import OrderProduct from '@/intefaces/OrderProduct';
import StockLazydropdown from "@/components/lazyDropdown/StockLazydropdown.vue";
import Order from "@/intefaces/Order";
import AsyncRender from "@/components/AsyncRender.vue";

@Component({
  components: {
    [Divider.name]: Divider,
    StockLazydropdown,
    AsyncRender
  }
})

export default class OrderEdit extends Vue {
  @Prop() title!: string;
  @Prop() currentEntity!: any;
  @Prop({default: false}) editable!: boolean;
  @Prop() saveEditedOrder!: boolean;
  @Prop() discount!: number;
  private subTotal: number = 0;
  private salesReturn: number = 0;
  @Prop() entity!: string;
  @Prop() entityProducts!: OrderProduct[];
  @Prop() comment!: String;
  @Prop() orderIncludeLot!: boolean;
  private deliveredProducts: any = [];
  public tempProducts: any = [];
  private qty = 0;
  private products: any = [{
    id: '',
    product_id: '',
    qty: 0,
    price: 0,
    promotion_discount: 0,
    custom_discount: 0,
    promotion_id: '',
    freeWith: '',
    offerId: '',
    offerQty: 0,
    is_free: 0,
    free_with: null,
    free_with_product: {},
    promotion: {},
    message: '',
    availableQty: 0,
    lot_number: ''
  }]
  private orderProducts: any = [{
    id: '',
    product_id: '',
    qty: 0,
    price: 0,
    promotion_discount: 0,
    custom_discount: 0,
    promotion_id: '',
    freeWith: '',
    offerId: '',
    offerQty: 0,
    is_free: 0,
    free_with: null,
    free_with_product: {},
    promotion: {},
    message: '',
    availableQty: 0,
    lot_number: ''
  }]
  private orderFreeProducts: any = [{
    id: '',
    product_id: '',
    qty: 0,
    price: 0,
    promotion_discount: 0,
    custom_discount: 0,
    promotion_id: '',
    freeWith: '',
    offerId: '',
    offerQty: 0,
    is_free: 0,
    free_with: null,
    free_with_product: {},
    promotion: {},
    message: '',
    availableQty: 0
  }]
  private salesReturns: any = [{
    product_id: '',
    qty: 0,
    return_rate: 0,
    promotion_discount: 0,
    custom_discount: 0,
    message: '',
    is_free: 0,
    free_with: null,
    free_with_product: {},
    reason: 'damage',
    amount: 0,
  }]

  addNewReturnedProduct() {
    this.tempProducts = [...this.salesReturns];
    this.salesReturns.push({
      product_id: '',
      qty: 0,
      return_rate: 0,
      promotion_discount: 0,
      custom_discount: 0,
      message: '',
      is_free: 0,
      free_with: null,
      free_with_product: null,
      reason: 'damage',
      amount: 0,
    })
  }

  addNewProduct() {
    this.tempProducts = [...this.orderProducts];
    this.orderProducts.push({
      id: '',
      product_id: '',
      qty: 0,
      price: 0,
      promotion_discount: 0,
      custom_discount: 0,
      promotion_id: '',
      freeWith: '',
      offerId: '',
      offerQty: 0,
      is_free: 0,
      free_with: null,
      free_with_product: null,
      promotion: {},
      message: '',
      lot_number: ''
    })
  }

  getFreeProductName(orderedProductId: number) {
    const freeProduct = this.orderFreeProducts.find((product: any) => product.free_with === orderedProductId);
    return freeProduct ? freeProduct.name : null;
  }

  getFreeProductQty(orderedProductId: number) {
    const freeProduct = this.orderFreeProducts.find((product: any) => product.free_with === orderedProductId);
    return freeProduct ? freeProduct.qty : null;
  }

  deleteProductFromArray(orderedProduct: OrderProduct, index: number) {
    this.orderProducts.splice(index, 1);
    this.calculateOrderTotalCal();
    this.checkForExistingPromotion(orderedProduct);
    this.$emit('disabledSaveBtn', false)
    this.tempProducts = [...this.orderProducts];
  }

  deleteReturnedProductFromArray(index: number) {
    this.salesReturns.splice(index, 1);
    this.calculateOrderTotalCal();
    // this.checkForExistingPromotion();
    this.$emit('disabledSaveBtn', false)
    this.tempProducts = [...this.salesReturns];
  }

  checkForExistingPromotion(orderedProduct: OrderProduct) {
    if (orderedProduct.promotion) {
      this.orderFreeProducts.forEach((product: any, index: number) => {
        if (product.is_free && product.free_with && (orderedProduct.product_id == product.free_with)) {
          this.orderFreeProducts.splice(index, 1);
        }
      });
    }
  }

  calculateProductAmount(orderedProduct: any) {
    return (orderedProduct.qty * orderedProduct.price) - orderedProduct.promotion_discount - orderedProduct.custom_discount;
  }

  calculateReturnProductAmount(returnProduct: any) {
    return returnProduct.qty * returnProduct.return_rate;
  }

  async getProductQtyForSeller(productId: number, product: any) {
    if (this.currentEntity.hasOwnProperty('is_requisition')) {
      // const response = await this.$http.get(this.$api(`/stocks?distinct&department_id=${this.currentEntity.seller_id}&product_id=${productId}`));
      // product.availableQty = response.data.stocks[0].qty;

      await this.$http.get(this.$api(`/stocks?distinct&department_id=${this.currentEntity.seller_id}&product_id=${productId}`))
        .then(response => {
          product.availableQty = response.data.stocks[0].qty;
        })
    }
  }

  calculateOrderTotalCal() {
    let subTotal = 0;
    let salesReturn = 0;
    this.orderProducts.forEach((product: any) => {
      if (!product.is_free) {
        subTotal += (product.qty * product.price) - product.promotion_discount - product.custom_discount;
      }
    })
    this.salesReturns.forEach((product: any) => {
      if (!product.is_free) {
        salesReturn += product.qty * product.return_rate;
      } else {
        salesReturn += 0
      }
    })
    this.subTotal = subTotal;
    this.salesReturn = salesReturn;
    this.$emit('subTotal', subTotal);
    this.$emit('salesReturn', salesReturn);
  }

  async getPrice(selectedProduct: any) {
    if (selectedProduct.product_id == '') {
      selectedProduct.id = '';
      selectedProduct.price = 0;
      selectedProduct.message = '';
      selectedProduct.qty = 0;
      selectedProduct.promotion_discount = 0;
      selectedProduct.custom_discount = 0;
      this.$emit('disabledSaveBtn', true);
    } else {
      if (this.tempProducts.some((product: any) => parseInt(product.product_id) === parseInt(selectedProduct.product_id))) {
        selectedProduct.price = 0;
        selectedProduct.message = "The product already selected, choose others"
        selectedProduct.qty = 0;
        selectedProduct.promotion_discount = 0;
        selectedProduct.custom_discount = 0;
        this.$emit('disabledSaveBtn', true);
      } else {
        await this.$http.get(this.$api(`/products/${selectedProduct.product_id}?with=prices.priceable`))
          .then(response => {
          //TODO:: when new product is added offer product or last product is removed from product list

          let productPriceFound = response.data.product?.prices.find((productPrice: any) => productPrice.priceable_type === 'dept' && productPrice.priceable_id === this.currentEntity.buyer_id);
          if (!productPriceFound) {
            productPriceFound = response.data.product?.prices.find((productPrice: any) => productPrice.priceable_id === (productPrice.priceable_type == 'depttype' ? this.currentEntity.department?.department_type_id : this.currentEntity.buyer_id));
          }

          if (productPriceFound) {
            selectedProduct.message = '';
            if (selectedProduct.hasOwnProperty('return_rate')) {
              selectedProduct.return_rate = productPriceFound.price;
            }
            selectedProduct.price = productPriceFound.price;
            this.$emit('disabledSaveBtn', false)
          } else {
            selectedProduct.price = 0;
            selectedProduct.message = "Product Price is not associated with buyer departments."
            selectedProduct.qty = 0;
            selectedProduct.promotion_discount = 0;
            selectedProduct.custom_discount = 0;
            this.$emit('disabledSaveBtn', true);
          }
          // response.data.product?.prices.forEach((productPrice: any) => {
          //   if (productPrice.priceable_id == (productPrice.priceable_type == 'depttype' ? this.currentEntity.department.department_type_id : this.currentEntity.buyer_id)) {
          //     selectedProduct.message = '';
          //     if (selectedProduct.hasOwnProperty('return_rate')) {
          //       selectedProduct.return_rate = productPrice.price;
          //     }
          //     selectedProduct.price = productPrice.price;
          //     this.$emit('disabledSaveBtn', false)
          //   } else {
          //     selectedProduct.price = 0;
          //     selectedProduct.message = "Product Price is not associated with buyer departments."
          //     selectedProduct.qty = 0;
          //     selectedProduct.promotion_discount = 0;
          //     selectedProduct.custom_discount = 0;
          //     this.$emit('disabledSaveBtn', true);
          //   }
          // });
        })
        this.calculateOrderTotalCal();
      }
    }
  }

  clearInput(removeProductId: any, index: number) {
    let foundProduct: any = this.entityProducts.find((product: any) => product.product_id == removeProductId);
    if (foundProduct.promotion && foundProduct.promotion!.offer_type == 'qty') {
      let offerProductIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == foundProduct.product_id);
      this.$delete(this.orderFreeProducts, offerProductIndex);
    }
    // this.orderProducts.splice(index, 1);
  }

  removePromotionProduct(selectedProduct: OrderProduct) {
    if (this.orderFreeProducts.some((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id)) {
      let productIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == selectedProduct.product_id);
      this.$delete(this.orderFreeProducts, productIndex);
    }
  }

  getProductPromotion(selectedProduct: any, index: number) {
    const findFreeProduct: any = this.entityProducts.find(
      (product: any) => product.free_with === selectedProduct.product_id
    );
    if (findFreeProduct) {
      findFreeProduct.is_free = 1;
    }
    this.$http.get(this.$api(`/promotions?promotionable_id=product,${selectedProduct.product_id}`)).then(response => {
      selectedProduct.promotion = response.data.promotions[0];
      if (response.data.promotions.length > 0) {
        const expireDateFormatted = new Date(selectedProduct.promotion.expires_at).toISOString().split('T')[0];
        const currentDateFormatted = new Date().toISOString().split('T')[0];
        if (expireDateFormatted < currentDateFormatted) {
          if (selectedProduct.promotion.promotion_type == "qty" && selectedProduct.promotion.offer_type == "qty") {
            let productIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == selectedProduct.product_id);
            if (productIndex !== -1) {
              this.$delete(this.orderFreeProducts, productIndex);
            }
          } else {
            selectedProduct.promotion_discount = 0;
          }
        } else {
          this.calculatePromotion(findFreeProduct, selectedProduct, index)
        }
      }
    })
  }
  calculatePromotion(findFreeProduct: any, selectedProduct: OrderProduct, index: number) {
    let amount = selectedProduct.qty * selectedProduct.price;
    if (selectedProduct.promotion.promotion_type == 'qty') {
      this.calculatePromotionForQtyType(findFreeProduct, selectedProduct, amount);
    } else if (selectedProduct.promotion.promotion_type == 'amount') {
      this.calculationPromotionForAmountType(findFreeProduct, selectedProduct, amount);
    }
  }

  calculatePromotionForQtyType(findFreeProduct: any, selectedProduct: OrderProduct, amount: number) {
    //TODO::(need test: product not removed on removePromotionProduct call )
    //check expires data for promotion product
    if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
      this.removePromotionProduct(selectedProduct);
      this.orderFreeProducts.push({
        product_id: selectedProduct.promotion.offer_product!.id,
        name: selectedProduct.promotion.offer_product!.full_name,
        qty: selectedProduct.promotion.offer_value *
          Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value),
        price: 0,
        promotion_discount: 0,
        custom_discount: 0,
        promotion_id: selectedProduct.promotion.id,
        freeWith: findFreeProduct!.free_with,
        is_free: 1,
        free_with: findFreeProduct!.free_with,
        free_with_product: findFreeProduct!.free_with_product ?? null,
      })
    } else if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value > selectedProduct.qty) {
      this.removePromotionProduct(selectedProduct);
    } else if (selectedProduct.promotion!.offer_type == 'amount' &&
      selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
      selectedProduct.promotion_discount = selectedProduct.promotion.offer_value *
        Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value);
    } else if (selectedProduct.promotion!.offer_type == 'percent' &&
      selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
      selectedProduct.promotion_discount = (amount * selectedProduct.promotion.offer_value) / 100
    } else {
      if (selectedProduct.promotion!.promotion_value > selectedProduct.qty) {
        // this.removePromotionProduct(selectedProduct);
      }
    }
  }

  calculationPromotionForAmountType(findFreeProduct: any, selectedProduct: OrderProduct, amount: number) {
    if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value <= amount) {
      // if (this.products.some((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id)) {
      //   this.products.splice(this.products.findIndex((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id, 1));
      // }
      // this.removePromotionProduct(selectedProduct);

      this.orderFreeProducts.push({
        product_id: selectedProduct.promotion.offer_product!.id,
        name: selectedProduct.promotion.offer_product!.full_name,
        qty: selectedProduct.promotion.offer_value *
          Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value),
        price: 0,
        promotion_discount: 0,
        custom_discount: 0,
        freeWith: findFreeProduct!.free_with,
        is_free: 1,
        free_with: findFreeProduct!.free_with,
        free_with_product: findFreeProduct!.free_with_product ?? null,
      })
    } else if (selectedProduct.promotion!.offer_type == 'amount' &&
      selectedProduct.promotion!.promotion_value <= amount) {
      selectedProduct.promotion_discount = selectedProduct.promotion.offer_value *
        Math.floor(amount / selectedProduct.promotion.promotion_value);
    } else if (selectedProduct.promotion!.offer_type == 'percent' &&
      selectedProduct.promotion!.promotion_value <= amount) {
      selectedProduct.promotion_discount = (amount * selectedProduct.promotion.offer_value) / 100
    }
  }
  @Watch('editable')
  getExistingOrderedProducts(newVal: boolean) {
    if (newVal) {
      this.products = [];
      this.salesReturns = [];
      this.orderProducts = [];
      this.orderFreeProducts = [];
      // finds order products and order free products
      this.entityProducts.forEach((orderedProduct: OrderProduct, index: number) => {
        if (!orderedProduct.free_with) {
          this.orderProducts.push({
            id: orderedProduct.id,
            product_id: orderedProduct.product.id,
            name: orderedProduct.product!.hasOwnProperty("full_name") ?
              orderedProduct.product!.full_name : orderedProduct.product.base_product!.name,
            qty: orderedProduct.qty,
            price: orderedProduct.price,
            promotion_discount: orderedProduct.promotion_discount,
            custom_discount: orderedProduct.hasOwnProperty('custom_discount') ? orderedProduct.custom_discount : 0,
            // freeWith: orderedProduct.is_free ? orderedProduct.promotion!.promotionable_id : '',
            freeWith: orderedProduct.free_with,
            is_free: orderedProduct.free_with ? 1 : 0,
            free_with: orderedProduct.free_with,
            free_with_product: orderedProduct.free_with_product ?? null,
            promotion: orderedProduct.promotion,
            promotion_id: orderedProduct.promotion ? orderedProduct.promotion!.id : '',
            lot_number: orderedProduct.lot_number
          });
        } else {
          this.orderFreeProducts.push({
            id: orderedProduct.id,
            product_id: orderedProduct.product.id,
            name: orderedProduct.product!.hasOwnProperty("full_name") ?
              orderedProduct.product!.full_name : orderedProduct.product.base_product!.name,
            qty: orderedProduct.qty,
            price: orderedProduct.price,
            promotion_discount: orderedProduct.promotion_discount,
            custom_discount: orderedProduct.hasOwnProperty('custom_discount') ? orderedProduct.custom_discount : 0,
            // freeWith: orderedProduct.is_free ? orderedProduct.promotion!.promotionable_id : '',
            freeWith: orderedProduct.free_with,
            is_free: orderedProduct.free_with ? 1 : 0,
            free_with: orderedProduct.free_with,
            free_with_product: orderedProduct.free_with_product ?? null,
            promotion: orderedProduct.promotion,
            promotion_id: orderedProduct.promotion ? orderedProduct.promotion!.id : '',
          });
        }
        this.getProductQtyForSeller(orderedProduct.product.id, orderedProduct);
      });
      // finds order return products
      // window.console.log("returned_products")
      // window.console.log(this.currentEntity.latest_revision.returned_products);
      if (this.currentEntity.latest_revision.returned_products != null) {
        this.currentEntity.latest_revision.returned_products.forEach((returnedProduct: any) => {
          this.salesReturns.push({
            product_id: returnedProduct.product_id,
            return_rate: returnedProduct.return_rate,
            qty: returnedProduct.qty,
            promotion_discount: 0,
            custom_discount: 0,
            is_free: returnedProduct.is_free,
            reason: returnedProduct.reason,
            amount: returnedProduct.amount,
          });
        });
      }
    }
    this.calculateOrderTotalCal();
  }

  @Watch('saveEditedOrder')
  editOrder(newVal: boolean) {
    if (newVal) {
      this.salesReturns.forEach((returnedProduct: any) => {
        returnedProduct.amount = returnedProduct.qty * returnedProduct.return_rate;
      });
      this.deliveredProducts = [...this.orderProducts, ...this.orderFreeProducts];
      if (this.currentEntity.latest_status === 'ontheway') {
        this.saveAndDelivery();
      } else {
        this.$http.post(this.$api(`/${this.entity}/${this.currentEntity.id}/revisions`), {
            buyer_id: this.currentEntity.buyer_id,
            seller_id: this.currentEntity.seller_id,
            amount: this.subTotal > 0 ? this.subTotal: 0,
            promotion_discount: this.currentEntity.promotion_discount,
            discount: this.discount ? this.discount : 0,
            is_requisition: this.currentEntity.hasOwnProperty('is_requisition') ? this.currentEntity.is_requisition : '',
            products: this.deliveredProducts,
            returned_products: this.salesReturns,
          }).then(response => {
            // if (this.salesReturns.length > 0) {
            //   window.console.log(this.salesReturns);
            //   this.$http.post(this.$api('/sales-returns'), {
            //     order_id: response.data.revision.order_id,
            //     buyer_id: this.currentEntity.buyer_id,
            //     seller_id: this.currentEntity.seller_id,
            //     from_delivery: 0,
            //     products: this.salesReturns
            //   }).catch(error => {
            //     this.$notify({
            //       title: 'Warning',
            //       message: 'Something went wrong for sales return.',
            //       type: 'warning'
            //     });
            //   });
            // }
            this.$emit('update:editable', false);
            this.$emit('changeStatus');
            this.$emit('update:saveEditedOrder', false);
            this.$emit('editedEntity', response.data.revision);
            this.$notify({
              title: 'Success',
              message: 'Order successfully updated.',
              type: 'success'
            });
          }).catch(error => {
            this.$emit('update:saveEditedOrder', false);
            this.$emit('update:editable', false);
            this.$notify({
              title: 'Warning',
              message: 'Something went wrong.',
              type: 'warning'
            });
          });
      }
    }
  }

  saveAndDelivery() {
    const productDiscount = this.currentEntity.latest_revision.invoiced_products.reduce((acc: any, product: any) => {
      return acc + parseFloat(product.promotion_discount) + parseFloat(product.custom_discount);
    }, 0);
    this.$http.post(this.$api(`/orders/${this.currentEntity.order_id}/deliveries`), {
      buyer_id: this.currentEntity.buyer_id,
      seller_id: this.currentEntity.seller_id,
      amount: this.subTotal > 0 ? this.subTotal + productDiscount : 0,
      discount: this.discount ? this.discount : 0,
      promotion_discount: this.currentEntity.promotion_discount,
      products: this.deliveredProducts,
      comment: this.comment
    })
      .then(response => {
        // this.$emit('updatedStatus', this.currentEntity);
        this.$notify({
          title: 'Success',
          message: 'Delivered Successfully',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
      })
      .catch((error: any) => {
        const errorMessages: any = [];
        if (error.response.data.errors) {
          this.deliveredProducts.forEach((product: any, index: number) => {
            if (error.response.data.errors['products.' + index + '.product_id'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.product_id'][0] });
              return;
            }
            if (error.response.data.errors['products.' + index + '.qty'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.qty'][0] });
              return;
            }
            if (error.response.data.errors['products.' + index + '.free_with'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.free_with'][0] });
              return;
            }
            if (error.response.data.errors['products.' + index + '.promotion_id'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.promotion_id'][0] });
              return;
            }
            if (error.response.data.errors['products.' + index + '.promotion_discount'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.promotion_discount'][0] });
              return;
            }
            if (error.response.data.errors['products.' + index + '.custom_discount'][0]) {
              errorMessages.push({ name: product.product.full_name, error: error.response.data.errors['products.' + index + '.custom_discount'][0] });
              return;
            }
          });
        }
        this.$emit('errorMessages', errorMessages);
      }).finally(() => {
        this.$emit('update:editable', false);
        this.$emit('changeStatus');
        this.$emit('update:saveEditedOrder', false);
      }) ;
  }
}
