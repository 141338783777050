















































































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import moment from "moment";
import {
  Tree, ButtonGroup, Button, Link, DatePicker, Select
} from 'element-ui';
import DateRangeForReports from "@/components/DateRangeForReports.vue";
import DateRange from "@/components/DateRange.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import { Radio, RadioGroup, Cascader } from 'element-ui'

@Component({
  components: {
    DateRangeForReports,
    TerritoryCascaderDropdown,
    UserLazydropdown,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    [Tree.name]: Tree,
    DateRange,
    DepartmentCategoryLazyDropdown,
    DepartmentLocationTypeLazyDropdown,
    BadgeLazydropdown,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    ElCascader: Cascader,
    components: {
      [Cascader.name]: Cascader,
    },
  }
})
export default class VisitCoverageReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public downloadingSingle: boolean = false;
  public user: any = this.$store.getters['Auth/user'];
  public query: any = {};
  public props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };
  public formData = {
    parent_id: 0,
    range: '',
    user_id: '',
    territory_id: null,
    department_type_id: '',
    department_category_id: '',
    department_location_type_id: '',
    badge_id: '',
    selected_user_id: '',
    visit_times: '',
  };

  public allItemDownloadFormData: any = {};

  public loading: boolean = false;
  public reports = [];
  public filterData = null;
  public parametersChannelTypes: any = [];

  public onClickGenerate() {
    this.allItemDownloadFormData = this.formData;
    this.fetchReport();
  }

  sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  created() {
    this.$http.get(this.$api(`/department-types-list?page=${this.pagination.currentPage}`)).then(response => {
      const units = this.sortItems(response.data.departmentTypes);
      units.forEach((element: any, key: number) => {
        this.parametersChannelTypes.push({
          name: element.name,
          value: element.id
        });
      })
    });
  }

  async fetchReport() {
    this.loading = true
    this.reports = [];
    const {['parent_id']: _, ...filteredObject} = this.formData;
    await this.$http.get(this.$api3(`/visit-coverage-fetch${this.$toQueryString(filteredObject)}`)).then((response: any) => {
      this.reports = response.data.reports;
      this.filterData = response.data.filterData;
    }).catch((e) => {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
    }).finally(() => {
      this.loading = false
    })
  }

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  loadNode(node: any, resolve: any) {
    let territories: any[] = [];
    this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
    const {['territory_id']: _, ...filteredObject} = this.formData;
    if (this.formData.range) {
      this.$http.get(this.$api3(`/visit-coverage-fetch${this.$toQueryString(filteredObject)}`)).then((response) => {
        territories = response.data.reports;
        let treeData = territories.map((territory) => {
          const container: any = {};
          container['name'] = territory.name;
          container['total_department'] = territory.total_department;
          container['visited_department'] = territory.visited_department;
          container['not_visited_department'] = territory.not_visited_department;
          container['percentage_not_visited_department'] = territory.percentage_not_visited_department;
          container['leaf'] = !territory.children_count;
          container['id'] = territory.id;
          return container;
        });
        resolve(treeData);
        this.loading = false;
      });
    }
  }

  getValueType() {
    const foundItem = this.parametersChannelTypes.find((item: any) => item.value === this.formData.department_type_id);
    if (foundItem) {
      return foundItem.name;
    }
    if(!this.formData.department_type_id){
      return 'outlet'
    }
    return this.formData.department_type_id;
  }
  async handleaDownload(territoryId: number, type: string) {
    this.downloadingSingle = true;
    try {
      this.formData.parent_id = territoryId;
      const {['territory_id']: _, ...filteredObject} = this.formData;
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/visit-coverage-fetch${this.$toQueryString(filteredObject)}&download&status=${type}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloadingSingle = false;
    }
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/visit-coverage-fetch${this.$toQueryString(this.formData)}&downloadDetails`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }

}
