import Department from '@/intefaces/Department';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// @ts-ignore
@Component
abstract class PromotionComponent extends Vue {
    abstract products: any;

    private cartPromotions: any = [];
    private cartPromotionItems: any = [];
    public actualPromotions: any = [];
    public productPromotionalPrice: any = [];
    public freeProduct: any = [];
    public freeProductForCart: any = [];
    public promotionSuggestions: any = [];
    private freeProductPromotion: any = [];
    private checkPromoIsSame: any = [];
    public promotions: any = [];
    public department: Department | any = {
        department_type_id: 0,
        department_category_id: 0,
        department_location_type_id: 0,
        badge_id: 0
    };
    public departmentPromotions: any = [];

    get getCurrency() {
        return this.$store.getters['Settings/getCurrency'];
    }

    async fetchPromotionInitialData() {
        await this.getAllPromotions();
        this.getBuyerPromotions();
    }

    // Utility function to calculate total amount or quantity
    cartTotal(type: 'amount' | 'qty', promotion_on: string = '', promotion_able_id: number | string = ''): number {
        const getAmountOrQty = (p: any) => (type === 'amount' ? p.price * p.qty : p.qty);

        if (promotion_on === 'brand' || promotion_on === 'category') {
            return this.products.reduce((sum: number, cartItem: any) => {
                const isEligible =
                    cartItem.promotions.length > 0 &&
                    ((promotion_on === 'brand' && cartItem.base_product.product_brand_id == promotion_able_id) ||
                        (promotion_on === 'category' && cartItem.base_product.product_category_id == promotion_able_id));

                return sum + parseFloat(isEligible ? getAmountOrQty(cartItem) : 0);
            }, 0);
        }

        return this.products.reduce((sum: number, p: any) => sum + parseFloat(getAmountOrQty(p)), 0);
    }

    private async getAllPromotions() {
        let page = 1;
        let allPromotions: any = [];
        const territoryId = this.$store.getters['Auth/user'].territory_id;
        const fetchPromotions = async () => {
            const response = await this.$http.get(this.$api2(`/promotions?status=active&page=${page}&parent_territory_id=${this.department.territory_id}&start_date=${moment().format('YYYY-MM-DD HH:mm:ss')}`));
            const { promotions } = response.data;
            // check promotion is array then push to allPromotions
            if (Array.isArray(promotions)) {
                allPromotions.push(...promotions);
            }

            if (response.data.current_page !== response.data.last_page) {
                page += 1;
                await fetchPromotions();
            }
        };

        await fetchPromotions();
        this.promotions = allPromotions;
    }

    // Get promotions based on department properties
    getBuyerPromotions() {
        let currentPromotions = this.promotions;
        const { department_type_id, department_category_id, department_location_type_id, badge_id } = this.department;

        if (department_type_id === 0) {
            this.departmentPromotions = [];
            return false;
        }
        // Filter promotions based on department type
        currentPromotions = currentPromotions.filter((promotion: any) =>
            promotion.department_types.some((department: Department) => department.id === department_type_id)
        );

        // Additional filters based on other department properties
        const filters = [
            {
                field: 'department_categories',
                id: department_category_id
            },
            {
                field: 'department_location_types',
                id: department_location_type_id
            },
            {
                field: 'badges',
                id: badge_id
            }
        ];

        filters.forEach(({ field, id }) => {
            if (id) {
                currentPromotions = currentPromotions.filter((promotion: any) => {
                    if (promotion[field].length > 0) {
                        return promotion[field].some((item: any) => item.id === id);
                    } else {
                        return promotion;
                    }
                });
            }
        });
        this.departmentPromotions = currentPromotions;
    }

    getActualPromotions(product: any, promotions: any) {
        let actualPromotions: any[] = [];
        const getPromotionableId = (type: string) => {
            if (type === 'category') {
                return product.base_product.product_category_id;
            } else if (type === 'brand') {
                return product.base_product.product_brand_id;
            }
            return product.product_id;
        };
        const promotionTypes = ['product', 'brand', 'category'];

        promotionTypes.forEach(type => {
            const promotionableId = getPromotionableId(type);

            const relevantPromotions = promotions
                .filter((promotion: any) => promotion.promotion_on === type)
                .filter((promotion: any) => promotion.items.some((item: any) => item.promotion_able_id === promotionableId));

            actualPromotions = [...actualPromotions, ...relevantPromotions];
        });
        return actualPromotions;
    }

    getProductPromotion(product: any) {
        // Determine the promotion type and apply relevant filters
        let actualPromotions = this.getActualPromotions(product, this.departmentPromotions);

        // Remove duplicate promotions based on id and set the actual promotions
        this.actualPromotions = [...this.actualPromotions, ...actualPromotions].filter(
            (promotion, index, self) => index === self.findIndex(t => t.id === promotion.id)
        );

        product.promotions = actualPromotions;
        if(!product.promotion_id)
            product.promotion_id = actualPromotions.length > 0 ? actualPromotions[0].id : '';

        return product;
    }

    // Function to check and apply promotions
    applyPromotions() {
        this.cartPromotions = [];
        this.cartPromotionItems = [];
        this.freeProduct = [];
        this.productPromotionalPrice = [];
        let productNotFound = false;

        this.products.forEach((product: any, index: number) => {
            if (!product.product_id) {
                productNotFound = true;
            }

            const productPromotion = product.promotion_id
                ? this.actualPromotions.find((promotion: any) => promotion.id === product.promotion_id)
                : this.getActualPromotions(product, this.actualPromotions)[0];

            this.$set(product, 'amount', product.qty * product.price);
            this.$set(this.productPromotionalPrice, index, product.qty * product.price);
            this.$set(this.freeProduct, index, '');
            this.$set(product, 'promotion_discount', 0);

            if (!productPromotion) return false;

            this.cartPromotions.push(productPromotion);
            this.cartPromotionItems.push(product);
        });

        if (productNotFound) return false;
        if (this.cartPromotions.length === 0) return false;

        // Remove duplicate promotions based on id
        this.cartPromotions = [...new Set(this.cartPromotions.map((p: any) => p.id))].map(id =>
            this.cartPromotions.find((promotion: any) => promotion.id === id)
        );

        this.freeProductPromotion = [];
        this.cartPromotions.forEach((promotion: any) => {
            this.checkPromoIsSame = [];
            if (this.checkMissingPromotionProducts(promotion, promotion.promotion_on)) return false;
            if (!this.checkPromoIsActivated(promotion)) return false;

            const validItems: { product: any[]; brand: any[]; category: any[] } = {
                product: [],
                brand: [],
                category: []
            };

            promotion.items.map((item: any) => {
                this.checkActivatePromotion(promotion, item, validItems);
                this.applyBestOfferForPromotion(promotion, item, validItems);
            });
        });
    }

    private checkMissingPromotionProducts(productPromotion: any, promotion_on: string) {
        let productAddInCarts: any = [];
        switch (promotion_on) {
            case 'product':
                productAddInCarts = productPromotion.items.map((item: any) => ({
                    item,
                    missing: item.promotion_able_item.full_name,
                    status: this.products.some((p: any) => p.product_id === item.promotion_able_id),
                    index: this.products.findIndex((p: any) => p.product_id === item.promotion_able_id)
                }));
                break;
            case 'brand':
                productAddInCarts = productPromotion.items.map((item: any) => ({
                    item,
                    missing: item.promotion_able_item.name,
                    status: this.products.some((p: any) => p.base_product.product_brand_id === item.promotion_able_id),
                    index: this.products.findIndex((p: any) => p.base_product.product_brand_id === item.promotion_able_id)
                }));
                break;
            case 'category':
                productAddInCarts = productPromotion.items.map((item: any) => ({
                    item,
                    missing: item.promotion_able_item.name,
                    status: this.products.some((p: any) => p.base_product.product_category_id === item.promotion_able_id),
                    index: this.products.findIndex((p: any) => p.base_product.product_category_id === item.promotion_able_id)
                }));
                break;
        }
        const missingProducts = productAddInCarts.filter((item: any) => !item.status);
        const { index } = productAddInCarts.find((item: any) => item.status);
        this.promotionSuggestions.splice(index, 1);
        if (missingProducts.length > 0) {
            this.$set(
                this.promotionSuggestions,
                index,
                promotion_on === 'product'
                    ? `Add product ${missingProducts.map((item: any) => item.missing).join(', ')} to get promotion`
                    : `Add ${missingProducts.map((item: any) => item.missing).join(', ')} products to get promotion`
            );
            return true;
        } else {
            this.promotionSuggestions.splice(index, 1);
            return false;
        }
    }

    private checkPromoIsActivated(promotion: any) {
        if (promotion.items.length > 1) {
            let isPromoIsSame = false;

            const checkPromoItems = (promotionAbleId: number, productIndex: number) => {
                promotion.items
                    .filter((item: any) => item.promotion_able_id === promotionAbleId)
                    .forEach((item: any) => {
                        const promoItem = { ...item, index: productIndex };
                        this.checkPromoIsSame.push(promoItem);
                    });
            };

            this.products.forEach((product: any, index: number) => {
                if (product.promotion_id === promotion.id) {
                    switch (promotion.promotion_on) {
                        case 'product':
                            checkPromoItems(product.product_id, index);
                            break;
                        case 'brand':
                            checkPromoItems(product.base_product.product_brand_id, index);
                            break;
                        case 'category':
                            checkPromoItems(product.base_product.product_category_id, index);
                            break;
                    }
                }
            });

            this.checkPromoIsSame = Array.from(new Set(this.checkPromoIsSame.map((item: any) => item.id))).map(id =>
                this.checkPromoIsSame.find((item: any) => item.id === id)
            );

            if (this.checkPromoIsSame.length !== promotion.items.length) {
                this.$set(
                    this.promotionSuggestions,
                    this.checkPromoIsSame[0].index,
                    `Add all products with the same promotion to get the promotion: ${promotion.title}`
                );
                isPromoIsSame = true;
            }

            if (isPromoIsSame) return false;
        }

        promotion.items.map((item: any) => {
            this.products.map((product: any, index: number) => {
                if (promotion.promotion_on === 'product' && product.product_id === item.promotion_able_id) {
                    this.checkPromotionByType(promotion, item, product, index);
                } else if (promotion.promotion_on === 'brand' && product.base_product.product_brand_id === item.promotion_able_id) {
                    this.checkPromotionByType(promotion, item, product, index);
                } else if (promotion.promotion_on === 'category' && product.base_product.product_category_id === item.promotion_able_id) {
                    this.checkPromotionByType(promotion, item, product, index);
                }
            });
        });

        return true;
    }

    private checkPromotionByType(promotion: any, item: any, product: any, index: number) {
        if (promotion.promotion_type === 'amount') {
            if (product.qty == 0) {
                this.$set(this.promotionSuggestions, index, `Add minimum ${item.minimum_value} amount to get promotion`);
                return true;
            }
            const comparisonValue =
                promotion.activating_type === 'percent'
                    ? (this.cartTotal('amount', promotion.promotion_on, item.promotion_able_id) * 100) / this.cartTotal('amount')
                    : product.qty * product.price;

            // window.console.log(
            //     'Item Minimum Value: ' + comparisonValue,
            //     promotion.promotion_on + ' Amt: ' + this.cartTotal('amount', promotion.promotion_on, item.promotion_able_id),
            //     'Total Amt: ' + this.cartTotal('amount')
            // );

            if (comparisonValue < item.minimum_value) {
                this.$set(
                    this.promotionSuggestions,
                    index,
                    `Add ${item.minimum_value}${promotion.activating_type === 'percent' ? '%' : " " + this.getCurrency} or more to get promotion`
                );
            } else {
                this.$set(this.promotionSuggestions, index, '');
                return true;
            }
        } else if (promotion.promotion_type === 'qty') {
            if (product.qty == 0) {
                this.$set(this.promotionSuggestions, index, `Add minimum ${item.minimum_value} qty to get promotion`);
                return true;
            }

            const comparisonValue =
                promotion.activating_type === 'percent'
                    ? (this.cartTotal('qty', promotion.promotion_on, item.promotion_able_id) * 100) / this.cartTotal('qty')
                    : product.qty;

            // window.console.log(
            //     'compare value:' + comparisonValue,
            //     'Item Minimum Value: ' + item.minimum_value,
            //     promotion.promotion_on + ' Qty: ' + this.cartTotal('qty', promotion.promotion_on, item.promotion_able_id),
            //     'Total Qty: ' + this.cartTotal('qty')
            // );

            if (comparisonValue < item.minimum_value) {
                if (promotion.activating_type === 'percent') {
                    this.$set(this.promotionSuggestions, index, `Add ${item.minimum_value}% or more qty to get promotion`);
                } else {
                    const qty = item.minimum_value - this.cartTotal(promotion.promotion_type, promotion.promotion_on, item.promotion_able_id);
                    if (qty > 0) {
                        this.$set(this.promotionSuggestions, index, `Add ${qty} qty or more to get promotion`);
                    } else {
                        this.$set(this.promotionSuggestions, index, '');
                    }
                }
            } else {
                this.$set(this.promotionSuggestions, index, '');
                return true;
            }
        }
    }

    private checkActivatePromotion(promotion: any, item: any, validItems: any) {
        if (promotion.activating_type == 'flat') {
            this.checkFlatPromotion(promotion, item, validItems);
        } else if (promotion.activating_type == 'percent') {
            this.checkPercentPromotion(promotion, item, validItems);
        }
    }

    private checkFlatPromotion(promotion: any, item: any, validItems: any) {
        const checkConditions = (cartItem: any, key: string, value: any, type: string) => {
            if (type === 'amount') {
                return cartItem.qty * cartItem.price >= value;
            } else if (type === 'qty') {
                return cartItem.qty >= value;
            }
            return false;
        };

        const accumulateItems = (key: string, id: any) => {
            const items: any[] = [];
            let totalAmount: number = 0;
            this.cartPromotionItems.forEach((cartItem: any) => {
                if (cartItem.base_product[key] == id) {
                    items.push(cartItem);
                    totalAmount += promotion.promotion_type === 'amount' ? cartItem.qty * cartItem.price : parseInt(cartItem.qty);
                }
            });
            return { items, totalAmount };
        };

        if (promotion.promotion_on === 'product') {
            this.cartPromotionItems.forEach((cartItem: any) => {
                if (
                    cartItem.product_id == item.promotion_able_id &&
                    checkConditions(cartItem, 'product_id', item.minimum_value, promotion.promotion_type)
                ) {
                    validItems[promotion.promotion_on].push(cartItem);
                }
            });
        } else {
            const keyMap: any = {
                brand: 'product_brand_id',
                category: 'product_category_id'
            };
            const { items, totalAmount } = accumulateItems(keyMap[promotion.promotion_on], item.promotion_able_id);

            if (totalAmount >= item.minimum_value) {
                validItems[promotion.promotion_on].push(items);
            }
        }
    }

    private checkPercentPromotion(promotion: any, item: any, validItems: any) {
        const calculatePercent = (numerator: number, denominator: number) => {
            return (numerator * 100) / denominator;
        };

        const accumulateItems = (key: string, id: any, type: string) => {
            const items: any[] = [];
            let totalAmountOrQty: number = 0;
            this.cartPromotionItems.forEach((cartItem: any) => {
                if (cartItem.base_product[key] == id) {
                    items.push(cartItem);
                    totalAmountOrQty += type === 'amount' ? cartItem.qty * cartItem.price : parseInt(cartItem.qty);
                }
            });
            return { items, totalAmountOrQty };
        };

        const validateAndPushItems = (totalAmountOrQty: number, denominator: number, minimumValue: number, items: any[], key: string) => {
            if (calculatePercent(totalAmountOrQty, denominator) >= minimumValue) {
                validItems[key].push(items);
            }
        };

        if (promotion.promotion_on === 'product') {
            this.cartPromotionItems.forEach((cartItem: any) => {
                if (cartItem.product_id == item.promotion_able_id) {
                    const numerator = promotion.promotion_type === 'amount' ? cartItem.qty * cartItem.price : cartItem.qty;
                    const denominator = this.cartTotal(promotion.promotion_type);
                    if (calculatePercent(numerator, denominator) >= item.minimum_value) {
                        validItems[promotion.promotion_on].push(cartItem);
                    }
                }
            });
        } else {
            const keyMap: any = {
                brand: 'product_brand_id',
                category: 'product_category_id'
            };
            const { items, totalAmountOrQty } = accumulateItems(keyMap[promotion.promotion_on], item.promotion_able_id, promotion.promotion_type);
            const denominator = this.cartTotal(promotion.promotion_type);

            validateAndPushItems(totalAmountOrQty, denominator, item.minimum_value, items, promotion.promotion_on);
        }
    }

    private applyBestOfferForPromotion(promotion: any, item: any, validItems: any) {
        const itemsValid = validItems[promotion.promotion_on as keyof typeof validItems];
        const minValuesValid = promotion.items.length === itemsValid.length;

        if (minValuesValid && promotion.promotion_on !== 'product') {
            itemsValid.forEach((items: any) => items.forEach((singleItem: any) => validItems['product'].push(singleItem)));
        }

        if (!minValuesValid) return false;

        const { totalAmount, totalQty } = validItems['product'].reduce(
            (totals: any, product: any) => {
                totals.totalAmount += product.qty * product.price;
                totals.totalQty += parseInt(product.qty, 10);
                return totals;
            },
            { totalAmount: 0, totalQty: 0 }
        );

        const totalValue = this.calculateTotalValue(promotion, totalAmount, totalQty);

        const offerId = this.getBestOfferId(promotion, totalValue);
        if (!offerId) return false;

        const offer = promotion.offers.find((offer: any) => offer.id === offerId);

        offer.is_last_offer = promotion.offers[promotion.offers.length - 1].id === offerId;
        offer.offer_multiplier = this.offerMultiplierCount(offer, promotion);
        // window.console.log('Offer', offer);

        this.applyOfferCalculation(offer, validItems['product'], totalAmount);
    }

    private calculateTotalValue(promotion: any, totalAmount: number, totalQty: number): number {
        if (promotion.activating_type === 'flat') {
            return promotion.promotion_type === 'amount' ? totalAmount : totalQty;
        } else if (promotion.activating_type === 'percent') {
            const total = promotion.promotion_type === 'amount' ? totalAmount : totalQty;
            const cartTotal = this.cartTotal(promotion.promotion_type);
            return (total / cartTotal) * 100;
        }
        return 0;
    }

    private getBestOfferId(promotion: any, totalValue: number): any {
        const offerValueDifference = promotion.offers
            .map((offer: any) => ({
                id: offer.id,
                value: totalValue - parseFloat(offer.promotion_value)
            }))
            .filter((offer: any) => offer.value >= 0)
            .sort((a: any, b: any) => a.value - b.value);

        // window.console.log('Offer Value Difference', offerValueDifference);
        return offerValueDifference.length ? offerValueDifference[0].id : null;
    }

    private applyOfferCalculation(offer: any, validProduct: any[], totalAmount: number) {
        validProduct.forEach((product: any) => {
            let finalDiscount = this.calculateDiscount(offer, product, totalAmount);
            const productIndex = this.products.findIndex((p: any) => p.product_id === product.product_id);

            if (offer.offer_type === 'qty') {
                const freeProducts = this.calculateFreeProducts(offer, product);
                // Check if the product is already bundled with another product
                const existPromoProducts = this.freeProductPromotion.filter((p: any) => p.promotion_id === product.promotion_id);
                if (existPromoProducts.length >= 1) {
                    existPromoProducts.map((existPro: any) => {
                        this.$set(
                            this.freeProduct,
                            this.products.findIndex((p: any) => p.product_id === existPro.product_id),
                            'Product bundled with another product'
                        );
                    });
                }
                this.freeProductPromotion.push({ product_id: product.product_id, promotion_id: product.promotion_id, freeProducts });
                this.$set(this.freeProduct, productIndex, freeProducts);
            } else {
                this.freeProductForCart = this.freeProductForCart.filter((p: any) => p.free_with !== product.product_id);
            }

            this.$set(product, 'promotion_discount', finalDiscount.toFixed(2));
            this.$set(this.productPromotionalPrice, productIndex, product.qty * product.price - finalDiscount);
        });
    }

    private calculateDiscount(offer: any, product: any, totalAmount: number): number {
        let discount = 0;

        if (offer.additional_discount) {
            discount = product.qty * product.price * (offer.additional_discount / 100);
        }

        if (offer.offer_type === 'amount') {
            discount += ((offer.offer_value * (product.qty * product.price)) / totalAmount) * offer.offer_multiplier;
        } else if (offer.offer_type === 'percent') {
            discount += product.qty * product.price * (offer.offer_value / 100);
        }

        return discount;
    }

    private calculateFreeProducts(offer: any, product: any): string {
        return offer.offer_products
            .map((offerProduct: any) => {
                this.freeProductForCart.push({
                    product_id: offerProduct.offer_product.id,
                    qty: offerProduct.offer_product_qty * offer.offer_multiplier,
                    price: 0,
                    promotion_id: product.promotion_id,
                    promotion_discount: 0,
                    free_with: product.product_id,
                    is_free: offerProduct.offer_product.is_free
                });

                // remove duplicate free products
                this.freeProductForCart = this.freeProductForCart.filter(
                    (product: any, index: number, self: any) => index === self.findIndex((t: any) => t.id === product.id)
                );
                return `${offerProduct.offer_product.full_name} - ${offerProduct.offer_product_qty * offer.offer_multiplier} qty`;
            })
            .join(', ');
    }

    private offerMultiplierCount(offer: any, promotion: any) {

        // this code is working but commented because apk promotion multiplier is not implemented yet

        // if promotion is activated by multiple items and effected by last offer
        // then calculate the offer multiplier based on the last offer

        // if(promotion.activating_type && offer.is_last_offer){
        //     let finalTotal = 0;
        //     const lastOfferStrict = false;
        //     if(lastOfferStrict){
        //         let itemOfferMeets: any[] = [];
        //         promotion.items.map((item: any, key: number) => {
        //             const itemTotal = this.cartTotal(promotion.promotion_type, promotion.promotion_on, item.promotion_able_id);
        //             itemOfferMeets[key] = Math.floor(itemTotal/item.minimum_value);
        //             finalTotal += itemTotal;
        //             // window.console.log(item.minimum_value, itemTotal, itemOfferMeets[key]);
        //         });
        //         const offerMeets = Math.min(...itemOfferMeets);
        //         const offerMultiplier = Math.floor(finalTotal/offer.promotion_value);

        //         if(offerMultiplier >= offerMeets){
        //             return offerMeets;
        //         }
        //         return offerMultiplier;
        //     } else {
        //         promotion.items.map((item: any) => {
        //             finalTotal += this.cartTotal(promotion.promotion_type, promotion.promotion_on, item.promotion_able_id);
        //             // window.console.log(offer.promotion_type, promotion.promotion_on, item.promotion_able_id);
        //         });
        //         return Math.floor(finalTotal/offer.promotion_value);
        //     }
        // }

        return 1;
    }
}

@Component
//@ts-ignore
export default class PromotionMixin extends PromotionComponent {}
