














































































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import CreateWalletForm from '@/views/payment/wallet/CreateWalletForm.vue';
import PaymentMethodDetails from '@/views/payment/wallet/PaymentMethodDetails.vue';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import {
    Permission as PermissionEnum
} from '@/enums/Permission';

@Component({
    components: {
        CreateWalletForm,
        PaymentMethodDetails
    }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin) {
    public entities: any = [];
    public loading: boolean = false;
    public isActive: boolean = false;
    private queryBuilder = new QueryBuilder();
    public currentEntity: any = {};
    public isShowMethod: boolean = false;
    get permissionEnum() {
        return PermissionEnum;
    }

    created() {
        this.fetchEntity();
    }

    @Watch('pagination.currentPage')
    @Watch('queryBuilder.watch')
    async fetchEntity() {
        this.loading = true;
        let {
            data
        } = await this.$http.get(
            this.$api(`/payments${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?'}&page=${this.pagination.currentPage}&type=wallet&status=pending`)
        );
        this.entities = data.payments;
        this.setPagination(data);
        this.loading = false;
    }
}
