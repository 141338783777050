















































import { Component, Vue } from 'vue-property-decorator';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Button } from 'element-ui';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class PerformanceReport extends Vue {
  private reports: any[] = [
    {
      name: 'Daily KPI Report',
      tooltip: 'Daily KPI Report.',
      permission: PermissionEnum.PRODUCT_BASED_REPORT,
      path: 'daily-kpi-report'
    },
    {
      name: 'User-Wise Product Performance Report',
      tooltip: 'Find out how the product is performing user wise.',
      permission: PermissionEnum.PRODUCT_BASED_REPORT,
      path: 'user-wise-product-performance-report'
    },
    {
      name: 'Dept-Wise Product Performance Report',
      tooltip: 'Find out how the product is performing department wise.',
      permission: PermissionEnum.PRODUCT_BASED_REPORT,
      path: 'dept-wise-product-performance-report'
    },
    {
      name: 'No Order Report',
      tooltip: 'No order report',
      permission: PermissionEnum.PRODUCT_BASED_REPORT,
      path: 'no-order-report'
    },
    {
      name: 'Daily Call Report',
      tooltip: 'Daily Performance',
      permission: PermissionEnum.DAILY_CALL_REPORT,
      path: 'daily-call-report'
    },
    {
      name: 'Performance Board Report',
      tooltip: 'Performance Board Report',
      permission: PermissionEnum.PERFORMANCE_BOARD_REPORT,
      path: 'performance-board-report'
    },
    {
      name: 'Product Target Achievement Report',
      tooltip: 'Product Target Achievement Report',
      permission: PermissionEnum.TARGET_VS_ACHIEVEMENT_REPORT,
      path: 'product-target-achievement-report'
    },
    {
      name: 'Not Ordered Outlet List Report',
      tooltip: 'Not Ordered Outlet List Report',
      permission: PermissionEnum.NOT_ORDERED_REPORT,
      path: 'not-ordered-outlet-list-report'
    }
  ];

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}
