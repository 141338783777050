






















































































































































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import Delivery from '@/intefaces/Delivery';
import DeliveryDetails from '@/views/order/delivery/DeliveryDetails.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
// import InvoiceDetails from "@/views/order/invoice/InvoiceDetails.vue";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Table,
    TableColumn,
    Tag,
    Tooltip,
    Drawer,
    ButtonGroup,
    Button
} from 'element-ui';
import CursorPagination from '@/components/CursorPagination.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import TenantModulesMixin from '@/mixins/HasTenantModules';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import HasCursorPaginationMixin from '@/mixins/HasCursorPagination';
import DateRange from '@/components/DateRange.vue';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import moment from 'moment';
import swal from 'sweetalert2';
import printJS from 'print-js';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';

@Component({
    components: {
        GIFModal,
        CursorPagination,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Tag.name]: Tag,
        [ButtonGroup.name]: ButtonGroup,
        [Button.name]: Button,
        FilterDrawerComponent,
        DateRange,
        DeliveryDetails,
        UserLazydropdown,
        TerritoryLazydropdown,
        DepartmentLazydropdown
    }
})
export default class DeliveryList extends Mixins(HasCursorPaginationMixin, MultiSelectRow, TaxonomyMixin, TenantModulesMixin) {
    private deliveries: Delivery[] = [];
    private isActiveInvoice: boolean = false;
    private invoiceDetail: any = [];
    private isDeliver: boolean = false;
    private showGIF: boolean = false;
    private demoTourEntity: string = 'delivery';
    private showDownloadModal: boolean = false;
    private recipient: string = '';
    private queryBuilder: QueryBuilder = new QueryBuilder();
    private isFilterModelActive: boolean = false;
    private filterApplied: boolean = false;
    private selectedButton: string = 'Today';
    private generating: boolean = false;
    private GIFs: Array < any > = [{
            label: 'Create a delivery',
            url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
        },
        {
            label: 'Update a delivery',
            url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
        },
        {
            label: 'Delete a delivery',
            url: 'https://www.youtube.com/embed/LeAltgu_pbM'
        }
    ];
    private demoTourTitle: string = 'A quick demo of delivery';
    public order_type: string = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';

    public filters: any = {
        deliveryDateRange: '',
        status: 'unpaid',
        code: '',
        created_by: '',
        buyer_id: '',
        territory_id: ''
    };

    async updateDateRange(range: string) {
        this.selectedButton = range;
        if (range === 'Today') {
            await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
        }
        if (range === 'WTD') {
            await this.queryBuilder.setParam(
                'created_between',
                moment()
                .day(-1)
                .format('YYYY-MM-DD') +
                ',' +
                moment().format('YYYY-MM-DD')
            );
        }
        if (range === 'MTD') {
            await this.queryBuilder.setParam(
                'created_between',
                moment()
                .startOf('month')
                .format('YYYY-MM-DD') +
                ',' +
                moment().format('YYYY-MM-DD')
            );
        }
        if (range === 'QTD') {
            await this.queryBuilder.setParam(
                'created_between',
                moment()
                .startOf('quarter')
                .format('YYYY-MM-DD') +
                ',' +
                moment().format('YYYY-MM-DD')
            );
        }
        if (range === 'YTD') {
            await this.queryBuilder.setParam(
                'created_between',
                moment()
                .startOf('year')
                .format('YYYY-MM-DD') +
                ',' +
                moment().format('YYYY-MM-DD')
            );
        }
        this.$nextTick(function () {
            this.applyFilter();
        });
    }
    closeBtn() {
        this.isFilterModelActive = false;
        this.applyFilter();
    }

    async mounted() {
        await this.queryBuilder.setParam('created_between', moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD'));
        // await this.queryBuilder.setParam('status', 'unpaid');
        this.$nextTick(function () {
            this.applyFilter();
        });
        this.checkTenantModule('order_include_lot,order_transportation');
    }

    @Watch('filters.deliveryDateRange', {
        deep: true
    })
    onChangeSubmissionDateRange(value: string) {
        this.queryBuilder.addListener('created_between', value);
    }

    @Watch('filters.code', {
        deep: true
    })
    onChangeOrderCode(value: string) {
        this.queryBuilder.addListener('code', value);
    }

    @Watch('filters.created_by', {
        deep: true
    })
    onChangeCreatedBy(value: string) {
        this.queryBuilder.addListener('created_by', value);
    }

    @Watch('filters.buyer_id', {
        deep: true
    })
    onChangeBuyerId(value: string) {
        this.queryBuilder.addListener('buyer_id', value);
    }

    @Watch('filters.territory_id', {
        deep: true
    })
    onChangeTerritoryId(value: string) {
        this.queryBuilder.addListener('territory_id', value);
    }

    @Watch('currentCursor')
    async fetchDelivery(cursor: string | null) {
        const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
        this.loading = true;

        let queryFilters = this.queryBuilder.getFilters(true);
        queryFilters += cursor ? `&cursor=${cursor}` : '';
        queryFilters += `&latest_status=ontheway&with=payments,products.product&order_type=${order_type}`;

        try {
            let {
                data
            } = await this.$http.get(
                this.$api(`/deliveries${queryFilters}`)
            );
            this.setPagination(data);
            this.deliveries = data.data;
            // eslint-disable-next-line no-console
            // console.log('testttt', this.deliveries);
        } catch (e) {
            this.$notify({
                title: 'Something Went Wrong!',
                message: 'Please Try again.',
                type: 'error'
            });
        } finally {
            this.loading = false;
        }
    }

    async downloadDeliveryList(status: string) {
        this.loading = true;
        await this.queryBuilder.setParam('recipient', this.recipient);
        await this.queryBuilder.setParam('type', 'csv');
        if (!this.allItemSelected) {
            await this.queryBuilder.setParam(
                'id',
                this.selectedRows.map(order => order.id)
            );
        }
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api(`/download/bulk-delivery${this.queryBuilder.getFilters(true)}`));
            await swal.fire('Delivery List Sent', 'You will receive the report within a few minutes.', 'success');
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.'
            });
        } finally {
            this.loading = false;
            this.showDownloadModal = false;
            await this.queryBuilder.setParam('recipient', null);
            await this.queryBuilder.setParam('type', null);
            await this.queryBuilder.setParam('id', null);
            this.recipient = '';
        }
    }

    invoiceDetails(row: any) {
        this.isActiveInvoice = true;
        this.invoiceDetail = row;
        this.isDeliver = true;
        // eslint-disable-next-line no-console
        // console.log(this.invoiceDetail);
    }

    applyFilter() {
        this.filterApplied = true;
        this.fetchDelivery(null);
    }

    resetBtn() {
        this.filters.deliveryDateRange = '';
        this.filters.status = 'unpaid';
        this.fetchDelivery(null);
    }

    async ordersObjectUrl(pos = false) {
        let {
            data
        } = await this.$http.post(
            this.$api(`/pdf/deliveries${this.queryBuilder.getFilters() ? '?' + this.queryBuilder.getFilters() : ''}`), {
                delivery_id: this.allItemSelected ? null : this.selectedRows.map(value => value.id).join(',')
            }, {
                responseType: 'blob'
            }
        );
        const blob = await new Blob([data], {
            type: 'application/pdf'
        });
        let fileURL = await URL.createObjectURL(blob);
        return fileURL;
    }
    async printDelivery() {
        this.generating = true;
        try {
            await printJS({
                printable: await this.ordersObjectUrl()
            });
        } catch (e) {
            this.$notify({
                title: 'Something Wrong!',
                message: 'Please Try again.',
                type: 'error'
            });
        } finally {
            this.generating = false;
        }
    }
}
