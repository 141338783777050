































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PriorityProductView'
})
export default class PriorityProductView extends Vue {
  @Prop() isView!: boolean;
  @Prop() viewPriorityProduct!: any;

  close() {
    this.$emit('update:isView', false);
  }
}
