import { RouteConfig } from "vue-router";
// @ts-ignore
import Payment from "@/views/payment/Payment.vue";
import { Permission } from "@/enums/Permission";
// @ts-ignore
import PaymentList from "@/views/payment/InvoiceList.vue";
// @ts-ignore
import AdvancePaymentList from "@/views/payment/AdvancePaymentList.vue";
// @ts-ignore
import InvoiceList from "@/views/payment/InvoiceList.vue";
// @ts-ignore
import InvoicePayments from "@/views/payment/InvoicePayments.vue";
// @ts-ignore
import InvoicePage from "@/views/payment/InvoicePage.vue";
// @ts-ignore
import DepartmentWallet from "@/views/payment/wallet/wallet.vue";
// @ts-ignore
import PendingWallet from "@/views/payment/wallet/pendingWallet.vue";
import { PermissionHelper } from "@/classes/PermissionHelper";

// Redirect to user's authorized (has permission) tab
function redirectToUserAuthorizedRoute() {
    return '/payment/' + PermissionHelper([
        Permission.VIEW_PAYMENT,
        Permission.VIEW_ADVANCE_PAYMENT,
        Permission.CREATE_DEPARTMENT_WALLET,
    ], [{ permission: Permission.VIEW_PAYMENT, name: 'payments' },
        { permission: Permission.VIEW_ADVANCE_PAYMENT, name: 'advance-payments' },
        { permission: Permission.CREATE_DEPARTMENT_WALLET, name: 'wallet' },
    ]);
}

const PaymentRoutes: RouteConfig = {
    path: 'payment',
    components: {dashboard: Payment},
    name: 'payment',
    redirect: to => {
        return redirectToUserAuthorizedRoute();
    },
    children: [
        {
            path: '/payment/payments',
            name: 'payments',
            meta: {
                label: 'Payment',
                icon: 'fa fa-money-check-alt',
                permission: Permission.VIEW_PAYMENT,
                breadCrumb: 'payment'
            },
            component: InvoicePage,
            children: [
                {
                    path: '',
                    name: 'payments_invoices',
                    components:{invoicePage: InvoiceList},

                },
                {
                    path: 'invoice-id/payments',
                    name: 'invoice-payments',
                    components:{invoicePage: InvoicePayments},
                    props: {invoicePage: true}

                }
            ]
        },
        // {
        //     path: '/payment/advance-payments',
        //     name: 'advance-payments',
        //     meta: {
        //         label: 'Advance Payment',
        //         icon: 'fa fa-money-bill-wave',
        //         permission: Permission.VIEW_ADVANCE_PAYMENT,
        //         breadCrumb: 'advance-payment'
        //     },
        //     component: AdvancePaymentList
        // },
        {
            path: '/payment/wallet',
            name: 'wallet',
            meta: {
                label: 'Wallet',
                icon: 'fas fa-wallet',
                permission: Permission.CREATE_DEPARTMENT_WALLET,
                breadCrumb: 'wallet'
            },
            component: DepartmentWallet
        },
        {
            path: '/payment/wallet/pending',
            name: 'pendingWallet',
            meta: {
                label: 'Pending Wallet',
                icon: 'fas fa-wallet',
                permission: Permission.UPDATE_DEPARTMENT_WALLET,
                breadCrumb: 'pending wallet'
            },
            component: PendingWallet
        },
    ]
};
export default PaymentRoutes;
