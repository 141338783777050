














































































































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import PaymentMethodDetails from '@/views/payment/wallet/PaymentMethodDetails.vue';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import {
    Permission as PermissionEnum
} from '@/enums/Permission';

@Component({
    components: {
        PaymentMethodDetails
    }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin, MultiSelectRow) {
    public entities: any = [];
    public currentEntity: any = {};
    public loading: boolean = false;
    public statusLoading: boolean = false;
    public isShowMethod: boolean = false;
    public dialogVisible: boolean = false;
    public multipleSelection: any = '';
    private queryBuilder = new QueryBuilder();
    get permissionEnum() {
        return PermissionEnum;
    }
    public bulkForm = {
        comment: ''
    };
    created() {
        this.fetchEntity();
    }

    @Watch('pagination.currentPage')
    @Watch('queryBuilder.watch')
    async fetchEntity() {
        this.loading = true;
        let {
            data
        } = await this.$http.get(
            this.$api(`/payments${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?'}&page=${this.pagination.currentPage}&type=wallet&status=pending`)
        );
        this.entities = data.payments;
        this.setPagination(data);
        this.loading = false;
    }
    submitBulkAction(status: string) {
        this.statusLoading = true;
        this.$http.post(this.$api3(`/payment-status-update`),
            this.allItemSelected ?
                {
                    payment_ids: [],
                    status: status,
                    comment: this.bulkForm.comment
                } :
                {
                    payment_ids: this.selectedRows.map(payment => payment.id),
                    status: status,
                    comment: this.bulkForm.comment
                }
        )
            .then(response => {
                this.$notify({
                    title: 'Successful!',
                    message: 'Status update successfully.'
                })
                this.selectedRows = [];
                this.bulkForm.comment = '';
                this.pagination.currentPage = 1;
                this.fetchEntity();
            })
            .catch(error => {
                this.$notify({
                    title: 'Something went wrong.',
                    message: 'Unable to update status.',
                    type: "error"
                })
            })
            .finally(() => {
                this.statusLoading = false;
                this.dialogVisible = false;
            });
    }
}
