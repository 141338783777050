












































import {
    Vue,
    Component,
    Prop
} from 'vue-property-decorator';
import Statuses from "@/intefaces/Statuses";
import TransportationDetails from '@/views/order/Components/TransportationDetails.vue';
import {
    Button,
    Popover,
    Table,
    TableColumn,
    Tooltip,
} from "element-ui";

@Component({
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Popover.name]: Popover,
        [Button.name]: Button,
        TransportationDetails
    }
})
export default class BaseOrderComment extends Vue {
    @Prop() commentFromSr!: string;
    @Prop() remarks!: Statuses;
    @Prop() commentor!: string;
    @Prop() orderTransportEnable!: boolean;
    @Prop() orderInfo!: any;;
}
