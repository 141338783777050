














































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Announcement from "@/intefaces/Announcement";
import Permission from "@/intefaces/Permission";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import RoleLazydropdown from "@/components/lazyDropdown/RoleLazydropdown.vue";
import DateRange from "@/components/DateRange.vue";
//@ts-ignore
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";

@Component({
    name: "CreateAnnouncement",

    components: {
        DateRange,
        SingleImageUploader,
        UserLazydropdown,
        RoleLazydropdown,
        TerritoryCascaderDropdown,
    },
})
export default class CreateAnnouncement extends Vue {
    @Prop() newAnnouncement!: boolean;
    @Prop() isUpdate!: boolean;
    private announcement: Announcement[] = [];
    @Prop() isForSetupWizard!: boolean;
    @Prop() currentEntity!: any;
    @Prop() currentAnnouncement!: any;
    @Prop({
        default: () => ({
            title: "",
            body: "",
            type: "",
            range: "",
            image: "",
            selectedTerritories: [],
            selectedUsers: [],
            selectedRoles: [],
        }),
    })
    form!: {
        title: string;
        body: string;
        type: string;
        range: string;
        image: any;
        selectedTerritories: number[];
        selectedUsers: number[];
        selectedRoles: number[];
    };
    private is_remove_permission = false;
    private permissions: Permission[] = [];
    private disableBtn = false;
    private isActivePermission: boolean = false;

    createAnnouncement() {
        this.isUpdate = false;
        this.disableBtn = true;
        const formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("body", this.form.body);
        formData.append("type", this.form.type);
        formData.append("range", this.form.range);
        formData.append("image", this.form.image);
        formData.append(
            "selectedUsers",
            JSON.stringify(this.form.selectedUsers),
        );
        formData.append(
            "selectedRoles",
            JSON.stringify(this.form.selectedRoles),
        );
        formData.append(
            "selectedTerritories",
            JSON.stringify(this.form.selectedTerritories),
        );
        this.$http
            .post(this.$api("/announcements"), formData)
            .then((response) => {
                this.$notify({
                    title: "Success",
                    message: "Successfully Created",
                    duration: 5000,
                    iconClass: "ni ni-bell-55",
                    type: "success",
                });
                this.$emit("created", response.data.announcement);
                this.formReset();
                this.disableBtn = false;
            })
            .catch((error) => {
                this.disableBtn = false;
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            });
    }

    updateAnnouncement() {
        this.disableBtn = true;
        const formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("body", this.form.body);
        formData.append("type", this.form.type);
        formData.append("range", this.form.range);
        if (this.form.image) {
            formData.append("image", this.form.image);
        }
        formData.append(
            "selectedUsers",
            JSON.stringify(this.form.selectedUsers),
        );
        formData.append(
            "selectedRoles",
            JSON.stringify(this.form.selectedRoles),
        );
        formData.append(
            "selectedTerritories",
            JSON.stringify(this.form.selectedTerritories),
        );
        formData.append("_method", "patch");

        this.$http
            .post(
                this.$api(`/announcements/${this.currentEntity.id}`),
                formData,
            )
            .then((response) => {
                this.$emit("updated", response.data.announcement);
                this.$notify({
                    title: "Success",
                    message: "Successfully Updated",
                    duration: 3000,
                    iconClass: "ni ni-bell-55",
                    type: "success",
                });
                this.formReset();
            })
            .catch((error) => {
                this.disableBtn = false;
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            });
    }

    save() {
        this.isUpdate ? this.updateAnnouncement() : this.createAnnouncement();
    }

    formReset(): void {
        this.form.selectedTerritories = [];
        this.form.selectedUsers = [];
        this.form.selectedRoles = [];
        this.form.title = "";
        this.form.body = "";
        this.form.type = "";
        this.form.range = "";
        this.form.image = "";
    }

    close() {
        this.disableBtn = false;
        this.$emit("closed");
        this.$emit("update:isActive", false);
        this.formReset();
        this.$emit("update:isUpdate", true);
        this.disableBtn = false;
    }
    get selectedTerritoryItemsLabel(): string {
    if (!this.isUpdate) {
      return "Select Territory";
    }
    if (!this.currentEntity.territories || this.currentEntity.territories.length === 0) {
      return 'Select Territory';
    }
    return 'Selected Territory : ' + this.currentEntity.territories.map((item:any) => item.name).join(', ');
  }
}
