




























import {
    Vue,
    Component,
    Prop,
    Mixins,
    Watch
} from 'vue-property-decorator';
import BaseCUModalComponent from '@/mixins/BaseCUModalComponent';
import {
    AxiosResponse
} from 'axios';
import {
    Button
} from "element-ui";
import SMSPackageAssign from "@/intefaces/SMSPackageAssign";
import LazyDropdown from "@/components/LazyDropdown.vue";

@Component({
    components: {
        ElButton: Button,
        LazyDropdown,
    }
})
export default class SMSPackageAssignForm extends Mixins(BaseCUModalComponent) {
    @Prop({
        type: Object,
        default: {}
    })
    currentEntity!: SMSPackageAssign;
    @Prop({
        type: Object,
        default: {}
    })
    form!: SMSPackageAssign;

    formReset(): any {
        this.form.sms_package_id = null;
        this.form.org_id = null;
        this.form.remarks = null;
        return this.form;
    }

    async afterCreate(response: AxiosResponse < any > ): Promise < void > {
        this.$emit('created', response.data.package_assign);
    }

    afterUpdate(response: AxiosResponse < any > ): void {
        this.$emit('updated', response.data.package_assign)
    }

    landlordCheck(): boolean {
        return true;
    }

    get createApi(): string {
        return '/package_assign';
    }

    get updateApi(): string {
        return `/package_assign/${this.currentEntity.id}`;
    }

    created() {
        if (!this.isUpdate) {
            this.formReset();
        }
    }
}
