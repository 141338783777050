






































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import moment from "moment";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import DateRange from "@/components/DateRange.vue";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import TerritoryTypeAutocomplete from "@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue";
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import LazyDropdown from "@/components/LazyDropdown.vue";
import RemarkLazydropdown from "@/components/lazyDropdown/RemarkLazydropdown.vue";


const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    LazyDropdown,
    TerritoryCascaderDropdown,
    TerritoryTypeAutocomplete,
    DepartmentLazydropdown,
    TerritoryLazydropdown,
    UserLazydropdown,
    flatPicker,
    DateRange,
    RemarkLazydropdown
  }
})
export default class VisitReport extends mixins(PaginationComponentMixin) {
  public downloading: boolean = false;
  public loading: boolean = false;
  public visitReport = [];
  public total_users = '';
  public avgWorkHour: any = 0;
  public total_planned_visits: any = 0;
  public total_unplanned_visits: any = 0;
  public firstCheckinTime: any = 0;
  public lastCheckoutTime: any = 0;
  public avgFaceTime: any = 0;

  public formData = {
    range: '',
    user_id: '',
    territory_id: null,
    department_id: '',
    remark: '',
  };

  async downloadVisitReport() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(
        this.$api('/visit-report' + this.$toQueryString(this.formData) + '&download&all'
        ), {
          responseType:
            'arraybuffer'
        });
      const blob = await new Blob([data], {type: 'application/csv'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
    }
  }

  timeSpent(checkOutTime: string, checkInTime: string) {
    return moment(checkOutTime).diff(moment(checkInTime), 'minutes')
  }

  timeOnly(timeString: string) {
    if(timeString){
    return moment(timeString).format('LT')
    }
  }
  @Watch('pagination.currentPage', { deep: true })
  async getVisitReport() {
    if (!this.formData.range) {
      this.visitReport = [];
      return
    }
    this.loading = true;
    try {
      let {data} = await this.$http.get(this.$api(`/visit-report${this.$toQueryString(this.formData)}&page=${this.pagination.currentPage}` + '&all'))
      '&all'
      this.visitReport = data.visitReport.data;
      this.setPagination(data.visitReport);
            if (this.visitReport.length > 0) {
          this.total_users = data.headerData.total_users;
          this.avgWorkHour = parseFloat(data.headerData.average_work_hour.toFixed(2));
          this.avgFaceTime = parseFloat(data.headerData.avg_department_face_time.toFixed(2));
          this.total_planned_visits = data.headerData.total_planned_visits;
          this.total_unplanned_visits = data.headerData.total_unplanned_visits;
          this.firstCheckinTime = data.headerData.firstCheckinTime;
          this.lastCheckoutTime = data.headerData.lastCheckoutTime;
      } else {
          // Reset or clear the values
          this.total_users = '';
          this.avgWorkHour = 0;
          this.avgFaceTime = 0;
          this.total_planned_visits = 0;
          this.total_unplanned_visits = 0;
          this.firstCheckinTime = null;
          this.lastCheckoutTime = null;
      }

    } catch (e) {
      this.$notify({message: "Please contact your service provider.", title: "Something went wrong!", type: 'warning'})
    } finally {
      this.loading = false;
    }
  }
}
