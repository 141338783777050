

























































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PriorityProductReportDetails'
})
export default class PriorityProductReportDetails extends Vue {
  @Prop() isView!: boolean;
  @Prop() viewPriorityProduct!: any;
  @Prop() filterData!: any;

  private loading: boolean = true;
  private fetchedEntity: any;
  private valueTypeName: string = "";


  close() {
    this.$emit('update:isView', false);
  }

  fetchEntity(): void {
    this.loading = true;
    this.$http
      .get(this.$api3(`/priority-product-report-details${this.$toQueryString(this.filterData)}`))
      .then(response => {
        this.fetchedEntity = response.data.reports;
        this.valueTypeName = response.data.valueTypeName;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    this.fetchEntity();
  }

}
