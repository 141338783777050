












































import { Component, Vue } from 'vue-property-decorator';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Button } from 'element-ui';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class CheckinSummaryReport extends Vue {
  public reports: any[] = [
    // {
    //   label: 'Checkin Summary',
    //   tooltip: 'Checkin Summary Report.',
    //   permission: PermissionEnum.VIEW_REPORT,
    //   name: 'checkin-summary'
    // },
    {
      label: 'Checkin Summary Details',
      tooltip: 'Checkin Summary Details Report.',
      permission: PermissionEnum.CHECKIN_SUMMARY_DETAILS_REPORT,
      name: 'checkin-summary-details'
    },
  ];
}
