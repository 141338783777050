












































































































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryLazydropdown from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import swal from 'sweetalert2';
import moment, { now } from "moment";
import {
  DatePicker,
  Select
} from 'element-ui';
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";

@Component({
  components: {
    ChannelLazydropdown,
    TerritoryCascaderDropdown,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    UserLazydropdown,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    DateRange,
    DepartmentLazydropdown,
    BaseProductLazydropdown
  },
})
export default class StockReportDetailsReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public downloadingDetails: boolean = false;
  public formData: any = {};
  public selectedTerritoriesName = '';
  public loading: boolean = false;
  public reports = [];
  public table_fields = [];
  public maxDateRange = 30;
  public filter_data = {};
  public openingPickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };

  public closingPickerOptions = {
    disabledDate: (time: any) => {
      const startDate = moment(this.formData.opening_date);
      const nowDate = moment(now());
      const daysDiff = nowDate.diff(startDate, 'days');

      if (daysDiff <= this.maxDateRange) {
        return time.getTime() > Date.now();
      } else {
        const endDate = moment(this.formData.opening_date).add(this.maxDateRange, 'days');
        return time.getTime() > endDate;
      }
    },
  };
  public parametersValueTypes: any = [{
    'name': 'Amount',
    'value': 'amount'
  }];

  public onClickGenerate() {
    if (!this.formData.opening_date && !this.formData.closing_date && !this.formData.dt && !this.formData.value_type && !this.formData.territory_id) {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!'
      });
    } else {
      this.fetchReport();
    }
  }

  sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  async fetchReport() {
    this.loading = true;
    this.reports = [];
    this.$http.get(this.$api3(`/stock-details-report${this.$toQueryString(this.formData)}&type=summary`))
      .then(response => {
      this.reports = response.data.reports.map((report: {
        value_type: number;
        opening_stock_value_type_amount: number;
        current_stock_value_type_amount: number;
        secondary_sales_value_type_amount: number;
        primary_lifting_value_type_amount: number;
        opening_stock: number;
        current_stock: number;
        secondary_sales: number;
        primary_lifting: number;
        base_product_id: number;
        product_sku: string;
        product_name: string;
      }) => {
        return ({
          opening_stock_value_type_amount: report.opening_stock * report.value_type,
          current_stock_value_type_amount: report.current_stock * report.value_type,
          secondary_sales_value_type_amount: report.secondary_sales * report.value_type,
          primary_lifting_value_type_amount: report.primary_lifting * report.value_type,
          base_product_id: report.base_product_id,
          product_sku: report.product_sku,
          product_name: report.product_name,
          current_stock: report.current_stock,
          opening_stock: report.opening_stock,
          product_price: report.value_type,
          secondary_sales: report.secondary_sales,
          primary_lifting: report.primary_lifting,
        });
      });
      this.table_fields = response.data.table_fields;
      this.filter_data = response.data.filter_data;
      if (this.reports.length === 0) {
        this.$notify({
          type: 'warning',
          message: 'There is no data in selected filters',
          title: 'No data found!',
        });
      }
    })
      .catch((error) => {
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  async onClickDownload(type: string) {
    if (type === 'summary') {
      this.downloading = true;
    } else {
      this.downloadingDetails = true;
    }
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/stock-details-report${this.$toQueryString(this.formData)}&type=${type}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      if (type === 'summary') {
        this.downloading = false;
      } else {
        this.downloadingDetails = false;
      }
    }
  }

  created() {
    this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
      const units = this.sortItems(response.data.units);
      units.forEach((element: any, key: number) => {
        this.parametersValueTypes.push({
          name: element.name,
          value: 'unit' + (key + 1)
        });
      })
    });
  }
}
