






































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Divider } from "element-ui";
import OrderRevision from "@/intefaces/OrderRevision";

@Component({
  components: {
    [Divider.name]: Divider,
  }
})

export default class BaseOrderProducts extends Vue {
  @Prop() currentProductEntity!: any;
  @Prop() returnProductEntity!: any;
  @Prop() title!: string;
  @Prop() orderIncludeLot!: boolean;

  get filteredProducts() {
    return this.currentProductEntity.filter((product:any) => !product.free_with);
  }

  getFreeProductName(orderedProductId:number) {
    const freeProduct = this.currentProductEntity.find((product:any) => product.free_with === orderedProductId);
    return freeProduct ? freeProduct.product.full_name : null;
  }

  getFreeProductQty(orderedProductId:number) {
    const freeProduct = this.currentProductEntity.find((product:any) => product.free_with === orderedProductId);
    return freeProduct ? freeProduct.qty : null;
  }

}

