// @ts-ignore
import CouponList from '@/views/admin/coupon/CouponList.vue';
import { RouteConfig } from 'vue-router';
// @ts-ignore
import Coupon from "@/views/admin/coupon/Coupon.vue";
// @ts-ignore
const CouponRoutes: RouteConfig = {
    path: 'coupon',
    name: 'coupon',
    redirect: '/coupon/coupons',
    components: { dashboard: Coupon },
    children: [

        {
            path: 'coupons',
            name: 'Coupons',
            // @ts-ignore
            label: 'Coupon',
            icon: 'fas fa-receipt',
            component: CouponList
        },
    ]
};

export default CouponRoutes;
