



















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FeatureLazydropdown from '@/components/lazyDropdown/FeatureLazydropdown.vue';
import { AxiosResponse } from "axios";

@Component({
  components: {
    FeatureLazydropdown,
  },
})
export default class CreatePackageForm extends Vue {
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() currentPackage!: any;

  @Prop({
    default: () => ({
      name: '',
      schemes: [
        {
          cycle: 0,
          price: 0,
          newScheme: 0, //newScheme = 0, existing scheme, newScheme = 1, new scheme
        },
      ],
      trialDays: 0,
      status: true,
      features: [],
    }),
  })
  form!: {
    name: string;
    schemes: any;
    trialDays: number;
    status: boolean;
    price: number;
    features: any;
  };
  private disableBtn = false;
  private loading = false;
  formReset(): any {
    this.form.name = '';
    this.form.status = true;
    (this.form.schemes = [
      {
        cycle: 0,
        price: 0,
        newScheme: 0,
      },
    ]),
      (this.form.trialDays = 0);
    this.form.features = [];
    return this.form;
  }
  addNewScheme() {
    this.form.schemes.push({
      cycle: 0,
      price: 0,
      newScheme: 0,
    });
  }
  deleteScheme(index: number) {
    this.form.schemes.splice(index, 1);
  }

  createPackage() {
    this.disableBtn = true;
    this.loading = true;
    // this.isUpdate = false;
    this.$http
      .post(this.$landLordApi('/packages'), this.form)
      .then((response) => {
        this.disableBtn = false;
        this.loading = false;
        this.$emit('created', response.data.package);
        this.formReset();
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        // this.close();
        this.$nextTick(() => {
          (this.$refs.formValidator as HTMLFormElement).reset();
        });
      })
      .catch((error) => {
        this.disableBtn = false;
        this.loading = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  updatePackage() {
    this.disableBtn = true;
    this.loading = true;
    this.$http
      .patch(this.$landLordApi(`/packages/${this.currentPackage.id}`), this.form)
      .then((response) => {
        this.disableBtn = false;
        this.loading = false;
        // this.isUpdate = false;
        this.$emit('updated', response.data.package);
        this.formReset();
        this.$notify({
          title: 'Success',
          message: 'Successfully Updated',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.$nextTick(() => {
          (this.$refs.formValidator as HTMLFormElement).reset();
        });

      })
      .catch((error) => {
        this.disableBtn = false;
        this.loading = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }


  save() {
    this.form.status ? this.form.status : false;
    this.isUpdate ? this.updatePackage() : this.createPackage();
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:form', {
      name: '',
      status: true,
      trialDays: 0,
      features: [],
      schemes: [
        {
          cycle: 0,
          price: 0,
        },
      ],
    });
    this.disableBtn = false;
    this.loading = false;
  }
}
