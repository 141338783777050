









































































































































































import {Component, Watch} from 'vue-property-decorator';
import { QueryBuilder } from "@/classes/QueryBuilder";
import { mixins } from 'vue-class-component';
import moment from "moment";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import printJS from "print-js";
import DateRange from "@/components/DateRange.vue";
import {Permission as PermissionEnum} from "@/enums/Permission";
import TerritoryLazydropdown from "@/components/lazyDropdown/TerritoryLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import TerritoryTypeAutocomplete from "@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue";
import swal from 'sweetalert2';
import PaginationComponentMixin from '@/mixins/PaginationComponent';

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    TerritoryTypeAutocomplete,
    DepartmentLazydropdown,
    TerritoryLazydropdown,
    UserLazydropdown,
    flatPicker,
    DateRange
  }
})
export default class CheckInReport extends  mixins(PaginationComponentMixin) {
  public downloading: boolean = false;
  public loading: boolean = false;
  public printing: boolean = false;
  private showModal: boolean = false;
  public user_id: number | null = null;
  public department_id: number | null = null;
  public territory_id: number | null = null;
  public territory_type_id: number | null = null;
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public checkInReport = [];
  public range: string = '';
  public showDownloadModal: boolean = false;
  public recipient: string = '';
  public rangeSelectConfig = {
    allowInput: true,
    disable: [
      function (range: Date) {
        return moment().diff(range) < 0;
      }
    ]
  }

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
  async downloadCheckInReport(status: string) {
    this.loading = true;
    try {
      let { data, headers } = await this.$http.get(
        this.$api('/supervisor-checkin-queue-report' + this.queryBuilder.getFilters(true) + '&all' + '&' + 'recipient=' + this.recipient)
      );
      await swal.fire('Thanks For Entering Your Mail', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({ title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.' });
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      this.recipient = '';
    }
  }

  timeSpent(checkOutTime: string, checkInTime: string) {
    // return moment(moment(checkOutTime, 'hh:mm:ss a').diff(moment(checkInTime, 'hh:mm:ss a')), 'hh:mm:ss a');
    return moment(checkOutTime).diff(moment(checkInTime), 'minutes')
  }
  @Watch('pagination.currentPage')
  onChangePage(newVal: any) {
    this.queryBuilder.addListener('page', newVal);
  }

  @Watch('queryBuilder.watch')
  async getCheckInReport() {
    if (!this.range) {
      this.checkInReport = [];
      return
    }
    this.loading = true;
    try {
      let {data} = await this.$http.get(this.$api(`/supervisor-checkin-report${this.queryBuilder.getFilters(true)}&page=${this.pagination.currentPage}`+ '&all'))
      '&all'
      this.checkInReport = data.checkinReport;
      this.setPagination(data);
    } catch (e) {
      this.$notify({message: "Please contact your service provider.", title: "Something went wrong!", type: 'warning'})
    } finally {
      this.loading = false;
    }
  }

  @Watch('territory_type_id')
  updateTerritory(){
    this.territory_id = null;
  }


  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api('/checkin-report?download&' + this.queryBuilder.getFilters() + '&all'),
        {
          responseType:
            'arraybuffer'
        });
      const blob = await new Blob([data], {type: 'application/csv'});
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.',})
    } finally {
      this.downloading = false;
    }
  }

  async reportObjectUrl() {
    let {data} = await this.$http.get(
      this.$api('/checkin-report?download&' + this.queryBuilder.getFilters() + '&all'),
      {
        responseType: 'blob'
      }
    );
    const blob = await new Blob([data], {type: 'application/csv'});
    let fileURL = await URL.createObjectURL(blob);
    return fileURL;
  }

  async onClickPrint() {
    this.printing = true;
    try {
      let {data} = await this.$http.get(this.$api('/checkin-report?' + this.queryBuilder.getFilters() + '&all'));
      let checkInReport = data.checkinReport.map((checkIn: any) => {
        checkIn.isPlanned = checkIn.route_id ? "Yes" : "No";
        checkIn.departmentAddress = checkIn.department.address ? checkIn.department.address : "-";
        checkIn.checkOutTime = "-";
        checkIn.checkInTime = moment(checkIn.created_at).format('lll')
        checkIn.remarks = "-";
        return checkIn;
      });
      await printJS({
        printable: checkInReport,
        properties: [
          {field: 'user.code', displayName: 'Employee code'},
          {field: 'user.name', displayName: 'Employee Name'},
          {field: 'route.name', displayName: 'Route'},
          {field: 'isPlanned', displayName: 'Planned Visit'},
          {field: 'department.name', displayName: 'Department Name'},
          {field: 'departmentAddress', displayName: 'Address'},
          {field: 'checkInTime', displayName: 'Check-In Time'},
          {field: 'checkOutTime', displayName: 'Check-Out Time'},
          {field: 'remarks', displayName: 'Remarks'},
        ],
        type: 'json',
        header: 'Check-In Report'
      })
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'warning'});
    } finally {
      this.printing = false;
    }
  }

  @Watch('user_id')
  onChangeUser(value: number) {
    this.queryBuilder.addListener('user_id', value);
  }

  @Watch('department_id')
  onChangeDepartment(value: number) {
    this.queryBuilder.addListener('department_id', value);
  }

  @Watch('territory_id')
  onChangeTerritory(value: number) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  close() {
    this.showModal = false;
  }

  get permissionEnum() {
    return PermissionEnum;
  }
}
