







































































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import swal from 'sweetalert2';
import moment from "moment";
import {
    Radio,
    RadioGroup,
} from 'element-ui';

@Component({
    components: {
        ProductBrandLazydropdown,
        UserLazydropdown,
        DateRange,
        ElRadio: Radio,
        ElRadioGroup: RadioGroup,
        TerritoryCascaderDropdown
    }
})
export default class ECO extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public formData: any = { basedOn: 'order' };
    public selectedTerritoriesName = '';
    public selectedTerritoriesUsers = '';
    public totalOutletsCount: any = 0;
    public totalBilledOutlet: any = 0;
    public totalNotBilledOutlet: any = 0;
    public loading: boolean = false;
    public outletReport = [];
    public selectedBrands: any = '';
    public maxDate: string = moment().subtract(1, "days").format('YYYY-MM-DD');
    public basedOnOptions: any = [{
        value: 'order',
        label: 'Order',
    },
    {
        value: 'delivery',
        label: 'Delivery',
    },
    ];
    public onClickGenerate() {
        if (this.formData.range == null || this.formData.territory_id == null || this.formData.basedOn == null) {
            this.$notify({
                type: 'warning',
                message: 'Select the required field',
                title: 'Required!'
            });
        } else {
            this.fetchEcoReport();
        }
    }

    async fetchEcoReport() {
        try {
            this.loading = true
            this.outletReport = [];
            let {
                data
            } = await this.$http.get(this.$api3(`/eco-report${this.$toQueryString(this.formData)}`))
            this.outletReport = data.reports;
            if (this.outletReport.length > 0) {
                this.selectedTerritoriesName = data.territory.name + (this.selectedBrands ? ',' + this.selectedBrands.name : '')
                this.selectedTerritoriesUsers = data.territory.total_reports
                this.totalOutletsCount = data.territory.total_outlets
                this.totalBilledOutlet = data.territory.billed_outlets
                this.totalNotBilledOutlet = data.territory.not_billed_outlets
            } else {
                this.$notify({
                    type: 'info',
                    message: 'Minimum 1 ordered required to generate report',
                    title: 'No ordered found!'
                });
            }
        } catch (e) {
            let err_msg = [];
            if (e.response.data.errors.range) {
                err_msg.push(e.response.data.errors.range[0]);
            }
            if (e.response.data.errors.territory_id) {
                err_msg.push(e.response.data.errors.territory_id[0]);
            }
            err_msg.forEach(error => {
                this.$notify({
                    type: 'warning',
                    message: error,
                    title: 'Validation error'
                });
            })
        } finally {
            this.loading = false
        }
    }
    onSelectBrand(value: []) {
        // this.selectedBrands = value.sort((a: any, b: any) => a.id - b.id);
        this.selectedBrands = value;
    }
    downloadNotBilledOutlet(user_id: any, outlets: any) {
        // window.console.log(outlets, user_id);
        const link = document.createElement("a");
        link.href = this.$api(`/department-bulk-download?download&ids=${outlets}`);
        link.target = '_blank';
        link.click();
    }
    async onClickDownload() {
        try {
            this.downloading = true;
            this.formData.user_id = this.formData.user_id ? this.formData.user_id.join(',') : null;
            const link = document.createElement("a");
            link.href = this.$api3(`/eco-report${this.$toQueryString(this.formData)}&download`);
            link.click();
        } catch (e) {
            this.$notify({
                type: 'warning',
                message: 'Unable to download.',
                title: 'Something went wrong!'
            });
        } finally {
            this.downloading = false;
        }
    }
}
