var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isGenerateReport)?_c('div',[(_vm.loading)?_c('div',{staticClass:"loading",attrs:{"align":"center"}},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"chart"},[_c('br'),(_vm.headerData)?_c('h4',{staticStyle:{"color":"gray"},attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.headerData.label))]):_vm._e(),(!_vm.growthList.length)?_c('h5',{staticClass:"text-danger",attrs:{"align":"center"}},[_vm._v("No Data Found")]):_vm._e(),_c('br'),_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},[(_vm.growthList.length > 0)?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped table-sm",attrs:{"header-row-class-name":"thead-light","data":_vm.growthList}},[_c('el-table-column',{attrs:{"label":"Brand Name","min-width":"150px","sortable":"","prop":"product_brand_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.product_brand_name)+" ")]}}],null,false,1788427848)}),_c('el-table-column',{attrs:{"label":String(_vm.headerData.currentYear),"sortable":"","prop":"current_year_value_type_sum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.current_year_value_type_sum)+" ")]}}],null,false,536285153)}),_c('el-table-column',{attrs:{"label":String(_vm.headerData.previousYear),"sortable":"","prop":"previous_year_value_type_sum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.previous_year_value_type_sum)+" ")]}}],null,false,3068105529)}),_c('el-table-column',{attrs:{"label":"Growth","sortable":"","prop":"growth_percentage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.growth_percentage)+" % ")]}}],null,false,1376152460)})],1):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"h2 font-weight-bold mb-0"},[_c('i',{staticClass:"el-icon-loading"})])}]

export { render, staticRenderFns }