






























































































































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import moment from "moment";
import {
  Tree, ButtonGroup, Button, Link, DatePicker, Select
} from 'element-ui';
import DateRangeForReports from "@/components/DateRangeForReports.vue";
import PriorityProductReportDetails from "@/components/products/PriorityProductReportDetails.vue";
import PriorityProductLazyDropdown from "@/components/lazyDropdown/PriorityProductLazyDropdown.vue";

@Component({
  components: {
    DateRangeForReports,
    PriorityProductReportDetails,
    PriorityProductLazyDropdown,
    TerritoryCascaderDropdown,
    UserLazydropdown,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    [Tree.name]: Tree,
  }
})
export default class PriorityProductReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public downloadingSingle: boolean = false;
  public showDetailsModal: boolean = false;
  public currentDetailsData = null;
  public downloadingDetails: boolean = false;
  public user: any = this.$store.getters['Auth/user'];
  public props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };
  public formData = {
    parent_id: 0,
    range: '',
    priority_product_id: '',
    value_type: '',
    view_base_on: '',
    user_id: '',
    territory_id: '',
  };
  public detailsFormData: any;


  public allItemDownloadFormData: any = {};

  public selectedPriorityProduct: any = {};
  public firstDate = '';
  public lastDate = '';
  public loading: boolean = false;
  public reports = [];
  public filterData = null;
  public parametersValueTypes: any = [{
    'name': 'Amount',
    'value': 'amount'
  }, {
    'name': 'Quantity',
    'value': 'qty'
  }];
  private viewBaseValueList = [
    {'label': 'Order', 'name': 'order'},
    {'label': 'Delivery', 'name': 'delivery'},
  ];


  @Watch('formData.priority_product_id')
  PriorityProductChange(priorityProductId: number) {
    if (!this.formData.priority_product_id) {
      return;
    }
    this.$http.get(this.$api(`/priority-products/${priorityProductId}`))
      .then(response => {
        this.selectedPriorityProduct = response.data.priorityProduct;
        this.firstDate = this.selectedPriorityProduct.start_date;
        this.lastDate = this.selectedPriorityProduct.expires_at;
        this.formData.range = '';
        this.formData.territory_id = this.selectedPriorityProduct.territory_id;
      })
  }

  public onClickGenerate() {
    this.allItemDownloadFormData = this.formData;
    this.fetchReport();
  }

  sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  created() {
    this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
      const units = this.sortItems(response.data.units);
      units.forEach((element: any, key: number) => {
        this.parametersValueTypes.push({
          name: element.name,
          value: 'unit' + (key + 1)
        });
      })
    });
  }

  async fetchReport() {
    this.loading = true
    this.reports = [];
    const {['parent_id']: _, ...filteredObject} = this.formData;
    await this.$http.get(this.$api3(`/priority-product-report${this.$toQueryString(filteredObject)}`)).then((response: any) => {
      this.reports = response.data.reports;
      this.filterData = response.data.filterData;
    }).catch((e) => {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
    }).finally(() => {
      this.loading = false
    })
  }

  async onClickDownload(type: string) {
    if (type === 'summary') {
      this.downloading = true;
    } else {
      this.downloadingDetails = true;
    }
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/priority-product-report-summary-download${this.$toQueryString(this.allItemDownloadFormData)}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      if (type === 'summary') {
        this.downloading = false;
      } else {
        this.downloadingDetails = false;
      }
    }
  }

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  handleDetails(value: any) {
    let { parent_id, ...filteredObject } = this.formData;
    filteredObject.territory_id = value;
    this.detailsFormData = filteredObject;
  }

  loadNode(node: any, resolve: any) {
    let territories: any[] = [];
    this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
    const {['territory_id']: _, ...filteredObject} = this.formData;
    if (this.formData.range) {
      this.$http.get(this.$api3(`/priority-product-report${this.$toQueryString(filteredObject)}`)).then((response) => {
        territories = response.data.reports;
        let treeData = territories.map((territory) => {
          const container: any = {};
          container['name'] = territory.name;
          container['priority_type'] = territory.priority_type;
          container['priority'] = territory.priority;
          container['total_outlets'] = territory.total_outlets;
          container['covered_outlets'] = territory.covered_outlets;
          container['percentage_covered_outlets'] = territory.percentage_covered_outlets;
          container['not_covered_outlets'] = territory.not_covered_outlets;
          container['percentage_not_covered_outlets'] = territory.percentage_not_covered_outlets;
          container['total_memo'] = territory.total_memo;
          container['total_products'] = territory.total_products;
          container['lpc'] = territory.lpc;
          container['value_type'] = territory.value_type;
          container['leaf'] = !territory.children_count;
          container['id'] = territory.id;
          return container;
        });
        resolve(treeData);
        this.loading = false;
      });
    }
  }

  getValueType() {
    const foundItem = this.parametersValueTypes.find((item: any) => item.value === this.formData.value_type);
    if (foundItem) {
      return foundItem.name;
    }
    return this.formData.value_type;
  }

}
