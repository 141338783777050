





























































// import swal from "sweetalert2";

import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ElNotificationOptions } from 'element-ui/types/notification';
import { AxiosResponse } from "axios";
import swal from "sweetalert2";

const auth = namespace('AdminAuth');
@Component
export default class AdminForgotPassword extends Vue {
  private disableButton = false;
  private loading: boolean = false;
  fields = {
    email: '',
    base_url: '',
  };
  $refs!: {
    formValidator: HTMLFormElement | Vue
  }

  async onSubmit() {
    window.console.log(12);
    this.loading = true;
    this.fields.base_url = window.location.origin;
    this.$http
      .post(this.$landLordApi('/admin/password/email'), this.fields)
      .then((response: AxiosResponse) => {
        window.console.log(response);
        this.$notify({
          title: 'Success',
          message: 'Send mail successful',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.$router.push({
          name: 'admin-success-mail-send',
        });
      })
      .catch((error) => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
        if (error.response && error.response.status === 422) {
          this.$notify({
            title: 'Error',
            message: 'Email Not Found',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'warning'
          });
        }
      }).finally(() => {
      this.loading = false;
    })
  }
}
