






































































































































































































//@ts-ignore
import { Component, Mixins, Watch } from 'vue-property-decorator';
import Payment from '@/intefaces/Payment';
// import CreatePaymentForm from '@/views/payment/CreatePaymentForm.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import { InvoiceDTO } from '@/intefaces/Invoice';
import moment from 'moment';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { QueryBuilder } from '@/classes/QueryBuilder';
import FilterDrawer from '@/components/FilterDrawer.vue';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import { Tag } from 'element-ui';

@Component({
  components: {
    // CreatePaymentForm,
    GIFModal,
    FilterDrawer,
    FilterDrawerComponent,
    DateRangeForReport,
    UserLazydropdown,
    DepartmentLazydropdown,
    [Tag.name]: Tag
  }
})
export default class InvoiceList extends Mixins(PaginationComponentMixin, TaxonomyMixin, MultiSelectRow) {
  public loading = false;
  public entities: InvoiceDTO[] = [];
  public statusLoading: boolean = false;
  public dialogVisible: boolean = false;
  private download: boolean = false;
  private currentEntity!: InvoiceDTO;
  private isFilterModelActive = false;
  private form: Payment = {
    advance: false,
    amount: null,
    created_by: 0,
    department_id: null,
    invoices: [],
    method: null,
    paid_at: null,
    seller_id: null
  };
  public filters: any = {
    invoiceCode: '',
    status: 'pending',
    range: '',
    departmentId: '',
    created_by: ''
  };
  public statuses: any = [
    { title: 'All', value: '' },
    { title: 'Pending', value: 'pending' },
    { title: 'Accepted', value: 'accepted' },
    { title: 'Rejected', value: 'rejected' },
  ];
  public bulkForm = {
    remarks: ''
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'paymentList';
  private GIFs: Array<any> = [
    {
      label: 'Create a payment',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a payment',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a payment',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of payment';
  private queryBuilder = new QueryBuilder();

  get permissionEnum() {
    return PermissionEnum;
  }

  created() {
    this.queryBuilder.addListener('status', 'pending');
  }
  onDetails(row: any) {
    this.$router.push({ name: 'invoice-payments', params: { invoiceId: row.id, currentInvoice: row } });
  }
  onFilterSubmit() {
    this.isFilterModelActive = false;
  }
  resetBtn() {
    this.filters.invoiceCode = '';
    this.filters.created_by = '';
    this.filters.departmentId = '';
    this.filters.status = 'pending';
    this.filters.range = '';
  }
  @Watch('filters.invoiceCode', { deep: true })
  onChangePhoneFiler(newVal: string) {
    this.queryBuilder.addListener('invoicecode', newVal);
  }
  @Watch('filters.range', { deep: true })
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('created_between', value);
  }
  @Watch('filters.created_by', { deep: true })
  onChangeCreator(value: number) {
    this.queryBuilder.addListener('created_by', value);
  }
  @Watch('filters.departmentId', { deep: true })
  onChangeBuyer(value: number) {
    this.queryBuilder.addListener('departmentId', value);
  }
  @Watch('filters.status', { deep: true })
  onChangeStatus(value: string) {
    this.queryBuilder.addListener('status', value);
  }

  @Watch('pagination.currentPage')
  @Watch('queryBuilder.watch')
  async fetchEntity() {
    this.loading = true;
    let { data } = await this.$http.get(
      this.$api3(`/invoices-payments${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) + '&' : '?'}page=${this.pagination.currentPage}`)
    );
    this.entities = data.invoice_payments;
    // moment().diff(moment(lastCheckinTime).format('lll'), 'minute');
    this.setPagination(data);
    this.loading = false;
  }
  submitBulkAction(status: string) {
    this.statusLoading = true;
    this.$http.put(this.$api3(`/invoices-payments`),
      this.allItemSelected ?
        {
          payment_ids: [],
          status: status,
          remarks: this.bulkForm.remarks
        } :
        {
          payment_ids: this.selectedRows.map(payment => payment.id),
          status: status,
          remarks: this.bulkForm.remarks
        }
    )
      .then(response => {
        this.$notify({
          title: 'Successful!',
          message: 'Status update successfully.'
        })
        this.selectedRows = [];
        this.pagination.currentPage = 1;
        this.fetchEntity();
      })
      .catch(error => {
        this.$notify({
          title: 'Something went wrong.',
          message: 'Unable to update status.',
          type: "error"
        })
      })
      .finally(() => {
        this.statusLoading = false;
        this.dialogVisible = false;
      });
  }
  uniqueSelectedStatus() {
    return this.selectedRows.map(payment => payment.status).filter((payment, index, self) => {
      return self.indexOf(payment) === index;
    }).toString();
  }
  downloadPayment() {
    this.download = true;
    this.$http.get(this.$api3(`/payments-list-download${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) + '&' : '?'}page=${this.pagination.currentPage}`), {
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payment-invoice-list.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        this.$notify({
          title: 'Something went wrong.',
          message: 'Unable to download file.',
          type: "error"
        })
      })
      .finally(() => {
        this.download = false;
      });
  }
}
