


























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DepartmentType from "@/intefaces/DepartmentType";
import CreateProductPrice from "@/views/product/productModalComponent/CreateProductPrice.vue";

@Component({
  name: 'viewProductPrice',
  components: {
    CreateProductPrice
  }
})
export default class ViewProductPrice extends Vue {
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() product!: any;
  private departments: any[] = [];
  private departmentTypes: DepartmentType[] = [];
  private isActiveCreateProductPrice = false;
  private isUpdateProductPrice: boolean = false;
  private productPrices: any[] = [];
  private currentProductPrice: any = [];
  private showDeleteModal: boolean = false;
  private loading: boolean = false;
  private singlePrice = {
    priceable_id: '',
    price: '',
    priceable_type: '',
  };

  onDelete() {
    this.$http.delete(this.$api(`/product-prices/${this.currentProductPrice.id}`)).then(response => {
      this.showDeleteModal = false;
      this.productPrices.splice(this.productPrices.findIndex(price => price.id == this.currentProductPrice.id),
          1)
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    })
  }

  updatePrice(event: any) {
    this.productPrices.splice(this.productPrices.findIndex((price: any) => price.id == event.id),
        1, event);
  }

  addPrice(event: any) {
    this.productPrices.unshift(event);
  }

  onEdit(row: any) {
    this.currentProductPrice = row;
    this.singlePrice = {
      priceable_id: this.currentProductPrice.priceable_id,
      price: this.currentProductPrice.price,
      priceable_type: this.currentProductPrice.priceable_type,
    }
    this.isUpdateProductPrice = true;
    this.isActiveCreateProductPrice = true;
  }

  fetchDepartmentType() {
    this.$http.get(this.$api('/department-types')).then(response => {
      this.departmentTypes = response.data.departmentTypes;
    });
  }

  fetchDepartment() {
    this.$http.get(this.$api('/departments')).then(response => {
      this.departments = response.data.departments;
    });
  }

  fetchProductPrice() {
    this.loading = true;
    this.$http.get(this.$api(`/products/${this.product.id}?with=prices.priceable`)).then(response => {
      this.productPrices = response.data.product.prices;
      this.loading = false;
    })
  }

  created() {
    this.fetchDepartment();
    this.fetchDepartmentType();
    this.fetchProductPrice();
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isAUpdate', false);
    this.$emit('update:variant', {variantValues: [], variantCategories: [], departmentTypes: [], prices: [], departmentId: []})
  }
}
