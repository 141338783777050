














































































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import moment from 'moment';
import LazyDropdown from '@/components/LazyDropdown.vue';
import DateRange from "@/components/DateRange.vue";


@Component({
    components: {
        UserLazydropdown,
        LazyDropdown,
        DateRange
    },
})
export default class SystemHistoriesReport extends Mixins(PaginationComponentMixin) {
    public formData: any = {};
    public loading: boolean = false;
    public reports = [];
    public selectedType: any = {};
    public selectedUser: any = {};

    // created() {
    //     this.formData.date = moment().format('YYYY-MM-DD');
    //     this.fetchReport();
    // }

    @Watch('pagination.currentPage')
    async fetchReport() {
        this.loading = true;
        await this.$http.get(this.$api3(`/system-history-fetch${this.$toQueryString(this.formData)}&page=${this.pagination.currentPage}&per_page=25`)).then(response => {
                this.reports = response.data.histories;
                this.setPagination(response.data);
                if (this.reports.length == 0) {
                    this.$message({
                        type: 'error',
                        message: 'The selected filters yield no data',
                    });
                }
            })
            .catch((error) => {
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            })
            .finally(() => {
                this.loading = false;
            })
    }

    isDateTime(value: any) {
        return moment(value, moment.ISO_8601, true).isValid();
    }

    checkDateOrValue(value: any) {
        if (this.isDateTime(value)) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
        return value;
    }

    excludedProperties(value: any, properties: any = ['id', 'updated_at']) {

        // Filter out properties that are not in the excluded list
        const filteredMeta = Object.keys(value)
            .filter(key => !properties.includes(key))
            .reduce((obj: any, key) => {
                obj[key] = value[key];
                return obj;
            }, {});

        return filteredMeta;
    }

    keyToLabel(key: string) {
        return key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
    }

    formattedRange() {
      if (!this.formData.range) return '';
      const dates = this.formData.range.split(',');
      return dates.length === 2 ? `${dates[0]} to ${dates[1]}` : this.formData.range;
    }
}
