


























































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import Department from "@/intefaces/Department";
import DepartmentType from "@/intefaces/DepartmentType";
import { Tooltip } from "element-ui";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";

@Component({
  components: {
    ProductLazydropdown,
    DepartmentLazydropdown,
    ChannelLazydropdown,
    [Tooltip.name]: Tooltip,
  }
})
export default class ProductPriceComponent extends Mixins(TaxonomyMixin) {
  @Prop() productPrices!: any;
  private priceType = ['dept', 'depttype'];
  private disabled = true;
  private singlePrice = {
    priceable_id: '',
    price: '',
    priceable_type: '',
    id: ''
  };
  private departmentTypes: any[] = [];
  private departments: any[] = [];
  public selectedPriceAbleData = "";


  @Watch('singlePrice', {deep: true})
  enableAddButton() {
    if (this.singlePrice.priceable_id && this.singlePrice.price && this.singlePrice.priceable_type) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  saveProductBatchPrice() {
    this.productPrices.push({...this.singlePrice});
    this.singlePriceReset();
  }

  changePriceType() {
    this.singlePrice.priceable_id = '';
  }

  singlePriceReset() {
    this.singlePrice.priceable_id = '';
    this.singlePrice.price = '';
    this.singlePrice.priceable_type = '';
    this.singlePrice.id = '';
    this.disabled = true;
  }

  deletePrice(index: number) {
    this.productPrices.splice(index, 1)
  }

  newCreatedDepartment(event: Department) {
    this.departments.unshift(event);
  }

  newCreatedChannel(event: DepartmentType) {
    this.departmentTypes.unshift(event);
  }

  fetchDepartmentTypes() {
    this.$http.get(this.$api('/department-types')).then(response => {
      this.departmentTypes = response.data.departmentTypes;
    })
  }

  fetchDepartment() {
    this.$http.get(this.$api('/departments')).then(response => {
      this.departments = response.data.departments;
    })
  }

  created() {
    this.fetchDepartment();
    this.fetchDepartmentTypes();
  }
}
