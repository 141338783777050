








import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import DashboardStatsCard from '@/components/DashboardStatsCard.vue';

@Component({
    components: {
        DashboardStatsCard
    },
})
export default class CheckInDsrCount extends Mixins(TaxonomyMixin) {
    @Prop({ default: '' })
    public dateRange!: string;
    @Prop({ default: 1 })
    public dsr!: boolean;
    @Prop({ default: null })
    public territoryId!: number | null;
    @Prop({ default: null })
    public userId!: number | null;
    public result: number = 0;
    public loading: boolean = true;
    @Prop({ default: 0 })
    public totalActiveDsr!: number;

    mounted() {
        this.fetch();
    }

    async fetch() {
        this.loading = true;

        const params = {
            only: 'checkInDsrCount',
            range: this.dateRange,
            territory_id: this.territoryId,
            user_id: this.userId
        };

        try {
            let { data } = await this.$http.get(this.$api(`/daily-reports${this.$toQueryString(params)}`))
            this.result = data.dailyReports.checkInDsrCount.toLocaleString('en-US');
        }
        // catch (e) {
        //
        // }
        finally {
            this.loading = false
        }
    }

    @Watch('dateRange')
    onChangeDateRange() {
        this.fetch();
    }

    @Watch('territoryId')
    onTerritoryId() {
        this.fetch();
    }

    @Watch('userId')
    onUserId() {
        this.fetch();
    }
}
