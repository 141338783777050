





import {
    Component,
    Mixins
} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
@Component({
    components: {
        LazyDropdown,
    }
})
export default class RemarkLazydropdown extends Mixins(DropdownField) {}
