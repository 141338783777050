import { render, staticRenderFns } from "./RegisterInfo.vue?vue&type=template&id=7ad8568d&scoped=true&"
import script from "./RegisterInfo.vue?vue&type=script&lang=ts&"
export * from "./RegisterInfo.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterInfo.vue?vue&type=style&index=0&id=7ad8568d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad8568d",
  null
  
)

export default component.exports