








import { Component, Mixins, Prop } from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import AddProduct from "@/views/product/AddProduct.vue"
import DropdownField from "../../mixins/DropdownField";

  @Component({
    components: {
      LazyDropdown,
      AddProduct
    }
  })
  export default class ProductLazydropdown extends Mixins(DropdownField) {
    @Prop() endPoint!: string;
    @Prop() disabled!: boolean;
    @Prop({default: true}) addButton!: boolean;
    @Prop({default: false}) multiple!: boolean;
    @Prop({default: 'name'}) itemText!: string;
    @Prop({default: ''}) itemSubText!: string;
    @Prop({ default: 'id' }) itemValue!: string;
  }
