












































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import LazyDropdown from '@/components/LazyDropdown.vue';
import moment from "moment";
import {
  Tree
} from 'element-ui';

@Component({
  components: {
    TerritoryCascaderDropdown,
    UserLazydropdown,
    DateRange,
    LazyDropdown,
    [Tree.name]: Tree,
  }
})
export default class PaymentReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public formData = {
    range: '',
    parent_id: 0,
    territory_id: null,
    department_id: null,
    user_id: null,
  };
  public loading: boolean = false;
  public outletReport = [];
  public maxDate: string = moment().format('YYYY-MM-DD');
  public user: any = this.$store.getters['Auth/user'];
  public props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };

  created() {
    //
  }

  public onClickGenerate() {
    if (this.formData.range) {
      this.fetchReport();
    } else {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!'
      });
    }
  }

  async fetchReport() {
    this.loading = true
    this.outletReport = [];
    const { ['parent_id']: _, ...filteredObject } = this.formData;
    await this.$http.get(this.$api3(`/payment-summary${this.$toQueryString(filteredObject)}`))
      .then((response: any) => {
        const territories = response.data.territories;
        territories.forEach((territory: any) => {
          territory.downloading = false;
        })
        this.outletReport = territories;
    }).catch((e) => {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
    }).finally(() => {
      this.loading = false
    })
  }

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  async downloadReport(territory: any) {
    territory.downloading = true;
    const reportData = {
      'range': this.formData.range,
      'territory_id': territory.id,
      'department_id': this.formData.department_id,
      'user_id': this.formData.user_id,
    }
    let {
      data,
      headers
    } = await this.$http.get(this.$api3(`/payment-summary-download${this.$toQueryString(reportData)}`), {
      responseType: 'arraybuffer'
    });

    const blob = await new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const link = document.createElement("a");
    link.href = await URL.createObjectURL(blob);
    link.download = this.$getFileNameFromContentDisposition(headers);
    link.click();
    territory.downloading = false;
  }
  loadNode(node: any, resolve: any) {
    let territories: any[] = [];
    this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
    const { ['territory_id']: _, ...filteredObject } = this.formData;
    if (this.formData.range) {
      this.$http.get(this.$api3(`/payment-summary${this.$toQueryString(filteredObject)}`))
        .then((response) => {
          territories = response.data.territories;
          let treeData = territories.map((territory) => {
            const container: any = {};
            container['name'] = territory.name;
            container['code'] = territory.code;
            container['total_accepted'] = territory.total_accepted;
            container['total_amount'] = territory.total_amount;
            container['leaf'] = !territory.children_count;
            container['id'] = territory.id;
            container['downloading'] = false;
            return container;
          });
          resolve(treeData);
          this.loading = false;
        });
    }
  }
}
