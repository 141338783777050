import { RouteConfig } from 'vue-router';
// @ts-ignore
import Report from '@/views/customReport/Report.vue';
// @ts-ignore
import CustomReport from "@/views/customReport/index.vue";
// @ts-ignore
import Order from "@/views/customReport/Order/Order.vue";
// @ts-ignore
import Delivery from "@/views/customReport/Delivery/Delivery.vue";
// @ts-ignore
import DownloadReprot from "@/views/customReport/downloadReport.vue";
import { Permission } from "@/enums/Permission";
import { PermissionHelper } from "@/classes/PermissionHelper";


// Redirect to user's authorized (has permission) tab
function redirectToUserAuthorizedRoute() {
    return '/custom-report/' + PermissionHelper([
        Permission.CREATE_CUSTOME_REPORT,
        Permission.VIEW_CUSTOME_ORDER_REPORT,
        Permission.VIEW_CUSTOME_DELIVERY_REPORT
    ], [{permission: Permission.CREATE_CUSTOME_REPORT, name: 'custom-Report'},
        {permission: Permission.VIEW_CUSTOME_ORDER_REPORT, name: 'custom-report-order'},
        {permission: Permission.VIEW_CUSTOME_DELIVERY_REPORT, name: 'custom-report-delivery'}
    ]);
}

// @ts-ignore
const CustomReportRoutes: RouteConfig = {
    path: 'custom-report',
    name: 'custom-report',
    redirect: to => {
        return redirectToUserAuthorizedRoute();
    },
    components: { dashboard: Report },
    children: [
        {
            path: '',
            name: 'custom-Report',
            meta: {
                label: 'Custom Report',
                icon: 'ni ni-active-40',
                permission: Permission.CREATE_CUSTOME_REPORT,
                breadCrumb: 'custom-report'
            },
            components: { report: CustomReport },
        },
        {
            path: 'order',
            name: 'custom-report-order',
            meta: {
                label: 'Custom Order Report',
                icon: 'ni ni-cart',
                permission: Permission.VIEW_CUSTOME_ORDER_REPORT,
                breadCrumb: 'order'
            },
            components: { report: Order },
            children: [
                {
                    path: 'download-report/:reportId',
                    name: 'download-report-order',
                    meta: {
                        label: 'Custom Report Order Download',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'download-report'
                    },
                    components: { report: DownloadReprot }
                }
            ]
        },
        {
            path: 'delivery',
            name: 'custom-report-delivery',
            meta: {
                label: 'Custom Delivery Report',
                icon: 'ni ni-delivery-fast',
                permission: Permission.VIEW_CUSTOME_DELIVERY_REPORT,
                breadCrumb: 'delivery'
            },
            components: { report: Delivery },
            children: [
                {
                    path: 'delivery-report/:reportId',
                    name: 'download-report-delivery',
                    meta: {
                        label: 'Custom Report Delivery Download',
                        icon: 'ni ni-button-power',
                        breadCrumb: 'download-report-delivery'
                    },
                    components: { report: DownloadReprot }
                }
            ]
        }
    ]
};
export default CustomReportRoutes;
