




































//@ts-ignore
import { Vue, Component } from 'vue-property-decorator';
import RouteBreadCrumb from '@/components/argon-core/Breadcrumb/RouteBreadcrumb.vue';

import Route from '@/intefaces/Route';
import CouponRoutes from '@/router/admin-routes/CouponRoutes';
import { RouteConfig } from 'vue-router';
@Component({
  components: { RouteBreadCrumb },
})
class Coupon extends Vue {
  activeTab: number | null = 0;
  showModal: boolean = false;
  routes: RouteConfig = CouponRoutes;
  formErrors: any = {};
  formInputs: any = {};

  get CouponRoutes() {
    return CouponRoutes;
  }

  created(): void {
    if (this.routes.children === undefined) return;
    this.routes.children.forEach((route, index) => {
      if (this.$route.name === route.name) {
        this.activeTab = index;
      }
    });
  }

  setCurrentTab(tab = null) {
    if (tab === null) {
      this.activeTab = 0;
    } else {
      this.activeTab = tab;
    }
  }

  closeAndResetForm() {
    this.showModal = false;
    this.formInputs = {};
    this.formErrors = {};
  }
}

export default Coupon;
