









































































































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import RequisitionForm from '@/views/order/requisition/RequisitionForm.vue';
import RevisionList from '@/views/order/RevisionList.vue';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tag,
  Tooltip
} from 'element-ui';
import InvoiceDetails from '@/views/order/invoice/InvoiceDetails.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import InvoiceFilters from '@/views/order/invoice/InvoiceFilters.vue';
import { Invoice } from '@/intefaces/Invoice';
import InvoiceAmountDetailsForm from '@/views/order/invoice/InvoiceAmountDetailsForm.vue';
import InvoicePaymentDetailsForm from '@/views/order/invoice/InvoicePaymentDetailsForm.vue';
import InvoiceFilterDrawer from '@/views/order/invoice/InoviceFilterDrawer.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import GIFModal from '@/components/tour/GIFModal.vue';
import MultiSelectRow from '@/mixins/MultiSelectRow';
import printJS from 'print-js';
import FilterDrawer from '@/components/FilterDrawer.vue';
import { ElTable } from 'element-ui/types/table';
import InvoiceStatusDetails from '@/views/order/invoice/InvoiceStatusDetails.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import { Permission as PermissionEnum } from '@/enums/Permission';
import BaseInvoiceDetails from '@/views/order/invoice/BaseInvoiceDetails.vue';
import InvoiceRevision from '@/intefaces/InvoiceRevision';
import HasCursorPaginationMixin from '@/mixins/HasCursorPagination';
import CursorPagination from '@/components/CursorPagination.vue';
import moment from 'moment';
import { QueryBuilder } from '@/classes/QueryBuilder';
import TenantModulesMixin from '@/mixins/HasTenantModules';
import swal from 'sweetalert2';
import widgets from "@/pages/widgets.vue";

@Component({
  components: {
    CursorPagination,
    FilterDrawer,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
    [Tag.name]: Tag,
    GIFModal,
    InvoiceDetails,
    InvoiceFilters,
    InvoiceAmountDetailsForm,
    InvoicePaymentDetailsForm,
    InvoiceFilterDrawer,
    RequisitionForm,
    RevisionList,
    InvoiceStatusDetails,
    BaseInvoiceDetails
  }
})
export default class InvoiceList extends Mixins(HasCursorPaginationMixin, MultiSelectRow, TaxonomyMixin, TenantModulesMixin) {
  public invoiceFilter: string = '';
  public isInvoiceStatusUpdate: boolean = false;
  public renderKey: number = 0;
  private invoices: Invoice[] = [];
  private invoiceDetails: Invoice[] = [];
  private isShowAmountDetails: boolean = false;
  private isShowPaymentDetails: boolean = false;
  private isActiveInvoiceDetails: boolean = false;
  private isFilterModelActive: boolean = false;
  private dialogVisible: boolean = false;
  private isStatusUpdateActive: boolean = false;
  private currentInvoice: any = [];
  private isInvoice: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private isViewRevision: boolean = false;
  private updateAmountCount: boolean = false;
  private allRevision: OrderRevision[] = [];
  private lastestRevision: OrderRevision[] = [];
  private isInvoiceForRevision: boolean = false;
  private isDelivery: boolean = false;
  private currentDelivery: any = '';
  private generating: boolean = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'invoice';
  private selectedButton: string = 'Today';
  private queryBuilder: QueryBuilder = new QueryBuilder();
  private recipient: string = '';
  private showGenerateInvoiceModal: boolean = false;
  public filterInvoiceList: any = [];
  private bulkForm = {
    remarks: ''
  };
  private GIFs: Array<any> = [
    {
      label: 'Create a invoice',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a invoice',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a invoice',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of invoice';

  get permissionEnum() {
    return PermissionEnum;
  }

  created(): void {
    this.checkTenantModule('order_include_lot,order_transportation');
  }
  @Watch('isViewRevision')
  getRevision() {
    this.$http.get(this.$api(`/invoices/${this.currentInvoice.id}/revisions`)).then(response => {
      this.allRevision = response.data.revisions;
      this.lastestRevision = response.data.revisions[0];
    });
  }

  @Watch('invoiceDetails')
  async updateRenderKey() {
    // await this.fetchInvoices(null);
    this.renderKey += 1;
  }

  handleBulkActionNotification(type: string, message: string) {
    this.$notify({
      title: type == 'success' ? 'Success' : 'Failed',
      message: message,
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'top',
      horizontalAlign: 'right',
      //@ts-ignore
      type: type
    });
  }

  submitBulkAction(status: string) {
    this.loading = true;
    this.dialogVisible = false;
    this.$http
      .post(
        this.$api(`/bulk-delivery-status?${this.queryBuilder.getFilters()}`),
        this.allItemSelected
          ? {
            id: '',
            status_action: status,
            remarks: this.bulkForm.remarks
          }
          : {
            id: this.selectedRows.map(order => order.id),
            status_action: status,
            remarks: this.bulkForm.remarks
          }
      )
      .then(response => {

        if (response.data.failed_updates && response.data.failed_updates.length > 0) {
          response.data.failed_updates.forEach((update: { id: any; message: string; },index:number) => {
            setTimeout(() => {
              this.handleBulkActionNotification('warning', update.message);
            }, 1000 * (index + 1));
          });
        }

        if (response.data.success_updates && response.data.success_updates.length > 0) {
          response.data.success_updates.forEach((update: { id: any; message: string; },index:number) => {
            setTimeout(() => {
              this.handleBulkActionNotification('success', update.message);
            }, 1000 * (index + 1));
          });
        }

        if (!response.data.success_updates && !response.data.failed_updates) {
          this.$notify({
            title: 'No Updates',
            message: 'No invoices were updated',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            // @ts-ignore
            verticalAlign: 'bottom',
            horizontalAlign: 'top',
            type: 'success'
          });
        }

        this.selectedRows = [];
        this.bulkForm.remarks = '';
        this.fetchInvoices(null);
      })
      .catch(error => {
        this.handleBulkActionNotification('warning', error.response.data.message);
      })
      .finally(() => (this.loading = false));
  }

  updateInvoice(event: InvoiceRevision) {
    this.$http.get(this.$api(`/invoices/${event.invoice_id}`))
      .then(response => {
        // Rename the 'code' field to 'invoice_code'
        response.data.invoice.invoice_code = response.data.invoice.code;
        delete response.data.invoice.code;
        this.invoices.splice(
          this.invoices.findIndex((invoice: any) => invoice.id == response.data.invoice.id),
          1,
          response.data.invoice
        );
      });
  }

  entityUpdated(entity: Invoice): void {
    this.invoices.splice(
      this.invoices.findIndex(type => type.id == entity.id),
      1,
      entity
    );
    // this.fetchInvoices(null);
    this.isActive = false;
  }

  createDelivery(event: any) {
    this.isActive = false;
    this.isDelivery = false;
    this.currentDelivery = true;
  }

  @Watch('invoiceFilter')
  onChangeInvoiceFilter() {
    this.fetchInvoices(null);
    (this.$refs.multipleTable as ElTable).clearSelection();
  }

  @Watch('currentCursor')
  async fetchInvoices(cursor: string | null) {
    const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
    this.loading = true;
    try {
      let {data} = await this.$http.get(
        this.$api3(`/invoices-info${cursor ? '?cursor=' + cursor + '&' : ''}${cursor ? this.invoiceFilter : '?' + this.invoiceFilter}&order_type=${order_type}&latest_status=accepted`)
        // this.$api2(`/invoices?with=payments&page=${this.pagination.currentPage}${this.invoiceFilter ? '&' + this.invoiceFilter : ''}`)
      );
      this.setPagination(data);
      this.invoices = [];
      this.filterInvoiceList = data.data;
      this.filterInvoiceList.map((singleInvoice: any) => {
        if (singleInvoice.payable > 0) {
          this.invoices.push(singleInvoice);
        }
      });
    } catch (e) {
      this.$notify({title: 'Something Went Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.loading = false;
    }
  }

  async invoicesObjectUrl(pos = false) {
    let posParams = '';
    if (pos) {
      posParams = this.invoiceFilter ? '&pos=1' : '?pos=1';
    }
    let {data} = await this.$http.post(
      this.$api(`/pdf/invoices${this.invoiceFilter ? '?' + this.invoiceFilter : ''}${posParams}`),
      {invoice_id: this.allItemSelected ? null : this.selectedRows.map(value => value.id).join(',')},
      {
        responseType: 'blob'
      }
    );
    const blob = await new Blob([data], {type: 'application/pdf'});
    let fileURL = await URL.createObjectURL(blob);
    return fileURL;
  }

  async printInvoices() {
    this.generating = true;
    try {
      await printJS({printable: await this.invoicesObjectUrl()});
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
    }
  }

  async printPOSInvoices() {
    this.generating = true;
    try {
      await printJS({printable: await this.invoicesObjectUrl(true)});
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
    }
  }

  async generateAdvanceInvoices() {
    this.generating = true;
    await this.queryBuilder.setParam('recipient', this.recipient);
    if (!this.allItemSelected) {
      await this.queryBuilder.setParam(
        'invoice_id',
        this.selectedRows.map(invoice => invoice.id)
      );
    }
    try {
      await this.$http.post(this.$api(`/pdf/advance-invoices${this.queryBuilder.getFilters(true)}&${this.invoiceFilter}`));
      await swal.fire('Report Sent', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({title: 'Something Wrong!', message: 'Please Try again.', type: 'error'});
    } finally {
      this.generating = false;
      this.showGenerateInvoiceModal = false;
      await this.queryBuilder.setParam('recipient', null);
      await this.queryBuilder.setParam('invoice_id', null);
    }
  }

  checkPromotionOn(promotion: string, promotion_on: string) {
    if (promotion) {
      if (promotion_on != 'all') {
        return promotion_on;
      } else {
        return 'product';
      }
    } else {
      return 'product';
    }
  }

  async downloadInvoices() {
    this.loading = true;
    try {
      let {data, headers} = await this.$http.get(
        this.$api(
          `/invoice-bulk-download?type=csv${this.allItemSelected ? '' : '&id=' + this.selectedRows.map(value => value.id)}&${
            this.invoiceFilter ? this.invoiceFilter : ''
          }`
        ),
        {
          responseType: 'arraybuffer'
        }
      );
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({type: 'warning', message: 'Unable to download.', title: 'Something went wrong!'});
    } finally {
      this.loading = false;
    }
  }
}
