










































// import swal from "sweetalert2";

import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from "axios";

@Component
export default class AdminMailSender extends Vue {

}
