







































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip, InputNumber, MessageBox } from 'element-ui';
import { mixins } from 'vue-class-component';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import DepartmentProduct from '@/intefaces/DepartmentProduct';
import Department from '@/intefaces/Department';
import ProductDetailsForm from '@/views/stock/departmentStock/ProductDetailsForm.vue';
import DepartmentDetailsForm from '@/views/stock/departmentStock/DepartmentDetailsForm.vue';
import DepartmentStockFilterDrawer from '@/views/stock/departmentStock/DepartmentSrockFilterDrawer.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import FileInput from '@/components/argon-core/Inputs/FileInput.vue';
import { AxiosError, AxiosResponse } from 'axios';
import { Permission as PermissionEnum } from '@/enums/Permission';
import swal from 'sweetalert2';
import StockHistoryLazydropdown from "@/components/lazyDropdown/StockHistoryLazyDropdown.vue";
import StockSingleForm from "@/views/stock/departmentStock/StockSingleForm.vue";
import BatchLazydropdown from "@/components/lazyDropdown/BatchLazydropdown.vue";

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [InputNumber.name]: InputNumber,
    ProductDetailsForm,
    DepartmentDetailsForm,
    DepartmentStockFilterDrawer,
    FileInput,
    StockHistoryLazydropdown,
    StockSingleForm,
    BatchLazydropdown
  }
})
export default class DepartmentStockList extends mixins(PaginationComponentMixin, TaxonomyMixin) {
  //variable section +++
  private departments: Department[] = [];
  private stocks: any = [];
  public stocksHistory: any = [];
  public formData: any = {};
  public closeHistoryModal: boolean = false;
  private isFilterModelActive: boolean = false;
  private isActiveProductDetails = false;
  private currentDepartmentProduct: DepartmentProduct[] = [];
  private isActiveDepartment = false;
  private isBuyer = false;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'departmentStock';
  public AddSingleStock: boolean = false;
  public RequisitionModal: boolean = false;
  public AddModal: boolean = false;
  public UpdateModal: boolean = false;
  public ChallanUpdateModal: boolean = false;
  public downloading: boolean = false;
  public loading: boolean = false;
  public btnDisabled: boolean = false;
  private csvFile: Blob | string = '';
  private errors: Array<string> = [];
  private is_owner: boolean = false;
  public showDownloadModal: boolean = false;
  public recipient: string = '';
  public challan_no: string = '';
  public duplicateChallan: string = '';
  public batch_id: any = '';
  private GIFs: Array<any> = [
    {
      label: 'Create a department stock',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a department stock',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a department stock',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of department stock';
  // Variable section ---

  //method +++
  buyerDepartment(row: any) {
    this.isActiveDepartment = true;
    this.isBuyer = true;
    this.currentDepartmentProduct = row;
  }

  closeModal() {
    this.errors = [];
    if (this.AddModal)
      this.AddModal = false;
    else if (this.UpdateModal)
      this.UpdateModal = false;
    else
      this.RequisitionModal = false;
  }

  get permissionEnum() {
    return PermissionEnum;
  }

  created() {
    this.currentUser();
    this.fetchDepartmentProduct();
    this.fetchDepartment();
  }

  //Watcher +++
  @Watch('departmentFilter')
  filterDepartmentWiseProduct(newVal: any) {
    if (newVal == 'all') {
      this.fetchDepartmentProduct();
    } else {
      this.loading = true;
      this.$http.get(this.$api3(`/stocks?department_id=${newVal}`)).then(response => {
        this.stocks = response.data.products;
        this.setPagination(response.data);
        this.loading = false;
      });
    }
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }

  importNewEntry() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkStock', this.csvFile);
    form.append('batch_id', this.batch_id);
    this.$http
      .post(this.$api3('/stock/bulk-import/requisition'), form)
      .then((response: AxiosResponse) => {
        swal.fire('Total ' + response.data.total + ' Stock Uploading...', 'You will receive the stocks data within a few minutes.', 'success');
        this.btnDisabled = false;
        this.AddModal = false;
        this.fetchDepartmentProduct();
        this.errors = [];
      })
      .catch((err: any) => {
        if (this.csvFile) {
          if (err.response.status == 422) {
            this.errors = err.response?.data.errors;
          } else {
            this.$notify({
              title: 'Warning',
              message: 'Please import the correct file',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
          }
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }

  _import() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkStock', this.csvFile);
    form.append('batch_id', this.batch_id);
    this.$http.post(this.$api('/stock/bulk-import/add'), form)
      .then((response: AxiosResponse) => {
        swal.fire('Stock Uploading...', 'You will receive the stocks data within a few minutes.', 'success');
        this.btnDisabled = false;
        this.AddModal = false;
        this.fetchDepartmentProduct();
        this.errors = [];
      })
      .catch((err: any) => {
        if (this.csvFile) {
          if (err.response.status == 422) {
            this.errors = err.response?.data.errors;
          } else if (err.response.status == 409) {
            this.$notify({
              title: 'Conflict',
              message: 'Duplicate entities.',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
            this.duplicateChallan = err.response.data.challan_no;
          } else {
            this.$notify({
              title: 'Warning',
              message: 'Please import the correct file',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
          }
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }

  importFileUpdate() {
    this.btnDisabled = true;
    let form = new FormData();
    form.append('bulkStock', this.csvFile);
    form.append('batch_id', this.batch_id);
    this.$http
      .post(this.$api('/stock/bulk-import/update'), form)
      .then((response: AxiosResponse) => {
        swal.fire('Stock Updating...', 'You will receive the stocks data within a few minutes.', 'success');
        this.btnDisabled = false;
        this.UpdateModal = false;
        this.fetchDepartmentProduct();
        this.errors = [];
      })
      .catch((err: any) => {
        if (this.csvFile) {
          if (err.response.status == 422) {
            this.errors = err.response?.data.errors;
          } else {
            this.$notify({
              title: 'Warning',
              message: 'Please import the correct file',
              duration: 3000,
              iconClass: 'ni ni-bell-55',
              type: 'warning'
            });
          }
        } else {
          this.errors = err.response?.data.errors;
        }
        this.btnDisabled = false;
      });
  }

  async onClickDownload(type: string = '') {
    window.console.log(type);
    this.downloading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api(`/stock/bulk-export?download&type=${type}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.'});
    } finally {
      this.downloading = false;
    }
  }

  async stockDownload(status: string) {
    this.loading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api('/all-stock-download?' + 'recipient=' + this.recipient));
      await swal.fire('Stock List Sending...', 'You will receive the report within a few minutes.', 'success');
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.'});
    } finally {
      this.loading = false;
      this.showDownloadModal = false;
      this.recipient = '';
    }
  }

  // Lifecycle hooks +++
  fetchDepartmentProduct() {
    this.loading = true;
    this.$http.get(this.$api3(`/stocks?page=${this.pagination.currentPage}`)).then(response => {
      this.stocks = response.data.stocks;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  fetchDepartment() {
    this.$http.get(this.$api(`/departments`)).then(response => {
      this.departments = response.data.departments;
    });
  }

  currentUser() {
    this.$http.get(this.$api(`/owner-status`)).then(response => {
      this.is_owner = response.data.is_owner == 1 ? true : false;
    });
  }

  challanUpdate() {
    this.formData = [];
    this.stocksHistory.stocks.forEach((stock: any) => {
      if (stock.update_qty > 0) {
        this.formData.push({
          id: stock.id,
          quantity: stock.quantity,
          update_qty: stock.update_qty
        })
      }
    });
    if (this.formData.length > 0) {
      this.$http.put(this.$api3(`/stocks-history-update`), this.formData).then(response => {
        if (response.status === 200) {
          this.challan_no = '';
          this.stocksHistory = [];
          this.closeHistoryModal = true;
          this.ChallanUpdateModal = false;
          this.$notify({
            title: 'Confirmation!',
            type: 'success',
            message: 'Stock update successfully'
          });
        }
      });
    }
  }

  handleChangeStatus(row: any) {
    const message = row.active ? "Are you sure you want to change the status to Inactive? Once you change it, department stock will be inactive." : "Are you sure you want to change the status to Active? Once you change it, department stock will be active.";
    MessageBox.alert('<span style="font-size: 14px">' + message + ' </span>', 'Warning', {
      confirmButtonText: row.active ? "Inactive" : "Active",
      confirmButtonClass: 'el-button--mini',
      cancelButtonText: row.active ? "Keep Active" : "Keep Inactive",
      cancelButtonClass: 'el-button--mini',
      showCancelButton: true,
      dangerouslyUseHTMLString: true,
      type: 'warning',
      center: true
    })
      .then(() => {
        this.$http.put(this.$api3(`/stocks/${row.id}`), {active: !row.active}).then(response => {
          if(response.status === 200) {
            this.$notify({
              title: 'Success',
              message: `Status changed ${row.active ? 'Inactive' : 'Active'} successfully`,
              duration: 5000,
              iconClass: 'ni ni-bell-55',
              type: 'success'
            });
            row.active = !row.active;
          }
        });
      });
  }
}
