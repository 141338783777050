






























































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import 'flatpickr/dist/flatpickr.css';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import {
    Button,
    ButtonGroup
} from 'element-ui';
import moment from 'moment';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
import ActiveUsers from '@/views/dashboard/BasicReporting/ActiveUsers.vue';
import AverageWorking from '@/views/dashboard/BasicReporting/AvgWorking.vue';
import visitVsPlan from '@/views/dashboard/BasicReporting/visitVsPlan.vue';
import NewOutlets from '@/views/dashboard/BasicReporting/NewOutlets.vue';
import CoveredOutlet from '@/views/dashboard/BasicReporting/CoveredOutlet.vue';
import ProductiveTime from '@/views/dashboard/BasicReporting/ProductiveTime.vue';
import visitingHours from '@/views/dashboard/BasicReporting/visitingHours.vue';
import LazyDropdown from "@/components/LazyDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import BasicBrandReport from "@/views/dashboard/BrandReporting/BasicBrandReport.vue";
import YearToYearGrowthComparisonReport from "@/views/dashboard/BrandReporting/YearToYearGrowthComparisonReport.vue";
import EffectiveCoverageReport from "@/views/dashboard/BrandReporting/EffectiveCoverageReport.vue";
import QuarterYearLineChartReport from "@/views/dashboard/BrandReporting/QuarterYearLineChartReport.vue";
import DateRangeForReports from "@/components/DateRangeForReports.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";

const flatPicker = require('vue-flatpickr-component');

@Component({
    components: {
        ProductBrandLazydropdown,
        DateRangeForReports,
        QuarterYearLineChartReport,
        EffectiveCoverageReport,
        YearToYearGrowthComparisonReport,
        BasicBrandReport,
        ChannelLazydropdown,
        LazyDropdown,
        ActiveUsers,
        AverageWorking,
        visitVsPlan,
        NewOutlets,
        ProductiveTime,
        CoveredOutlet,
        visitingHours,
        MemberAutocomplete,
        TerritoryCascaderDropdown,
        flatPicker,
        [Button.name]: Button,
        [ButtonGroup.name]: ButtonGroup
    }
})

export default class BrandReporting extends Mixins(TaxonomyMixin, PaginationComponentMixin, Vue) {

    public filterData: any = {
        territory_id: null,
        user_ids: '',
        department_ids: '',
        brand_ids: '',
        range: '',
        view_base_on: '',
        limit_brand: 5,
        value_type: '',
    };

    public chartColStyle: any = {
        basicBrandReport: 'col-6',
        yearToYearGrowthComparisonReport: 'col-6',
        quarterYearLineChartReport: 'col-6',
        effectiveCoverageReport: 'col-6',
    };

    public generateKey: number = 0;
    public isGenerateReport: boolean = false;
    public error: string = '';
    public loading: boolean = false;
    private viewBaseValueList = [{
            'label': 'Order',
            'name': 'order'
        },
        {
            'label': 'Delivery',
            'name': 'delivery'
        },
    ];
    public parametersValueTypes: any = [{
        'name': 'Amount',
        'value': 'amount'
    }, {
        'name': 'Quantity',
        'value': 'qty'
    }];

    checkRequiredFields() {
        const requiredFields = ['territory_id', 'range', 'view_base_on', 'value_type', 'limit_brand'];
        let message = '';
        for (const field of requiredFields) {
            if (!this.filterData[field]) {
                message = `${field.replace('_', ' ').toUpperCase()} Field Is Required !`;
                this.notification(message);
                return;
            }
        }
        this.isGenerateReport = true;
    }

    notification(message: string) {
        this.$notify({
            title: 'Validation Error !',
            type: 'warning',
            message: message,
        })
    }

    public onClickGenerate() {
        this.isGenerateReport = false;
        if (this.filterData.limit_brand <= 10) {
            this.chartColStyle.basicBrandReport = 'col-6 mb-5';
            this.chartColStyle.yearToYearGrowthComparisonReport = 'col-6 mb-5';
            this.chartColStyle.quarterYearLineChartReport = 'col-6 mb-5 ';
            this.chartColStyle.effectiveCoverageReport = 'col-6 mb-5';
        } else {
            this.chartColStyle.basicBrandReport = 'col-8 mb-5';
            this.chartColStyle.yearToYearGrowthComparisonReport = 'col-4 mb-5';
            this.chartColStyle.quarterYearLineChartReport = 'col-12 mb-5';
            this.chartColStyle.effectiveCoverageReport = 'col-12 mb-5';
        }

        this.checkRequiredFields();
        if (this.isGenerateReport) {
            this.generateKey++;
        }
    }

    sortItems(arr: []) {
        return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    }

    created() {
        this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
            const units = this.sortItems(response.data.units);
            units.forEach((element: any, key: number) => {
                this.parametersValueTypes.push({
                    name: element.name,
                    value: 'unit' + (key + 1)
                });
            })
        });
    }

}
