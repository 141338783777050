










































































// import swal from "sweetalert2";

import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ElNotificationOptions } from 'element-ui/types/notification';

const auth = namespace('AdminAuth');
@Component
export default class AdminLogin extends Vue {
  private loading: boolean = false;
  fields = {
    email: '',
    password: '',
    device_name: '',
    remember_me: false
  };

  @auth.Getter
  public isLoggedIn!: boolean;

  @auth.Action
  public login!: (payload: any) => Promise<any>;
  navigateToForgotPassword() {
    this.$router.push({ name: 'admin-forgot-password' });
  }

  async onSubmit() {
    this.loading = true;
    this.fields.device_name = navigator.userAgent;
    try {
      await this.login({ vm: this, fields: this.fields });
      this.$notify({
        title: 'Success',
        message: 'Login successful',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      await this.$router.push({ name: 'admin-home' });
    } catch (error) {
      if (error.response.status === 422) {
        this.$notify({
          title: 'Error',
          message: 'Incorrect username or password.',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'warning'
        });
      }
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    // swal.fire("The Internet?", "That thing is still around?", "question");
  }
}
