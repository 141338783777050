





































































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import DateRangeReport from "@/components/DateRangeForReports.vue";
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryLazydropdown from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import swal from 'sweetalert2';
import moment, {
    now
} from "moment";
import {
    DatePicker,
    Select
} from 'element-ui';

@Component({
    components: {
        TerritoryCascaderDropdown,
        ProductBrandLazydropdown,
        ProductCategoryLazydropdown,
        UserLazydropdown,
        ElDatePicker: DatePicker,
        ElSelect: Select,
        DateRangeReport,
        DepartmentLazydropdown,
        BaseProductLazydropdown
    },
})
export default class StockReportDetails extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public downloadingDetails: boolean = false;
    public formData: any = {};
    public selectedTerritoriesName = '';
    public loading: boolean = false;
    public reports = [];
    public table_fields = [];
    public maxDateRange = 30;
    public maxDate: string = moment().subtract(1, 'days').format('YYYY-MM-DD');
    public filter_data = {};
    public openingPickerOptions = {
        disabledDate(time: any) {
            return time.getTime() > Date.now();
        },
    };
    public closingPickerOptions = {
        disabledDate: (time: any) => {
            const startDate = moment(this.formData.opening_date);
            const nowDate = moment(now());
            const daysDiff = nowDate.diff(startDate, 'days');

            if (daysDiff <= this.maxDateRange) {
                return time.getTime() > Date.now();
            } else {
                const endDate = moment(this.formData.opening_date).add(this.maxDateRange, 'days');
                return time.getTime() > endDate;
            }
        },
    };

    public onClickGenerate() {
        this.fetchReport();
    }

    async fetchReport() {
        this.loading = true;
        this.reports = [];
        this.$http.get(this.$api3(`/stock-report${this.$toQueryString(this.formData)}&type=summary`)).then(response => {
                this.reports = response.data.reports;
                this.table_fields = response.data.table_fields;
                this.filter_data = response.data.filter_data;
                if (this.reports.length === 0) {
                    this.$notify({
                        type: 'warning',
                        message: 'There is no data in selected filters',
                        title: 'No data found!',
                    });
                }
            })
            .catch((error) => {
                (
                    this.$refs.formValidator as Vue & {
                        setErrors: (errors: []) => any;
                    }
                ).setErrors(error.response.data.errors);
            })
            .finally(() => {
                this.loading = false;
            })
    }

    async onClickDownload(type: string) {
        if (type === 'summary') {
            this.downloading = true;
        } else {
            this.downloadingDetails = true;
        }
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api3(`/stock-report${this.$toQueryString(this.formData)}&type=${type}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            if (type === 'summary') {
                this.downloading = false;
            } else {
                this.downloadingDetails = false;
            }
        }
    }
    created() {}
}
