
























import {
  Vue,
  Component,
  Prop
} from 'vue-property-decorator';
import {
  Button,
  Select,
} from "element-ui";
import moment from 'moment';
import { MessageType } from 'element-ui/types/message';

@Component({
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
  }
})

export default class TransportationDetails extends Vue {
  @Prop() orderInfo!: any;
  public maxDate = moment().add(2, 'month').format('YYYY-MM-DD');
  public orderedTransportDetails: any = {
    challan_no: '',
    transportation_name: '',
    // order_reference: '',
    date: '',
    order_status: this.orderInfo.order_status
  };
  public orderedTransportExists: any = {};
  public orderReferences: any = {};
  public orderReferencesStatus: boolean = false;
  public disabled: boolean = this.orderInfo.order_status === 'delivered' ? true : false;

  created() {
    const settings = JSON.parse(localStorage.getItem('settings') || '{}');
    if (settings.order) {
      const order = JSON.parse(settings.order);
      this.orderReferencesStatus = order.references_status;
      if (order.references_status) {
        this.fetchOrderReferences();
      }
    }
    this.fetchTransportationData();
  }

  resetFrom() {
    this.orderedTransportDetails = {
      challan_no: '',
      transportation_name: '',
      date: '',
      // order_reference: '',
      order_status: this.orderInfo.order_status
    };
  }

  fetchTransportationData() {
    if (!this.orderInfo.order_id) {
      window.console.error('Order ID not found');
    } else {
      this.$http.get(this.$api(`/orders/${this.orderInfo.order_id}/transportations`))
        .then((response: any) => {
          this.orderedTransportExists = response.data.transportations.length > 0 ? response.data.transportations[0] : {};
          this.setOrderTransExistence();
        })
        .catch((error: any) => {
          this.message('Oops!', 'Failed to fetch transportation details', 'error');
        });
    }
  }

  async fetchOrderReferences() {
    let page = 1;
    let allReferences: any = [];

    const fetchReferences = async () => {
      const response = await this.$http.get(this.$api(`/order-reference?page=${page}`));
      const {order_references} = response.data;
      allReferences.push(...order_references.data);

      if (response.data.current_page !== response.data.last_page) {
        page += 1;
        await fetchReferences();
      }
    };

    await fetchReferences();
    this.orderReferences = allReferences;
  }

  saveTransportationData() {
    if (!this.orderInfo.order_id) {
      window.console.error('Order ID not found');
      return;
    }
    this.$http.post(this.$api(`/orders/${this.orderInfo.order_id}/transportations`), this.orderedTransportDetails)
      .then((response: any) => {
        this.orderedTransportExists = response.data.transportation;
        this.setOrderTransExistence();
        this.message('Success', 'Transportation details saved successfully', 'success');
      })
      .catch((error: any) => {
        this.message('Oops!', 'Failed to save transportation details', 'error');
      });
  }

  updateTransporationData() {
    if (!this.orderInfo.order_id) {
      window.console.error('Order ID not found');
      return;
    }
    this.$http.patch(this.$api(`/orders/${this.orderInfo.order_id}/transportations/${this.orderedTransportExists._id}`), this.orderedTransportDetails
    )
      .then((response: any) => {
        this.orderedTransportExists = response.data.transportation;
        this.setOrderTransExistence();
        this.message('Success', 'Transportation details updated successfully', 'success');
      })
      .catch((error: any) => {
        this.message('Oops!', 'Failed to update transportation details', 'error');
      });
  }

  message(title: string, message: string, type: MessageType) {
    this.$notify({
      type: type,
      title: title,
      message: message,
      duration: 5000,
      iconClass: 'ni ni-bell-55',
    });
  }

  setOrderTransExistence() {
    if (this.orderedTransportExists._id) {
      this.orderedTransportDetails = {
        challan_no: this.orderedTransportExists.challan_no,
        transportation_name: this.orderedTransportExists.transportation_name,
        date: this.orderedTransportExists.date ? moment(this.orderedTransportExists.date).format('YYYY-MM-DD') : null,
        order_reference: this.orderedTransportExists.order_reference?._id,
        order_status: this.orderInfo.order_status
      };
    }
  }
}
