




















































































// import swal from "sweetalert2";

import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ElNotificationOptions } from 'element-ui/types/notification';
import { AxiosResponse } from "axios";
import swal from "sweetalert2";

@Component
export default class AdminTokenVerify extends Vue {
  private showPassword: boolean = false;
  private loading: boolean = false;
  fields = {
    password: '',
    password_confirmation: '',
    old_token: new URLSearchParams(window.location.search).get('token') ?? '',
  };
  $refs!: {
    formValidator: HTMLFormElement | Vue
  }
  async onSubmit() {
    if (this.fields.password !== this.fields.password_confirmation) {
      this.$notify({
        title: 'Error',
        message: 'Passwords do not match',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'warning'
      });
      return;
    }
    this.loading = true;

    this.$http
      .post(this.$landLordApi('/admin/token/verify'), this.fields)
      .then((response: AxiosResponse) => {
        this.$notify({
          title: 'Success',
          message: 'Password Reset Successfully. Now login with your credentials',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.$router.push({
          name: 'admin-login',
        });
      })
      .catch((error) => {
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
        if (error.response && error.response.status === 422) {
          this.$notify({
            title: 'Error',
            message: "Invalid token or token expired.",
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'warning'
          });
        }
      }).finally(() => {
      this.loading = false;
    })
  }
}
