













































import { Permission as PermissionEnum } from '@/enums/Permission';
import { Button } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    [Button.name]: Button
  }
})
export default class HRReport extends Vue {
  private reports: any[] = [
    {
      name: 'TA Report',
      tooltip: 'A detailed Report on Travel Allowances.',
      permission: PermissionEnum.HR_TRAVEL_ALLOWANCE_REPORT,
      path: 'ta-report'
    },
    {
      name: 'Tracking Report',
      tooltip: 'A detailed Report on Trackings of users.',
      permission: PermissionEnum.HR_TRACKING_REPORT,
      path: 'tracking-report'
    },
    {
      name: 'Check-In Report',
      tooltip: 'A detailed Report on Check-Ins of users.',
      permission: PermissionEnum.HR_CHECKIN_REPORT,
      path: 'checkin-report'
    },
    {
      name: 'Attendance Report',
      tooltip: 'A detailed Report on Attendance of users.',
      permission: PermissionEnum.HR_CHECKIN_REPORT,
      path: 'attendance-report'
    },
    {
      name: 'Login History Report',
      tooltip: 'A detailed login history Report of previous 90 days of users.',
      permission: PermissionEnum.VIEW_LOGIN_HISTORY_REPORT,
      path: 'login-history-report'
    },
    {
      name: 'Visit Report',
      tooltip: 'A detailed Report on Visits of users.',
      permission: PermissionEnum.HR_VISIT_REPORT,
      path: 'visit-report'
    },
    {
      name: 'Day In Day Out Report',
      tooltip: 'A detailed Report on Day In Day Out of users.',
      permission: PermissionEnum.HR_DAY_IN_DAY_OUT_REPORT,
      path: 'day-in-day-out-report'
    },
    {
      name: 'Late Report',
      tooltip: 'A detailed Report on Late DayIn or CheckIn of users.',
      permission: PermissionEnum.HR_LATE_REPORT,
      path: 'late-report'
    }
  ];

  todaysDate() {
    var currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '/');
    return currentDate;
  }
}
