






























































































import {
    Component,
    Watch
} from 'vue-property-decorator';
import {
    mixins
} from 'vue-class-component';
import DateRange from '@/components/DateRange.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import moment from 'moment';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import LazyDropdown from '@/components/LazyDropdown.vue';
import {
    Radio,
    RadioGroup,
    Button,
    Divider
} from 'element-ui';

@Component({
    components: {
        TerritoryCascaderDropdown,
        DateRange,
        ElRadio: Radio,
        ElRadioGroup: RadioGroup,
        [Button.name]: Button,
        [Divider.name]: Divider,
        DepartmentLazydropdown,
        BaseProductLazydropdown,
        LazyDropdown
    },
})
export default class StockHistoryReport extends mixins(PaginationComponentMixin) {
    public downloading: boolean = false;
    public formData = {
        'range': '',
        'territory_id': '',
        'department_id': '',
        'base_product_id': [],
    };
    public loading: boolean = false;
    public reports = [];
    public table_fields = [];
    public filter_data = [];
    public maxDate: string = moment().format('YYYY-MM-DD');

    public onClickGenerate() {
        if (this.formData.range === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the Date range',
                title: 'Required!',
            });
        } else if (this.formData.territory_id === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the Territory',
                title: 'Required!',
            });
        } else {
            this.fetchReport();
        }
    }

    @Watch('pagination.currentPage')
    async fetchReport() {
        this.loading = true;
        this.reports = [];
        let parameter = this.$toQueryString(this.formData);
        parameter = parameter.includes('?') ? parameter.concat(`&page=${this.pagination.currentPage}`) : parameter.concat(`?page=${this.pagination.currentPage}`);
        this.$http.get(this.$api3(`/stock-history-report${parameter}`)).then((response) => {
            this.reports = response.data.stock_histories;
            this.setPagination(response.data);
            if (this.reports.length == 0) {
                this.$notify({
                    type: 'info',
                    message: 'Data not found!',
                    title: 'Not found!',
                });
            }
        }).catch((e) => {
            // let err_msg = [];
            // if (e.response.data.errors.range) {
            //     err_msg.push(e.response.data.errors.range[0]);
            // }
            // if (e.response.data.errors.territory_id) {
            //     err_msg.push(e.response.data.errors.territory_id[0]);
            // }
            // if (e.response.data.errors.basedOn) {
            //     err_msg.push(e.response.data.errors.territory_id[0]);
            // }
            // err_msg.forEach((error) => {
            //     this.$notify({
            //         type: 'warning',
            //         message: error,
            //         title: 'Validation error',
            //     });
            // });

            (
                this.$refs.formValidator as Vue & {
                    setErrors: (errors: []) => any;
                }
            ).setErrors(e.response.data.errors);
        }).finally(() => {
            this.loading = false;
        })
    }

    async onClickDownload() {
        this.downloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api3(`/stock-history-report${this.$toQueryString(this.formData)}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.downloading = false;
        }
    }
}
