var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.isView,"size":"xl"},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Priority Product Details")])]),_c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"col-md-12",staticStyle:{"min-height":"100px"}},[_c('h2',{staticClass:"mb-4"},[_vm._v("List of "+_vm._s(_vm.viewPriorityProduct.type))]),(_vm.valueTypeName)?_c('el-table',{staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.fetchedEntity}},[_c('el-table-column',{attrs:{"label":"#SL","type":"index"}}),_c('el-table-column',{attrs:{"label":"Name","prop":"priority_type_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.priority_value_name))])]}}],null,false,2805413396)}),_c('el-table-column',{attrs:{"label":"Outlets","prop":"total_outlets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.total_outlets))])]}}],null,false,3265308119)}),_c('el-table-column',{attrs:{"label":"Covered Outlets","prop":"covered_outlets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.covered_outlets))])]}}],null,false,3114522425)}),_c('el-table-column',{attrs:{"label":"%","prop":"percentage_covered_outlets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.percentage_covered_outlets))])]}}],null,false,1986540958)}),_c('el-table-column',{attrs:{"label":"Not Covered Outlets","prop":"not_covered_outlets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.not_covered_outlets))])]}}],null,false,17119699)}),_c('el-table-column',{attrs:{"label":"%","prop":"percentage_not_covered_outlets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.percentage_not_covered_outlets))])]}}],null,false,3480217588)}),_c('el-table-column',{attrs:{"label":"MEMO","prop":"total_memo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.total_memo))])]}}],null,false,1023905757)}),_c('el-table-column',{attrs:{"label":"SKU","prop":"total_products"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.total_products))])]}}],null,false,232275599)}),_c('el-table-column',{attrs:{"label":"LPC","prop":"lpc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.lpc))])]}}],null,false,3599152501)}),_c('el-table-column',{attrs:{"label":_vm.valueTypeName,"prop":"value_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.value_type==null ? 0 :row.value_type))])]}}],null,false,2494019072)})],1):_vm._e()],1)]),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v("Close")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }