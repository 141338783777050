// @ts-ignore
import { RouteConfig } from 'vue-router';
// @ts-ignore
import SupervisorReport from '@/views/SupervisorReport/SupervisorReport.vue';
// @ts-ignore
import CheckInReport from '@/views/SupervisorReport/CheckInReport/CheckInReport.vue';
// @ts-ignore
import { Permission } from "@/enums/Permission";
import store from "@/store";
import Taxonomy from "@/intefaces/Taxonomy";
import TaxonomyJson from "@/assets/json/Taxonomy.json";
import { PermissionHelper } from "@/classes/PermissionHelper";
function getTaxonomy(label: any) {
    let currentLabel: any = '';
    let taxonomy = store.getters['Settings/getTaxonomyStore'];
    let taxonomyArray: Taxonomy[] = [];
    if (taxonomy) {
        taxonomyArray = typeof taxonomy == 'string' ? JSON.parse(taxonomy) : taxonomy;
    } else {
        taxonomyArray = TaxonomyJson;
    }
    taxonomyArray.forEach((taxonomy: any) => {
        if (Object.keys(taxonomy)[0] == label) {
            currentLabel = Object.values(taxonomy)[0]
        }
    });
    return currentLabel;
}
// Redirect to user's authorized (has permission) tab
function redirectToUserAuthorizedRoute() {
    return '/supervisor-report/' + PermissionHelper([
        Permission.SUPERVISOR_CHECKIN_REPORT,
    ], [
        {permission: Permission.SUPERVISOR_CHECKIN_REPORT, name: 'checkin-report'},
    ]);
}

const SupervisorReportRoutes: RouteConfig = {
    path: 'supervisor-report',
    components: {dashboard: SupervisorReport},
    name: 'supervisor-report',
    redirect: to => {
        return redirectToUserAuthorizedRoute();
    },
    children: [
        {
            path: '/supervisor-report/checkin-report',
            name: 'supervisor-checkin-report',
            meta: {
                label: 'Check In Report',
                icon: 'fa fa-cart-arrow-down',
                permission: Permission.SUPERVISOR_CHECKIN_REPORT,
                breadCrumb: 'Check In Report',
            },
            component: CheckInReport
        },
    ]
};
export default SupervisorReportRoutes;
