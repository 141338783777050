// @ts-ignore
import InvoiceList from '@/views/admin/payment/invoice/InvoiceList.vue';
// @ts-ignore
import PaymentList from '@/views/admin/payment/payment/PaymentList.vue';
// @ts-ignore
import { RouteConfig } from 'vue-router';
// @ts-ignore
import Payment from "@/views/admin/payment/Payment.vue";

// @ts-ignore
const PaymentRoutes: RouteConfig = {
    path: 'payment',
    name: 'payment',
    redirect: '/payment/invoices',
    components: {dashboard: Payment},
    children: [
        {
            path: 'invoices',
            name: 'payment_invoices',
            // @ts-ignore
            label: 'Invoice',
            icon: 'fas fa-file-invoice',
            component: InvoiceList
        },
        {
            path: 'payments',
            name: 'Payment',
            // @ts-ignore
            label: 'Payment',
            icon: 'ni ni-credit-card',
            component: PaymentList
        }
    ]
};
export default PaymentRoutes;
