





























































import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({})

export default class BaseOrderCal extends Mixins(TaxonomyMixin) {
  @Prop() subTotal!: number;
  @Prop() badgePercentage!: number;
  @Prop({default: 0}) salesReturn!: number;
  @Prop() discount!: number;
  @Prop() grandTotal!: number;
  @Prop() totalPaid!: number;
  @Prop() totalDue!: number;
  @Prop() editable!: boolean;
  public manualDiscount: number = 0;

  calculateBadgePercentage(subTotal: number, badgePercentage: number) {
    if (badgePercentage == 0) {
      return 0;
    }
    return (subTotal * badgePercentage) / 100;
  }

  calculateGrandTotal() {
    if (this.editable) {
      return this.subTotal - this.salesReturn - this.manualDiscount - this.calculateBadgePercentage(this.subTotal, this.badgePercentage);
    } else {
      return this.grandTotal;
    }
  }

  @Watch('editable')
  getDiscountInInput(newVal: boolean) {
    this.manualDiscount = this.discount;
  }

  @Watch('manualDiscount')
  getInputedManualDiscount(value: number) {
    if (this.editable) {
      this.$emit('inputedDiscount', this.manualDiscount);
    }
  }

  calculateTotalDue() {
    if (this.editable) {
      return this.calculateGrandTotal() - this.totalPaid;
    } else {
      return this.totalDue;
    }
  }

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency']
  }
}
