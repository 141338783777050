























































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip, Tag,
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import Feature from "@/intefaces/Feature";
import CreateFeatureForm from "@/views/admin/package/feature/CreateFeatureForm.vue"
import ViewFeaturePermission from "@/views/admin/package/feature/ViewFeaturePermission.vue";
import GIFModal from "@/components/tour/GIFModal.vue";

@Component({
  components: {
    GIFModal,
    ViewFeaturePermission,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Tag.name]: Tag,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CreateFeatureForm
  }
})
export default class FeatureList extends Mixins(PaginationComponentMixin) {
  private features: Feature[] = [];
  private showViewModal = false;
  private showDeleteModal = false;
  private showGIF: boolean = false;
  private currentFeature: any = [];
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private demoTourEntity: string = 'feature';
  private demoTourTitle: string = 'A quick demo of feature';
  private GIFs: Array<any> = [
    {
      label: 'Create a role',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a role',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a role',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];

  public form = {
    title: '',
    status: true,
    permissions: []
  };

  deleteFeature() {
    this.$http.delete(this.$landLordApi(`/features/${this.currentFeature.id}`)).then(response => {
      this.showDeleteModal = false;
      this.features.splice(this.features.findIndex(feature => feature.id == this.currentFeature.id), 1);
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    })
  }

  save() {
    alert(1);
  }

  entityCreated(entity: Feature) {
    this.features.unshift(entity);
  }

  entityUpdated(entity: Feature) {
    this.features.splice(this.features.findIndex(feature => feature.id == entity.id), 1, entity);
  }

  onEdit(row: Feature) {
    this.isUpdate = true;
    this.currentFeature = row;
    let permissionList: any = [];
    this.currentFeature.permissions.forEach((permission: any) => {
      permissionList.push(permission.id);
    });
    this.form = {
      title: this.currentFeature.title,
      status: Boolean(this.currentFeature.status),
      permissions: permissionList
    };
    this.isActive = true;
  }

  @Watch('pagination.currentPage')
  fetchFeatures() {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/features?page=${this.pagination.currentPage}`))
      .then(response => {
        this.features = response.data.features;
        this.setPagination(response.data);
        this.loading = false;
      })
  }

  created() {
    this.fetchFeatures();
  }
}
