var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"logo":(_vm.$cdn + "/img/logo.png")}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Home',
          icon: 'ni ni-planet text-primary',
          path: '/admin',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Package',
          path: '/admin/package/features',
          icon: 'fa fa-cart-plus text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Subscription',
          path: '/admin/subscription/subscribers',
          icon: 'fa fa-thumbs-up text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Payment',
          path: '/admin/payment/invoices',
          icon: 'fa fa-credit-card text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Coupon',
          path: '/admin/coupon/coupons',
          icon: 'fa fa-plus-square text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Tenants',
          path: '/admin/tenant/tenants',
          icon: 'fa fa-sitemap text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'SMS',
          path: '/admin/sms/package',
          icon: 'fa fa-comment text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Billings',
          path: '/admin/billing/billing-list',
          icon: 'fa fa-credit-card text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Transaction',
          path: '/admin/transaction/transaction-report',
          icon: 'fa fa-credit-card text-primary',
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('admin-dashboard-navbar',{attrs:{"type":_vm.$route.name === 'alternative' ? 'light' : 'default'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('dashboard-content')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }