// @ts-ignore
import FeatureList from '@/views/admin/package/feature/FeatureList.vue';
// @ts-ignore
import PackageList from '@/views/admin/package/package/PackageList.vue';
// @ts-ignore
import { RouteConfig } from 'vue-router';
import Package from "@/views/admin/package/Package.vue";
// @ts-ignore
const PackageRoutes: RouteConfig = {
    path: 'package',
    name: 'package',
    redirect: '/package/features',
    components: {dashboard: Package},
    children: [
        {
            path: 'features',
            name: 'features',
            // @ts-ignore
            label: 'features',
            icon: 'fas fa-list-ul',
            component: FeatureList
        },
        {
            path: 'packages',
            name: 'packages',
            // @ts-ignore
            label: 'Packages',
            icon: 'fa fa-cart-plus',
            component: PackageList
        },
    ]
};
export default PackageRoutes;
