


































































import {
    Component,
    Mixins,
    Prop,
    Vue,
    Watch
} from 'vue-property-decorator';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import {
    PaymentTypes
} from '@/enums/PaymentTypes';

@Component({
    name: 'PaymentMethodDetails',
})
export default class PaymentMethodDetails extends Mixins(TaxonomyMixin) {
    @Prop()
    isVisible!: boolean;
    @Prop()
    currentEntity!: any;
    public payemnt_method: any = PaymentTypes.METHOD_CASH;

    close(): void {
        this.$parent.$emit('update:isVisible', false);
        this.$emit('update:isVisible', false);
    }
    get getCurrency() {
        return this.$store.getters['Settings/getCurrency'];
    }
    get paymentTypes() {
        return PaymentTypes;
    }
    created() {
        if (this.currentEntity.method === PaymentTypes.METHOD_CHEQUE) {
            this.payemnt_method = 'cheque_payment';
        } else if (this.currentEntity.method === PaymentTypes.METHOD_BANK) {
            this.payemnt_method = 'bank_payment';
        } else if (this.currentEntity.method === PaymentTypes.METHOD_MFS) {
            this.payemnt_method = 'mfs_payment';
        }
    }
}
