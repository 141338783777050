










































































































































































































import {
    Component,
    Vue,
    Watch
} from "vue-property-decorator";
import {
    Popover,
    Switch,
    Select,
    Option
} from "element-ui";

@Component({
    components: {
        [Switch.name]: Switch,
        [Popover.name]: Popover,
        [Select.name]: Select,
        [Option.name]: Option,
    }
})

export default class Tracking extends Vue {
    public form: any = {
        selfie_interval: null,
        checkin_limit_per_outlet: null,
        checkin_interval_per_outlet: null,
        pjp_status: null,
        checkin_required_before_order: null,
        day_in_out_status: null,
        not_order_remarks: null,
    };
    public selfieInterval = false;
    public checkinLimitPerOutlet = false;
    public checkinIntervalPerOutlet = false;
    public pjpStatus = false;
    public checkinRequiredBeforeOrder = false;
    public notOrderRemarksStatus = false;
    public dayInDayOutStatus = false;
    public loading = false;

    public remarks: {
        id: number,
        description: string
    } [] = [];
    public newRemark: {
        id: number,
        description: string
    } = {
        id: 0,
        description: ''
    };

    public isTooltipOpen = false;

    updateTrackingSettings() {

        this.loading = true;
        this.$http.patch(this.$api('/settings/1'), {
            tracking: {
                selfie_interval: this.selfieInterval ? this.form.selfie_interval : null,
                checkin_limit_per_outlet: this.checkinLimitPerOutlet ? this.form.checkin_limit_per_outlet : null,
                checkin_interval_per_outlet: this.checkinIntervalPerOutlet ? this.form.checkin_interval_per_outlet : null,
                pjp_status: this.pjpStatus,
                checkin_required_before_order: this.checkinRequiredBeforeOrder,
                not_order_remarks: {
                    status: this.notOrderRemarksStatus,
                    options: this.remarks.map(remark => remark.description)
                },
                day_in_out_status: this.dayInDayOutStatus
            }
        }).then(response => {

            if (response.status == 200) {
                this.updateTrackingNotification(response.status);
            } else {
                this.updateTrackingNotification(response.status);
            }
        }).catch(error => {
            (this.$refs.formValidator as Vue & {
                setErrors: (errors: []) => any;
            }).setErrors(error.response.data.errors);
            this.$notify({
                message: "Please contact your service provider.",
                title: "Something went wrong!",
                type: 'warning'
            })
        }).finally(
            () => (this.loading = false)
        );
    }

    fetchSettings() {
        this.$http.get(this.$api(`/settings`)).then(response => {
            if (response.data.settings.tracking != null) {
                this.form = JSON.parse(response.data.settings.tracking);
                this.selfieInterval = !!this.form.selfie_interval;
                this.checkinLimitPerOutlet = !!this.form.checkin_limit_per_outlet;
                this.checkinIntervalPerOutlet = !!this.form.checkin_interval_per_outlet;
                this.pjpStatus = this.form.pjp_status;
                this.dayInDayOutStatus = this.form.day_in_out_status;
                this.checkinRequiredBeforeOrder = this.form.checkin_required_before_order;
                const notOrderRemarks = this.form.not_order_remarks;
                if (notOrderRemarks != null) {
                    this.notOrderRemarksStatus = notOrderRemarks.status;
                    this.remarks = notOrderRemarks.options.map((option: any, index: any) => ({
                        id: index + 1,
                        description: option
                    }));

                }
            }
        });
    }

    updateTrackingNotification(status: number) {
        this.$notify({
            title: status == 200 ? 'Success' : 'Error',
            message: status == 200 ? "Settings successfully updated" : "Settings could not be updated",
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            // @ts-ignore
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            type: 'success'
        });
    }
    orderRemarkExceedErrorNotification() {
        this.$notify({
            title: 'Error',
            message: "You can only add up to 10 remarks.",
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            // @ts-ignore
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            type: 'warning'
        });
    }

    created() {
        this.fetchSettings();
    }

    addRemark() {
        if (this.remarks.length >= 10) {
            this.orderRemarkExceedErrorNotification();
            return;
        }
        if (this.newRemark.description.trim() !== '') {
            const maxId = this.remarks.reduce((max, remark) => Math.max(max, remark.id), 0);
            this.newRemark.id = maxId + 1;
            this.remarks.push({
                ...this.newRemark
            });
            this.newRemark.description = '';

        }
    }

    removeRemark(id: number) {
        const index = this.remarks.findIndex(remark => remark.id === id);
        if (index !== -1) {
            this.remarks.splice(index, 1);
        }
    }

    hideTooltip() {
        this.isTooltipOpen = false;
    }
}
