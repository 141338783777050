











































































































































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import PriorityProductData from '@/intefaces/PriorityProductData';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag, Switch, MessageBox } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'
import PriorityProductForm from "@/components/products/PriorityProductForm.vue";
import PromotionViewForm from "@/components/products/PromotionViewForm.vue";
import PriorityProductView from "@/components/products/PriorityProductView.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";

const flatPicker = require("vue-flatpickr-component");

@Component({
  components: {
    TerritoryCascaderDropdown,
    PriorityProductView,
    PromotionViewForm,
    PriorityProductForm,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    [Switch.name]: Switch,
    GIFModal,
    FilterDrawerComponent,
    DateRangeForReport

  }
})
export default class PriorityProduct extends Mixins(PaginationComponentMixin) {
  private priorityProducts: any = [];
  private currentPriorityProduct: any;
  public showDetailsModal: boolean = false;
  public currentDetailsData = null;
  private isActive = false;
  private isFilterModelActive = false;
  private isUpdate = false;
  private showGIF: boolean = false;
  private form: PriorityProductData = {
    title: '',
    territory_id: null,
    start_date: '',
    expires_at: '',
    type: '',
    status: '',
    value: [],
  };
  private demoTourEntity: string = 'priority products';
  private queryBuilder = new QueryBuilder();
  private GIFs: Array<any> = [
    {
      label: 'Create a category',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a category',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a category',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of priority products';
  private filters: any = {
    title: '',
    start_date: '',
    expires_at:'',
    type:'',
    status: '',
    territory_id: '',
    parent_territory_id: '',
  };
  private valueTypeList = [
    {'label': 'Brand', 'name': 'brand'},
    {'label': 'Category', 'name': 'category'},
    {'label': 'SKU', 'name': 'sku'}
  ]
  switchColor(status: string): string {
    switch (status) {
      case 'active':
        return 'green';
      case 'inactive':
        return 'red';
      case 'draft':
        return 'black';
      case 'completed':
        return 'blue';
      default:
        return 'yellow';
    }
  }
  data() {
    return {
      options: [{
        value: 'active',
        label: 'Live'
      }, {
        value: 'inactive',
        label: 'Inactive'
      }, {
        value: 'draft',
        label: 'Draft'
      },{
        value: 'completed',
        label: 'Completed'
      }],
      value: ''
    }
  }

  resetBtn() {
    this.filters.title = '';
    this.filters.start_date = '';
    this.filters.expires_at = '';
    this.filters.type = '';
    this.filters.status = '';
    this.filters.territory_id = '';
    this.filters.parent_territory_id = '';

  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }

  get permissionEnum() {
    return PermissionEnum;
  }
  created() {
    this.fetchPriorityProducts();
  }

  @Watch('filters.title', {deep: true})
  onChangeNameFiler(newVal: string) {
    this.queryBuilder.addListener('title', newVal);
  }

  @Watch('filters.status', {deep: true})
  onChangeStatusFiler(newVal: string) {
    this.queryBuilder.addListener('status', newVal);
  }

  @Watch('filters.expires_at', {deep: true})
  onChangeExpiresAtFiler(value: string) {
    this.queryBuilder.addListener('expires_at', value);
  }
  @Watch('filters.start_date', {deep: true})
  onChangeStartDateFiler(value: string) {
    this.queryBuilder.addListener('start_date', value);
  }
  @Watch('filters.type', {deep: true})
  onChangeTypeFiler(value: string) {
    this.queryBuilder.addListener('type', value);
  }
  @Watch('filters.territory_id', {deep: true})
  onChangeTerritoryIdFiler(value: string) {
    this.queryBuilder.addListener('territory_id', value);
  }

  @Watch('filters.parent_territory_id', {deep: true})
  onChangeParentTerritoryIdFiler(value: string) {
    this.queryBuilder.addListener('parent_territory_id', value);
  }

  @Watch('pagination.currentPage')
  @Watch('queryBuilder.watch')
  fetchPriorityProducts() {
    this.priorityProducts = [];
    this.loading = true;
    this.$http.get(this.$api(`/priority-products${this.queryBuilder.getFilters(true)
      ? this.queryBuilder.getFilters(true) : '?'}&page=${this.pagination.currentPage}`))
      .then(response => {
        this.priorityProducts = response.data.priorityProducts.map((product: any) => ({
          ...product,
          is_switch_on: product.status === 'active' ? true : false,
        }));
        this.setPagination(response.data);
        this.loading = false;
      });
  }

  isExpired(expires_at: string): boolean {
    const expiresAtDate = new Date(expires_at);
    const today = new Date();
    return expiresAtDate < today;
  }

  onSwitchChange(row: any) {
    let text = row.status == "active" ? "active to inactive" : "inactive to active";
    MessageBox.alert('<span style="font-size: 14px">' +
      'Please review the information carefully. Once this status changed ' + text +
      ', it cannot be retrieved or deleted from the system.' +
      '<br> <br>System admin may help to adjust this data if made any mistakes.</span>', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      dangerouslyUseHTMLString: true,
      type: 'warning',
      center: true
    })
      .then(() => {
        this.$http.get(this.$api(`/priority-products/change-status/${row.id}`))
          .then(response => {
            this.fetchPriorityProducts();
            this.$notify({
              title: 'Success',
              message: `Status changed ${text} successfully`,
              duration: 5000,
              iconClass: 'ni ni-bell-55',
              type: 'success'
            });
          })
      })
      .catch(() => {
        row.is_switch_on = !row.is_switch_on;
      });
  }

  onEdit(row: PriorityProductData) {
    this.isUpdate = true;
    this.currentPriorityProduct = row;
    const valueString = this.currentPriorityProduct.value;
    let value = valueString.length == undefined ? [parseInt(valueString)] : valueString.split(',');
    this.form = {
      title: this.currentPriorityProduct.title,
      territory_id: this.currentPriorityProduct.territory_id,
      start_date: this.currentPriorityProduct.start_date,
      expires_at: this.currentPriorityProduct.expires_at,
      type: this.currentPriorityProduct.type,
      status: this.currentPriorityProduct.status,
      value: value,
    };
    this.isActive = true;
  }
  priorityProductCreated(priorityProduct: any) {
    this.isActive = false;
    this.pagination.currentPage = 1;
    this.fetchPriorityProducts();
  }

  priorityProductUpdated(priorityProduct: any) {
    this.isUpdate = false;
    this.isActive = false;
    this.fetchPriorityProducts();
  }
}
