var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-inline"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Invoices")]),_c('el-tooltip',{staticClass:"ml-3",attrs:{"content":"Manage similar type of department (ex. dealer, distributor) for your business.","placement":"right"}},[_c('i',{staticClass:"fas fa-info-circle",staticStyle:{"color":"grey","font-size":"18px"}})])],1)]),_c('div',{staticClass:"col-6 text-right"},[_c('el-tooltip',{attrs:{"content":"Add New Department Type","placement":"top"}},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){_vm.isActive = true}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add")])])],1)],1)])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.departmentTypes}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"250px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Label","min-width":"280px","prop":"description","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.label))])]}}])}),_c('el-table-column',{attrs:{"label":"Created At","min-width":"210px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(_vm._f("datetime")(row.created_at,'ll')))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Update Department Type","placement":"top"}},[_c('a',{staticClass:"table-action",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Edit product"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('i',{staticClass:"fas fa-edit"})])]),_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Delete Territory Type"},on:{"click":function($event){$event.preventDefault();(_vm.showDeleteModal = true), (_vm.currentEntity = row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}])})],1),(_vm.departmentTypes.length > 0)?_c('base-pagination',{staticClass:"mt-3",attrs:{"perPage":_vm.pagination.entityPerPage,"total":_vm.pagination.totalEntity,"align":"center","disabled":_vm.loading},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e(),_c('modal',{attrs:{"show":_vm.showDeleteModal},on:{"update:show":function($event){_vm.showDeleteModal=$event}}},[[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel1"}},[_vm._v(" Are you sure you want to delete this ? ")])],_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("Close ")]),_c('base-button',{attrs:{"type":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteEntity($event)}}},[_vm._v("Delete ")])],1)],2),_c('GIFModal',{attrs:{"GIFs":_vm.GIFs,"demoTourEntity":_vm.demoTourEntity,"demoTourTitle":_vm.demoTourTitle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }