





























import {
    Vue,
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import Order from "@/intefaces/Order";
import RevisionList from "@/views/order/Components/RevisionList.vue";
import OrderRevision from "@/intefaces/OrderRevision";
import Department from "@/intefaces/Department";

@Component({
    components: {
        RevisionList,
    }
})
export default class OrderDepartmentInfo extends Vue {
    @Prop() title!: string;
    @Prop() currentEntity!: Order;
    @Prop() revisionList!: OrderRevision[];
    @Prop({
        default: null
    }) orderDeliveredBy!: any | null;
    public isActiveRevision: boolean = false;
    public buyerDepartment: any = {};
    public sellerDepartment: any = {};
    public ordereReference: any = {};

    created() {
        this.$http.get(this.$api(`/departments/${this.currentEntity.buyer_id}`)).then(response => {
            this.buyerDepartment = response.data.department;
        })
        this.$http.get(this.$api(`/departments/${this.currentEntity.seller_id}`)).then(response => {
            this.sellerDepartment = response.data.department;
        })
        this.fetchOrderReference();
    }

    checkOrderReferenceStatus() {
        const settings = JSON.parse(localStorage.getItem('settings') || '{}');
        if (settings.order) {
            const order = JSON.parse(settings.order);
            return order && order.references_status;
        }
        return false;
    }

    fetchOrderReference() {
        if (this.checkOrderReferenceStatus()) {
            let orderId = null;
            if(this.currentEntity.latest_status === 'pending' || this.currentEntity.status === 'delivered') {
               orderId = this.currentEntity.id;
            } else if(this.currentEntity.latest_status === 'accepted' || this.currentEntity.latest_status === 'readyToDispatch' || this.currentEntity.latest_status === 'ontheway') {
              // @ts-ignore
               orderId = this.currentEntity.order_id;
            }
            if (orderId) {
              this.$http.get(this.$api(`/orders/${orderId}/transportations`))
                .then((response: any) => {
                    this.ordereReference = response.data.transportations.length > 0 ? response.data.transportations[0]?.order_reference : {};
                });
            }

        }
    }
}
