




































import {
    Component,
    Prop,
    Vue,
    Watch,
    Mixins,
} from 'vue-property-decorator';
import {
    QueryBuilder
} from "@/classes/QueryBuilder";
import moment from "moment";
import DateRange from "@/components/DateRange.vue";
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import {
    Permission as PermissionEnum
} from "@/enums/Permission";

const flatPicker = require('vue-flatpickr-component');
@Component({
    components: {
        flatPicker,
        DateRange,

    }
})

export default class DownloadReprot extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public loading: boolean = false;
    public printing: boolean = false;
    private showModal: boolean = false;
    public created_by: number | null = null;
    @Prop() currentReport!: any;
    public queryBuilder: QueryBuilder = new QueryBuilder();
    public reports = [];
    public fields = [];
    public range: string = '';
    public rangeSelectConfig = {
        allowInput: true,
        disable: [
            function (range: Date) {
                return moment().diff(range) < 0;
            }
        ]
    }

    todaysDate() {
        var currentDate = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, '/');
        return currentDate;
    }

    @Watch('queryBuilder.watch')
    async getReport() {
        if (!this.range) {
            this.reports = [];
            return
        }
        this.loading = true;
        try {
            let {
                data
            } = await this.$http.get(
                this.$api3('/custom-report/' + this.$route.params.reportId + this.queryBuilder.getFilters(true))
                );
            this.fields = data.fields;
            this.reports = data.data.data;
            this.setPagination(data.data);
            if (this.reports.length === 0) {
                this.$notify({
                    message: "Data isn't available in this selected date",
                    title: "Data Empty!",
                    type: 'info'
                })
            }
        } catch (e) {
            this.$notify({
                message: "Please contact your service provider.",
                title: "Something went wrong!",
                type: 'warning'
            })
        } finally {
            this.loading = false;
        }
    }
    async onClickDownload() {
        this.downloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api3('/custom-report/' + this.$route.params.reportId + this.queryBuilder.getFilters(true) + '&download'), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.downloading = false;
        }
    }

    @Watch('range')
    onChangeRange(value: number) {
        this.queryBuilder.addListener('page', 1);
        this.queryBuilder.addListener('range', value);
    }

    @Watch('pagination.currentPage')
    onChangePage(newVal: any) {
        this.queryBuilder.addListener('page', newVal);
    }

    get permissionEnum() {
        return PermissionEnum;
    }
}
