











































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import Order from '@/intefaces/Order';
import { Divider } from 'element-ui';
import BaseOrderProducts from '@/views/order/Components/BaseOrderProducts.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
import BaseOrderComment from '@/views/order/BaseOrderComment.vue';
import BaseOrderCal from '@/views/order/Components/BaseOrderCal.vue';
import OrderStatus from '@/views/order/Components/OrderStatusButtons.vue';
import OrderDepartmentInfo from '@/views/order/Components/OrderDepartmentInfo.vue';
import OrderEdit from '@/views/order/Components/OrderEdit.vue';
import OrderRevision from '@/intefaces/OrderRevision';
import Statuses from '@/intefaces/Statuses';

@Component({
  components: {
    [Divider.name]: Divider,
    BaseOrderProducts,
    BaseOrderComment,
    BaseOrderCal,
    OrderStatus,
    OrderDepartmentInfo,
    OrderEdit
  }
})
export default class BaseOrderDetails extends Mixins(TaxonomyMixin) {
  @Prop() title: string | undefined;
  @Prop() isActive: boolean | undefined;
  @Prop() currentEntity!: Order;
  @Prop() filterForRequisition!: string;
  @Prop() editPermission!: string;
  @Prop() statusPermission!: string;
  private editable: boolean = false;
  private disabledSaveBtn: boolean = false;
  private subTotal: number = 0;
  private salesReturn: number = 0;
  private returnProducts: any = [];
  private discount: number = 0;
  private saveEditedOrder: boolean = false;
  private revisionList: OrderRevision[] = [];
  private statuses: Statuses[] = [];

  updateOrder(event: any) {
    this.$emit('editedEntity', event);
  }

  @Watch('isActive')
  getTotalValues(newVal: boolean) {
    if (newVal) {
      this.subTotal = this.currentEntity.latest_revision.amount;
      if(this.currentEntity.latest_revision.returned_products) {
        this.salesReturn = this.currentEntity.latest_revision.returned_products.reduce( (acc: any, item: any) => {
          return acc + item.amount;
        }, 0)
      }
    }
  }

  @Watch('isActive')
  fetchList(newVal: boolean) {
    if (newVal) {
      this.$http.get(this.$api(`/orders/${this.currentEntity.id}/revisions`)).then(resposne => {
        this.revisionList = resposne.data.revisions;
      });
      this.$http.get(this.$api(`/orders/${this.currentEntity.id}/statuses`)).then(resposne => {
        this.statuses = resposne.data.statuses;
      });
      // this.$http.get(this.$api(`/sales-returns?order_id=${this.currentEntity.id}`)).then(resposne => {
      //   this.returnProducts = resposne.data.salesReturns[0].returned_products;
      //   this.salesReturn = resposne.data.salesReturns[0].total_amount;
      // });
    }
  }

  updateStatus(event: any) {
    this.$http.get(this.$api(`/orders/${event.status.order.id}`)).then(responseOrder => {
      this.$emit('updatedStatus', responseOrder.data.order);
    });
  }

  close(): void {
    this.$emit('update:isActive', false);
    this.editable = false;
    this.saveEditedOrder = false;
  }
}
