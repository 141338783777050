




































































































































import {
  Component,
  Mixins,
  Vue,
  Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import LazyDropdown from '@/components/LazyDropdown.vue';
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import moment from "moment";
import {
  Tree, ButtonGroup, Button, Link
} from 'element-ui';

@Component({
  components: {
    TerritoryCascaderDropdown,
    UserLazydropdown,
    DateRange,
    LazyDropdown,
    [Tree.name]: Tree,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    BaseProductLazydropdown,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
    [Link.name]: Link,
  }
})
export default class OutletRejectionReport extends Mixins(PaginationComponentMixin, Vue) {
  public downloading: boolean = false;
  public downloadingSingle: boolean = false;
  public formData = {
    range: '',
    parent_id: 0,
    territory_id: 1,
    department_id: '',
    user_id: '',
    value_type: '',
    category_id: '',
    brand_id: '',
    product_id: '',
  };
  public loading: boolean = false;
  public reports = [];
  public selectedBrands: any = '';
  public showOutletModal: boolean = false;
  public usersNotBuilledOutlets: any = [];
  public user: any = this.$store.getters['Auth/user'];
  public props: object = {
    label: 'name',
    isLeaf: 'leaf',
  };
  public maxDate: string = moment().format('YYYY-MM-DD');
  public selectedButton: string = '';
  public parametersValueTypes: any = [{
    'name': 'Amount',
    'value': 'amount'
  }, {
    'name': 'Quantity',
    'value': 'qty'
  }];

  created() {
    // this.formData.range = moment().subtract(8, "days").format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD')
    // this.formData.territory_id = this.checkParentId;
    this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
      const units = this.sortItems(response.data.units);
      units.forEach((element: any, key: number) => {
        this.parametersValueTypes.push({
          name: element.name,
          value: 'unit' + (key + 1)
        });
      })
    });
  }

  sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  public onClickGenerate() {
    if (this.formData.range && this.formData.territory_id && this.formData.value_type) {
      this.fetchReport();
    } else {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!'
      });
    }
  }

  async fetchReport() {
    this.loading = true
    this.reports = [];
    const {['parent_id']: _, ...filteredObject} = this.formData;
    await this.$http.get(this.$api3(`/bounce-rate${this.$toQueryString(filteredObject)}`)).then((response: any) => {
      this.reports = response.data.territories;
    }).catch((e) => {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.territory_id) {
        err_msg.push(e.response.data.errors.territory_id[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
    }).finally(() => {
      this.loading = false
    })
  }

  async onClickDownload() {
    this.downloading = true;
    const {['parent_id']: _, ...filteredObject} = this.formData;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/bounce-rate${this.$toQueryString(filteredObject)}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }

  get checkParentId() {
    if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
      {
        if (this.user.managed_territory.id != 1) {
          return this.user.managed_territory.parent_id;
        } else {
          return '';
        }
      }
    } else {
      if (this.user.territory && this.user.territory.parent_id) {
        return this.user.territory.parent_id;
      } else {
        return '';
      }
    }
  }

  loadNode(node: any, resolve: any) {
    let territories: any[] = [];
    this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
    const {['territory_id']: _, ...filteredObject} = this.formData;
    if (this.formData.range) {
      this.$http.get(this.$api3(`/bounce-rate${this.$toQueryString(filteredObject)}`)).then((response) => {
        territories = response.data.territories;
        let treeData = territories.map((territory) => {
          const container: any = {};
          container['name'] = territory.name;
          container['total_order'] = territory.total_order;
          container['total_value'] = territory.total_value;
          container['accepted_order'] = territory.accepted_order;
          container['accepted_value'] = territory.accepted_value;
          container['accepted_percentage'] = territory.accepted_percentage;
          container['delivered_order'] = territory.delivered_order;
          container['delivered_value'] = territory.delivered_value;
          container['delivered_percentage'] = territory.delivered_percentage;
          container['rejected_order'] = territory.rejected_order;
          container['rejected_value'] = territory.rejected_value;
          container['rejected_percentage'] = territory.rejected_percentage;
          container['leaf'] = !territory.children_count;
          container['id'] = territory.id;
          return container;
        });
        resolve(treeData);
        this.loading = false;
      });
    }
  }

  @Watch('formData.range')
  todayButtonFocus(range: any) {
    const rangeArray = range.split(',').map((item: any) => item.trim());
    if (rangeArray[0] === moment().format('YYYY-MM-DD') && rangeArray[0] === rangeArray[1]) {
      this.selectedButton = 'Today';
    }
  }

  updateDateRange(range: string) {
    this.selectedButton = range;
    if (range === 'Today') {
      this.formData.range = moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
    } else if (range === 'WTD') {
      this.formData.range =
        moment()
          .day(-1)
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    } else if (range === 'MTD') {
      this.formData.range =
        moment()
          .startOf('month')
          .format('YYYY-MM-DD') +
        ',' +
        moment().format('YYYY-MM-DD');
    }
  }

  getValueType() {
    const foundItem = this.parametersValueTypes.find((item: any) => item.value === this.formData.value_type);
    if (foundItem) {
      return foundItem.name;
    }
    return this.formData.value_type;
  }

  getProductParams() {
    if (this.formData.category_id !== null || this.formData.category_id !== null) {
      let params = this.formData.category_id ? '?category_ids=' + this.formData.category_id : '';
      params = params.includes('?') ? params.concat(`&brand_ids=${this.formData.brand_id}`) : params.concat(`?brand_ids=${this.formData.brand_id}`);
      return params;
    }
    return '';
  }

  async handleaDownload(territoryId: number, type: string) {
    this.downloadingSingle = true;
    try {
      this.formData.parent_id = territoryId;
      const {['territory_id']: _, ...filteredObject} = this.formData;
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/bounce-rate${this.$toQueryString(filteredObject)}&download&status=${type === 'all' ? '' : type}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloadingSingle = false;
    }
  }
}
