


















// import SMSRecharge from './SMSRecharge.vue'
import {
    Component,
    Mixins,
    Vue,
    Watch
} from "vue-property-decorator";

@Component({
    components: {
        // SMSRecharge
    }
})
export default class Balanace extends Mixins(Vue) {
    public total_sms: number = 0;

    created() {
        this.fetchTenant();
    }
    fetchTenant() {
        this.$http.post(this.$landLordApi('/validate-org'), { domain: localStorage.getItem('orgName') })
            .then(response => {
                this.total_sms = response.data.org.total_sms;
            })
    }
}
