









































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Popover, Switch, Select, Option } from 'element-ui';
@Component({
  components: {
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    [Select.name]: Select,
    [Option.name]: Option,
  },
})
export default class Vat extends Vue {
  private vat: number = 0;
  private retrievedSettings: any = '';
  private loading: boolean = false;
  updateSettings() {
    this.loading = true;
    this.$http
      .patch(this.$api('/settings/1'), {
        vat: this.vat,
      })
      .then((response) => {
        this.updateSettingsNotification();
      })
      .catch((error) => {
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
        this.$notify({
          message: 'Please contact your service provider.',
          title: 'Something went wrong!',
          type: 'warning',
        });
      })
      .finally(() => (this.loading = false));
  }
  updateSettingsNotification() {
    this.$notify({
      title: 'Success',
      message: 'Settings Updated',
      duration: 5000,
      iconClass: 'ni ni-bell-55',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: 'success',
    });
  }
  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then((response) => {
      this.vat = response.data.settings.vat;
    });
  }
  created() {
    this.fetchSettings();
  }
}
