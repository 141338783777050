




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  Tooltip,
  Card,
  Tag
} from 'element-ui';
// import ManagerList from "@/views/department/ManagerList.vue";

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Card.name]: Card,
    [Tag.name]: Tag,
    // ManagerList,
  }
})
export default class TerritoryDetailsModal extends Vue {
  private territory: any = [];
  private loading: boolean = false;
  @Prop()
  territoryId!: number;

  @Prop({type: Boolean, default: false})
  public showTerritory!: boolean;

  close() {
    this.$emit('update:showTerritory', false);
  }

  async mounted() {
    this.loading = true;
    let {data} = await this.$http.get(this.$api(`/territories/${this.territoryId}`));
    this.territory = data.territory;
    this.loading = false;
  }

}
