




























import { Component, Vue } from 'vue-property-decorator';
import PerformanceReport from '@/views/Report/KPI/PerformanceReport.vue'; import {
  Button
} from 'element-ui';

@Component({
  components: { PerformanceReport, [Button.name]: Button },
})
export default class KPIReports extends Vue { }
