






















































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tooltip, Tag } from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import Package from '@/intefaces/Package';
import CreatePackageForm from '@/views/admin/package/package/CreatePackageForm.vue';
import ViewPackageFeature from '@/views/admin/package/package/ViewPackageFeature.vue';

@Component({
  components: {
    CreatePackageForm,
    ViewPackageFeature,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
  },
})
export default class PackageList extends Mixins(PaginationComponentMixin) {
  private packages: Package[] = [];
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private isActivePackageView: boolean = false;
  private currentPackage: any = [];
  private showDeleteModal: boolean = false;

  private form: any = {
    name: '',
    status: true,
    schemes: [
      {
        cycle: 0,
        price: 0,
        newScheme: 0, //newScheme = 0, new scheme, newScheme >= 0 (scheme.id), existing scheme
      },
    ],
    trialDays: 0,
    features: [],
  };

  deleteEntity(): void {
    this.$http.delete(this.$landLordApi(`/packages/${this.currentPackage.id}`))
      .then((response) => {
        this.showDeleteModal = false;
        if (response.data.schemes === 0) {
          this.packages.splice(
            this.packages.findIndex((value) => value.id == this.currentPackage.id),
            1
          );
          this.$notify({
            title: 'Success',
            message: 'Successfully Deleted',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success',
          });
        } else {
          this.$notify({
            title: 'Warning',
            message: "This package schema has subscription records. Deletion is not possible.",
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'warning',
          });
        }
      });
  }

  createPackage(newPackage: any) {
    this.isActive = false;
    this.packages.unshift(newPackage);
  }

  updatePackage(updatedPackage: any) {
    this.isUpdate = false;
    this.packages.splice(
      this.packages.findIndex((pack) => pack.id == updatedPackage.id),
      1,
      updatedPackage
    );
    this.isActive = false;
  }

  onEdit(row: any) {
    this.isUpdate = true;
    this.isActive = true;
    this.currentPackage = row;
    this.form.schemes = [];
    this.form.features = [];
    this.form.name = this.currentPackage.name;
    this.form.status = Boolean(this.currentPackage.status);
    this.form.trialDays = this.currentPackage.trial_days;
    this.currentPackage.features.forEach((feature: any) => {
      this.form.features.push(feature.id);
    });
    this.currentPackage.schemes.forEach((scheme: any) => {
      this.form.schemes.push({
        cycle: scheme.cycle,
        price: scheme.price,
        newScheme: scheme.id,
      });
    });
  }

  @Watch('pagination.currentPage')
  fetchPackage() {
    this.loading = true;
    this.$http.get(this.$landLordApi(`/packages?page=${this.pagination.currentPage}`)).then((response) => {
      this.packages = response.data.packages;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchPackage();
  }
}
