
































































































































import {Component, Vue} from "vue-property-decorator";
// @ts-ignore
import Collapse from '@/components/argon-core/Collapse/Collapse.vue';
// @ts-ignore
import CollapseItem from '@/components/argon-core/Collapse/CollapseItem.vue';
import SmsEvents from '@/assets/json/SmsEvents.json'
import {Checkbox, Card, Tag, Popover} from 'element-ui'
import GIFModal from "@/components/tour/GIFModal.vue";

@Component({
  components: {
    [Checkbox.name]: Checkbox,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Popover.name]: Popover,
    Collapse,
    CollapseItem,
    GIFModal
  }
})
export default class SMS extends Vue {
  private smsEvents: Array<object> = []
  private keywords: Array<object> = [
    {
      "name": "{buyer}",
      "about": "Indicates the department which is buying."
    },
    {
      "name": "{seller}",
      "about": "Indicates the department which is selling."
    },
    {
      "name": "{invoice}",
      "about": "Indicates the invoice ID."
    },
    {
      "name": "{fieldforce}",
      "about": "Indicates the associated field force."
    },
    {
      "name": "{amount}",
      "about": "Indicates the total amount of invoice."
    },
    {
      "name": "{status}",
      "about": "Indicates the associated Status of Payment."
    }
  ]
  private alwaysTrue: boolean = true;
  private showGIF: boolean = false;
  private demoTourEntity: string = 'smsEvents';
  private GIFs: Array<any> = [
    {
      label: 'Create an SMS event',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update an SMS event',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete an SMS event',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of SMS events';

  fetchSettings() {
    this.$http.get(this.$api(`/settings`)).then(response => {
      if (response.data.settings.sms_events != null) {
        if (!JSON.parse(response.data.settings.sms_events)[0].hasOwnProperty('recipient')) {
          this.smsEvents = JSON.parse(response.data.settings.sms_events).map(function (el: any) {
            var smsEvent = Object.assign({}, el);
            smsEvent.recipient = 'buyer';
            return smsEvent;
          })
          this.$http.patch(this.$api('/settings/1'), {sms_events: this.smsEvents});
        } else {
          this.smsEvents = JSON.parse(response.data.settings.sms_events);
        }
      } else {
        this.smsEvents = SmsEvents;
        this.$http.patch(this.$api('/settings/1'), {sms_events: this.smsEvents});
      }
    });
  }

  decamelize(str: string) {
    var result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  updateSMS() {
    this.$http.patch(this.$api('/settings/1'), {sms_events: this.smsEvents}).then(response => {
      if (response.status == 200) {
        this.updateSMSNotification(response.status);
      } else {
        this.updateSMSNotification(response.status);
      }
    })
  }

  updateSMSNotification(status: number) {
    this.$notify({
      title: status == 200 ? 'Success' : 'Error',
      message: status == 200 ? "SMS settings updated" : "SMS settings could not be updated",
      duration: 5000,
      iconClass: 'fa fa-sms',
      // @ts-ignore
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type: status == 200 ? 'success' : 'warning'
    });
  }

  created() {
    this.fetchSettings();
  }
}
