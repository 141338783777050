













import {
  Component,
  Mixins,
  Prop
} from "vue-property-decorator";
import LazyDropdown from "../LazyDropdown.vue";
import DropdownField from "../../mixins/DropdownField";
import CreateChannelForm from "@/views/department/CreateChannelForm.vue";

@Component({
  components: {
    LazyDropdown,
    CreateChannelForm
  }
})
export default class ChannelLazydropdown extends Mixins(DropdownField) {
  @Prop() addButton!: boolean;
  @Prop() disabled!: boolean;
}
