
// @ts-ignore
import { RouteConfig } from 'vue-router';
import AdminDashboard from '@/views/admin/AdminDashboard.vue';
import AdminLogin from '@/views/admin/auth/AdminLogin.vue';
import AdminForgotPassword from '@/views/admin/auth/AdminForgotPassword.vue';
import AdminSubmitCodeForgotPassword from '@/views/admin/auth/AdminTokenVerify.vue';
import AdminHome from '@/views/admin/AdminHome.vue';
import SubscriptionRoutes from '@/router/admin-routes/SubscriptionRoutes';
import PackageRoutes from '@/router/admin-routes/PackageRoutes';
import CouponRoutes from "@/router/admin-routes/CouponRoutes";
import PaymentRoutes from "@/router/admin-routes/PaymentRoutes";
import AdminTokenVerify from "@/views/admin/auth/AdminTokenVerify.vue";
import AdminMailSender from "@/views/admin/auth/AdminMailSender.vue";
import TenantRoutes from "@/router/admin-routes/TenantRoutes";
import SMSRoutes from "@/router/admin-routes/SMSRoutes";
import BillingRoutes from "@/router/admin-routes/BillingRoutes";
import TransactionRoutes from "@/router/admin-routes/TransactionRoutes";

// @ts-ignore
export const AdminRoutes: RouteConfig[] = [
    {
        path: '/admin',
        meta: {requiresAdminAuth: true},
        component: AdminDashboard,
        children: [
            {
                path: '',
                name: 'admin-home',
                components: {dashboard: AdminHome}
            },
            SubscriptionRoutes,
            PackageRoutes,
            PaymentRoutes,
            CouponRoutes,
            TenantRoutes,
            SMSRoutes,
            BillingRoutes,
            TransactionRoutes,
        ]
    },
    {
        path: '/admin/login',
        name: 'admin-login',
        component: AdminLogin
    },
    {
        path: '/admin/forgot-password',
        name: 'admin-forgot-password',
        component: AdminForgotPassword
    },
    {
        path: '/admin/token-verify',
        name: 'admin-token-verify',
        component: AdminTokenVerify
    },
    {
        path: '/admin/success-mail-send',
        name: 'admin-success-mail-send',
        component: AdminMailSender
    }
];
