








































































































import {
    Component,
    Mixins,
    Watch
} from "vue-property-decorator";
import {
    Tooltip,
    Tag,
    MessageBox
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import packageAssignForm from "@/views/admin/sms/packageAssign/packageAssignForm.vue";
import GIFModal from "@/components/tour/GIFModal.vue";
import SMSPackageAssign from "@/intefaces/SMSPackageAssign";
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import LazyDropdown from "@/components/LazyDropdown.vue";
import {
    QueryBuilder
} from '@/classes/QueryBuilder';

@Component({
    components: {
        GIFModal,
        [Tooltip.name]: Tooltip,
        [Tag.name]: Tag,
        packageAssignForm,
        FilterDrawerComponent,
        LazyDropdown
    }
})
export default class PackageAssignList extends Mixins(PaginationComponentMixin) {
    public sms_packages: any = [];
    public showGIF: boolean = false;
    private currentEntity: any = {};
    private isActive: boolean = false;
    private isUpdate: boolean = false;
    private demoTourEntity: string = 'SMS Package Assign';
    private demoTourTitle: string = 'A quick demo of SMS Package Assign';
    public isFilterModelActive = false;
    private queryBuilder = new QueryBuilder();
    private GIFs: Array < any > = [{
            label: 'Create a SMS Package Assign',
            url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
        },
        {
            label: 'Update a SMS Package Assign',
            url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
        },
        {
            label: 'Delete a SMS Package Assign',
            url: 'https://www.youtube.com/embed/LeAltgu_pbM'
        }
    ];

    private form: SMSPackageAssign = {
        sms_package_id: null,
        org_id: null,
        remarks: null,
    }

    public filters: any = {
        org_id: null,
        sms_package_id: null,
        remarks: null,
    };

    entityCreated(entity: SMSPackageAssign) {
        this.sms_packages.unshift(entity);
    }

    entityUpdated(entity: SMSPackageAssign) {
        this.sms_packages.splice(this.sms_packages.findIndex((item: any) => item.id == entity.id), 1, entity);
    }

    onEdit(row: SMSPackageAssign) {
        this.isUpdate = true;
        this.currentEntity = row;
        this.isActive = true;
        this.form = {
            id: this.currentEntity.id,
            sms_package_id: this.currentEntity.sms_package_id,
            org_id: this.currentEntity.org_id,
            remarks: this.currentEntity.remarks,
        }
    }
    @Watch('queryBuilder.watch')
    @Watch('pagination.currentPage')
    fetchPackages() {
        this.loading = true;
        this.$http.get(this.$landLordApi(`/package_assign${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?'}&page=${this.pagination.currentPage}`))
            .then(response => {
                this.sms_packages = response.data.package_assigns;
                this.setPagination(response.data);
                this.loading = false;
            })
    }

    created() {
        this.fetchPackages();
    }

    resetBtn() {
        this.filters.org_id = null;
        this.filters.sms_package_id = null;
        this.filters.remarks = null;
    }

    onFilterSubmit() {
        this.isFilterModelActive = false;
    }

    @Watch('filters.org_id', {
        deep: true
    })
    onChangeOrg(newVal: string) {
        this.queryBuilder.addListener('org_id', newVal);
    }

    @Watch('filters.sms_package_id', {
        deep: true
    })
    onChangeSMSPackage(value: string) {
        this.queryBuilder.addListener('sms_package_id', value);
    }

    @Watch('filters.remarks', {
        deep: true
    })
    onChangePaymentId(value: string) {
        this.queryBuilder.addListener('remarks', value);
    }
}
