





















































































































































































































































import {
    Component,
    Prop,
    Vue,
    Watch
} from 'vue-property-decorator';
import "flatpickr/dist/flatpickr.css";
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";
import BrandForm from "@/components/products/BrandForm.vue";
import CategoryForm from "@/components/products/CategoryForm.vue";
import AddProduct from "@/views/product/AddProduct.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    MessageBox,
    Tooltip,
} from 'element-ui';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import moment from "moment";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";

const flatPicker = require("vue-flatpickr-component");

@Component({
    components: {
        BaseProductLazydropdown,
        DepartmentLazydropdown,
        BadgeLazydropdown,
        DepartmentLocationTypeLazyDropdown,
        DepartmentCategoryLazyDropdown,
        ChannelLazydropdown,
        TerritoryCascaderDropdown,
        [Tooltip.name]: Tooltip,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        flatPicker,
        SingleImageUploader,
        BrandForm,
        CategoryForm,
        AddProduct,
        ProductBrandLazydropdown,
        ProductCategoryLazydropdown,
        ProductLazydropdown,
        UserLazydropdown
    },
})

export default class PromotionNewForm extends Vue {
    @Prop() isActive!: boolean;
    @Prop() isUpdate!: boolean;
    @Prop() form!: {
        territory_ids: [];
        badge_ids: [];
        department_type_ids: [];
        department_category_ids: [];
        department_location_type_ids: [];
        title: string,
        start_date: string,
        expires_at: string,
        activating_type: string,
        promotion_type: string,
        promotion_on: string,
        status: string,
        items: [{
            promotion_able_id: '',
            minimum_value: '',
        }, ]
        offers: [{
            promotion_value: '',
            offer_type: '',
            offer_value: '',
            additional_discount: '',
            offer_products: [{
                offer_product_id: '',
                offer_product_qty: ''
            }],
        }, ]
    };
    @Prop() currentPromotion!: any;
    @Prop() userPagination!: any;

    $refs!: {
        formValidator: HTMLFormElement | Vue
    }
    public selectedTerritories: any = [];
    public selectedBadges: any = [];
    public selectedDepartmentTypes: any = [];
    public selectedDepartmentCategories: any = [];
    public selectedDepartmentLocationTypes: any = [];
    private disableDraftButton = false;
    private disableLiveButton = false;
    private draftLoading = false;
    private liveLoading = false;
    private validationErrors: any = {};
    private disableButton = false;
    private departmentTypes: any = [];
    private variantCategories: any = [];
    private promotionOn = [{
            'label': 'Product Brand',
            'name': 'brand'
        },
        {
            'label': 'Product Category',
            'name': 'category'
        },
        {
            'label': 'SKU',
            'name': 'product'
        }
    ]
    private offerTypes = [{
            'label': 'Amount',
            'name': 'amount'
        },
        {
            'label': 'Quantity',
            'name': 'qty'
        },
        {
            'label': 'Percent',
            'name': 'percent'
        }
    ]
    private promotionTypes = [{
            'label': 'Quantity',
            'name': 'qty'
        },
        {
            'label': 'Amount',
            'name': 'amount'
        },
        // {'label': 'Unit', 'name': 'unit'},
    ]
    private activatingTypes = [{
            'label': 'Flat',
            'name': 'flat'
        },
        {
            'label': 'Percent',
            'name': 'percent'
        },
    ]

    optimizeErrorMessage(key: string) {
        if (key.includes('.')) {
            return key.replace(/\.(\d+)/, (match, p1) => ` ${parseInt(p1) + 1} `).replace(/_/g, ' ');
        } else {
            return key.replace(/_/g, ' ');
        }
    }

    formSubmitErrorMessage(error: any) {
        if (error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const readableKey = this.optimizeErrorMessage(key);
                    errors[key].forEach((errMessage: string) => {
                        const readableMessage = errMessage.replace(key, readableKey);
                        this.$notify({
                            title: 'Error',
                            message: `${readableMessage}`,
                            duration: 3000,
                            iconClass: 'ni ni-bell-55',
                            type: 'warning'
                        });
                    });
                }
            }
        } else {
            this.$notify({
                title: 'Error',
                message: 'An unexpected error occurred.',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
            });
        }
    }

    save(status: string) {
        this.form.status = status;
        this.form.territory_ids = this.selectedTerritories;
        this.form.badge_ids = this.selectedBadges;
        this.form.department_type_ids = this.selectedDepartmentTypes;
        this.form.department_category_ids = this.selectedDepartmentCategories;
        this.form.department_location_type_ids = this.selectedDepartmentLocationTypes;

        let isFormValidation = this.formValidation();
        if (!isFormValidation) {
            return;
        }
        if (status == 'draft') {
            this.isUpdate ? this.updatePromotion() : this.createPromotion();
        } else {
            MessageBox.alert('<span style="font-size: 14px">' +
                    'Please review the information carefully. Once this data is saved,' +
                    ' it cannot be retrieved or deleted from the system. If needed, you can locate the data in the list and mark it as inactive.. ' +
                    '<br> <br>System admin may help to adjust this data if made any mistakes.</span>', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true,
                        dangerouslyUseHTMLString: true,
                        type: 'warning',
                        center: true
                    })
                .then(() => {
                    this.isUpdate ? this.updatePromotion() : this.createPromotion();
                });
        }
    }

    createPromotion() {
        this.buttonFunction(this.form.status, true);
        this.$http.post(this.$api2('/promotions'), this.form)
            .then((response: {
                data: {
                    promotion: any;
                };
            }) => {
                this.$emit('created', response.data.promotion);
                this.$notify({
                    title: 'Success',
                    message: 'Successfully Created',
                    duration: 3000,
                    iconClass: 'ni ni-bell-55',
                    type: 'success'
                });
                this.close();
                this.buttonFunction(this.form.status, false);
            })
            .catch((error: any) => {
                this.buttonFunction(this.form.status, false);
                this.formSubmitErrorMessage(error);

                // (this.$refs.formValidator as Vue & {
                //   setErrors: (errors: []) => any;
                // }).setErrors(error.response.data.errors);
            });
    }

    updatePromotion() {
        this.buttonFunction(this.form.status, true);
        this.$http.patch(this.$api2(`/promotions/${this.currentPromotion.id}`), this.form)
            .then((response: {
                data: {
                    promotion: any;
                };
            }) => {
                this.$emit('updated', response.data.promotion);
                this.$notify({
                    title: 'Success',
                    message: 'Successfully Updated',
                    duration: 3000,
                    iconClass: 'ni ni-bell-55',
                    type: 'success'
                });
                this.close();
                this.buttonFunction(this.form.status, false);
            })
            .catch((error: any) => {
                this.buttonFunction(this.form.status, false);
                this.formSubmitErrorMessage(error);

                // (this.$refs.formValidator as Vue & {
                //   setErrors: (errors: []) => any;
                // }).setErrors(error.response.data.errors);
            });
    }

    created() {
          this.selectedBadges = this.form.badge_ids;
          this.selectedDepartmentTypes = this.form.department_type_ids;
          this.selectedDepartmentCategories = this.form.department_category_ids;
          this.selectedDepartmentLocationTypes = this.form.department_location_type_ids;
    }

    // @Watch('form.start_date')
    // onStartDateChange(newDate: string) {
    //   if (newDate) {
    //     // this.form.expires_at = '';
    //   }
    // }

    getConfigStartDate() {
        return {
            allowInput: true,
            disable: [
                function (date: Date) {
                    return moment(date).isBefore(moment(), 'day');
                }
            ]
        };
    }

    getConfigEndDate() {
        let self = this;
        return {
            allowInput: true,
            disable: [
                function (date: Date) {
                    if (!self.form.start_date) {
                        return true;
                    }
                    return !moment(date).isSameOrAfter(moment(self.form.start_date), 'day');
                }
            ]
        };
    }

    close() {
        this.$emit('update:isActive', false);
        this.$emit('update:isUpdate', false);
        this.$emit('update:form', {
            title: '',
            start_date: '',
            expires_at: '',
            promotion_on: '',
            activating_type: '',
            promotion_type: '',
            status: '',
            items: [{
                promotion_able_id: '',
                minimum_value: '',
            }],
            offers: [{
                promotion_value: '',
                offer_type: '',
                offer_value: '',
                additional_discount: '',
                offer_products: [{
                    offer_product_id: '',
                    offer_product_qty: ''
                }],
            }],
        });
        this.selectedTerritories = [];
        this.selectedBadges = [];
        this.selectedDepartmentTypes = [];
        this.selectedDepartmentCategories = [];
        this.selectedDepartmentLocationTypes = [];

        this.$nextTick(() => {
            (this.$refs.formValidator as HTMLFormElement).reset();
        })
    }

    formReset() {
        this.form.title = '';
        this.form.start_date = '';
        this.form.expires_at = '';
        this.form.promotion_on = '';
        this.form.activating_type = '';
        this.form.promotion_type = '';
        this.form.status = '';
        this.form.items = [{
            promotion_able_id: '',
            minimum_value: '',
        }, ];
        this.form.offers = [{
            promotion_value: '',
            offer_type: '',
            offer_value: '',
            additional_discount: '',
            offer_products: [{
                offer_product_id: '',
                offer_product_qty: ''
            }],
        }, ];
        this.selectedTerritories = [];
        this.selectedBadges = [];
        this.selectedDepartmentTypes = [];
        this.selectedDepartmentCategories = [];
        this.selectedDepartmentLocationTypes = [];
        this.disableButton = false;
        this.$nextTick(() => {
            (this.$refs.formValidator as HTMLFormElement).reset();
        })
    }

    addNewPromotionItem() {
        this.form.items.push({
            promotion_able_id: '',
            minimum_value: '',
        });
    }

    deletePromotionItem(index: number) {
        this.form.items.splice(index, 1)
    }

    addNewPromotionOffer() {
        this.form.offers.push({
            promotion_value: '',
            offer_type: '',
            offer_value: '',
            additional_discount: '',
            offer_products: [{
                offer_product_id: '',
                offer_product_qty: ''
            }],
        });
    }

    deletePromotionOffer(index: number) {
        this.form.offers.splice(index, 1);
    }

    selectedPromotionableData(event: any, index: any) {
        this.validateUniquePromotionalAbleId(index);
    }

    validateUniquePromotionalAbleId(index: any) {
        const currentId = this.form.items[index].promotion_able_id;
        if (!currentId) return;
        const ids = this.form.items.map((item, idx) => idx === index ? null : item.promotion_able_id);
        const duplicates = ids.filter(id => id === currentId);

        if (duplicates.length > 0) {
            this.$notify({
                title: 'Error',
                message: 'Promotion Able Item ID Must Be Unique',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
            });
            this.$set(this.form.items[index], 'promotion_able_id', '');
        }
    }

    @Watch('form.items', {
        deep: true
    })
    onPromotionItemsChange() {
        this.form.items.forEach((item, index) => {
            this.validateUniquePromotionalAbleId(index);
        });
    }

    @Watch('form.offers', {
        deep: true
    })
    onPromotionOffersChange() {
        this.form.offers.forEach((item, index) => {
            this.validateUniquePromotionValue(index);
            if (Array.isArray(item.offer_products)) {
                item.offer_products.forEach((product, productIndex) => {
                    this.validateUniqueOfferProductId(index, productIndex);
                });
            }
        });
    }

    validateUniquePromotionValue(index: any) {
        const currentPromotionValue = this.form.offers[index].promotion_value;
        if (!currentPromotionValue) return;
        const promotionValues = this.form.offers.map((offer, idx) => idx === index ? null : offer.promotion_value);
        const duplicates = promotionValues.filter(value => value === currentPromotionValue);

        if (duplicates.length > 0) {
            this.$notify({
                title: 'Error',
                message: 'Promotion Value Must Be Unique',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
            });
            this.$set(this.form.offers[index], 'promotion_value', '');
        }
    }

    selectedOfferProduct(event: any, index: any, productIndex: any) {
        this.validateUniqueOfferProductId(index, productIndex);
    }

    validateUniqueOfferProductId(index: number, productIndex: number) {
        const currentProductId = this.form.offers[index].offer_products[productIndex].offer_product_id;
        if (!currentProductId) return;

        const duplicateIndices = this.form.offers[index].offer_products
            .map((product, idx) => product.offer_product_id === currentProductId ? idx : -1)
            .filter(idx => idx !== -1);

        if (duplicateIndices.length > 1) {
            this.$notify({
                title: 'Error',
                message: 'Offer product ID must be unique within a promotion item',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
            });
            this.$set(this.form.offers[index].offer_products[productIndex], 'offer_product_id', '');
        }
    }

    addOfferProduct(item: any) {
        item.offer_products.push({
            offer_product_id: '',
            offer_product_qty: ''
        });
    }

    removeOfferProduct(item: any, productIndex: any) {
        item.offer_products.splice(productIndex, 1);
    }

    buttonFunction(type: string, isActive: boolean) {
        if (isActive) {
            if (type === 'draft') {
                this.disableDraftButton = true;
                this.draftLoading = true;
            } else if (type === 'active') {
                this.disableLiveButton = true;
                this.liveLoading = true;
            }
        } else {
            if (type === 'draft') {
                this.disableDraftButton = false;
                this.draftLoading = false;
            } else if (type === 'active') {
                this.disableLiveButton = false;
                this.liveLoading = false;
            }
        }
    }

    setItemsLabel(property: string, defaultLabel: string, selectedLabelPrefix: string): string {
        if (!this.isUpdate) {
            return `Select ${defaultLabel}`;
        }
        const items = this.currentPromotion[property];
        if (!items || items.length === 0) {
            return `Select ${defaultLabel}`;
        }
        return `Selected ${selectedLabelPrefix} : ${items.map((item: any) => item.name).join(', ')}`;
    }

    get selectedTerritoryItemsLabel(): string {
        return this.setItemsLabel('territories', 'Territory', 'Territory');
    }

    get selectedDepartmentTypesLabel(): string {
        return this.setItemsLabel('department_types', 'Channel', 'Channel');
    }

    get selectedBadgesLabel(): string {
        return this.setItemsLabel('badges', 'Badge', 'Badge');
    }

    get selectedDepartmentCategoriesLabel(): string {
        return this.setItemsLabel('department_categories', 'Department Category', 'Department Category');
    }

    get selectedDepartmentLocationTypesLabel(): string {
        return this.setItemsLabel('department_location_types', 'Location Type', 'Location Type');
    }

    parseDate(dateString: any) {
        return new Date(dateString);
    }

    formValidation() {
        this.resetValidation();
        const errorMessages = [];
        if (!this.form.title) {
            errorMessages.push(this.validationPromotionMessage(this.form.title));
        }
        if (!this.form.start_date) {
            errorMessages.push(this.validationPromotionMessage(this.form.start_date));
        }
        if (!this.form.expires_at) {
            errorMessages.push(this.validationPromotionMessage(this.form.expires_at));
        }
        if (this.parseDate(this.form.start_date) > this.parseDate(this.form.expires_at)) {
            errorMessages.push('Expire date must be equal to or greater than start date');
        }
        if (!this.form.promotion_on) {
            errorMessages.push(this.validationPromotionMessage(this.form.promotion_on));
        }
        if (!this.form.status) {
            errorMessages.push(this.validationPromotionMessage(this.form.status));
        }
        if (this.selectedTerritories.length === 0 && !this.isUpdate) {
            errorMessages.push('At least one territory is required');
        }
        if (this.selectedDepartmentTypes.length === 0 && !this.isUpdate) {
            errorMessages.push('At least one channel is required');
        }

        this.form.items.forEach((item, index) => {
            if (!item.promotion_able_id) {
                errorMessages.push(this.fieldNameMessage("promotion_able_id", index + 1, 'items'));
            }
            if (this.form.activating_type && this.form.activating_type == 'percent' && item.minimum_value) {
                if (item.minimum_value > 100 || item.minimum_value < 0) {
                    errorMessages.push(`Minimum value fields in ${index + 1} promotion item need to be  between 0 and 100 when activating type is selected as percent. Please set a valid value.`);
                    // item.minimum_value = '';
                }
            }
            if (!item.minimum_value) {
                errorMessages.push(this.fieldNameMessage("minimum_value", index + 1, 'items'));
            }
        });

        this.form.offers.forEach((item, index) => {
            if (!item.promotion_value) {
                errorMessages.push(this.fieldNameMessage("promotion_value", index + 1, 'offers'));
            }

            const promotionValues = this.form.offers.map(offer => offer.promotion_value);
            const seenPromotionValues = new Set();
            const duplicates: any = [];
            promotionValues.forEach((value, index) => {
                if (seenPromotionValues.has(value)) {
                    duplicates.push(index);
                } else {
                    seenPromotionValues.add(value);
                }
            });
            duplicates.forEach((index: any) => {
                this.$set(this.form.offers[index], 'promotion_value', '');
            });
            if (duplicates.length > 0) {
                errorMessages.push(`Promotion Value Must Be Unique. Duplicates found.`);
            }

            if (!item.offer_type) {
                errorMessages.push(this.fieldNameMessage("offer_type", index + 1, 'offers'));
            }
            if (item.offer_type) {
                if (item.offer_type != 'qty' && !item.offer_value) {
                    errorMessages.push(this.fieldNameMessage("offer_value", index + 1, 'offers'));
                }
                if (item.offer_type == 'percent') {
                    if (parseInt(item.offer_value) > 100 || parseInt(item.offer_value) < 0) {
                        errorMessages.push(`Offer value fields in ${index + 1} promotion item need to be  between 0 and 100 when offer type is selected as percent. Please set a valid value.`);
                        // item.offer_value = '';
                    }
                }

            }

            if (item.offer_type && item.offer_type == 'qty') {
                item.offer_value = '';
                item.offer_products.forEach((offer_product, productIndex) => {
                    if (!offer_product.offer_product_id || !offer_product.offer_product_qty) {
                        errorMessages.push(`Offer product and quantity in promotion offers ${productIndex + 1} are required`);
                    }
                });
            } else {
                item.offer_products = [{
                    offer_product_id: '',
                    offer_product_qty: ''
                }];
            }
        });
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                this.$notify({
                    title: 'Validation Error',
                    message: errorMessage,
                    duration: 5000,
                    type: 'warning'
                });
            });
        }
        return errorMessages.length <= 0;
    }

    fieldNameMessage(fieldName: string, serial: number, key: string): string {
        return `${fieldName.replace(/_/g, ' ')} fields in ${key} ${serial} is required`;
    }

    validationPromotionMessage(fieldName: string): string {
        return `${fieldName.replace(/_/g, ' ')} fields is required`;
    }

    resetValidation() {
        this.validationErrors = {
            title: '',
            start_date: '',
            expires_at: '',
            promotion_on: '',
            activating_type: '',
            promotion_type: '',
            status: '',
            selectedTerritories: '',
            selectedDepartmentTypes: '',
            items: [],
            offers: [],
        };
    }

}
