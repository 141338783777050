// @ts-ignore
import SMS from "@/views/admin/sms/index.vue";
// @ts-ignore
import PackageList from '@/views/admin/sms/smsPackage/index.vue';
// @ts-ignore
import PackageAssign from '@/views/admin/sms/packageAssign/index.vue';
import { RouteConfig } from 'vue-router';
const SMSReports: RouteConfig = {
    path: 'sms',
    name: 'sms',
    redirect: '/sms/package',
    components: { dashboard: SMS },
    children: [
        {
            path: 'package',
            name: 'sms_package',
            // @ts-ignore
            label: 'SMS Package',
            icon: 'fa fa-comment',
            component: PackageList
        },
        {
            path: 'package_assign',
            name: 'sms_package_assign',
            // @ts-ignore
            label: 'SMS Package Assign',
            icon: 'fa fa-plus',
            component: PackageAssign
        },
    ]
};

export default SMSReports;
