












































































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import Productable from '@/intefaces/Productable';
import BrandForm from '@/components/products/BrandForm.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import FilterDrawerComponent from "@/components/FilterDrawerComponent.vue";
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BrandForm,
    GIFModal,
    FilterDrawerComponent,
    DateRangeForReport,

  }
})
export default class ProductBrandList extends Mixins(PaginationComponentMixin) {
  private productBrands: Productable[] = [];
  private showDeleteModal = false;
  private isActiveBrand = false;
  private isUpdate = false;
  private isFilterModelActive = false;

  private currentProductBrand!: Productable;
  private showGIF: boolean = false;
  private form: { name: string; parent_id: number | string } = {
    name: '',
    parent_id: ''
  };
  private demoTourEntity: string = 'brand';
  private queryBuilder = new QueryBuilder();
  private GIFs: Array<any> = [
    {
      label: 'Create a brand',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a brand',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a brand',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of brand';

  get permissionEnum () {
    return PermissionEnum;
  }

  onEdit(row: Productable) {
    this.isUpdate = true;
    this.currentProductBrand = row;
    let parent = this.currentProductBrand.parent_id == null;
    this.form = {
      name: this.currentProductBrand.name,
      parent_id: parent ? '' : this.currentProductBrand.parent_id
    };
    this.isActiveBrand = true;
  }

  deleteProductBrand() {
    this.$http.delete(this.$api(`/product-brands/${this.currentProductBrand.id}`)).then(response => {
      this.showDeleteModal = false;
      this.productBrands.splice(
        this.productBrands.findIndex(productBrand => productBrand.id == this.currentProductBrand.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  @Watch('filters.name', { deep: true })
  onChangeNameFiler(newVal: string) {
    this.queryBuilder.addListener('name', newVal);
  }

  @Watch('filters.range', { deep: true })
  onChangeSubmissionDateRange(value: string) {
    this.queryBuilder.addListener('range', value);
  }
  data() {
    return {

    }
  }
  private filters: any = {
    name: '',
    range: ''
  };

  resetBtn() {
    this.filters.name= '';
    this.filters.range= '';
  }
  onFilterSubmit() {
    this.isFilterModelActive = false;
  }

  @Watch('pagination.currentPage')
  @Watch('queryBuilder.watch')
  /*fetchProductBrands() {
    this.loading = true;
    this.$http.get(this.$api(`/product-brands?page=${this.queryBuilder.pagination.currentPage}`)).then(response => {
      this.productBrands = response.data.productBrands;
      this.setPagination(response.data);
      this.loading = false;
    });
  }*/
  fetchProductBrands() {
    this.loading = true;
    this.$http.get(this.$api(`/product-brands${this.queryBuilder.getFilters(true) ? this.queryBuilder.getFilters(true) : '?'}&allStatus&page=${this.pagination.currentPage}`)).then(response => {
      this.productBrands = response.data.productBrands;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  brandCreated(productBrand: Productable) {
    this.isActiveBrand = false;
    this.productBrands.unshift(productBrand);
  }

  brandUpdated(productBrand: Productable) {
    this.isActiveBrand = false;
    this.productBrands.splice(
      this.productBrands.findIndex(brand => brand.id == productBrand.id),
      1,
      productBrand
    );
  }

  created() {
    this.fetchProductBrands();
  }
}
