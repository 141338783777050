// @ts-ignore
import Billing from "@/views/admin/billing";
import { RouteConfig } from 'vue-router';
// @ts-ignore
import BillingList from "@/views/admin/tenant/billing/billingList.vue";
// @ts-ignore
const BillingRoutes: RouteConfig = {
    path: 'billing',
    name: 'billing',
    redirect: '/billing/billing-list',
    components: { dashboard: Billing },
    children: [
        {
            path: 'billing-list',
            name: 'billing-list',
            // @ts-ignore
            label: 'Billing List',
            icon: 'fa fa-credit-card',
            component: BillingList
        },
    ]
};

export default BillingRoutes;
