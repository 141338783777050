




































































import {
    Component,
    Mixins,
    Vue
} from 'vue-property-decorator';
import DateRange from '@/components/DateRange.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import moment from 'moment';
import {
    Radio,
    RadioGroup,
    Button
} from 'element-ui';

@Component({
    components: {
        TerritoryCascaderDropdown,
        UserLazydropdown,
        DateRange,
        ElRadio: Radio,
        ElRadioGroup: RadioGroup,
        [Button.name]: Button,
    },
})
export default class KPIReportByOrder extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public formData = {
        'range': '',
        'territory_id': '',
        'user_id': [],
        'basedOn': '',
    };
    public loading: boolean = false;
    public reports = [];
    public table_fields = [];
    public filter_data = [];
    public maxDate: string = moment().format('YYYY-MM-DD');

    public basedOnOptions: any = [{
            value: 'order',
            label: 'Order',
        },
        {
            value: 'delivery',
            label: 'Delivery',
        },
    ];

    public onClickGenerate() {
        if (this.formData.range === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the Date range',
                title: 'Required!',
            });
        } else if (this.formData.territory_id === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the Territory',
                title: 'Required!',
            });
        } else if (this.formData.basedOn === '') {
            this.$notify({
                type: 'warning',
                message: 'Select the Based on',
                title: 'Required!',
            });
        } else {
            this.fetchReport();
        }
    }

    async fetchReport() {
        this.loading = true;
        this.reports = [];
        this.$http.get(this.$api3(`/kpi-report${this.$toQueryString(this.formData)}`)).then((response) => {
            this.reports = response.data.reports;
            this.table_fields = response.data.table_fields;
            this.filter_data = response.data.filter_data;
            if (this.reports.length == 0) {
                this.$notify({
                    type: 'info',
                    message: 'Data not found!',
                    title: 'Not found!',
                });
            }
        }).catch((e) => {
            let err_msg = [];
            if (e.response.data.errors.range) {
                err_msg.push(e.response.data.errors.range[0]);
            }
            if (e.response.data.errors.territory_id) {
                err_msg.push(e.response.data.errors.territory_id[0]);
            }
            if (e.response.data.errors.basedOn) {
                err_msg.push(e.response.data.errors.territory_id[0]);
            }
            err_msg.forEach((error) => {
                this.$notify({
                    type: 'warning',
                    message: error,
                    title: 'Validation error',
                });
            });
        }).finally(() => {
            this.loading = false;
        })
    }

    async onClickDownload() {
        this.downloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$api3(`/kpi-report${this.$toQueryString(this.formData)}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.downloading = false;
        }
    }
}
