

































































import {
    Component,
    Mixins,
    Vue,
    Watch
} from 'vue-property-decorator';
import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import LazyDropdown from '@/components/LazyDropdown.vue';
import moment from "moment";
import {
    Tree
} from 'element-ui';

@Component({
    components: {
        TerritoryCascaderDropdown,
        UserLazydropdown,
        DateRange,
        LazyDropdown,
        [Tree.name]: Tree,
    }
})
export default class OutletRejectionReport extends Mixins(PaginationComponentMixin, Vue) {
    public downloading: boolean = false;
    public formData = {
        range: '',
        parent_id: 0,
        territory_id: 1
    };
    public loading: boolean = false;
    public outletReport = [];
    public selectedBrands: any = '';
    public showOutletModal: boolean = false;
    public usersNotBuilledOutlets: any = [];
    public user: any = this.$store.getters['Auth/user'];
    public props: object = {
        label: 'name',
        isLeaf: 'leaf',
    };

    created() {
        // this.formData.range = moment().subtract(8, "days").format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD')
        // this.formData.territory_id = this.checkParentId;
    }

    public onClickGenerate() {
        if (this.formData.range) {
            this.fetchReport();
        } else {
            this.$notify({
                type: 'warning',
                message: 'Select the required field',
                title: 'Required!'
            });
        }
    }

    async fetchReport() {
        this.loading = true
        this.outletReport = [];
        const { ['parent_id']: _, ...filteredObject } = this.formData;
        await this.$http.get(this.$api3(`/order-rejection${this.$toQueryString(filteredObject)}`)).then((response: any) => {
            this.outletReport = response.data.territories;
        }).catch((e) => {
            let err_msg = [];
            if (e.response.data.errors.range) {
                err_msg.push(e.response.data.errors.range[0]);
            }
            if (e.response.data.errors.territory_id) {
                err_msg.push(e.response.data.errors.territory_id[0]);
            }
            err_msg.forEach(error => {
                this.$notify({
                    type: 'warning',
                    message: error,
                    title: 'Validation error'
                });
            })
        }).finally(() => {
            this.loading = false
        })
    }

    get checkParentId() {
        if (this.user && this.user.managed_territory && this.user.managed_territory.id) {
            {
                if (this.user.managed_territory.id != 1) {
                    return this.user.managed_territory.parent_id;
                } else {
                    return '';
                }
            }
        } else {
            if (this.user.territory && this.user.territory.parent_id) {
                return this.user.territory.parent_id;
            } else {
                return '';
            }
        }
    }

    loadNode(node: any, resolve: any) {
        var territories: any[] = [];
        this.formData.parent_id = (node.level === 0 ? this.checkParentId : node.data.id);
        const { ['territory_id']: _, ...filteredObject } = this.formData;
        this.$http.get(this.$api3(`/order-rejection${this.$toQueryString(filteredObject)}`)).then((response) => {
            territories = response.data.territories;
            let treeData = territories.map((territory) => {
                const container: any = {};
                container['name'] = territory.name;
                container['total_rejected'] = territory.total_rejected;
                container['total_amount'] = territory.total_amount;
                container['unique_users'] = territory.unique_users;
                container['leaf'] = !territory.children_count;
                container['id'] = territory.id;
                return container;
            });
            resolve(treeData);
            this.loading = false;
        });
    }
}
