




























































import {
    Vue,
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import {
    Badge,
    Divider
} from 'element-ui';

@Component({
    name: 'PromotionList',
    components: {
        [Badge.name]: Badge,
        [Divider.name]: Divider,
    }
})

export default class PromotionList extends Vue {
    @Prop() promotions!: any[];
    @Prop({
        default: 'col-md-4'
    }) colSize!: string;
    @Prop({}) promotionId!: number;
    @Prop({default: true}) selection!: boolean;
    public selectedPromotion: any = {};

    get getCurrency() {
        return this.$store.getters['Settings/getCurrency'];
    }

    onSelectPromotion(promotion: any) {
        this.selectedPromotion = promotion;
        this.$emit('promotionSelected', promotion);
    }

    capitalizeFirstLetter(string: string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    @Watch('promotionId', {
        immediate: true
    })
    onPromotionIdChange(val: number) {
        if (!val) {
          this.selectedPromotion = {};
          return;
        }
        const promotion = this.promotions.find((promotion) => promotion.id === val);
        if(promotion){
          this.selectedPromotion = promotion;
        }
    }
}
