





















































































import {
    Component,
    Mixins,
    Watch
} from "vue-property-decorator";
import {
    Tooltip,
    Tag,
    MessageBox
} from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import smsPackageForm from "@/views/admin/sms/smsPackage/smsPackageForm.vue";
import ViewFeaturePermission from "@/views/admin/package/feature/ViewFeaturePermission.vue";
import GIFModal from "@/components/tour/GIFModal.vue";
import SMSPackage from "@/intefaces/SMSPackage";

@Component({
    components: {
        GIFModal,
        ViewFeaturePermission,
        [Tooltip.name]: Tooltip,
        [Tag.name]: Tag,
        smsPackageForm
    }
})
export default class PackageList extends Mixins(PaginationComponentMixin) {
    private sms_packages: any = [];
    private showGIF: boolean = false;
    private currentEntity: any = {};
    private isActive: boolean = false;
    private isUpdate: boolean = false;
    private demoTourEntity: string = 'SMS Package';
    private demoTourTitle: string = 'A quick demo of SMS Package';
    private GIFs: Array < any > = [{
            label: 'Create a role',
            url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
        },
        {
            label: 'Update a role',
            url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
        },
        {
            label: 'Delete a role',
            url: 'https://www.youtube.com/embed/LeAltgu_pbM'
        }
    ];

    private form: SMSPackage = {
        name: '',
        amount: null,
        qty: null,
        price: null,
        status: false,
    }

    entityCreated(entity: SMSPackage) {
        this.sms_packages.unshift(entity);
    }

    entityUpdated(entity: SMSPackage) {
        this.sms_packages.splice(this.sms_packages.findIndex((item: any) => item.id == entity.id), 1, entity);
    }

    onEdit(row: SMSPackage) {
        this.isUpdate = true;
        this.currentEntity = row;
        this.isActive = true;
        this.form = {
            id: this.currentEntity.id,
            name: this.currentEntity.name,
            amount: this.currentEntity.amount,
            qty: this.currentEntity.qty,
            price: this.currentEntity.price,
            status: this.currentEntity.status,
            created_at: this.currentEntity.created_at,
            updated_at: this.currentEntity.updated_at,
        }
    }

    @Watch('pagination.currentPage')
    fetchPackages() {
        this.loading = true;
        this.$http.get(this.$landLordApi(`/sms_package?page=${this.pagination.currentPage}`))
            .then(response => {
                this.sms_packages = response.data.sms_packages;
                this.setPagination(response.data);
                this.loading = false;
            })
    }

    created() {
        this.fetchPackages();
    }

    deleteSMSPackage(entity: SMSPackage) {
        MessageBox.confirm('Are you sure you want to delete it permanently?', 'Confirmation', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
            .then(() => {
                this.$http.delete(this.$landLordApi(`/sms_package/${entity.id}`))
                    .then(response => {
                        if (response.status === 200) {
                            this.sms_packages.splice(this.sms_packages.findIndex((item: any) => item.id == entity.id), 1);
                        }
                        if (response.data.message) {
                            this.$notify({
                                title: response.status === 200 ? 'Success' : 'Warning',
                                message: response.data.message,
                                duration: 5000,
                                iconClass: 'ni ni-bell-55',
                                type: response.status === 200 ? 'success' : 'warning'
                            });
                        }
                    })
            })
    }
}
