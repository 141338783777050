




































































































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import OrderDetails from '@/components/order/OrderDetails.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

@Component({
  components: {
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    OrderDetails
  }
})
export default class SalesReturnList extends Mixins(TaxonomyMixin) {
  private salesReturn: any = [];
  private showOrder: boolean = false;
  private currentOrderDetails: any = [];
  private events: any = [];
  private loading = false;
  private pagination = {
    currentPage: 1,
    total: 0,
    perPage: 0
  };
  private showGIF: boolean = false;
  private demoTourEntity: string = 'salesReturn';
  private GIFs: Array<any> = [
    {
      label: 'Create a sales return',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a sales return',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a sales return',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of sales return';

  onView(row: any) {
    this.$http.get(this.$api(`/orders/${row.order.id}`)).then(response => {
      this.currentOrderDetails = response.data.order;
    });
    this.events = [
      {
        label: 'Outlet',
        icon: 'ni ni-bell-55',
        type: 'success'
      },
      {
        label: 'Expected Delivery Date',
        name: this.currentOrderDetails.expected_delivery_date,
        icon: 'ni ni-html5',
        type: 'danger'
      },
      {
        label: 'Created At',
        name: this.currentOrderDetails.created_at,
        icon: 'ni ni-like-2',
        type: 'info'
      }
    ];
    this.showOrder = true;
  }

  fetchSalesReturn() {
    const order_type = this.$route.path.split('/')[1] == 'primary-order' ? 'primary' : 'secondary';
    this.loading = true;
    let isFirstPage = this.pagination.currentPage < 2;
    let url = isFirstPage ? `/sales-returns?order_type=${order_type}` : `/sales-returns?page=${this.pagination.currentPage}&order_type=${order_type}`;

    this.$http.get(this.$api(url)).then(response => {
      this.salesReturn = response.data.salesReturns;
      this.pagination.total = response.data.total;
      this.pagination.perPage = response.data.per_page;
      this.loading = false;
    });
  }

  created() {
    this.fetchSalesReturn();
  }
}
