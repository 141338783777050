








































































































































/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
//@ts-ignore
import SetupWizard from '@/views/setup-wizard';
import DashboardNavbar from '@/components/layouts/argon/DashboardNavbar.vue';
import ContentFooter from '@/components/layouts/argon/ContentFooter.vue';
import DashboardContent from '@/components/layouts/argon/Content.vue';
import {
    Component,
    Mixins
} from 'vue-property-decorator';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";

function hasElement(className: string) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

@Component({
    components: {
        DashboardNavbar,
        ContentFooter,
        DashboardContent,
        SetupWizard,
    },
})
export default class Dashboard extends Mixins(TaxonomyMixin) {
    get permissions() {
        return this.$store.getters['Auth/menus'];
    }

    get logoUrl() {
        return this.$store.getters['Theme/getLogoUrl'];
    }

    initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
            initScrollbar('scrollbar-inner');
        }
    }

    mounted() {
        this.initScrollbar();
        this.loadTawkToScript();
    }

    loadTawkToScript() {
      if (!hasElement('tawk')) {
          (function() {
              var s1 = document.createElement("script"),
                  s0 = document.getElementsByTagName("script")[0];
              s1.async = true;
              s1.src = `https://embed.tawk.to/659b783e8d261e1b5f50962e/1hjjkrdcm?token=e0d6bd814f80099471de96cd97132272dbb3d0ba`;
              s1.charset = 'UTF-8';
              s1.setAttribute('crossorigin', '*');
              if (s0.parentNode) {
                  s0.parentNode.insertBefore(s1, s0);
              }
          })();
      }
    }
}
