






























































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { QueryBuilder } from '@/classes/QueryBuilder';
import moment from 'moment';
import DateRangeForReport from '@/components/DateRangeForReports.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Tag } from 'element-ui';
import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import PaginationComponentMixin from '@/mixins/PaginationComponent';

const flatPicker = require('vue-flatpickr-component');
@Component({
  components: {
    TerritoryCascaderDropdown,
    UserLazydropdown,
    TerritoryTypeAutocomplete,
    flatPicker,
    DateRangeForReport,
    [Tag.name]: Tag
  }
})
export default class LoginHistoryReport extends Mixins(PaginationComponentMixin) {
  public downloading: boolean = false;
  public loading: boolean = false;
  public LoginHistoryReports = [];
  public queryBuilder: QueryBuilder = new QueryBuilder();
  public filters: any = {
    range: '',
    territory_id: null,
    user_id: null,
  };

  public onClickGenerate() {
    this.pagination.currentPage = 1;
    this.fetchReport();
  }
  @Watch('pagination.currentPage', { deep: true })
  async fetchReport() {
    this.loading = true;
    this.$http.get(this.$api3(`/login-history-fetch${this.$toQueryString(this.filters)}&page=${this.pagination.currentPage}`)).then(response => {
      this.LoginHistoryReports = response.data.data;
      this.setPagination(response.data);
      if (this.LoginHistoryReports.length == 0) {
        this.$notify({
          type: 'warning',
          message: 'There is no reports set in selected filters',
          title: 'No data found!',
        });
      }
    })
      .catch((error) => {
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/login-history-fetch${this.$toQueryString(this.filters)}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }


  @Watch('filters.range')
  onChangeRange(value: number) {
    this.queryBuilder.addListener('range', value);
  }

  @Watch('filters.user_id')
  onChangeUser(value: number) {
    this.queryBuilder.addListener('user_id', value);
  }

  identifyBrowser(userAgent: string): string {

    const customAppRegex = /Sokrio App\/(\S+)\/([^/]+)/;
    const customAppMatch = customAppRegex.exec(userAgent);

    if (customAppMatch) {
        const version = customAppMatch[1];
        const device = customAppMatch[2].replace(/\s+/g, '_');
        return `Sokrio App_${version}_${device}`;
    }
    const regexps: { [key: string]: RegExp | RegExp[] } = {
        'Microsoft Edge': /Edg(?:e)?\/(\S+)/,
        'Internet Explorer': /Trident\/7(?:.0;)? rv:(\S+)/,
        'Firefox': /Firefox\/(\S+)/,
        'Safari': [
            /Version\/(\S+).*?Safari\//,
            /AppleWebKit\/(\S+).*?Version\/(\S+).*?Safari\//
        ],
        'Opera': [
            /Opera\/.*?Version\/(\S+)/, 
            /Opera\/(\S+)/
        ],
        'Chrome': /Chrome\/(\S+)/,
        'UC Browser': /UCBrowser\/(\S+)/,
        'Samsung Internet': /SamsungBrowser\/(\S+)/,
        'Opera Mini': /Opera Mini\/(\S+)/,
        'Opera Mobile': /OPR\/(\S+)/,
        'Firefox Mobile': /Mobile; Firefox\/(\S+)/,
        'Chrome Mobile': /Mobile(?:.*(?:CriOS|Chrome))\/(\S+)/,
        'Safari Mobile': /Mobile(?:.*Safari)\/(\S+)/,
        'Other': /(?:.*(?:Browser|App))\/(\S+)/
    };

    let re: RegExpExecArray | null;
    let browser: string;

    for (browser in regexps) {
        const regex = regexps[browser];
        if (Array.isArray(regex)) {
            for (const r of regex) {
                re = r.exec(userAgent);
                if (re) {
                    return browser;
                }
            }
        } else {
            re = regex.exec(userAgent);
            if (re) {
                return browser;
            }
        }
    }

    return 'Unknown';
  }

}
