



































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';
import VueClipboard from 'vue-clipboard2';
import {
    Tag
} from 'element-ui';
Vue.use(VueClipboard);
@Component({
    components: {
        'el-tag': Tag
    }
})
export default class apiToken extends Vue {
    public token: any = null;
    private isNew: boolean = false;

    mounted() {
        this.fetchToken();
    }

    fetchToken() {
        // this.$http.get(this.$api3(`/api-token?isnew=${this.isNew}`)).then(response => {
        this.$http.get(this.$api3(`/api-token`)).then(response => {
            this.token = response.data.bearerToken;
            if (this.isNew == true) {
                this.$notify({
                    message: 'API token successfully regenerated!',
                    title: 'Success',
                    type: 'success'
                });
            }
        });
    }

    async generateNew() {
        this.isNew = true;
        this.fetchToken();
    }

    onCopy() {
        this.$notify({
            message: 'Copied',
            title: 'Success',
            type: 'success'
        });
    }
}
