







































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import "flatpickr/dist/flatpickr.css";
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";
import BrandForm from "@/components/products/BrandForm.vue";
import CategoryForm from "@/components/products/CategoryForm.vue";
import AddProduct from "@/views/product/AddProduct.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import { Dropdown, DropdownItem, DropdownMenu, Form, Tooltip, } from 'element-ui';
import Productable from "@/intefaces/Productable";
import { PromotionFormRequest } from "@/classes/PromotionFormRequest";

const flatPicker = require("vue-flatpickr-component");

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    flatPicker,
    SingleImageUploader,
    BrandForm,
    CategoryForm,
    AddProduct,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    ProductLazydropdown,
    UserLazydropdown
  },
})
export default class PromotionForm extends Vue {
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() form!: {
    title: string,
    type: string,
    date: string,
    users: any,
    offerType: string,
    promotionValue: string,
    image: any,
    promotionableId: any,
    offerProduct: any,
    offerValue: any,
    promotionType: any,
    startDate: string
    active: boolean
  }
  @Prop() users!: any;
  @Prop() productBrands!: any;
  @Prop() productCategories!: any;
  @Prop() products!: any;
  @Prop() currentPromotion!: any;
  @Prop() userPagination!: any;
  @Prop() productBrand!: any;
  @Prop() productCategoryPagination!: any;
  @Prop() productPagination!: any;

  $refs!: {
    formValidator: HTMLFormElement | Vue
  }
  public selectedOfferProduct = "";
  public selectedPromotionableData = "";
  private disableButton = false;
  private loading = false;
  private is_remove_permission = false;
  private departmentTypes: any = [];
  private variantCategories: any = [];
  private promotionOn = [
    {'label': 'All Product', 'name': 'all'},
    {'label': 'Product Brand', 'name': 'brand'},
    {'label': 'Product Category', 'name': 'category'},
    {'label': 'Single Product', 'name': 'product'}
  ]
  private offerTypes = [
    {'label': 'Discount-Amount', 'name': 'amount'},
    {'label': 'Quantity', 'name': 'qty'},
    {'label': 'Discount-Percent', 'name': 'percent'}]

  private promotionTypes = [
    {'label': 'Discount-Amount', 'name': 'amount'},
    {'label': 'Quantity', 'name': 'qty'},
  ]

  private isActiveBrand: boolean = false;

  private isActiveCategory: boolean = false;

  private variantForm: any = {
    variantValues: [],
    variantCategories: [],
    prices: [],
    departmentTypes: [],
    departmentId: []
  }
  private isActiveProduct: boolean = false;

  brandCreated(productBrand: Productable) {
    this.isActiveBrand = false;
    this.productBrands.unshift(productBrand);
    this.form.promotionableId = productBrand.id;
  }

  onRemoveUser(remove: any) {
    if (this.isUpdate == true) {
      this.is_remove_permission = true;
      this.$http
        .delete(this.$api(`/promotions/${this.currentPromotion.id}/assignees/${remove}`))
        .then(response => {
          this.is_remove_permission = false;
        })
    }
  }

  fetchUser() {
    this.$emit('userList', null);
  }

  fetchProductBrand() {
    this.$emit('brandList', null);
  }

  fetchProductCategory() {
    this.$emit('categoryList', null);
  }

  fetchProduct() {
    this.$emit('productList', null);
  }

  setProductBrandId() {
    if (this.form.promotionableId == 0) {
      this.form.promotionableId = '';
    }
  }

  categoryCreated(productCategory: Productable) {
    this.isActiveCategory = false;
    this.productCategories.unshift(productCategory);
    this.form.promotionableId = productCategory.id;
  }

  setProductCategoryId() {
    if (this.form.promotionableId == 0) {
      this.form.promotionableId = '';
    }
  }

  @Watch('form.promotionableId')
  productBrandUpdated(newVal: string) {
    if (newVal == '0' && this.form.type == 'brand') {
      this.isActiveBrand = true;
    } else if (newVal == '0' && this.form.type == 'category') {
      this.isActiveCategory = true;
    }
  }

  @Watch('form.users')
  setAssigne(old: any, current: any) {
    if (this.isUpdate == true && this.is_remove_permission == false && this.currentPromotion.assignees.findIndex((assigne: any) => assigne.id == old) == -1) {
      var newUser = '';
      if (this.form.users != '' && current != '') {
        newUser = old.filter((item: any) => {
          return current.indexOf(item) === -1
        })
      }
      if (current.length == 0 && old.length == 1) {
        this.$http
          .post(this.$api(`/promotions/${this.currentPromotion.id}/assignees`), {user_id: old[0]})
      }
      if (newUser != '') {
        this.$http
          .post(this.$api(`/promotions/${this.currentPromotion.id}/assignees`), {user_id: newUser[0]})
      }
    }
  }

  setNullPromotionableId(type: any) {
    this.form.promotionableId = '';
  }

  formReset() {
    this.form.title = '';
    this.form.type = 'all';
    this.form.date = '';
    this.form.offerProduct = '';
    this.form.image = '';
    this.form.promotionableId = '';
    this.form.promotionValue = '';
    this.form.offerValue = '';
    this.form.offerType = '';
    this.form.users = [];
    this.form.startDate = '';
    this.form.active = false;
    this.disableButton = false;
    this.$nextTick(() => {
      (this.$refs.formValidator as HTMLFormElement).reset();
    })
  }

  getForm() {
    let request = new PromotionFormRequest(this.form);
    if (this.form.type == 'all' && this.form.image) {
      return request.except([
        'promotion_value', 'promotionable_id', 'promotion_type', 'offer_product_id'
      ]);
    } else if (this.form.image && this.form.type != 'all') {
      return request.except([]);
    } else if (this.form.type == 'all' && this.form.image == '') {
      return request.except([
        'banner', 'promotion_value', 'promotionable_id', 'promotion_type', 'offer_product_id'
      ]);
    } else {
      return request.except([
        'banner'
      ]);
    }
  }

  getCurrentPromotionForm() {
    let request = new PromotionFormRequest(this.form, {_method: 'patch'});
    if (this.form.type == 'all') {
      if (typeof this.form.image != 'string') {
        return request.except([
          'promotion_value', 'promotionable_id', 'promotion_type', 'offer_product_id'
        ]);
      } else {
        return request.except(['banner', 'promotion_value', 'promotionable_id', 'promotion_type', 'offer_product_id'])
      }
    } else {
      if (typeof this.form.image != 'string') {
        return request.except([]);
      } else {
        return request.except(['banner'])
      }
    }
  }

  createPromotion() {
    this.disableButton = true;
    this.$http.post(this.$api("/promotions"), this.getForm())
      .then(response => {
        this.form.users.forEach((user: any) => {
          this.loading = true
          this.$http.post(this.$api(`/promotions/${response.data.promotion.id}/assignees`), {
            'user_id': user
          })
            .then(response => {
              this.loading = false;
            })
        })
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        if (this.loading) {
          this.$http.get(this.$api(`/promotions/${response.data.promotion.id}`)).then(responsePromotion => {
            this.$emit('created', responsePromotion.data.promotion)
          })
        }
        this.formReset();
      })
      .catch(error => {
        this.loading = false;
        this.disableButton = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  updatePromotion() {
    this.disableButton = true;
    this.loading = true;
    this.$http.post(this.$api(`/promotions/${this.currentPromotion.id}`), this.getCurrentPromotionForm())
      .then(response => {
        this.$notify({
          title: 'Success',
          message: 'Successfully Updated',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.$http.get(this.$api(`/promotions/${this.currentPromotion.id}`)).then(responsePromotion => {
          this.$emit('updated', response.data.promotion)
        })
        this.formReset();
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        this.disableButton = false;
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  save() {
    this.isUpdate ? this.updatePromotion() : this.createPromotion();
  }

  fetchDepartmentType() {
    this.$http.get(this.$api("/department-types")).then(response => {
      this.departmentTypes = response.data.departmentTypes;
    })
  }

  fetchVariantCategories() {
    this.$http.get(this.$api("/variant-categories")).then(response => {
      this.variantCategories = response.data.variantCategories;
    })
  }

  created() {
    this.fetchDepartmentType();
    this.fetchVariantCategories();
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:form',
      {
        title: '',
        type: 'all',
        date: '',
        users: [],
        offerType: '',
        productValue: '',
        image: '',
        promotionableId: '',
        offerProduct: '',
        offerValue: '',
        active: false
      })
    this.$nextTick(() => {
      (this.$refs.formValidator as HTMLFormElement).reset();
    })
  }
}
