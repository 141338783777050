






















































































































































































































































































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import ProductDetailsModal from '@/views/product/productModalComponent/ProductDetailsModal.vue';
import ProductImage from '@/components/products/ProductImage.vue';
import AddProduct from '@/views/product/AddProduct.vue';
import ViewProductPrice from "@/views/product/productModalComponent/ViewProductPrice.vue";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Tag } from 'element-ui';
import Product from '@/intefaces/Product';
import ProductFilterDrawer from '@/views/product/ProductFilterDrawer.vue';
import GIFModal from '@/components/tour/GIFModal.vue';
import UpdateProductModal from "@/views/product/productModalComponent/UpdateProductModal.vue";
import UpdateBatch from "@/views/product/productModalComponent/UpdateBatch.vue";
import ViewProductVariant from "@/views/product/productModalComponent/ViewProductVariant.vue";
import { Permission as PermissionEnum } from '@/enums/Permission'
import FileInput from "@/components/argon-core/Inputs/FileInput.vue";
import { AxiosResponse } from "axios";
import swal from "sweetalert2";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import BatchLazydropdown from "@/components/lazyDropdown/BatchLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import DepartmentTypeAutocomplete from "@/components/resources-autocomplete/DepartmentTypeAutocomplete.vue";


@Component({
  components: {
    DepartmentTypeAutocomplete,
    UserLazydropdown,
    BatchLazydropdown,
    DepartmentLazydropdown, ChannelLazydropdown,
    FileInput,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ProductDetailsModal,
    ProductImage,
    AddProduct,
    ProductFilterDrawer,
    GIFModal,
    ViewProductPrice,
    UpdateProductModal,
    UpdateBatch,
    ViewProductVariant
  }
})
export default class ProductList extends Mixins(TaxonomyMixin) {
  private products: Product[] = [];
  private isUpdate = false;
  private isActive = false;
  private isActiveProductDetails = false;
  private isActiveBatch: boolean = false;
  private showDeleteModal = false;
  private currentProduct: any = [];
  private loading = false;
  public downloading: boolean = false;
  public AddModal: boolean = false;
  private csvFile: Blob | string = '';
  private isFilterModelActive: boolean = false;
  private isActivePrices: boolean = false;
  private showDeleteModalBatch: boolean = false;
  private isActiveVariant: boolean = false;
  private isUpdateVariant: boolean = false;
  private product: any = [];
  private priceType = ['dept', 'depttype'];
  private errors: Array<string> = [];
  public btnDisabled: boolean = false;
  private showGIF: boolean = false;
  public duplicateChallan: string = '';
  private form: any = {
    name: '',
    productBrandId: '',
    productCategoryId: '',
    description: '',
    usp: '',
    sku: '',
    priority:null,
  };
  private bulkProductPriceForm: any = {
    batch_id: '',
    department_type_id: '',
    bulk_product_price_file: '',
  }
  private batchForm: any = {
    batch_id: '',
    qty: 0,
    mrp: 0,
    mfg_date: '',
    exp_date: '',
    image: '',
    is_free: false,
  }
  private variant: any = {
    variantValues: [],
    variantCategories: [],
    prices: [],
    priceableId: [],
    priceableType: [],
    departmentId: [],
    variantNames: [],
    variantId: [],
    priceId: []
  };
  private name: string = '';
  private pagination = {
    currentPage: 1,
    totalEntity: 0,
    entityPerPage: 0
  };
  private demoTourEntity: string = 'product';
  private GIFs: Array<any> = [
    {
      label: 'Create a product',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a product',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a product',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of product';

  get permissionEnum() {
    return PermissionEnum;
  }

  filesChange(files: FileList) {
    this.csvFile = files[0];
  }

  onAddProduct() {
    this.$router.push({name: 'create'});
  }

  closeModal() {
    this.errors = [];
    this.csvFile = '';
    // this.$refs.fileInput.reset();
    this.bulkProductPriceForm.batch_id = '';
    this.bulkProductPriceForm.department_type_id = '';
    if (this.AddModal)
      this.AddModal = false;
  }

  async onClickDownload() {
    this.downloading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api('/product-price/bulk-export-format?download'), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.'});
    } finally {
      this.downloading = false;
    }
  }

  _import() {
    if (this.csvFile instanceof File && this.csvFile.size > 0) {
      this.btnDisabled = true;
      let form = new FormData();
      form.append('batch_id', this.bulkProductPriceForm.batch_id);
      form.append('department_type_id', this.bulkProductPriceForm.department_type_id);
      form.append('bulk_product_price_file', this.csvFile);

      this.$http
        .post(this.$api('/product-price/bulk-import'), form)
        .then((response: AxiosResponse) => {
          swal.fire('Product Price Upload Complete', 'You can visit product price history report for more info.', 'success');
          this.btnDisabled = false;
          this.closeModal();
          // this.AddModal = false;
          // this.fetchDepartmentProduct();
          this.errors = [];
        })
        .catch((err: any) => {
          if (this.csvFile) {
            if (err.response.status == 422) {
              this.errors = err.response?.data.errors;
            } else if (err.response.status == 409) {
              this.$notify({
                title: 'Conflict',
                message: 'Duplicate entities.',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
              });
            } else if (err.message === 'net::ERR_UPLOAD_FILE_CHANGED') {
              window.console.log('File changed error #2:', err.message);
              swal.fire({
                title: 'File Changed',
                text: 'The selected file has changed. Please reload the page and try again.',
                icon: 'warning',
              }).then(() => {
                location.reload();
              });
            } else {
              this.$notify({
                title: 'Warning',
                message: 'Please import the correct file',
                duration: 3000,
                iconClass: 'ni ni-bell-55',
                type: 'warning'
              });
            }
          } else {
            this.errors = err.response?.data.errors;
          }
          this.btnDisabled = false;
        });
    } else {
      this.$notify({
        title: 'Warning',
        message: 'File has changed. Please select the file again.',
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'warning'
      });
      this.btnDisabled = false;
    }
  }

  onEdit(row: any) {
    this.currentProduct = row;
    this.form = {
      name: this.currentProduct.name,
      priority: this.currentProduct.priority,
      is_free: Boolean(this.currentProduct.is_free),
      productBrandId: this.currentProduct.product_brand_id,
      productCategoryId: this.currentProduct.product_category_id,
      description: this.currentProduct.description != 'null' ? this.currentProduct.description : '',
      usp: this.currentProduct.usp != 'null' ? this.currentProduct.usp : '',
      sku: this.currentProduct.sku,
    };
    this.isActive = true;
  }

  onEditBatch(row: any) {
    this.currentProduct = row;
    this.batchForm = {
      batch_id: this.currentProduct.batch_id,
      qty: this.currentProduct.qty,
      mrp: this.currentProduct.mrp,
      mfg_date: this.currentProduct?.mfg_date ?? '',
      exp_date: this.currentProduct?.exp_date ?? '',
      purchase_amount: this.currentProduct.purchase_amount,
      active: Boolean(this.currentProduct.active),
    };
    this.isActiveBatch = true;
  }

  updateProduct(updatedProduct: any) {
    this.isUpdate = false;
    this.products.splice(
      this.products.findIndex(product => product.id == updatedProduct.id),
      1,
      updatedProduct
    );
    this.isActive = false;
  }

  updateBatch(updateBatch: any) {
    this.products.splice(
      this.products.findIndex(product => product.id == updateBatch.id),
      1,
      updateBatch
    );
    this.isActiveBatch = false;
  }

  deleteProduct() {
    this.$http.delete(this.$api(`/base-products/${this.currentProduct.id}`)).then(response => {
      this.showDeleteModal = false;
      this.products.splice(
        this.products.findIndex(baseProduct => baseProduct.id == this.currentProduct.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  deleteBatch() {
    this.$http.delete(this.$api(`/products/${this.currentProduct.id}`)).then(response => {
      this.$http.get(this.$api(`/base-products/${this.currentProduct.base_product_id}`)).then(response => {
        this.products.splice(
          this.products.findIndex(baseProduct => baseProduct.id == response.data.baseProduct.id),
          1,
          response.data.baseProduct
        );
      })
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
      this.showDeleteModalBatch = false;
    });
  }
}
