





























































import {
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import 'flatpickr/dist/flatpickr.css';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';
// import TerritoryTypeAutocomplete from '@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue';
// import TerritoryAutocomplete from '@/components/resources-autocomplete/TerritoryAutocomplete.vue';
import TerritoryCascaderDropdown from '@/components/resources-autocomplete/TerritoryCascaderDropdown.vue';
import {
    QueryBuilder
} from '@/classes/QueryBuilder';
import {
    ButtonGroup,
    Button
} from 'element-ui';
import moment from 'moment';
import MemberAutocomplete from '@/components/resources-autocomplete/MemberAutocomplete.vue';
import ActiveUsers from '@/views/dashboard/BasicReporting/ActiveUsers.vue';
import AverageWorking from '@/views/dashboard/BasicReporting/AvgWorking.vue';
import visitVsPlan from '@/views/dashboard/BasicReporting/visitVsPlan.vue';
import NewOutlets from '@/views/dashboard/BasicReporting/NewOutlets.vue';
import CoveredOutlet from '@/views/dashboard/BasicReporting/CoveredOutlet.vue';
import ProductiveTime from '@/views/dashboard/BasicReporting/ProductiveTime.vue';
import visitingHours from '@/views/dashboard/BasicReporting/visitingHours.vue';

const flatPicker = require('vue-flatpickr-component');

@Component({
    components: {
        ActiveUsers,
        AverageWorking,
        visitVsPlan,
        NewOutlets,
        ProductiveTime,
        CoveredOutlet,
        visitingHours,
        MemberAutocomplete,
        // TerritoryAutocomplete,
        // TerritoryTypeAutocomplete,
        TerritoryCascaderDropdown,
        flatPicker,
        [Button.name]: Button,
        [ButtonGroup.name]: ButtonGroup
    }
})
export default class TodaysData extends Mixins(TaxonomyMixin) {
    public selectedDateRange: string = moment().format('YYYY-MM-DD');
    // private selectedTerritoryType: number | null = null;
    public selectedTerritory: any | null = null;
    public selectedUser: number | null = null;
    public error: string = '';
    private isFetchData: boolean = false;
    private loading: boolean = false;
    private queryBuilder: QueryBuilder = new QueryBuilder();

    @Watch('queryBuilder.watch')
    fetchTodyasData() {
        this.loading = true;
        this.isFetchData = false;
    }

    @Watch('selectedTerritoryType')
    onChangeTerritoryType(value: number) {}

    @Watch('selectedTerritory')
    onChangeTerritory(value: number) {
        this.queryBuilder.addListener('territory_id', value);
    }

    @Watch('selectedUser')
    onChangeUser(value: number) {
        this.queryBuilder.addListener('user_id', value);
    }

    @Watch('selectedDateRange')
    onChangeDateRange(value: string) {
        this.queryBuilder.addListener('range', value);
    }

    todaysDate() {
        var currentDate = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, '/');
        return currentDate;
    }

    created() {
        this.fetchTodyasData();
    }
}
