



















import {
  Component,
  Mixins,
  Prop,
  Watch
} from "vue-property-decorator";
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
// @ts-ignore
import LineChart from '@/components/argon-core/Charts/LineChart';

import moment from 'moment';

@Component({
  components: {
    LineChart,
  },
})
export default class QuarterYearLineChartReport extends Mixins(TaxonomyMixin) {
  @Prop({default: ''})
  public range!: string;

  @Prop({default: null})
  public territoryId!: number | null;

  @Prop({default: null})
  public userIds!: string | null;

  @Prop({default: null})
  public departmentIds!: string | null;

  @Prop({default: null})
  public brandIds!: string | null;

  @Prop({default: null})
  public viewBaseOn!: string | null;

  @Prop({default: null})
  public valueType!: string | null;

  @Prop({default: null})
  public limitBrand!: number | null;

  @Prop({default: false})
  public isGenerateReport!: boolean;

  private loading: boolean = true;

  private quarterYearlyChartInfo: any = {
    chartData: {
      datasets: [],
      labels: []
    },
    extraOptions: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Quarter Yearly Brand List'
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Quarter Year List'
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Value',
          },
        }]
      },
      legend: {
        display: true,
        position: 'top'
      },
      tooltips: {
        mode: 'single',
        intersect: false,
      }
    }
  };

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    this.quarterYearlyChartInfo.chartData.datasets = [];
    this.quarterYearlyChartInfo.chartData.labels = [];
    const params = {
      type: 'quarterYearBrands',
      range: this.range,
      territory_id: this.territoryId,
      user_ids: this.userIds,
      department_ids: this.departmentIds,
      brand_ids: this.brandIds,
      view_base_on: this.viewBaseOn,
      value_type: this.valueType,
      limit_brand: this.limitBrand
    };

    try {
      let {data} = await this.$http.get(this.$api3(`/brand-report${this.$toQueryString(params)}`))
      this.quarterYearlyChartInfo.chartData.datasets = data.quarterYearBrandReport.datasets;
      this.quarterYearlyChartInfo.chartData.labels = data.quarterYearBrandReport.labels;
      this.quarterYearlyChartInfo.extraOptions.title.text = data.quarterYearBrandReport.title;

    }
      // catch (e) {
      //
      // }
    finally {
      this.loading = false
    }
  }

  @Watch('key')
  onKeyChange() {
    this.fetch();
  }
}
