


































































































































































import {
    Mixins,
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import {
    Divider,
    Popover
} from "element-ui";
import OrderProduct from '@/intefaces/OrderProduct';
import StockLazydropdown from "@/components/lazyDropdown/StockLazydropdown.vue";
import Order from "@/intefaces/Order";
import AsyncRender from "@/components/AsyncRender.vue";
import PromotionMixin from '@/mixins/PromotionComponent';
import Department from '@/intefaces/Department';
import PromotionList from '@/views/order/Components/PromotionList.vue';
import moment from 'moment';

@Component({
    name: 'OrderEdit',
    components: {
        [Divider.name]: Divider,
        [Popover.name]: Popover,
        StockLazydropdown,
        AsyncRender,
        PromotionList
    },
})

export default class OrderEdit extends Mixins(PromotionMixin) {
    @Prop() title!: string;
    @Prop() currentEntity!: any;
    @Prop({
        default: false
    }) editable!: boolean;
    @Prop() saveEditedOrder!: boolean;
    @Prop() discount!: number;
    private subTotal: number = 0;
    public salesReturn: number = 0;
    @Prop() entity!: string;
    @Prop() entityProducts!: OrderProduct[];
    @Prop() comment!: String;
    @Prop() orderIncludeLot!: boolean;
    private deliveredProducts: any = [];
    public tempProducts: any = [];
    private qty = 0;
    public products: any = [{
        id: '',
        product_id: '',
        qty: 0,
        price: 0,
        promotion_discount: 0,
        custom_discount: 0,
        promotion_id: '',
        offerId: '',
        offerQty: 0,
        is_free: 0,
        free_with: null,
        free_with_product: {},
        promotion: {},
        message: '',
        availableQty: 0,
        lot_number: ''
    }]

    public salesReturns: any = [{
        product_id: '',
        qty: 0,
        return_rate: 0,
        promotion_discount: 0,
        custom_discount: 0,
        message: '',
        is_free: 0,
        free_with: null,
        free_with_product: {},
        reason: 'damage',
        amount: 0,
    }]

    addNewReturnedProduct() {
        this.tempProducts = [...this.salesReturns];
        this.salesReturns.push({
            product_id: '',
            qty: 0,
            return_rate: 0,
            promotion_discount: 0,
            custom_discount: 0,
            message: '',
            is_free: 0,
            free_with: null,
            free_with_product: null,
            reason: 'damage',
            amount: 0,
        })
    }

    addNewProduct() {
        this.tempProducts = [...this.products];
        this.products.push({
            id: '',
            product_id: '',
            qty: 0,
            price: 0,
            promotion_discount: 0,
            custom_discount: 0,
            promotion_id: '',
            offerId: '',
            offerQty: 0,
            is_free: 0,
            free_with: null,
            free_with_product: null,
            promotion: {},
            message: '',
            lot_number: ''
        })
    }

     created() {
      if(this.currentEntity.is_requisition && this.currentEntity.latest_status === 'pending') {
        this.department = this.currentEntity.buyer;
      } else {
        this.department = this.currentEntity.department;
      }
      if(this.department){
        this.fetchPromotionInitialData();
      }
    }

    // getFreeProductName(orderedProductId: number) {
    //   const freeProduct = this.orderFreeProducts.find((product: any) => product.free_with === orderedProductId);
    //   return freeProduct ? freeProduct.name : null;
    // }

    // getFreeProductQty(orderedProductId: number) {
    //   const freeProduct = this.orderFreeProducts.find((product: any) => product.free_with === orderedProductId);
    //   return freeProduct ? freeProduct.qty : null;
    // }

    deleteProductFromArray(orderedProduct: OrderProduct, index: number) {
        this.products.splice(index, 1);
        this.calculateOrderTotalCal();
        // this.checkForExistingPromotion(orderedProduct);
        this.$emit('disabledSaveBtn', false)
        this.tempProducts = [...this.products];
    }

    deleteReturnedProductFromArray(index: number) {
        this.salesReturns.splice(index, 1);
        this.calculateOrderTotalCal();
        // this.checkForExistingPromotion();
        this.$emit('disabledSaveBtn', false)
        this.tempProducts = [...this.salesReturns];
    }

    // checkForExistingPromotion(orderedProduct: OrderProduct) {
    //   if (orderedProduct.promotion) {
    //     this.orderFreeProducts.forEach((product: any, index: number) => {
    //       if (product.is_free && product.free_with && (orderedProduct.product_id == product.free_with)) {
    //         this.orderFreeProducts.splice(index, 1);
    //       }
    //     });
    //   }
    // }

    calculateProductAmount(orderedProduct: any) {
        return ((orderedProduct.qty * orderedProduct.price) - orderedProduct.promotion_discount - orderedProduct.custom_discount).toFixed(2);
    }

    calculateReturnProductAmount(returnProduct: any) {
        return returnProduct.qty * returnProduct.return_rate;
    }

    async getProductQtyForSeller(productId: number, product: any) {
        if (this.currentEntity.hasOwnProperty('is_requisition')) {
            // const response = await this.$http.get(this.$api(`/stocks?distinct&department_id=${this.currentEntity.seller_id}&product_id=${productId}`));
            // product.availableQty = response.data.stocks[0].qty;

            await this.$http.get(this.$api(`/stocks?distinct&department_id=${this.currentEntity.seller_id}&product_id=${productId}`))
                .then(response => {
                    product.availableQty = response.data.stocks[0].qty;
                })
        }
    }

    calculateOrderTotalCal() {
        let subTotal = 0;
        let salesReturn = 0;
        this.products.forEach((product: any) => {
            if (!product.is_free) {
                subTotal += (product.qty * product.price) - product.promotion_discount - product.custom_discount;
            }
        })
        this.salesReturns.forEach((product: any) => {
            if (!product.is_free) {
                salesReturn += product.qty * product.return_rate;
            }
        })
        this.subTotal = subTotal;
        this.salesReturn = salesReturn;
        this.$emit('subTotal', subTotal);
        this.$emit('salesReturn', salesReturn);
    }

    async getPrice(selectedProduct: any) {
        selectedProduct.id = '';
        selectedProduct.price = 0;
        selectedProduct.message = '';
        selectedProduct.qty = 0;
        selectedProduct.promotion_discount = 0;
        selectedProduct.custom_discount = 0;
        selectedProduct.promotion_id = '';
        selectedProduct.promotion_title = '';
        selectedProduct.availableQty = 0;
        selectedProduct.lot_number = '';
        selectedProduct.base_product = '';
        selectedProduct.name = '';
        selectedProduct.promotions = [];
        if (selectedProduct.product_id == '') {
            this.$emit('disabledSaveBtn', true);
        } else {
            if (this.tempProducts.some((product: any) => parseInt(product.product_id) === parseInt(selectedProduct.product_id))) {
                selectedProduct.message = "The product already selected, choose others"
                this.$emit('disabledSaveBtn', true);
            } else {
                await this.$http.get(this.$api(`/products/${selectedProduct.product_id}?with=prices.priceable,baseProduct`))
                    .then(response => {
                        //TODO:: when new product is added offer product or last product is removed from product list

                        const productPriceFound = response.data.product?.prices.find((productPrice: any) =>
                          (productPrice.priceable_type === 'dept' && productPrice.priceable_id === this.currentEntity.buyer_id) ||
                          (productPrice.priceable_id ===
                            (productPrice.priceable_type === 'depttype'
                              ? this.currentEntity.department?.department_type_id
                              : this.currentEntity.buyer_id)
                          )
                        );

                        if (productPriceFound) {
                            selectedProduct.message = '';
                            if (selectedProduct.hasOwnProperty('return_rate')) {
                                selectedProduct.return_rate = productPriceFound.price;
                            }
                            selectedProduct.price = productPriceFound.price;
                            selectedProduct.name = response.data.product.full_name;
                            selectedProduct.base_product = response.data.product.base_product;
                            this.$emit('disabledSaveBtn', false)
                            this.getProductPromotion(selectedProduct);
                        } else {
                            selectedProduct.message = "Product Price is not associated with buyer departments."
                            this.$emit('disabledSaveBtn', true);
                        }
                        // response.data.product?.prices.forEach((productPrice: any) => {
                        //   if (productPrice.priceable_id == (productPrice.priceable_type == 'depttype' ? this.currentEntity.department.department_type_id : this.currentEntity.buyer_id)) {
                        //     selectedProduct.message = '';
                        //     if (selectedProduct.hasOwnProperty('return_rate')) {
                        //       selectedProduct.return_rate = productPrice.price;
                        //     }
                        //     selectedProduct.price = productPrice.price;
                        //     this.$emit('disabledSaveBtn', false)
                        //   } else {
                        //     selectedProduct.price = 0;
                        //     selectedProduct.message = "Product Price is not associated with buyer departments."
                        //     selectedProduct.qty = 0;
                        //     selectedProduct.promotion_discount = 0;
                        //     selectedProduct.custom_discount = 0;
                        //     this.$emit('disabledSaveBtn', true);
                        //   }
                        // });
                    })
                this.calculateOrderTotalCal();
            }
        }
    }

    clearInput(removeProductId: any, index: number) {
        // let foundProduct: any = this.entityProducts.find((product: any) => product.product_id == removeProductId);
        // if (foundProduct.promotion && foundProduct.promotion!.offer_type == 'qty') {
            // let offerProductIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == foundProduct.product_id);
            // this.$delete(this.orderFreeProducts, offerProductIndex);
        // }
        // this.products.splice(index, 1);
    }

    // removePromotionProduct(selectedProduct: OrderProduct) {
    //   if (this.orderFreeProducts.some((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id)) {
    //     let productIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == selectedProduct.product_id);
    //     this.$delete(this.orderFreeProducts, productIndex);
    //   }
    // }

    // getProductPromotion(selectedProduct: any, index: number) {
    //   const findFreeProduct: any = this.entityProducts.find(
    //     (product: any) => product.free_with === selectedProduct.product_id
    //   );
    //   if (findFreeProduct) {
    //     findFreeProduct.is_free = 1;
    //   }
    //   this.$http.get(this.$api(`/promotions?promotionable_id=product,${selectedProduct.product_id}`)).then(response => {
    //     selectedProduct.promotion = response.data.promotions[0];
    //     if (response.data.promotions.length > 0) {
    //       const expireDateFormatted = new Date(selectedProduct.promotion.expires_at).toISOString().split('T')[0];
    //       const currentDateFormatted = new Date().toISOString().split('T')[0];
    //       if (expireDateFormatted < currentDateFormatted) {
    //         if (selectedProduct.promotion.promotion_type == "qty" && selectedProduct.promotion.offer_type == "qty") {
    //           let productIndex = this.orderFreeProducts.findIndex((product: any) => product.free_with == selectedProduct.product_id);
    //           if (productIndex !== -1) {
    //             this.$delete(this.orderFreeProducts, productIndex);
    //           }
    //         } else {
    //           selectedProduct.promotion_discount = 0;
    //         }
    //       } else {
    //         this.calculatePromotion(findFreeProduct, selectedProduct, index)
    //       }
    //     }
    //   })
    // }
    // calculatePromotion(findFreeProduct: any, selectedProduct: OrderProduct, index: number) {
    //   let amount = selectedProduct.qty * selectedProduct.price;
    //   if (selectedProduct.promotion.promotion_type == 'qty') {
    //     this.calculatePromotionForQtyType(findFreeProduct, selectedProduct, amount);
    //   } else if (selectedProduct.promotion.promotion_type == 'amount') {
    //     this.calculationPromotionForAmountType(findFreeProduct, selectedProduct, amount);
    //   }
    // }

    // calculatePromotionForQtyType(findFreeProduct: any, selectedProduct: OrderProduct, amount: number) {
    //   //TODO::(need test: product not removed on removePromotionProduct call )
    //   //check expires data for promotion product
    //   if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
    //     this.removePromotionProduct(selectedProduct);
    //     this.orderFreeProducts.push({
    //       product_id: selectedProduct.promotion.offer_product!.id,
    //       name: selectedProduct.promotion.offer_product!.full_name,
    //       qty: selectedProduct.promotion.offer_value *
    //         Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value),
    //       price: 0,
    //       promotion_discount: 0,
    //       custom_discount: 0,
    //       promotion_id: selectedProduct.promotion.id,
    //       freeWith: findFreeProduct!.free_with,
    //       is_free: 1,
    //       free_with: findFreeProduct!.free_with,
    //       free_with_product: findFreeProduct!.free_with_product ?? null,
    //     })
    //   } else if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value > selectedProduct.qty) {
    //     this.removePromotionProduct(selectedProduct);
    //   } else if (selectedProduct.promotion!.offer_type == 'amount' &&
    //     selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
    //     selectedProduct.promotion_discount = selectedProduct.promotion.offer_value *
    //       Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value);
    //   } else if (selectedProduct.promotion!.offer_type == 'percent' &&
    //     selectedProduct.promotion!.promotion_value <= selectedProduct.qty) {
    //     selectedProduct.promotion_discount = (amount * selectedProduct.promotion.offer_value) / 100
    //   } else {
    //     if (selectedProduct.promotion!.promotion_value > selectedProduct.qty) {
    //       // this.removePromotionProduct(selectedProduct);
    //     }
    //   }
    // }

    // calculationPromotionForAmountType(findFreeProduct: any, selectedProduct: OrderProduct, amount: number) {
    //   if (selectedProduct.promotion!.offer_type == 'qty' && selectedProduct.promotion!.promotion_value <= amount) {
    //     // if (this.products.some((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id)) {
    //     //   this.products.splice(this.products.findIndex((product: any) => product.product_id == selectedProduct.promotion.offer_product!.id, 1));
    //     // }
    //     // this.removePromotionProduct(selectedProduct);

    //     this.orderFreeProducts.push({
    //       product_id: selectedProduct.promotion.offer_product!.id,
    //       name: selectedProduct.promotion.offer_product!.full_name,
    //       qty: selectedProduct.promotion.offer_value *
    //         Math.floor(selectedProduct.qty / selectedProduct.promotion.promotion_value),
    //       price: 0,
    //       promotion_discount: 0,
    //       custom_discount: 0,
    //       freeWith: findFreeProduct!.free_with,
    //       is_free: 1,
    //       free_with: findFreeProduct!.free_with,
    //       free_with_product: findFreeProduct!.free_with_product ?? null,
    //     })
    //   } else if (selectedProduct.promotion!.offer_type == 'amount' &&
    //     selectedProduct.promotion!.promotion_value <= amount) {
    //     selectedProduct.promotion_discount = selectedProduct.promotion.offer_value *
    //       Math.floor(amount / selectedProduct.promotion.promotion_value);
    //   } else if (selectedProduct.promotion!.offer_type == 'percent' &&
    //     selectedProduct.promotion!.promotion_value <= amount) {
    //     selectedProduct.promotion_discount = (amount * selectedProduct.promotion.offer_value) / 100
    //   }
    // }

    @Watch('editable')
    getExistingOrderedProducts(newVal: boolean) {
        if (newVal) {
            this.products = [];
            this.salesReturns = [];
            // finds order products and order free products
            this.entityProducts.forEach((orderedProduct: OrderProduct, index: number) => {
                const product = {
                    id: orderedProduct.id,
                    product_id: orderedProduct.product.id,
                    base_product: orderedProduct.product.base_product,
                    name: orderedProduct.product!.hasOwnProperty("full_name") ?
                        orderedProduct.product!.full_name : orderedProduct.product.base_product!.name,
                    qty: orderedProduct.qty,
                    price: orderedProduct.price,
                    promotion_discount: orderedProduct.promotion_discount,
                    custom_discount: orderedProduct.hasOwnProperty('custom_discount') ? orderedProduct.custom_discount : 0,
                    is_free: orderedProduct.free_with ? 1 : 0,
                    free_with: orderedProduct.free_with,
                    free_with_product: orderedProduct.free_with_product ?? null,
                    promotion_id: orderedProduct.promotion ? orderedProduct.promotion!.id : '',
                    promotion_title: orderedProduct.promotion ? orderedProduct.promotion!.title : '',
                    lot_number: orderedProduct.lot_number,
                    promo_expired: orderedProduct.promotion ? moment(orderedProduct.promotion!.expires_at).isBefore(moment()) : false,
                };
                this.getProductPromotion(product);
                if (orderedProduct.free_with) {
                    this.freeProductForCart.push(product);
                } else {
                    this.products.push(product);
                }
                this.getProductQtyForSeller(orderedProduct.product.id, orderedProduct);
            });

            this.freeProductForCart.forEach((freeProduct: any) => {
                const findProduct = this.products.find((product: any) => product.product_id === freeProduct.free_with);

                if (!findProduct) return;

                this.products
                    .filter((product: any) => product.promotion_id === findProduct.promotion_id)
                    .forEach((product: any) => {
                        const index = this.products.findIndex((p: any) => p.product_id === product.product_id);
                        const name = product.product_id === freeProduct.free_with ?
                            freeProduct.name :
                            'Product bundled with another product';

                        this.$set(this.freeProduct, index, name);
                    });
            });
            // finds order return products
            // window.console.log("returned_products")
            // window.console.log(this.currentEntity.latest_revision.returned_products);
            if (this.currentEntity.latest_revision.returned_products != null) {
                this.currentEntity.latest_revision.returned_products.forEach((returnedProduct: any) => {
                    this.salesReturns.push({
                        product_id: returnedProduct.product_id,
                        return_rate: returnedProduct.return_rate,
                        qty: returnedProduct.qty,
                        promotion_discount: 0,
                        custom_discount: 0,
                        is_free: returnedProduct.is_free,
                        reason: returnedProduct.reason,
                        amount: returnedProduct.amount,
                    });
                });
            }
        }
        this.calculateOrderTotalCal();
    }

    @Watch('saveEditedOrder')
    editOrder(newVal: boolean) {
        if (newVal) {
            this.salesReturns.forEach((returnedProduct: any) => {
                returnedProduct.amount = returnedProduct.qty * returnedProduct.return_rate;
            });
            this.deliveredProducts = [...this.products, ...this.freeProductForCart].map((product: any) => {
                return {
                    id: product.id,
                    product_id: product.product_id,
                    qty: product.qty,
                    price: product.price,
                    promotion_discount: product.promotion_discount,
                    custom_discount: product.custom_discount,
                    promotion_id: product.promotion_id,
                    lot_number: product.lot_number,
                    is_free: product.is_free,
                    free_with: product.free_with,
                    free_with_product: product.free_with_product,
                }
            });

            // remove duplicate based on free_with
            this.deliveredProducts = this.deliveredProducts.filter((product: any, index: number, self: any) =>
                product.free_with === null || index === self.findIndex((t: any) => t.free_with === product.free_with)
            );
            if (this.currentEntity.latest_status === 'ontheway') {
                this.saveAndDelivery();
            } else {
                const promotionDiscount = this.deliveredProducts.reduce((acc: any, product: any) => {
                    return acc + parseFloat(product.promotion_discount);
                }, 0);
                this.$http.post(this.$api(`/${this.entity}/${this.currentEntity.id}/revisions`), {
                    buyer_id: this.currentEntity.buyer_id,
                    seller_id: this.currentEntity.seller_id,
                    amount: this.subTotal > 0 ? this.subTotal : 0,
                    promotion_discount: promotionDiscount,
                    discount: this.discount ? this.discount : 0,
                    is_requisition: this.currentEntity.hasOwnProperty('is_requisition') ? this.currentEntity.is_requisition : '',
                    products: this.deliveredProducts,
                    returned_products: this.salesReturns,
                }).then(response => {
                    // if (this.salesReturns.length > 0) {
                    //   window.console.log(this.salesReturns);
                    //   this.$http.post(this.$api('/sales-returns'), {
                    //     order_id: response.data.revision.order_id,
                    //     buyer_id: this.currentEntity.buyer_id,
                    //     seller_id: this.currentEntity.seller_id,
                    //     from_delivery: 0,
                    //     products: this.salesReturns
                    //   }).catch(error => {
                    //     this.$notify({
                    //       title: 'Warning',
                    //       message: 'Something went wrong for sales return.',
                    //       type: 'warning'
                    //     });
                    //   });
                    // }
                    this.$emit('update:editable', false);
                    this.$emit('changeStatus');
                    this.$emit('update:saveEditedOrder', false);
                    this.$emit('editedEntity', response.data.revision);
                    this.$notify({
                        title: 'Success',
                        message: 'Order successfully updated.',
                        type: 'success'
                    });
                }).catch(error => {
                    this.$emit('update:saveEditedOrder', false);
                    this.$emit('update:editable', false);
                    this.$notify({
                        title: 'Warning',
                        message: 'Something went wrong.',
                        type: 'warning'
                    });
                });
            }
        }
    }

    saveAndDelivery() {
        const promotionDiscount = this.deliveredProducts.reduce((acc: any, product: any) => {
            return acc + parseFloat(product.promotion_discount);
        }, 0);

        this.$http.post(this.$api(`/orders/${this.currentEntity.order_id}/deliveries`), {
          buyer_id: this.currentEntity.buyer_id,
          seller_id: this.currentEntity.seller_id,
          amount: this.subTotal > 0 ? this.subTotal : 0,
          discount: this.discount || 0,
          promotion_discount: promotionDiscount,
          products: this.deliveredProducts,
          comment: this.comment
        })
        .then(response => {
          // this.$emit('updatedStatus', this.currentEntity);
          this.$notify({
            title: 'Success',
            message: 'Delivered Successfully',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          window.location.reload();
        })
        .catch((error: any) => {
          const errorMessages = this.deliveredProducts.map((product: any, index: number) => {
            const productErrors = [
              'product_id', 'qty', 'free_with', 'promotion_id', 'promotion_discount', 'custom_discount'
            ].map(field => error.response.data.errors?.[`products.${index}.${field}`]?.[0])
            .filter(Boolean);

            return productErrors.length > 0 ? { name: product.product.full_name, error: productErrors[0] } : null;
          }).filter(Boolean);

          this.$emit('errorMessages', errorMessages);
        })
        .finally(() => {
          this.$emit('update:editable', false);
          this.$emit('changeStatus');
          this.$emit('update:saveEditedOrder', false);
        });
    }

    // new promotion code
    calculatePromotion() {
        this.applyPromotions();
    }

    removePromotion(product: any, index: number) {
        product.promotion_id = '';
        product.promotion_title = '';
        this.promotionSuggestions[index] = '';
        this.productPromotionalPrice[index] = product.qty * product.price;
    }
    // end new promotion code
}
