

















import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { Button, MessageBox } from "element-ui";
import VueClipboard from 'vue-clipboard2';
import { Tag } from 'element-ui';

Vue.use(VueClipboard);

@Component({
  name: 'TenantApiTokenModal',
  components: {
    ElButton: Button,
    'el-tag': Tag
  }
})
export default class TenantApiTokenModal extends Vue {
  @Prop() isView!: boolean;
  @Prop() currentEntity!: any;
  public token: any = null;
  private isNew: boolean = false;

  created() {
    this.fetchToken();
  }

  onCopy() {
    this.$notify({
      message: 'Copied',
      title: 'Success',
      type: 'success'
    });
  }

  async generateNew() {
    this.isNew = true;
    MessageBox.alert('<span style="font-size: 14px"> Are you sure you want to generate a new API token? </span>', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      dangerouslyUseHTMLString: true,
      type: 'warning',
      center: true
    })
      .then(() => {
        this.$http.post(this.$landLordApi(`/tenant-api-token`), {
          "org_id": this.currentEntity.id,
          "isnew": 1
        })
          .then(response => {
            this.token = response.data.bearerToken;
            if (this.isNew) {
              this.$notify({
                message: 'API token successfully regenerated!',
                title: 'Success',
                type: 'success'
              });
            }
          });
      })
  }

  fetchToken() {
    this.$http.post(this.$landLordApi(`/tenant-api-token`), {
      "org_id": this.currentEntity.id,
      "isnew": 0
    })
      .then(response => {
        this.token = response.data.bearerToken;
      });
  }

  close() {
    this.$emit('update:isView', false);
  }
}
