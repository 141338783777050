var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"logo":_vm.logoUrl}},[_c('template',{slot:"links"},[(_vm.permissions.find(function (permission) { return permission.dashboards == true; }))?_c('sidebar-item',{class:['/basic-reporting','/outlet-order','/miscellaneous', '/sales-growth', '/dashboard'].includes(this.$route.path)  ? '' : 'foo',attrs:{"link":{
            name: 'Dashboard',
            icon: 'ni ni-planet text-sokrio',
            path: '/',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.user == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'User',
            icon: 'ni ni-single-02 text-sokrio',
            path: '/user',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.organization == true; }))?_c('sidebar-item',{attrs:{"link":{
                name: 'Territory',
                path: '/territory',
                icon: 'fa fa-sitemap text-sokrio',
              }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.organization == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Customer Master',
            path: '/department',
            icon: 'fas fa-store text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.inventory == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Product Master',
            icon: 'ni ni-app text-sokrio',
            path: '/inventory',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.tracking == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Tracking',
            icon: 'fa fa-binoculars text-sokrio',
            path: '/tracking',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.attendance == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Attendance',
            path: '/attendance',
            icon: 'fas fa-hand-paper text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.inspection == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Inspection',
            path: '/inspection/survey',
            icon: 'fas fa-lightbulb text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.primaryorder == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Primary ' + _vm.getTaxonomy('order'),
            icon: 'ni ni-book-bookmark text-sokrio',
            path: '/primary-order',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.order == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Secondary ' + _vm.getTaxonomy('order'),
            icon: 'ni ni-book-bookmark text-sokrio',
            path: '/order',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.payment == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Payment',
            icon: 'ni ni-credit-card text-sokrio',
            path: '/payment',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.stock == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Stock',
            path: '/stock',
            icon: 'fas fa-cubes text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.report == true; }))?_c('sidebar-item',{attrs:{"link":{
                name: 'Report',
                path: '/report',
                icon: 'fa fa-file text-sokrio',
              }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.customreport == true; }))?_c('sidebar-item',{attrs:{"link":{
          name: 'Custom Report',
          path: '/custom-report',
          icon: 'fa fa-file text-sokrio',
        }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.supervisorReport == true; }))?_c('sidebar-item',{attrs:{"link":{
              name: 'Manager Report',
              path: '/supervisor-report',
              icon: 'fa fa-file text-sokrio',
            }}}):_vm._e(),(_vm.$user.id == 1 && _vm.permissions.find(function (permission) { return permission.setting == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'Settings',
            path: '/settings',
            icon: 'fas fa-cog text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.sms == true; }))?_c('sidebar-item',{attrs:{"link":{
            name: 'SMS',
            path: '/sms',
            icon: 'fa fa-envelope text-sokrio',
          }}}):_vm._e(),(_vm.permissions.find(function (permission) { return permission.billing == true; }))?_c('sidebar-item',{attrs:{"link":{
                  name: 'Billing info',
                  path: '/billing',
                    icon: 'fas fa-wallet text-sokrio',
              }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.name === 'alternative' ? 'light' : 'default'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('dashboard-content')],1),_c('div',{staticStyle:{"clear":"both"}}),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1),_c('SetupWizard')],1)}
var staticRenderFns = []

export { render, staticRenderFns }