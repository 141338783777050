






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import "flatpickr/dist/flatpickr.css";
import SingleImageUploader from "@/components/ui/SingleImageUploader.vue";
import BrandForm from "@/components/products/BrandForm.vue";
import CategoryForm from "@/components/products/CategoryForm.vue";
import AddProduct from "@/views/product/AddProduct.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import ProductLazydropdown from "@/components/lazyDropdown/ProductLazydropdown.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import { Dropdown, DropdownItem, DropdownMenu, Form, MessageBox, Tooltip, } from 'element-ui';
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";
import moment from "moment/moment";

const flatPicker = require("vue-flatpickr-component");

@Component({
  components: {
    TerritoryCascaderDropdown,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    flatPicker,
    SingleImageUploader,
    BrandForm,
    CategoryForm,
    AddProduct,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    ProductLazydropdown,
    UserLazydropdown
  },
})
export default class PriorityProductForm extends Vue {
  @Prop() currentEntity!: any;
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() form!: {
    title: string,
    territory_id: number | null,
    start_date: string,
    expires_at: string,
    type: string,
    status: string,
    value: any,
  }
  $refs!: {
    formValidator: HTMLFormElement | Vue
  }
  public selectedPriorityAbleData = "";
  private disableButton = false;
  private loading = false;
  private showAlertModal = false;
  private alertMessage = null;
  private submitPriorityProduct = false;
  private disableDraftButton = false;
  private disableLiveButton = false;
  private draftLoading = false;
  private liveLoading = false;
  private valueTypeList = [
    {'label': 'Brand', 'name': 'brand'},
    {'label': 'Category', 'name': 'category'},
    {'label': 'SKU', 'name': 'sku'}
  ]

  get getConfigEndDate(): any {
    let self: any = this;
    const start_date = new Date(this.form.start_date);
    return {
      allowInput: true,
      // maxDate: this.maxDate ? moment(this.maxDate).format('YYYY-MM-DD') : start_date.setDate(start_date.getDate() + 30),
      disable: [
        function (date: Date) {
          return moment(self.form.start_date).diff(date) > 0;
        }
      ]
    };
  }

  get territoryLabel() {
    const queryString = this.isUpdate ? ` > ${this.currentEntity.territory.name}` : '';
    return `Selected Territory ${queryString}`;
  }

  setNullPriorityItem(type: any) {
    this.form.value = [];
  }

  checkMaxSelection() {
    if (this.form.value.length > 4) {
      this.$notify({
        title: 'Warning',
        message: 'Only 4 item can be selected',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'warning'
      });
      this.form.value.pop();
    }
  }

  formReset() {
    this.form.title = '';
    this.form.territory_id = null;
    this.form.start_date = '';
    this.form.expires_at = '';
    this.form.type = '';
    this.form.status = '';
    this.form.value = [];
    this.disableButton = false;
    this.$nextTick(() => {
      (this.$refs.formValidator as HTMLFormElement).reset();
    })
  }

  close() {
    this.$emit('update:isActive', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:form',
      {
        title: '',
        territory_id: null,
        start_date: '',
        expires_at: '',
        type: '',
        status: '',
        value: [],
      })
    this.$nextTick(() => {
      (this.$refs.formValidator as HTMLFormElement).reset();
    })
  }

  save(status: string) {
    this.form.status = status;
    if (status == 'draft') {
      this.isUpdate ? this.updatePriorityProduct() : this.createPriorityProduct();
    } else {
      MessageBox.alert('<span style="font-size: 14px">' +
        'Please review the information carefully. Once this data is saved,' +
        ' it cannot be retrieved or deleted from the system. If needed, you can locate the data in the list and mark it as inactive.. ' +
        '<br> <br>System admin may help to adjust this data if made any mistakes.</span>', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true
      })
        .then(() => {
          this.isUpdate ? this.updatePriorityProduct() : this.createPriorityProduct();
        });
    }
  }

  createPriorityProduct() {
    this.buttonFunction(this.form.status, true);
    this.$http.post(this.$api('/priority-products'), this.form)
      .then(response => {
        this.$emit('created', response.data.priorityProduct);
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.close();
        this.buttonFunction(this.form.status, false);
      })
      .catch(error => {
        this.buttonFunction(this.form.status, false);
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  updatePriorityProduct() {
    this.buttonFunction(this.form.status, true);
    this.$http.put(this.$api(`/priority-products/${this.currentEntity.id}`), this.form)
      .then(response => {
        this.$emit('updated', response.data.priorityProduct);
        this.$notify({
          title: 'Success',
          message: 'Successfully Updated',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        this.close();
        this.buttonFunction(this.form.status, false);
      })
      .catch(error => {
        this.buttonFunction(this.form.status, false);
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  buttonFunction(type: string, isActive: boolean) {
    if (isActive) {
      if (type === 'draft') {
        this.disableDraftButton = true;
        this.draftLoading = true;
      } else if (type === 'active') {
        this.disableLiveButton = true;
        this.liveLoading = true;
      }
    } else {
      if (type === 'draft') {
        this.disableDraftButton = false;
        this.draftLoading = false;
      } else if (type === 'active') {
        this.disableLiveButton = false;
        this.liveLoading = false;
      }
    }
  }
}
