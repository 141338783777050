






























































































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import TargetModalData from "@/mixins/TargetModalData";
import { ElSelect } from "element-ui/types/select";
import UnitLazydropdown from "@/components/lazyDropdown/UnitLazydropdown.vue";
import SingleProduct from "@/views/user/target/SingleProduct.vue";

@Component({
  components: {SingleProduct, UnitLazydropdown}
})
export default class RegularTargetComponent extends Mixins(TargetModalData) {}
