



































































































import {
    Vue,
    Component,
    Mixins,
    Watch
} from 'vue-property-decorator';
import {
    Tag,
    Table,
    TableColumn,
    Tooltip,
} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import moment from 'moment';
import LazyDropdown from "@/components/LazyDropdown.vue";

@Component({
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Tag.name]: Tag,
        LazyDropdown
    }
})
export default class BillingList extends Mixins(PaginationComponentMixin) {
    public formData: any = {};
    public users: any = [];
    public downloading: boolean = false;
    public summaryDownloading: boolean = false;
    public selectedTenent: any = null;

    @Watch('pagination.currentPage')
    async onClickGenerate() {
        this.loading = true;
        this.users = [];
        await this.$http.get(this.$landLordApi(`/tenant-billing-list${this.$toQueryString(this.formData)}&page=${ this.pagination.currentPage }`)).then((response) => {
                this.users = response.data.users;
                this.setPagination(response.data);
            })
            .catch(error => {
                (this.$refs.formValidator as Vue & {
                    setErrors: (errors: []) => any;
                }).setErrors(error.response.data.errors);
            })
            .finally(() => (this.loading = false));
    }

    dateFormat(date: string) {
        if (date) {
            return moment(date).format('lll');
        }
        return '-';
    }
    async onClickDownload() {
        this.downloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$landLordApi(`/tenant-billing-list${this.$toQueryString(this.formData)}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.downloading = false;
        }
    }
    async downloadTenantSummaries() {
        this.summaryDownloading = true;
        try {
            let {
                data,
                headers
            } = await this.$http.get(this.$landLordApi(`/tenant-billing-summaries${this.$toQueryString(this.formData)}&download`), {
                responseType: 'arraybuffer'
            });
            const blob = await new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement("a");
            link.href = await URL.createObjectURL(blob);
            link.download = this.$getFileNameFromContentDisposition(headers);
            link.click();
        } catch (e) {
            this.$notify({
                title: 'Something wants wrong!',
                type: 'warning',
                message: 'Unable to download reports.',
            })
        } finally {
            this.summaryDownloading = false;
        }
    }
}
