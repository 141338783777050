





















































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import User from '@/intefaces/User.js';
import GIFModal from '@/components/tour/GIFModal.vue';
import ViewUserAcl from '@/views/user/user/ViewUserAcl.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';
import UserLazydropdown from '@/components/lazyDropdown/UserLazydropdown.vue';
import TerritoryLazydropdown from '@/components/lazyDropdown/TerritoryLazydropdown.vue';

import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import RoleLazydropdown from '@/components/lazyDropdown/RoleLazydropdown.vue';
import { QueryBuilder } from '@/classes/QueryBuilder';
import AsyncImage from '@/components/AsyncImage.vue';
import { Permission as PermissionEnum } from '@/enums/Permission';

@Component({
  components: {
    AsyncImage,
    RoleLazydropdown,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ViewUserAcl,
    GIFModal,
    FilterDrawerComponent,
    UserLazydropdown,
    TerritoryLazydropdown
  }
})
export default class UserList extends Mixins(PaginationComponentMixin) {
  private users: User[] = [];
  private downloadingUsers = false;
  private isActive = false;
  private isUpdate = false;
  private showUserAclList = false;
  private currentUser: any = [];
  private showGIF = false;
  private query: any = [];
  private isFilterModelActive = false;
  private afterQuery: boolean = false;
  private statuses = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' }
  ];
  private form = {
    name: '',
    email: '',
    phone: '',
    address: '',
    gender: '',
    territory_id: '',
    password: '',
    password_confirmation: '',
    permissions: [],
    roles: [],
    status: true,
    code: '',
    device_id: ''
  };
  private filters: any = {
    name: '',
    code: '',
    territory_id: ''
  };
  private queryBuilder = new QueryBuilder();
  private demoTourEntity: string = 'user';
  private GIFs: Array<any> = [
    {
      label: 'Create a user',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a user',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a user',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of user';

  get permissionEnum() {
    return PermissionEnum;
  }

  get user_id() {
    return this.$store.getters['Auth/user'].id;
  }

  created() {
    this.fetchUser();
  }

  @Watch('filters.name', { deep: true })
  onChangeNameFiler(newVal: string) {
    this.queryBuilder.addListener('q', newVal);
  }

  @Watch('filters.code', { deep: true })
  onChangeCodeFiler(newVal: string) {
    this.queryBuilder.addListener('code', newVal);
  }

  @Watch('filters.territory_id', { deep: true })
  onChangeTerritoryFiler(newVal: string) {
    this.queryBuilder.addListener('t', newVal);
  }

  @Watch('pagination.currentPage')
  onChangePage(newVal: any) {
    this.queryBuilder.addListener('page', newVal);
  }

  resetBtn() {
    this.filters.name = '';
    this.filters.code = '';
    this.filters.territory_id = '';
  }

  onFilterSubmit() {
    this.isFilterModelActive = false;
  }

  @Watch('queryBuilder.watch')
  fetchUser(): void {
    this.loading = true;
    this.$http.get(this.$api(`/users${this.queryBuilder.getFilters(true)}`)).then(response => {
      this.users = response.data.users;
      // eslint-disable-next-line no-console
      console.log('usersss', this.users);
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  async downloadUser() {
    this.downloadingUsers = true;
    try {
      const link = document.createElement('a');
      link.href = this.$api(`/user-app-info-download?download&${this.queryBuilder.getFilters(false)}`);
      link.click();
    } catch (e) {
      this.$notify({ type: 'warning', message: 'Unable to download.', title: 'Something went wrong!' });
    } finally {
      this.downloadingUsers = false;
    }
  }
}
