



































































































































































































































































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import 'flatpickr/dist/flatpickr.css';
import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn, Tag, Tooltip } from 'element-ui';
import CreateDepartmentForm from '@/views/department/CreateDepartmentForm.vue';
import BrandForm from '@/components/products/BrandForm.vue';
import CategoryForm from '@/components/products/CategoryForm.vue';
import SalesReturnAdd from '@/views/order/salesReturn/SalesReturnAdd.vue';
import DepartmentLazydropdown from '@/components/lazyDropdown/DepartmentLazydropdown.vue';
import ProductBrandLazydropdown from '@/components/lazyDropdown/ProductBrandLazydropdown.vue';
import ProductCategoryLazydropdown from '@/components/lazyDropdown/ProductCategoryLazydropdown.vue';
import ProductLazydropdown from '@/components/lazyDropdown/ProductLazydropdown.vue';
import StockLazydropdown from '@/components/lazyDropdown/StockLazydropdown.vue';
import Department from '@/intefaces/Department';
import AsyncRender from '@/components/AsyncRender.vue';
import TaxonomyMixin from '@/mixins/TaxonomyComponent';

const flatPicker = require('vue-flatpickr-component');

@Component({
  name: 'RequisitionForm',
  components: {
    AsyncRender: AsyncRender,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    flatPicker,
    CreateDepartmentForm,
    BrandForm,
    CategoryForm,
    DepartmentLazydropdown,
    ProductBrandLazydropdown,
    ProductCategoryLazydropdown,
    ProductLazydropdown,
    SalesReturnAdd,
    StockLazydropdown
  }
})
export default class RequisitionForm extends Mixins(TaxonomyMixin) {
  @Prop() isActive!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() currentEntity!: any;
  @Prop() updateAmountCount!: boolean;
  @Prop({default: false}) isInvoice!: boolean;
  @Prop() isDelivery!: boolean;
  @Prop() form!: {
    departmentId: any;
    sellerId: any;
    discount: any;
    expectedDeliveryDate: any;
    paid: any;
    type: string;
    productableId: any;
    promotionalDiscount: any;
    badgeDiscount: any;
  };
  @Prop() product!: any[];
  @Prop() salesReturns!: any[];

  private count = 0;
  private isShowPromotion: boolean = false;
  private promotionalDiscount: number = 0;
  private departmentLists: any[] = [];
  private products: any[] = [];
  private types = ['brand', 'category', 'product'];
  private fullProductList: any[] = [];
  private productable: any[] = [];
  private loading = false;
  private productPromotion: any = [];
  private salesReturn: boolean = false;
  private freeProduct: any = [];
  private disableDept: boolean = false;
  private isActiveRequestForm = false;
  private isActiveProducable = false;
  private stockChangeFlag = false;
  private calculateSalesReturn: any = 0;
  private amount = 0;
  private netTotalAmount: number = 0;
  private productPromotionalPrice: any = [];
  private productPriceTooltip = '';
  private buyerDepartment: any = [];
  private badgeDiscountAmount: number = 0;
  private isChangeProduct = 0;
  private flag: boolean = true;

  private brandForm: { name: string; parentId: number | string } = {
    name: '',
    parentId: ''
  };
  private categoryForm: { name: string; parentId: number | string } = {
    name: '',
    parentId: ''
  };
  private disableBtn = false;
  private netAmount: any = 0;

  get getCurrency() {
    return this.$store.getters['Settings/getCurrency'];
  }

  @Watch('form.type')
  nullProductableId() {
    this.form.productableId = '';
  }

  @Watch('form.departmentId')
  addNewDepartment(newVal: string) {
    if (newVal == '0') {
      this.isActiveRequestForm = true;
    }
  }

  @Watch('form.requestTo')
  addDepartment(newVal: string) {
    if (newVal == '0') {
      this.isActiveRequestForm = true;
    }
    this.product = [
      {
        product_id: '',
        price: '',
        qty: '',
        free_with: '',
        promotion_discount: 0,
        promotion_id: '',
        fullName: ''
      }
    ];
    this.productPriceTooltip = '';
  }

  @Watch('form.productableId')
  addNewProductable(newVal: string) {
    if (newVal == '0') {
      this.isActiveProducable = true;
    }
  }

  @Watch('isUpdate')
  setProductandSalesReturnValue(newVal: any) {
    if (newVal && !this.isInvoice && !this.isDelivery) {
      this.calculateSalesReturn = 0;
      // if (this.currentEntity.sales_return) {
      //     this.salesReturn = true
      // }

      this.salesReturns.forEach(salesReturn => {
        this.calculateSalesReturn = this.calculateSalesReturn + salesReturn.amount;
      });
    }
    this.netTotalAmount = this.currentEntity.amount;

    this.totalAmount();
  }

  @Watch('updateAmountCount')
  setTotalAmountForUpdate() {
    this.totalAmount();
    // this.freeProduct[index] =
  }

  updatedSalesReturn() {
    if (this.isUpdate && this.currentEntity.returned_products) {
      this.currentEntity.returned_products.forEach((product: any) => {
        this.calculateSalesReturn = product.qty * product.return_rate;
      });
    }
  }

  getProductPromotion(product: void) {
    if (this.form.type == 'product') {
      this.getPromotion(product);
    }
  }

  @Watch('form.departmentId')
  getSupplierId(departmentId: any) {
    if (departmentId) {
      this.$http.get(this.$api(`/departments/${departmentId}`)).then(response => {
        this.form.sellerId = response.data.department.supplier_id;
        this.form.badgeDiscount = response.data.department.badge ? response.data.department.badge.mrp_discount : 0;
        this.buyerDepartment = response.data.department;
      });
    }
  }


  calculateNetAmount() {
    return this.netTotalAmount - this.calculateSalesReturn;
  }

  updateSalesReturn() {
    if (!this.isUpdate) {
      this.$emit('update:salesReturns', [
        {
          product_id: '',
          qty: '',
          amount: '',
          return_rate: 0,
          paid: 0,
          reason: ''
        }
      ]);
    }
  }

  totalAmount() {
    this.amount = 0;
    this.product.forEach((product: any, index: any) => {
      if (product.qty < 1) {
        this.flag = true;
      } else {
        if (index > 0) {
          this.product.forEach((eachProduct: any) => {
            if (eachProduct.qty < 1) {
              this.flag = true;
            }
          });
        } else {
          this.flag = false;
        }
      }
    });

    if (this.updateAmountCount) {
      this.product.forEach((product: any, index: any) => {
        this.productPromotionalPrice[index] = this.amount + (product.qty * product.price - product.promotion_discount);
      });
      this.productPromotionalPrice.forEach((product: any) => {
        this.amount = this.amount + product;
      });
      this.$emit('update:updateAmountCount', false);
    } else {
      this.productPromotionalPrice.forEach((product: any) => {
        this.amount = this.amount + product;
      });
    }
    if (this.form.badgeDiscount) {
      this.netTotalAmount = this.amount - (this.form.badgeDiscount * this.amount) / 100;
    } else {
      this.netTotalAmount = this.amount;
    }
  }

  compairPromotion(product: any, index: any) {
    var amount = product.qty * product.price;
    this.productPromotionalPrice[index] = amount;
    var currentPromotion = this.productPromotion != '' ? this.productPromotion[this.productPromotion.length - 1] : '';
    if (currentPromotion != '' && currentPromotion.promotion_type == 'qty') {
      this.conditionForQtyPromotions(currentPromotion, index, amount, product);
    } else if (currentPromotion != '' && currentPromotion.promotion_type == 'amount') {
      this.conditionForPromotions(currentPromotion, index, amount, product);
    }
    this.product[index].promotion_id = currentPromotion != '' ? currentPromotion.id : '';
    this.totalAmount();
  }

  // TODO - reduce promotion calculation

  conditionForPromotions(currentPromotion: any, index: any, amount: any, product: any) {
    if (currentPromotion.offer_type == 'amount' && currentPromotion.promotion_value <= amount) {
      this.productPromotionalPrice[index] = amount - currentPromotion.offer_value * Math.floor(amount / currentPromotion.promotion_value);
      this.product[index].promotion_discount = currentPromotion.offer_value * Math.floor(amount / currentPromotion.promotion_value);
    } else if (currentPromotion.offer_type == 'qty' && currentPromotion.promotion_value <= amount) {
      this.freeProduct[index] =
        currentPromotion.offer_product.base_product.name +
        '-' +
        currentPromotion.offer_value * Math.floor(product.qty / currentPromotion.promotion_value) +
        ' qty';
      this.product[index].free_with = currentPromotion.promotionable_id;
      this.productPromotionalPrice[index] = amount;
    } else if (currentPromotion.offer_type == 'percent' && currentPromotion.promotion_value <= amount) {
      this.productPromotionalPrice[index] = amount - (amount * currentPromotion.offer_value) / 100;
      this.product[index].promotion_discount = (amount * currentPromotion.offer_value) / 100;
    }
  }

  conditionForQtyPromotions(currentPromotion: any, index: any, amount: any, product: any) {
    if (currentPromotion.offer_type == 'amount' && currentPromotion.promotion_value <= product.qty) {
      this.productPromotionalPrice[index] = amount - currentPromotion.offer_value * Math.floor(product.qty / currentPromotion.promotion_value);
      this.product[index].promotion_discount = currentPromotion.offer_value * Math.floor(product.qty / currentPromotion.promotion_value);
    } else if (currentPromotion.offer_type == 'qty' && currentPromotion.promotion_value <= product.qty) {
      this.freeProduct[index] =
        currentPromotion.offer_product.base_product.name +
        '-' +
        currentPromotion.offer_value * Math.floor(product.qty / currentPromotion.promotion_value) +
        ' qty';
      this.product[index].free_with = currentPromotion.offer_product_id;
      this.productPromotionalPrice[index] = amount;
    } else if (currentPromotion.offer_type == 'percent' && currentPromotion.promotion_value <= product.qty) {
      this.productPromotionalPrice[index] = amount - (amount * currentPromotion.offer_value) / 100;
      this.product[index].promotion_discount = (amount * currentPromotion.offer_value) / 100;
    }
  }


  getPromotion(product: any) {
    this.$http
      .get(this.$api(`/promotions?promotionable_id=${this.form.type},${this.form.type == 'product' ? product.product_id : this.form.productableId}`))
      .then(response => {
        this.productPromotion = response.data.promotions;
      });
  }

  setDepartmentId() {
    if (this.form.departmentId == 0) {
      this.form.departmentId = '';
    }
  }

  setProductableId() {
    if (this.form.productableId == 0) {
      this.form.productableId = '';
    }
  }

  addNewProduct() {
    this.product.push({
      product_id: '',
      price: 0,
      qty: 0,
      free_with: '',
      promotion_discount: 0,
      promotion_id: ''
    });
  }

  deleteProduct(index: number) {
    this.product.splice(index, 1);
    this.freeProduct.splice(index, 1);
    this.productPromotionalPrice.splice(index, 1);
    this.totalAmount();
    this.productPriceTooltip = '';
  }

  @Watch('product.product_id')
  public async getPrice(selectedProduct: any, row: any) {
    let isProductExist = false;
    row.price = 0;
    row.qty = 0;
    row.amount = 0;
    this.totalAmount();
    this.productPriceTooltip = '';
    let countThisProduct = 0;
    this.product.forEach((item: any) => {
      if (item.product_id === selectedProduct) {
        countThisProduct++;
      }
    });
    if (countThisProduct >= 2) {
      this.notification("error", "The product already selected, choose others", "warning");
      row.product_id = "";
    } else {
      await this.$http.get(this.$api(`/products/${selectedProduct}?with=prices.priceable`)).then(response => {
        row.fullName = response.data.product.full_name;
        row.product_id = parseInt(selectedProduct);
        response.data.product?.prices.forEach((productPrice: any) => {
          if (!(productPrice.priceable_id === (productPrice.priceable_type == 'depttype' ? this.buyerDepartment.department_type_id : this.buyerDepartment.id))) {
            row.price = 0;
          } else {
            row.price = productPrice.price;
          }
        });
      })
      if (row.price == 0) {
        this.notification("error", "The product has no price, choose others", "warning");
        row.fullName = "";
        row.product_id = "";
      }
    }
  }

  createRequisition() {
    this.$http
      .post(this.$api('/requisitions'), {
        buyer_id: this.form.departmentId,
        seller_id: this.form.sellerId,
        expected_delivery_date: this.form.expectedDeliveryDate,
        amount: this.amount,
        discount: 0,
        promotion_discount: this.form.promotionalDiscount,
        products: this.product
      })
      .then(response => {
        if (this.salesReturns[0].product_id != '') {
          this.$http.post(this.$api('/sales-returns'), {
            order_id: response.data.requisition.id,
            buyer_id: this.form.departmentId,
            seller_id: this.form.sellerId,
            from_delivery: 0,
            products: this.salesReturns
          });
        }
        this.$emit('created', response.data.requisition);
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success'
        });
        (this.amount = 0),
          (this.form.departmentId = ''),
          (this.form.sellerId = ''),
          (this.form.discount = 0),
          (this.promotionalDiscount = 0),
          (this.form.expectedDeliveryDate = ''),
          (this.form.paid = ''),
          (this.form.type = 'product'),
          (this.form.productableId = ''),
          (this.amount = 0);
        this.disableBtn = false;
        this.loading = false;
        this.$emit('update:product', [
          {
            product_id: '',
            price: '',
            qty: '',
            free_with: '',
            promotion_discount: 0,
            promotion_id: ''
          }
        ]);
        this.$emit('update:salesRetrun', [
          {
            product_id: '',
            qty: '',
            amount: '',
            paid: 0,
            return_rate: 0,
            reason: ''
          }
        ]);
        this.$nextTick(() => {
          if (this.$refs.formValidator) {
            (this.$refs.formValidator as HTMLFormElement).reset();
          }
        });
      })
      .catch(error => {
        if (error.response.status === 409) {
          this.$notify({
            title: 'Warning',
            message: error.response.data.message,
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'warning'
          });
        }
        if (error.response.data.errors.expected_delivery_date) {
          this.notification('Warning', error.response.data.errors.expected_delivery_date[0], 'warning');
        }
        this.disableBtn = false;
        this.loading = false;
        this.productPriceTooltip = error.response.data.errors['products.0.product_id'][0];
        (this.$refs.formValidator as Vue & {
          setErrors: (errors: []) => any;
        }).setErrors(error.response.data.errors);
      });
  }

  notification(title: string, message: string, type: 'success' | 'warning' | 'info' | 'error' | undefined) {
    this.$notify({
      title: title,
      message: message,
      duration: 3000,
      iconClass: 'ni ni-bell-55',
      type: type
    });
  }

  updateRequisition() {
    this.disableBtn = true;
    this.loading = true;
    if (this.isDelivery) {
      this.$http
        .post(this.$api(`/orders/${this.currentEntity.order_id}/deliveries`), {
          buyer_id: this.form.departmentId,
          seller_id: this.form.sellerId,
          amount: this.amount,
          discount: 0,
          promotion_discount: 0,
          products: this.product
        })
        .then(response => {
          (this.amount = 0),
            (this.form.departmentId = ''),
            (this.form.sellerId = ''),
            (this.form.discount = 0),
            (this.promotionalDiscount = 0),
            (this.form.paid = ''),
            (this.form.type = 'product'),
            (this.form.productableId = '');
          this.disableBtn = false;
          this.loading = false;
          this.$emit('created', response.data.delivery);
          this.$notify({
            title: 'Success',
            message: 'Successfully Created',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
        })
        .catch(error => {
          this.disableBtn = false;
          this.loading = false;
          this.productPriceTooltip = error.response.data.errors['products.0.product_id'][0];
          (this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }).setErrors(error.response.data.errors);
        });
    } else {
      this.$http
        .post(
          this.$api(`/${this.isInvoice ? 'invoices' : 'orders'}/${this.isInvoice ? this.currentEntity.id : this.currentEntity.order_id}/revisions`),
          {
            buyer_id: this.form.departmentId,
            seller_id: this.form.sellerId,
            expected_delivery_date: this.isInvoice ? '' : this.form.expectedDeliveryDate,
            amount: this.amount,
            discount: 0,
            promotion_discount: 0,
            products: this.product
          }
        )
        .then(response => {
          if (this.currentEntity.sales_return) {
            this.$http
              .patch(this.$api(`/sales-returns/${this.currentEntity.sales_return.id}`), {
                order_id: this.currentEntity.order_id,
                buyer_id: this.form.departmentId,
                seller_id: this.form.sellerId,
                from_delivery: 0,
                products: this.salesReturns
              })
              .then(resposne => {
              })
              .catch(error => {
                this.disableBtn = false;
                this.loading = false;
                this.productPriceTooltip = error.response.data.errors['products.0.product_id'][0];
                (this.$refs.formValidator as Vue & {
                  setErrors: (errors: []) => any;
                }).setErrors(error.response.data.errors);
              });
          } else if (!this.isInvoice) {
            this.$http
              .post(this.$api('/sales-returns'), {
                order_id: this.currentEntity.order_id,
                buyer_id: this.form.departmentId,
                seller_id: this.form.sellerId,
                from_delivery: 0,
                products: this.salesReturns
              })
              .then(resposne => {
              })
              .catch(error => {
                this.disableBtn = false;
                this.loading = false;
                (this.$refs.formValidator as Vue & {
                  setErrors: (errors: []) => any;
                }).setErrors(error.response.data.errors);
              });
          }
          this.$http
            .get(
              this.$api(
                `/${this.isInvoice ? 'invoices' : 'orders'}/${
                  this.isInvoice ? response.data.revision.invoice_id : response.data.revision.order_id
                }/revisions`
              )
            )
            .then(responseRevision => {
              this.$emit('updated', responseRevision.data.revisions[0]);
            });

          this.$notify({
            title: 'Success',
            message: 'Successfully Created',
            duration: 5000,
            iconClass: 'ni ni-bell-55',
            type: 'success'
          });
          (this.amount = 0),
            (this.form.departmentId = ''),
            (this.form.sellerId = ''),
            (this.form.discount = 0),
            (this.promotionalDiscount = 0),
            (this.form.expectedDeliveryDate = ''),
            (this.form.paid = ''),
            (this.form.type = 'product'),
            (this.form.productableId = '');
          this.disableBtn = false;
          this.loading = false;
          this.$emit('update:product', [
            {
              product_id: '',
              price: '',
              qty: '',
              free_with: '',
              promotion_discount: 0,
              promotion_id: ''
            }
          ]);
          this.$emit('update:salesRetrun', [
            {
              product_id: '',
              qty: '',
              amount: '',
              paid: 0,
              reason: ''
            }
          ]);
          this.$nextTick(() => {
            (this.$refs.formValidator as HTMLFormElement).reset();
          });
        })
        .catch(error => {
          this.disableBtn = false;
          this.loading = false;
          this.productPriceTooltip = error.response.data.errors['products.0.product_id'][0];
          (this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }).setErrors(error.response.data.errors);
        });
    }
  }

  save() {
    if (this.isUpdate || this.isInvoice) {
      this.updateRequisition();
    } else {
      this.createRequisition();
    }
  }

  fetchProducts() {
    this.$http.get(this.$api('/products')).then(response => {
      this.fullProductList = response.data.products;
    });
  }

  fetchDepartment() {
    this.$http.get(this.$api('/departments')).then(response => {
      this.departmentLists = response.data.departments;
    });
  }

  @Watch('isActive')
  callAPI(newVal: boolean) {
    if (newVal) {
      this.fetchProducts();
      this.fetchDepartment();
    }
  }

  close() {
    this.$nextTick(() => {
      (this.$refs.formValidator as HTMLFormElement).reset();
    });
    this.amount = 0;
    this.productPromotionalPrice = [];
    this.netTotalAmount = 0;
    this.salesReturn = false;
    this.$emit('update:isActive', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:isInvoice', false);
    this.$emit('update:updateAmountCount', false);
    this.$emit('update:form', {
      departmentId: '',
      sellerId: '',
      discount: 0,
      expectedDeliveryDate: '',
      paid: '',
      type: 'product',
      productableId: '',
      badgeDiscount: 0
    });
    this.$emit('update:product', [
      {
        product_id: '',
        price: 0,
        qty: 0,
        free_with: '',
        promotion_discount: 0,
        promotion_id: ''
      }
    ]);
    this.$emit('update:salesReturns', [
      {
        product_id: '',
        qty: '',
        amount: '',
        return_rate: 0,
        paid: 0,
        reason: ''
      }
    ]);
    this.disableBtn = false;
    this.productPriceTooltip = '';
  }
}
