





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Role from '@/intefaces/Role';
import Permission from '@/intefaces/Permission';
import PermissionModal from '@/views/user/permission-grouping/PermissionModal.vue';

@Component({
  name: 'CreateRole',
  components: {
    PermissionModal,
  },
})
export default class CreateRole extends Vue {
  @Prop() newRole!: boolean;
  @Prop() isUpdate!: boolean;
  @Prop() isForSetupWizard!: boolean;
  @Prop() currentRole!: any;
  @Prop({
    default: () => ({
      name: '',
      label: '',
      permissions: [],
    }),
  })
  form!: {
    name: string;
    label: string;
    permissions: any;
  };
  private is_remove_permission = false;
  private permissions: Permission[] = [];
  private disableBtn = false;
  private isActivePermission: boolean = false;

  updatePermission(event: any) {
    if (this.isUpdate) {
      if (event[0].indeterminate && event[0].checked.id) {
        event[1].forEach((permission: any) => {
          this.$http.post(this.$api(`/roles/${this.currentRole.id}/permissions`), {permission_id: permission});
        });
      } else if (!event[0].indeterminate) {
        event[1].forEach((permission: any) => {
          this.$http.delete(this.$api(`/roles/${this.currentRole.id}/permissions/${permission}`));
        });
      }
    }
  }

  createRole() {
    this.disableBtn = true;
    this.$http
      .post(this.$api('/roles'), this.form)
      .then((response) => {
        this.form.permissions.length &&
        this.form.permissions.forEach((permission: any) => {
          this.$http.post(this.$api(`/roles/${response.data.role.id}/permissions`), {permission_id: permission});
        });
        this.$notify({
          title: 'Success',
          message: 'Successfully Created',
          duration: 5000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.$http.get(this.$api(`/roles/${response.data.role.id}`)).then((responseRole) => {
          this.afterCreated(responseRole.data.role);
        });
        this.form.label = '';
        this.form.name = '';
        this.form.permissions = [];
        this.disableBtn = false;
      })
      .catch((error) => {
        this.disableBtn = false;
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
      });
  }

  async afterCreated(response: any) {
    this.$emit('created', response);
  }

  updateRole() {
    this.disableBtn = true;
    this.$http
      .patch(this.$api(`/roles/${this.currentRole.id}`), this.form)
      .then((response) => {
        this.$notify({
          title: 'Success',
          message: 'Successfully Updated',
          duration: 3000,
          iconClass: 'ni ni-bell-55',
          type: 'success',
        });
        this.$emit('updated', response.data.role);
        this.form.label = '';
        this.form.name = '';
        this.form.permissions = [];
        this.disableBtn = false;
      })
      .catch((error) => {
        this.disableBtn = false;
        (
          this.$refs.formValidator as Vue & {
            setErrors: (errors: []) => any;
          }
        ).setErrors(error.response.data.errors);
      });
  }

  checkedPermissions(event: any) {
    this.form.permissions = event;
  }

  save() {
    this.isUpdate ? this.updateRole() : this.createRole();
  }

  fetchPermission() {
    this.$http.get(this.$api('/permissions')).then((response) => {
      this.permissions = response.data.permissions;
    });
  }

  created() {
    this.fetchPermission();
  }

  close() {
    this.$parent.$emit('update:newRole', false);
    this.$emit('update:newRole', false);
    this.$emit('update:isUpdate', false);
    this.$emit('update:form', {name: '', label: '', permissions: []});
    this.$emit('onCloseSetRole');
  }
}
