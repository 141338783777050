



























































































































































































































import {
  Vue,
  Component,
  Mixins,
  Watch
} from 'vue-property-decorator';
import Department from '@/intefaces/Department';
import CreateDepartmentForm from './CreateDepartmentForm.vue';
import CreateBulkDepartments from './CreateBulkDepartments.vue';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
  Tooltip,
  Row,
  Col,
  Tag,
  MessageBox
} from 'element-ui';
import DepartmentFilters from '@/views/department/DepartmentFilters.vue';
import DepartmentFilterDrawer from '@/views/department/DeparmentFilterDrawer.vue';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import GIFModal from '@/components/tour/GIFModal.vue';
import TaxonomyMixin from "@/mixins/TaxonomyComponent";
import {
  AxiosResponse
} from "axios";
import {
  Permission as PermissionEnum
} from '@/enums/Permission'
import User from "@/intefaces/User";
import ViewDepartmentUser from "@/views/department/ViewDepartmentUser.vue";
import AsyncImage from "@/components/AsyncImage.vue";
import StaffList from "@/views/department/StaffList.vue";
import MultiSelectRow from '@/mixins/MultiSelectRow';
import DropdownField from "@/mixins/DropdownField";
import LazyDropdown from "@/components/LazyDropdown.vue";
import TerritoryAutocomplete from "@/components/resources-autocomplete/TerritoryAutocomplete.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import { QueryBuilder } from "@/classes/QueryBuilder";
import TenantModulesMixin from '@/mixins/HasTenantModules';

@Component({
  components: {
    DepartmentLazydropdown,
    TerritoryAutocomplete,
    LazyDropdown,
    AsyncImage,
    StaffList,
    DepartmentFilters,
    CreateDepartmentForm,
    CreateBulkDepartments,
    DepartmentFilterDrawer,
    GIFModal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Row.name]: Row,
    [Col.name]: Col,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tag.name]: Tag,
    ViewDepartmentUser,
  }
})
export default class DepartmentList extends Mixins(PaginationComponentMixin, MultiSelectRow, TaxonomyMixin, DropdownField, TenantModulesMixin) {
  private departments: Department[] = [];
  private showBulkDepartmentsModal: boolean = false;
  private showDeleteModal: boolean = false;
  private isActive: boolean = false;
  private isUpdate: boolean = false;
  private isFilterModelActive: boolean = false;
  private showGIF: boolean = false;
  private departmentStaff: User[] = [];
  private isActiveStaff: boolean = false;
  private currentEntity: any = [];
  public loading: boolean = false;
  public downloading: boolean = false;
  public filterQuery: string = '';
  private form: any = {
    address: '',
    created_by: null,
    lat: null,
    long: null,
    owner_name: '',
    phone_no: '',
    photo: '',
    photo_url: '',
    name: '',
    code: '',
    department_type_id: null,
    territory_id: null,
    territory: null,
    credit_limit: 0,
    outstanding_balance: 0,
    badge_id: null,
    supplier: null,
    supplier_id: null,
    users: null,
    is_visitable: true,
    staff: [],
    department_category_id: null,
    department_location_type_id: null,
    owner_dob: null,
    custom_code: null,
  };
  private demoTourEntity: string = 'department';
  private GIFs: Array<any> = [{
    label: 'Create a department',
    url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
  },
    {
      label: 'Update a department',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a department',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of department';
  private valueTypeList = [{
    'label': 'Shift DB',
    'name': 'db'
  },
    {
      'label': 'Shift Route',
      'name': 'route'
    }
  ]
  private bulkActionFilter: any = {
    type: '',
    supplier_id: '',
    territory_id: '',
  };

  get permissionEnum() {
    return PermissionEnum;
  }

  created() {
    this.checkTenantModule('order_include_lot');
    this.fetchDepartments();
  }

  entityCreated(department: Department) {
    this.departments.unshift(department);
  }

  async onClickDownloadBulkFormat() {
    this.loading = true;
    try {
      let {data, headers} = await this.$http.get(this.$api('/bulk-departments-sample'), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({title: 'Something wants wrong!', type: 'warning', message: 'Unable to download reports.'});
    } finally {
      this.loading = false;
    }
  }

  onEdit(row: Department) {
    this.isUpdate = true;
    this.currentEntity = row;
    let userList: any = [];
    if (this.currentEntity.users) {
      this.currentEntity.users.forEach((user: User) => {
        userList.push(user.id);
      });
    }
    this.form = {
      id: this.currentEntity.id,
      address: this.currentEntity.address,
      created_by: Number(this.currentEntity.created_by),
      lat: this.currentEntity.lat,
      long: this.currentEntity.long,
      owner_name: this.currentEntity.owner_name,
      phone_no: this.currentEntity.phone_no,
      photo: this.currentEntity.photo,
      photo_url: this.currentEntity.photo_url,
      name: this.currentEntity.name,
      code: this.currentEntity.code,
      department_type_id: Number(this.currentEntity.department_type_id),
      territory_id: Number(this.currentEntity.territory_id),
      territory: this.currentEntity.territory,
      credit_limit: this.currentEntity.credit_limit,
      outstanding_balance: this.currentEntity.outstanding_balance,
      badge_id: this.currentEntity.badge_id ? Number(this.currentEntity.badge_id) : null,
      supplier_id: Number(this.currentEntity.supplier_id),
      supplier: this.currentEntity.supplier,
      staffs: userList,
      is_visitable: Boolean(this.currentEntity.is_visitable),
      department_category_id: this.currentEntity.department_category_id,
      department_location_type_id: this.currentEntity.department_location_type_id,
      owner_dob: this.currentEntity.owner_dob,
      custom_code: this.currentEntity.custom_code,
    };
    this.isActive = true;
  }

  entityUpdated(department: Department) {
    this.departments.splice(
      this.departments.findIndex(value => value.id == department.id),
      1,
      department
    );
  }

  @Watch('pagination.currentPage')
  fetchDepartments() {
    this.loading = true
    this.$http.get(this.$api(`/departments?page=${this.pagination.currentPage}${this.filterQuery ?
      '&' + this.filterQuery : ''}`))
      .then((response: AxiosResponse) => {
        this.departments = response.data.departments;
        this.setPagination(response.data);
      })
      .finally(() => this.loading = false);
  }

  @Watch('filterQuery')
  onChangeFilterQuery() {
    this.pagination.currentPage = 1
    this.fetchDepartments();
  }

  deleteEntity(): void {
    this.$http.delete(this.$api(`/departments/${this.currentEntity.id}`)).then(response => {
      this.showDeleteModal = false;
      this.departments.splice(
        this.departments.findIndex(value => value.id == this.currentEntity.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 5000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  async download() {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api(`/department-bulk-download?download${this.filterQuery ? '&' + this.filterQuery : ''}`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }

  async handleBulkFilterUpdate() {
    if (this.handleBulkFilterValidation()) {
      const confirmed = await this.showConfirmationDialog();
      if (confirmed) {
        await this.handleBulkFilterRequest();
      }
    }
  }

  async handleBulkFilterRequest() {
    this.loading = true;
    try {
      this.$notify({
        title: 'Information',
        message: "Please Wait Some time to complete the operation",
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'info'
      });
      const response = await this.$http.post(this.$api(`/departments/bulk-db-or-route-shift?${this.filterQuery}`),
        this.allItemSelected ? {
          ids: '',
          type: this.bulkActionFilter.type,
          supplier_id: this.bulkActionFilter.supplier_id,
          territory_id: this.bulkActionFilter.territory_id,
        } : {
          ids: this.selectedRows.map(item => item.id),
          type: this.bulkActionFilter.type,
          supplier_id: this.bulkActionFilter.supplier_id,
          territory_id: this.bulkActionFilter.territory_id,
        });

      this.$notify({
        title: 'Success',
        message: response.data.message,
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });

    } catch (error) {
      this.$notify({
        title: 'Error',
        message: error.response.data.message || 'Failed to update departments',
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'warning'
      });
    } finally {
      this.loading = false;
      this.resetBulkActionFilter();
      this.fetchDepartments();
    }
  }

  async showConfirmationDialog() {
    try {
      await MessageBox.alert('<span style="font-size: 14px">' +
        'Please review the information carefully. Once this data is saved,' +
        ' it cannot be retrieved from the system.' +
        '<br> <br>System admin may help to adjust this data if made any mistakes.</span>', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true
      });
      return true;
    } catch {
      return false;
    }
  }

  resetBulkActionFilter() {
    this.bulkActionFilter = {
      type: '',
      supplier_id: '',
      territory_id: '',
    };
    this.selectedRows = [];
  }

  handleBulkFilterValidation() {
    if (!this.bulkActionFilter.type) {
      this.showNotification('Please select an Action Type');
      return false;
    } else if (this.bulkActionFilter.type === 'db' && !this.bulkActionFilter.supplier_id) {
      this.showNotification('Please select the Supplier');
      return false;
    } else if (this.bulkActionFilter.type === 'route' && !this.bulkActionFilter.territory_id) {
      this.showNotification('Please select the Territory');
      return false;
    } else if (this.selectedRows.length === 0 || (this.bulkActionFilter.type === 'db' && !this.bulkActionFilter.supplier_id) || (this.bulkActionFilter.type === 'route' && !this.bulkActionFilter.territory_id)) {
      this.showNotification('Please select the Departments');
      return false;
    }
    return true;
  }

  showNotification(message: string) {
    this.$message({
      showClose: true,
      message: message,
      type: 'warning'
    });
  }
}
