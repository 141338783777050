










































































































import {
  Component,
  Mixins,
  Vue,
  Watch,
} from 'vue-property-decorator';

import DateRange from "@/components/DateRange.vue";
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import ProductBrandLazydropdown from "@/components/lazyDropdown/ProductBrandLazydropdown.vue";
import TerritoryTypeAutocomplete from "@/components/resources-autocomplete/TerritoryTypeAutocomplete.vue";
import TerritoryAutocomplete from "@/components/resources-autocomplete/TerritoryAutocomplete.vue";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import moment from "moment";
import ProductCategoryLazydropdown from "@/components/lazyDropdown/ProductCategoryLazydropdown.vue";
import BaseProductLazydropdown from "@/components/lazyDropdown/BaseProductLazyDropdown.vue";
import DepartmentCategoryLazyDropdown from "@/components/lazyDropdown/DepartmentCategoryLazyDropdown.vue";
import DepartmentLocationTypeLazyDropdown from "@/components/lazyDropdown/DepartmentLocationTypeLazyDropdown.vue";
import BadgeLazydropdown from "@/components/lazyDropdown/BadgeLazydropdown.vue";
import ChannelLazydropdown from "@/components/lazyDropdown/ChannelLazydropdown.vue";
import DepartmentLazydropdown from "@/components/lazyDropdown/DepartmentLazydropdown.vue";
import { Radio, RadioGroup, Cascader } from 'element-ui'
import TerritoryCascaderDropdown from "@/components/resources-autocomplete/TerritoryCascaderDropdown.vue";


@Component({
  components: {
    DepartmentLazydropdown,
    ChannelLazydropdown,
    TerritoryAutocomplete,
    TerritoryTypeAutocomplete,
    ProductBrandLazydropdown,
    UserLazydropdown,
    DateRange,
    ProductCategoryLazydropdown,
    BaseProductLazydropdown,
    DepartmentCategoryLazyDropdown,
    DepartmentLocationTypeLazyDropdown,
    BadgeLazydropdown,
    TerritoryCascaderDropdown,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    ElCascader: Cascader,
    components: {
      [Cascader.name]: Cascader,
    },


  }
})
export default class Outlet extends Mixins(PaginationComponentMixin, Vue) {
  public loading: boolean = false;
  public downloadloading: boolean = false;
  public maxDate: string = moment().subtract(1, "days").format('YYYY-MM-DD');
  public totalRecords: number = 0;
  public downloadbtn: boolean = false;
  public downloading: boolean = false;
  public query: any = {};
  public fileName = 'outlet-report-' + moment().format('YYYY-MM-DD') + '.xls';
  public searchMsg = false;

  public sortItems(arr: []) {
    return arr.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  public onClickGenerate() {
    if (this.query.range === '' || this.query.ut === '') {
      this.$notify({
        type: 'warning',
        message: 'Select the required field',
        title: 'Required!'
      });
    } else {
      this.search();
    }
  }

  async search() {
    try {
      this.loading = true;
      this.downloadbtn = false;
      let {
        data
      } = await this.$http.get(this.$api3(`/search-outlet-report${this.$toQueryString(this.query)}`));
      this.totalRecords = data.totalRecords;
      if (this.totalRecords >= 500000) {
        this.downloadbtn = false;
        this.searchMsg = true;
      } else {
        this.downloadbtn = true;
      }
    } catch (e) {
      let err_msg = [];
      if (e.response.data.errors.range) {
        err_msg.push(e.response.data.errors.range[0]);
      }
      if (e.response.data.errors.ut) {
        err_msg.push(e.response.data.errors.ut[0]);
      }
      err_msg.forEach(error => {
        this.$notify({
          type: 'warning',
          message: error,
          title: 'Validation error'
        });
      })
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async onClickDownload(type: string) {
    this.downloading = true;
    try {
      let {
        data,
        headers
      } = await this.$http.get(this.$api3(`/export-outlet-report${this.$toQueryString(this.query)}&download`), {
        responseType: 'arraybuffer'
      });
      const blob = await new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement("a");
      link.href = await URL.createObjectURL(blob);
      link.download = this.$getFileNameFromContentDisposition(headers);
      link.click();
    } catch (e) {
      this.$notify({
        title: 'Something wants wrong!',
        type: 'warning',
        message: 'Unable to download reports.',
      })
    } finally {
      this.downloading = false;
    }
  }
}
