













































import { Component, Vue } from 'vue-property-decorator';
import { Permission as PermissionEnum } from '@/enums/Permission';
import { Button } from 'element-ui';

@Component({
    components: {
        [Button.name]: Button
    }
})
export default class EcoReport extends Vue {
    private reports: any[] = [
        {
            name: 'Eco Report',
            tooltip: 'Eco Report.',
            permission: PermissionEnum.ECO_REPORT,
            path: 'eco-report'
        },
        {
            name: 'Eco Report Summary',
            tooltip: 'Eco Report Summary',
            permission: PermissionEnum.ECO_REPORT,
            path: 'eco-report-summary'
        }
    ];
}
