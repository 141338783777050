// @ts-ignore
import { RouteConfig } from 'vue-router';
// @ts-ignore
import Transaction from '@/views/transaction/Transaction.vue';
// @ts-ignore
import TransactionReport from '@/views/transaction/TransactionReport/TransactionReport.vue';
// @ts-ignore
import { Permission } from "@/enums/Permission";
import store from "@/store";
import Taxonomy from "@/intefaces/Taxonomy";
import TaxonomyJson from "@/assets/json/Taxonomy.json";
import { PermissionHelper } from "@/classes/PermissionHelper";
function getTaxonomy(label: any) {
    let currentLabel: any = '';
    let taxonomy = store.getters['Settings/getTaxonomyStore'];
    let taxonomyArray: Taxonomy[] = [];
    if (taxonomy) {
        taxonomyArray = typeof taxonomy == 'string' ? JSON.parse(taxonomy) : taxonomy;
    } else {
        taxonomyArray = TaxonomyJson;
    }
    taxonomyArray.forEach((taxonomy: any) => {
        if (Object.keys(taxonomy)[0] == label) {
            currentLabel = Object.values(taxonomy)[0]
        }
    });
    return currentLabel;
}
// Redirect to user's authorized (has permission) tab
function redirectToUserAuthorizedRoute() {
    return '/transaction/' + PermissionHelper([
        Permission.VIEW_TRANSACTION_REPORT,
    ], [
        {permission: Permission.VIEW_TRANSACTION_REPORT, name: 'transaction-report'},
    ]);
}

const TransactionRoutes: RouteConfig = {
    path: 'transaction',
    components: {dashboard: Transaction},
    name: 'transaction',
    redirect: to => {
        return redirectToUserAuthorizedRoute();
    },
    children: [
        {
            path: '/transaction/transaction-report',
            name: 'transaction-report',
            meta: {
                label: 'Transaction Report',
                icon: 'fa fa-cart-arrow-down',
                permission: Permission.VIEW_TRANSACTION_REPORT,
                breadCrumb: 'Transaction Report',
            },
            component: TransactionReport
        },
    ]
};
export default TransactionRoutes;
