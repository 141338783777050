



































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import Unit from '@/intefaces/Unit';
import UnitForm from "@/components/products/UnitForm.vue";
import GIFModal from '@/components/tour/GIFModal.vue';
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip} from 'element-ui';
import PaginationComponentMixin from '@/mixins/PaginationComponent';
import { Permission as PermissionEnum } from '@/enums/Permission'

@Component({
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    UnitForm,
    GIFModal
  }
})
export default class unitCategoryList extends Mixins(PaginationComponentMixin) {
  private unitCategories: Unit[] = [];
  private showDeleteModal = false;
  private isActive = false;
  private isUpdate = false;
  private currentUnitCategory: any = [];
  private showGIF: boolean = false;
  private form: { name: String } = {
    name: ''
  };
  private demoTourEntity: string = 'unitCategory';
  private GIFs: Array<any> = [
    {
      label: 'Create a UnitCategory',
      url: 'https://www.youtube.com/embed/jNQXAC9IVRw'
    },
    {
      label: 'Update a UnitCategory',
      url: 'https://www.youtube.com/embed/WoM3wuI4sJQ'
    },
    {
      label: 'Delete a UnitCategory',
      url: 'https://www.youtube.com/embed/LeAltgu_pbM'
    }
  ];
  private demoTourTitle: string = 'A quick demo of unit';

  get permissionEnum () {
    return PermissionEnum;
  }

  onEdit(row: Unit) {
    this.isUpdate = true;
    this.currentUnitCategory = row;
    this.form = {
      name: this.currentUnitCategory.name
    };
    this.isActive = true;
  }

  deleteUnitCategory() {
    this.$http.delete(this.$api(`/units/${this.currentUnitCategory.id}`)).then(response => {
      this.showDeleteModal = false;
      this.unitCategories.splice(
        this.unitCategories.findIndex(unitCategories => unitCategories.id == this.currentUnitCategory.id),
        1
      );
      this.$notify({
        title: 'Success',
        message: 'Successfully Deleted',
        duration: 3000,
        iconClass: 'ni ni-bell-55',
        type: 'success'
      });
    });
  }

  unitCateogryCreated(unitCategory: Unit) {
    this.isActive = false;
    this.unitCategories.unshift(unitCategory);
  }

  unitCategoryUpdated(unit: Unit) {
    this.isUpdate = false;
    this.isActive = false;
    this.unitCategories.splice(
      this.unitCategories.findIndex(unitCategories => unitCategories.id == unit.id),
      1,
      unit
    );
  }

  @Watch('pagination.currentPage')
  fetchUnitCategories() {
    this.loading = true;
    this.$http.get(this.$api(`/units?page=${this.pagination.currentPage}`)).then(response => {
      this.unitCategories = response.data.units;
      this.setPagination(response.data);
      this.loading = false;
    });
  }

  created() {
    this.fetchUnitCategories();
  }

  unitMsg() {
    this.$notify({
      title: 'Warning',
      message: 'You cannot add more than 3 units',
      duration: 3000,
      type: 'warning'
    });
  }
}
