<template>
<div>
    <div ref="mapContainer" class="map-container" :style="containerStyle"></div>
    <div style="margin-top: 10px;">
        <base-button type="info" size="mini" @click="changeStyle('default')">Default</base-button>
        <base-button type="info" size="mini" @click="changeStyle('navigation_night')">Navigation Night</base-button>
        <base-button type="info" size="mini" @click="changeStyle('dark')">Dark</base-button>
        <base-button type="info" size="mini" @click="changeStyle('streets')">Streets</base-button>
    </div>
</div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import moment from 'moment';
// @ts-ignore
import {
    parse
} from 'geojson';

export default {
    name: 'MapboxMap',
    props: {
        width: {
            type: String,
            default: '100%',
        },
        zoom: {
            type: Number,
            default: 5.5,
        },
        height: {
            type: String,
            default: '100vh',
        },
        data: {
            type: Array,
            default: () => {
                return [];
            },
        }
    },
    data() {
        return {
            map: null,
            containerStyle: {
                position: 'relative',
                width: this.width,
                height: this.height,
            },
            mapStyle: process.env.VUE_APP_HEAT_MAP_STYLE,
            geoJsonSource: {},
            colors: ["#5A1846", "#900C3F", "#C70039", "#E3611C", "#F1920E", "#FFC300"],
            multiColors: [],
        };
    },
    watch: {
        mapStyle(newValue, oldValue) {
            this.initializeMap();
        },
        data(newValue) {
            this.geoJsonSource = {
                type: 'geojson',
                data: parse(newValue, {
                    Point: ['lat', 'long']
                }),
            };
            // this.uniqueData(newValue).forEach((item, index) => {
            //     const chosenNumber = Math.floor(Math.random() * this.colors.length);
            //     this.multiColors.push(item);
            //     this.multiColors.push(this.colors[chosenNumber]);
            // });
            this.initializeMap();
        }
    },
    mounted() {
        mapboxgl.accessToken = process.env.VUE_APP_HEAT_MAP_ACCESS_TOKEN;
        this.initializeMap();
    },
    methods: {
        uniqueData(data) {
            return [...new Set(data.map(x => x.route_name))]
        },
        initializeMap() {
            const map = new mapboxgl.Map({
                container: this.$refs.mapContainer,
                // style: process.env.VUE_APP_HEAT_MAP_STYLE,
                style: this.mapStyle,
                center: [90.3563, 23.6850],
                zoom: this.zoom,
                projection: 'globe'
            });

            if (this.geoJsonSource.data) {
                this.onload(map, this.geoJsonSource.data, this.multiColors);
            }
            // Add any additional map setup or customization here

            // // Add geocoder search control
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
            });
            map.addControl(geocoder, 'top-left');

            // // Add map controls
            map.addControl(new mapboxgl.FullscreenControl());
            map.addControl(new mapboxgl.GeolocateControl());
            map.addControl(new mapboxgl.NavigationControl());
            map.on('style.load', () => {
                map.setFog({
                    color: 'rgb(186, 210, 235)', // Lower atmosphere
                    'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
                    'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
                    'space-color': 'rgb(11, 11, 25)', // Background color
                    'star-intensity': 0.6 // Background star brightness (default 0.35 at low zoooms )
                });
            });
        },
        onload(map, data, multiColors) {
            map.on('load', function () {
                map.addSource('map-source', {
                    'type': 'geojson',
                    'data': data
                });
                // Add a custom layer using the custom source
                map.addLayer({
                    'id': 'map-layer',
                    'type': 'circle',
                    'source': 'map-source',
                    paint: {
                        'circle-radius': [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            // Define the zoom levels and corresponding circle radius
                            0, 1, // Zoom level 0, circle radius 1
                            5, 2, // Zoom level 5, circle radius 2
                            10, 3, // Zoom level 10, circle radius 3
                            12, 5, // Zoom level 12, circle radius 5
                            15, 30, // Zoom level 15, circle radius 30
                        ],
                        'circle-color': "#900C3F",
                        // 'circle-color': [
                        //     'match',
                        //     ["get", "route_name"],
                        //     ...multiColors,
                        //     "#900C3F"
                        // ],

                        // 'circle-color': [
                        //   "interpolate",
                        //   ["linear"],
                        //   // 'step',
                        //   ["get", "outlet_contact"],
                        //     0, "#5A1846",
                        //     100, "#900C3F",
                        //     200, "#C70039",
                        //     300, "#E3611C",
                        //     400, "#F1920E",
                        //     500, "#FFC300",
                        // ],
                        'circle-opacity': 0.7,
                    },
                });

                const popup = new mapboxgl.Popup({
                    anchor: 'bottom', // This sets the anchor to the bottom of the marker
                    closeButton: false, // Disable the close button on the popup
                });

                // Change the cursor to a pointer when hovering over the custom layer
                map.on('mouseenter', 'map-layer', function (e) {
                    map.getCanvas().style.cursor = 'pointer';
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const feature = e.features[0].properties;
                    // Create popup content
                    const popupContent = `<table class="table table_map">
              <tbody>
              <tr class="row_tr">
                <td class="row__name">Outlet Code</td>
                <td class="row__value"><span>${feature.outlet_code}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">Outlet Name</td>
                <td class="row__value"><span>${feature.outlet_name}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">Outlet Contact</td>
                <td class="row__value"><span>${feature.outlet_contact}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">DSR Code</td>
                <td class="row__value"><span>${feature.dsr_code}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">DSR Name</td>
                <td class="row__value"><span>${feature.dsr_name}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">DSR Contact</td>
                <td class="row__value"><span>${feature.dsr_contact}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">Route Name</td>
                <td class="row__value"><span>${feature.route_name}</span></td>
              </tr>
              <tr class="row_tr">
                <td class="row__name">Outlet Created</td>
                <td class="row__value"><span>${moment(feature.created_at).format('ll')}</span></td>
              </tr>
              </tbody>
            </table>`;
                    popup.setLngLat(coordinates)
                        .setHTML(popupContent)
                        .addTo(map)
                        .setMaxWidth('100');
                });

                // Change it back to a default cursor when no longer hovering
                map.on('mouseleave', 'map-layer', function () {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            });
        },
        changeStyle(type) {
            if (type === 'navigation_night') {
                this.mapStyle = 'mapbox://styles/mapbox/navigation-night-v1';
            } else if (type === 'dark') {
                this.mapStyle = 'mapbox://styles/mapbox/dark-v11';
            } else if (type === 'streets') {
                this.mapStyle = 'mapbox://styles/mapbox/streets-v12';
            } else {
                this.mapStyle = process.env.VUE_APP_HEAT_MAP_STYLE;
            }
        }
    },
};
</script>

<style>
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-attrib {
    display: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-bottom-color: rgb(41, 50, 60) !important;
    border-top-color: rgb(41, 50, 60) !important;
}

.mapboxgl-popup-content {
    background: rgb(41, 50, 60) !important;
    width: 100%;
}
</style>
