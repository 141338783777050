











































































































































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { PROPOSAL_STATUS, ProposedRoutePlanEntity } from "@/intefaces/ProposedRoutePlanModel";
import { Button, Dialog, Tag } from "element-ui";
import PaginationComponentMixin from "@/mixins/PaginationComponent";
import MultiSelectRow from "@/mixins/MultiSelectRow";
import RoutePlanProposalLazyDropdown from "@/components/lazyDropdown/RoutePlanProposalLazyDropdown.vue";
import { Permission as PermissionEnum } from '@/enums/Permission'
import UserLazydropdown from "@/components/lazyDropdown/UserLazydropdown.vue";
import FilterDrawerComponent from '@/components/FilterDrawerComponent.vue';

@Component({
  components: {
    UserLazydropdown,
    RoutePlanProposalLazyDropdown,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    FilterDrawerComponent
  }
})

export default class ProposedRoutePlan extends Mixins(PaginationComponentMixin, MultiSelectRow) {

  private routes: ProposedRoutePlanEntity[] = [];
  private isFilterModelActive: boolean = false;
  private filter: any = {
    search: null,
    status: null
  };
  private dialogVisible: boolean = false
  private remarks: string = '';
  private routeColumnTitle: string = 'route';
  private statuses: Array<string> = ['pending', 'accepted', 'rejected'];


  get permissionEnum() {
    return PermissionEnum;
  }

  /*   private dummyRoute: ProposedRoutePlanEntity= {
       routePlans: [
         {
           id: 1,
           name: 'Uttara'
         },
         {
           id: 2,
           name: 'Mirpur'
         },

         {
           id: 3,
           name: 'Lala'
         },

       ],
       assigned_to: 1,
       created_at: "2020-02-25",
       created_by: 1,
       creator: [{
         id: 1,
         name: "Mubir m C"
       }],
       days: ['sun', 'mon'],
       proposed_date:  "2020-03-25",
       startingPoint: "tareq store",
       proposed_by: "tareq",
       reporting_time: "09.00",
       status: false, //means pending
       timespan: "weekly",
       tour_dates: undefined,
       updated_at:  "09.00",
       id: 1,
       showPopOver: false

     };
     private dummyRoute2: ProposedRoutePlanEntity= {
       routePlans: [
         {
           id: 1,
           name: 'Uttara'
         },
         {
           id: 2,
           name: 'Mirpur'
         },

         {
           id: 3,
           name: 'Lala'
         },

       ],
       assigned_to: 1,
       created_at: "2020-02-25",
       created_by: 1,
       creator: [{
         id: 1,
         name: "Mubir m C"
       }],
       days: ['sun', 'mon'],
       proposed_date:  "2020-03-25",
       startingPoint: "tareq store",
       proposed_by: "tareq",
       reporting_time: "09.00",
       status: false, //means pending
       timespan: "weekly",
       tour_dates: undefined,
       updated_at:  "09.00",
       id: 2,
       showPopOver: false

     };
 */

  created() {
    //todo: delete dummy data
    this.routes.push(
      //  this.dummyRoute,
      // this.dummyRoute2,

    );

    this.getTerritoryType();
    this.fetchProposedVisitPlan();
  }


  onSelectAction(row: ProposedRoutePlanEntity, status: string) {
    if (row != null) {
      this.submitProposal([row.id], status, row.remark).then(
        value => {
          this.$notify({
            title: 'Success!',
            message: 'Your operation has been succeed',
          });
          this.updateRoute(new ProposedRoutePlanEntity(
            row.created_by,
            row.created_at,
            row.created_by,
            row.creator,
            row.days,
            row.id,
            row.code,
            row.creator?.name,
            row.created_at,
            row.reporting_time,
            row.routePlans,
            false,
            row.startingPoint,
            value[0].status == "accepted" ? PROPOSAL_STATUS.accepted : PROPOSAL_STATUS.rejected,
            row.timespan,
            row.tour_dates,
            row.updated_at,
            ''
          ));

        }
      ).catch(
        reason => {
          this.$notify({
            title: 'Failed!',
            message: 'Your operation has been failed',
            type: "warning"
          });
        }
      )

    } else {

      this.submitProposal(this.allItemSelected ? [] : this.selectedRows.map((e) => e.id), status, this.remarks).then(
        value => {
          this.$notify({
            title: 'Success!',
            message: 'Your operation has been succeed',
          });
          this.fetchProposedVisitPlan();
        }
      ).catch(
        reason => {
          this.$notify({
            title: 'Failed!',
            message: 'Your operation has been failed',
            type: "warning"

          });
        }
      )
    }


  }


  async getTerritoryType() {
    this.routeColumnTitle = 'territory';
    let {data} = await this.$http.get(
      this.$api(`/territory-types?   has_outlet=1 `)
    );
    this.routeColumnTitle = data?.territoryTypes[0]?.name ?? 'Territories'
  }


  @Watch('pagination.currentPage')
  async fetchProposedVisitPlan() {
    //todo: change url right in here

    this.loading = true;
    let {data} = await this.$http.get(
      this.$api(`/propose-route-plans?page=${this.pagination.currentPage}&status=${this.filter.status ?? ''}&created_by=${this.filter.search ?? ''}`)
    );
    this.setPagination(data);
    this.routes = data.proposeRoutePlans.map((e: any) => new ProposedRoutePlanEntity(
      e.created_by,
      e.created_at,
      e.created_by,
      {id: e.creator.id, name: e.creator.name},
      e.days,
      e.id,
      e.code,
      e.creator?.name,
      e.created_at,
      e.reporting_time,
      e.territories.map((e: any) =>
        Object.create({'id': e.territory_id, 'name': e.territory_name})
      ),
      false,
      e.outlets.name,
      this.checkStatus(e.status),
      e.timespan,
      e.route_plan_dates,
      e.updated_at,
      ''
    ));
    this.loading = false;
  }


  async submitProposal(routes: Array<number>, status: string, remarks: string) {
    let response = await this.$http.post(
      this.$api(`/approved-proposed-route-plans`), {
        "proposed_route_plan_id": routes,
        "remarks": remarks,
        "status": status,
        "user_id": this.filter.search
      }
    );
    return response.data.approvedProposeRoutePlan;


  }

  resetBtn() {
    this.filter.search = null;
    this.filter.status = null;
    this.pagination.currentPage = 1;
    this.fetchProposedVisitPlan();

  }

  onFilterSubmit() {
  }

  updateRoute(updatedRoute: any) {
    this.routes.splice(
      this.routes.findIndex(route => route.id == updatedRoute.id),
      1,
      updatedRoute
    );
  }

  @Watch('filter', {immediate: true, deep: true})
  onChangeFilter() {
    this.pagination.currentPage = 1;
    this.fetchProposedVisitPlan();
  }

  checkStatus(status: string) {
    if (status === 'pending') {
      return PROPOSAL_STATUS.pending;
    } else {
      if (status === 'accepted') {
        return PROPOSAL_STATUS.accepted;
      } else {
        return PROPOSAL_STATUS.rejected;
      }
    }
  }


}
