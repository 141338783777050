import { RouteConfig } from 'vue-router';
// @ts-ignore
import Billing from "@/views/billing/index.vue";
// @ts-ignore
import ClientSubscription from "@/views/billing/Subscription.vue";
// @ts-ignore
import BillingList from "@/views/billing/billingList.vue";
// @ts-ignore
import ClientInvoice from "@/views/billing/Invoice.vue";
import { Permission } from "@/enums/Permission";

const billingRoutes: RouteConfig = {
  path: 'billing',
  name: 'billing',
  redirect: '/billing/billing-list',
  components: { dashboard: Billing },
  meta:{
    permission: Permission.MANAGE_BILLING,
  },
  children: [
    {
      path: 'billing-list',
      name: 'Billing List',
      meta:{
        label:'Billing List',
        icon:'fa fa-bookmark',
        permission: Permission.MANAGE_BILLING,
      },
      component: BillingList
    },{
      path: 'subscription',
      name: 'subscription',
      meta:{
        label:'Tenant.vue',
        icon:'fa fa-bookmark',
      },
      component: ClientSubscription
    },
    {
      path: 'invoice',
      name: 'invoice',
      meta:{
        label:'Invoice',
        icon:'fa fa-file',
      },
      component: ClientInvoice
    }
  ]
};

export default billingRoutes;
